import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import FiveChallengesModal from '../modals/FiveChallengesModal.js';
import LeadershipTypeModal from '../modals/LeadershipTypeModal.js';
import JvEngagementModal from '../modals/JvEngagementModal.js';
import GroToolModal from '../modals/GroToolModal.js';
import MinistryPhasesModal from '../modals/MinistryPhasesModal.js';
import PopupButtonDescription from '../components/PopupButtonDescription.js';
import RolesModal from '../modals/RolesModal.js';

// import assets
import icon_glasses from '../assets/icon_glasses.png';
import icon_expand from '../assets/icon_expand.png';

class DiagnosticTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedRow: "null", // disciple, leader, ministry, church
            openAfter: "",
            showModalFiveChallenges: false,
            prepareToShowModalFiveChallenges: false,
            showModalLeadershipType: false,
            prepareToShowModalLeadershipType: false,
            showModalGroToolModal: false,
            prepareToShowModalGroToolModal: false,
            showModalMinistryPhases: false,
            prepareToShowModalMinistryPhases: false,
            showJvEngagementModal: false,
            prepareToShowModalJvEngagement: false,
            showModalAddRole: false,
            prepareToShowModalAddRole: false,
            fiveChallengesLevel: "-", // D0
            groToolLevel: "-", // GRO:00
            leadershipTypeLevel: "-", // L0
            ministryLensLeveL: "-", // M0
            jvEngagementLevel: "-", // JVE:0
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
            //listOfDiagnosticLenses: ([{id:1, segm_id: 1, seq_id: 5, cont_id: 1, segm_cd: "dsc", seg_val: 5, seg_cd: "D5"}]),
            churchId: 0,
            mainContactId: 0,
            mainContactName: "",
        }
    }

    async componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });

        const { from } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(from == "MyDiscipling"){
            await this.setState({ openAfter: "myDiscipling" });
            this.openModal("FiveChallenges");
        }
    }

    loadData = async () => {
        const pathname = window.location.href;
        var contact_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(contact_id.includes("?")){ contact_id = contact_id.substring(0, contact_id.indexOf("?")); }

        // it does not look good if at every refresh it flashes to zeros
        /*this.setState({ fiveChallengesLevel: "D0" });
        this.setState({ groToolLevel: "GRO:0" });
        this.setState({ leadershipTypeLevel: "L0" });
        this.setState({ ministryLensLeveL: "M0" });
        this.setState({ jvEngagementLevel: "JVE:0" });*/

        if(Number.isInteger(Number(contact_id))){ // if there is number received from URL

            Axios.get('/api/clens/get/'+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                var fiveChallengesLevelChanged = false;
                var groToolLevelChanged = false;
                var leadershipTypeLevelChanged = false;
                var ministryLensLeveLChanged = false;
                var jvEngagementLevelChanged = false;

                response.data.result.forEach((row)=>{

                    if(row.segm_id == 1){ // 5 Challenges of Christ
                        this.setState({ fiveChallengesLevel: row.seg_cd });
                        fiveChallengesLevelChanged = true;
                    }
                    if(row.segm_id == 2){ // GRO Health Overall
                        this.setState({ groToolLevel: "GRO:"+row.seg_cd });
                        groToolLevelChanged = true;
                    }
                    if(row.segm_id == 3){ // Leadership Type
                        this.setState({ leadershipTypeLevel: row.seg_cd });
                        leadershipTypeLevelChanged = true;
                    }
                    if(row.segm_id == 5){ // Ministry Phases
                        this.setState({ ministryLensLeveL: row.seg_cd });
                        ministryLensLeveLChanged = true;
                    }
                    if(row.segm_id == 8){ // JV Engagement
                        this.setState({ jvEngagementLevel: row.seg_cd });
                        jvEngagementLevelChanged = true;
                    }

                })

                // set "-" to records that were not recieved
                if(!fiveChallengesLevelChanged) { this.setState({ fiveChallengesLevel: "-" }); }
                if(!groToolLevelChanged) { this.setState({ groToolLevel: "-" }); }
                if(!leadershipTypeLevelChanged) { this.setState({ leadershipTypeLevel: "-" }); }
                if(!ministryLensLeveLChanged) { this.setState({ ministryLensLeveL: "-" }); }
                if(!jvEngagementLevelChanged) { this.setState({ jvEngagementLevel: "-" }); }

                //this.setState({ listOfDiagnosticLenses: response.data.result })
            });

            // check if person has role in church
            Axios.get('/api/cont/get/'+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var church_id = response.data.result[0].church_id;
                if(church_id == null){
                    this.setState({ churchId: 0 });
                }
                else {
                    this.setState({ churchId: church_id });
                }

                this.setState({ mainContactId: response.data.result[0].id });
                this.setState({ mainContactName: response.data.result[0].first_name + " " + response.data.result[0].last_name });
            });

        }
    }

    openRow = (row) => {
        if(this.state.openedRow == row){
            this.setState({ openedRow: "none" });
        }
        else {
            this.setState({ openedRow: row });
        }
    }

    addRole = () => {
        this.openModal("AddRole");
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "FiveChallenges"){
            await this.setState({ prepareToShowModalFiveChallenges: true });
            this.setState({ showModalFiveChallenges: true });
        }
        else if(modalWindow == "LeadershipType"){
            await this.setState({ prepareToShowModalLeadershipType: true });
            this.setState({ showModalLeadershipType: true });
        }
        else if(modalWindow == "GroTool"){
            await this.setState({ prepareToShowModalGroToolModal: true });
            this.setState({ showModalGroToolModal: true });
        }
        else if(modalWindow == "JvEngagement"){
            if(this.state.churchId == 0){
                this.props.openAlertMessageTwoActions("You have not yet added a role in a church.","Cancel","Add Role",this.addRole);
                return;
            }
            await this.setState({ prepareToShowModalJvEngagement: true });
            this.setState({ showJvEngagementModal: true });
        }
        else if(modalWindow == "MinistryPhases"){
            if(this.state.churchId == 0){
                this.props.openAlertMessageTwoActions("You have not yet added a role in a church.","Cancel","Add Role",this.addRole);
                return;
            }
            await this.setState({ prepareToShowModalMinistryPhases: true });
            this.setState({ showModalMinistryPhases: true });
        }
        else if(modalWindow == "AddRole"){
            await this.setState({ prepareToShowModalAddRole: true });
            this.setState({ showModalAddRole: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "FiveChallenges"){
            this.setState({ showModalFiveChallenges: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalFiveChallenges: false });
        }
        else if(modalWindow == "LeadershipType"){
            this.setState({ showModalLeadershipType: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalLeadershipType: false });
        }
        else if(modalWindow == "GroTool"){
            this.setState({ showModalGroToolModal: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalGroToolModal: false });
        }
        else if(modalWindow == "JvEngagement"){
            this.setState({ showJvEngagementModal: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalJvEngagement: false });
        }
        else if(modalWindow == "MinistryPhases"){
            this.setState({ showModalMinistryPhases: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalMinistryPhases: false });
        }
        else if(modalWindow == "AddRole"){
            this.setState({ showModalAddRole: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddRole: false });
        }
        this.loadData(); // refresh data when coming back
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className="diagnosticTile">

                <div className="verticalStack">

                    <div className="horizontalStack">
                        <div className="verticalStack flex">
                            <span className="fontPoppinsSemiBold15">Diagnostic Lens Tools</span>
                            <span className="fontPoppinsRegular13Gray clickable onHoverToOrange">Learn More</span>
                        </div>
                        <div className="verticalStack" style={{alignItems: "center"}}>
                            <img className="diagnosticTileIcon" src={icon_glasses}/>
                            <div className="flex"></div>
                        </div>
                    </div>

                    <div className="verticalStack">
                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "24px"}}></div>

                        {/* DISCIPLE LENS */}

                        <div className={["horizontalStackCenter clickable diagnosticTileRow",this.state.openedRow == "disciple" ? "diagnosticTileRowOpened" : ""].join(" ")} onClick={() => this.openRow("disciple")}>
                            <img className="diagnosticLensIconExpand" src={icon_expand} style={{transform: this.state.openedRow == "disciple" ? "rotate(90deg)" : ""}}/>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "disciple" ? "var(--crm-green)" : "var(--jv-orange)", marginLeft: "9px"}}>Disciple Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "disciple" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.fiveChallengesLevel}</span></div>
                            <div style={{width: "9px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "disciple" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.groToolLevel}</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                        {/* DISCIPLE LENS ROWS */}

                        <div style={{display: this.state.openedRow == "disciple" ? "" : "none"}}>

                            <div className="horizontalStack clickable diagnosticTileSubRow" onClick={() => this.openModal("FiveChallenges")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-green)"}}>5 Challenges of Christ</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Discipleship Level:</span>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensCircle diagnosticLensCircleHoverGreen"><span className="fontPoppinsSemiBold15">{this.state.fiveChallengesLevel}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"-26px"} blackArrow={true} />
                                    </div>
                                </div>

                            </div>

                            <div className="horizontalStack clickable diagnosticTileSubRow" onClick={() => this.openModal("GroTool")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-green)"}}>GRO Health Overall</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Grow Level:</span>
                                </div>

                                <div style={{width: "9px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensOval diagnosticLensOvalHoverGreen"><span className="fontPoppinsSemiBold15">{this.state.groToolLevel}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"2px"} blackArrow={true} />
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* LEADER LENS */}

                        <div className={["horizontalStackCenter clickable diagnosticTileRow",this.state.openedRow == "leader" ? "diagnosticTileRowOpened" : ""].join(" ")} onClick={() => this.openRow("leader")}>
                            <img className="diagnosticLensIconExpand" src={icon_expand} style={{transform: this.state.openedRow == "leader" ? "rotate(90deg)" : ""}}/>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "leader" ? "var(--blue-button-link)" : "var(--jv-orange)", marginLeft: "9px"}}>Leader Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "leader" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.leadershipTypeLevel}</span></div>
                            <div style={{width: "9px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "leader" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>-</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                        {/* LEADER LENS ROWS */}

                        <div style={{display: this.state.openedRow == "leader" ? "" : "none"}}>

                            <div className="horizontalStack clickable diagnosticTileSubRow" onClick={() => this.openModal("LeadershipType")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--blue-button-link)"}}>Leadership Type</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Leadership Level:</span>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensCircle diagnosticLensCircleHoverBlue"><span className="fontPoppinsSemiBold15">{this.state.leadershipTypeLevel}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"-26px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack clickable diagnosticTileSubRow">
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--blue-button-link)"}}>Leadership Behaviors</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Evaluaton:</span>
                                </div>

                                <div style={{width: "9px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensOval diagnosticLensOvalHoverBlue"><span className="fontPoppinsSemiBold15">-</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Coming Soon"} from={"top"} marginLeft={"-10px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* MINISTRY LENS */}

                        <div className={["horizontalStackCenter clickable diagnosticTileRow",this.state.openedRow == "ministry" ? "diagnosticTileRowOpened" : ""].join(" ")} onClick={() => this.openRow("ministry")}>
                            <img className="diagnosticLensIconExpand" src={icon_expand} style={{transform: this.state.openedRow == "ministry" ? "rotate(90deg)" : ""}}/>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "ministry" ? "var(--crm-pink)" : "var(--jv-orange)", marginLeft: "9px"}}>Ministry Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "ministry" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.ministryLensLeveL}</span></div>
                            <div style={{width: "9px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "ministry" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>-</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                        {/* MINISTRY LENS ROWS */}

                        <div style={{display: this.state.openedRow == "ministry" ? "" : "none"}}>

                            <div className="horizontalStack clickable diagnosticTileSubRow"  onClick={() => this.openModal("MinistryPhases")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-pink)"}}>Ministry Phases</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Phase of Ministry:</span>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensCircle diagnosticLensCircleHoverPink"><span className="fontPoppinsSemiBold15">{this.state.ministryLensLeveL}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"-26px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack clickable diagnosticTileSubRow">
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-pink)"}}>Ministry Map</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Map Level:</span>
                                </div>

                                <div style={{width: "9px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensOval diagnosticLensOvalHoverPink"><span className="fontPoppinsSemiBold15">-</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Coming Soon"} from={"top"} marginLeft={"-10px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* CHURCH LENS */}

                        <div className={["horizontalStackCenter clickable diagnosticTileRow",this.state.openedRow == "church" ? "diagnosticTileRowOpened" : ""].join(" ")} onClick={() => this.openRow("church")}>
                            <img className="diagnosticLensIconExpand" src={icon_expand} style={{transform: this.state.openedRow == "church" ? "rotate(90deg)" : ""}}/>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "church" ? "var(--crm-yellow)" : "var(--jv-orange)", marginLeft: "9px"}}>Church Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "church" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>-</span></div>
                            <div style={{width: "9px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "church" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.jvEngagementLevel}</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                        {/* CHURCH LENS ROWS */}

                        <div style={{display: this.state.openedRow == "church" ? "" : "none"}}>

                            <div className="horizontalStack clickable diagnosticTileSubRow">
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-yellow)"}}>7 Charasteristics</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Level:</span>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensCircle diagnosticLensCircleHoverYellow"><span className="fontPoppinsSemiBold15">-</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Coming Soon"} from={"top"} marginLeft={"-38px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack clickable diagnosticTileSubRow" onClick={() => this.openModal("JvEngagement")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-yellow)"}}>JV Engagement</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Level:</span>
                                </div>

                                <div style={{width: "9px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensOval diagnosticLensOvalHoverYellow"><span className="fontPoppinsSemiBold15">{this.state.jvEngagementLevel}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"1px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {this.state.prepareToShowModalFiveChallenges ? <FiveChallengesModal showModal={this.state.showModalFiveChallenges} closeModal={this.closeModal} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} openAfter={this.state.openAfter} /> : null }
                {this.state.prepareToShowModalGroToolModal ? <GroToolModal showModal={this.state.showModalGroToolModal} closeModal={this.closeModal} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalLeadershipType ? <LeadershipTypeModal showModal={this.state.showModalLeadershipType} closeModal={this.closeModal} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalJvEngagement ? <JvEngagementModal showModal={this.state.showJvEngagementModal} closeModal={this.closeModal} mode={"person"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalMinistryPhases ? <MinistryPhasesModal showModal={this.state.showModalMinistryPhases} closeModal={this.closeModal} mode={"person"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalAddRole ? <RolesModal showModal={this.state.showModalAddRole} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddRole"} roleId={-1} openAlertMessage={this.props.openAlertMessageTwoActions} /> : null }
            </div>
        );
    }
}

export default DiagnosticTile;
