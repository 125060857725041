import React from 'react';
import '../App.css';
import '../styles/menus.css';
import { Link } from "react-router-dom";

class LeftMenuItem extends React.Component {

	render(){
		return(
            <Link className="link" to={this.props.link} >
                <div className={this.props.active ? "horizontalStackCenter leftMenuButton leftButtonActive" : "horizontalStackCenter leftMenuButton"}>
                    <img className="menuLeftLinkImage" src={this.props.icon} style={this.props.iconStyle}/>
                    <span className={this.props.active ? "menuLeftLinkText menuLeftLinkTextActive" : "menuLeftLinkText"}>{this.props.title}</span>
                </div>
            </Link>
        );
	}
}

export default LeftMenuItem;