import React from 'react';
import { Link } from "react-router-dom";
import '../styles/modals.css';
//import Slider from '@material-ui/core/Slider';
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import EditDeleteButton from '../components/EditDeleteButton.js';
import Slider from '../components/Slider.js';
import DiagnosticModalConfirmation from '../components/DiagnosticModalConfirmation.js';

// assets
import close_white from '../assets/close_white.png';
import icon_action_down from '../assets/icon_action_down.png';
import icon_action_up from '../assets/icon_action_up.png';

const marks = [
  { value: 0, label: '0', },
  { value: 25, label: '1', },
  { value: 50, label: '2', },
  { value: 75, label: '3', },
  { value: 100, label: '4', },
];

class JvEngagementModal extends React.Component {
    savedHeight = null;

    constructor(props) {
        super(props);
        this.state = {
            modalExpanded: false,
            mainContactId: -1,
            mainContactName: "Loading...",
            editingModeOfRecord: -1,
            sliderValue: 75, // 0, 25, 50, 75, 100
            lastRecordDate: "",
            note_descr: "",
            arrayOfRecords: ([]),
            instructionsSectionOpened: 4, // 0,1,2,3,4
            sliderSize: 346,
            sliderMarginTop: -175,
            sliderMarginLeft: -383,
            showSaveRecordConfirmation: false,
        };

        this.refSlider = React.createRef();
        this.refSliderSteps = React.createRef();
        this.refImage = React.createRef();
        this.refModalBottom = React.createRef();
    }

    async componentDidMount(){
        this.loadData();
        window.addEventListener('resize', this.resizeSliderAndSteps);

        this.resizeSliderAndSteps();
        await this.timeout(50);
        this.resizeSliderAndSteps();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeSliderAndSteps);
    }

    componentDidUpdate() { /* another way of real-time updating size of slider */
        if(this.savedHeight !== this.refSliderSteps.current.style.height) {
            this.resizeSliderAndSteps();
        }
        this.savedHeight = this.refSliderSteps.current.style.height;
    }

    timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    resizeSliderAndSteps = () => {
        const refSliderSteps = this.refSliderSteps.current;
        const refImage = this.refImage.current;

        refSliderSteps.style.height = `${this.refImage.current.offsetHeight-(0.02*this.refImage.current.offsetHeight)}px`;
        refSliderSteps.style.marginTop = `${7}px`;

        this.setState({ sliderMarginLeft: -this.refImage.current.offsetHeight-(0.15*this.refImage.current.offsetHeight) });
        this.setState({ sliderMarginTop: -this.refImage.current.offsetHeight/2-(0.025*this.refImage.current.offsetHeight) });
        this.setState({ sliderSize: this.refImage.current.offsetHeight+(0.041*this.refImage.current.offsetHeight) });
    }

    loadData = async () => {
        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL

            var church_id = -1;

            if(this.props.mode == "person"){
                await Axios.get('/api/cont/get/'+id).then(response => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                    church_id = response.data.result[0].church_id;
                    this.setState({ mainContactId: response.data.result[0].church_id });
                    this.setState({ mainContactName: response.data.result[0].church });
                });
            }
            else {
                church_id = id;
                await Axios.get('/api/cont/get/'+id).then(response => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                    church_id = response.data.result[0].id;
                    this.setState({ mainContactId: response.data.result[0].id });
                    this.setState({ mainContactName: response.data.result[0].short_name });
                });
            }

            if(church_id == -1 || church_id == null){ return; }


            Axios.get('/api/lens/get?cont_id='+this.state.mainContactId+'&segm_id=8').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                if(response.data.result.length > 0){
                    response.data.result = response.data.result.reverse();
                    var savedValue = response.data.result[0].seg_id;
                    var countedValue = this.getSliderValue(savedValue); //ex.: from 3 do 75 (0-100)
                    this.setState({ sliderValue: countedValue });
                    this.setState({ lastRecordDate: response.data.result[0].ins_at });
                    this.setState({ arrayOfRecords: response.data.result });
                }
                else {
                    this.setState({ sliderValue: 75 });
                    this.setState({ lastRecordDate: "" });
                    this.setState({ arrayOfRecords: [] });
                }
            });
        }
    }

    saveNewRecord = () => {
        //alert("ContId: "+this.state.mainContactId+"\nSegmId: 8\nSegId: "+((this.state.sliderValue/25))+"\nDescr: "+this.state.note_descr);
        //alert(this.state.editingModeOfRecord);
        //return;

        if(this.state.mainContactId == undefined || this.state.mainContactId == 0){
            this.props.openAlertMessageTwoActions("This person doesn't have any church set yet.","OK","",null);
            return;
        }

        var url = "/api/lens/ins";

        if(this.state.editingModeOfRecord != -1){
            url = "/api/lens/upd/"+this.state.editingModeOfRecord;
        }

        Axios.post(url, {
            ContId: this.state.mainContactId, // id of church to whom I am setting the record
            SegmId: 8, // 8 is JV Engagement lens
            SegId: this.getSeqIdValue(this.state.sliderValue), // this is the ID of level I am saving
            NoteDescr: this.state.note_descr,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            if(this.state.editingModeOfRecord == -1){
                //this.props.openAlertMessageTwoActions("The record was saved.","OK","",null);
                this.setState({ showSaveRecordConfirmation: true });

                setTimeout(() => {
                    this.props.closeModal("JvEngagement");
                }, 2000);
            }
            else {
                this.setState({ editingModeOfRecord: -1 });
                this.setState({ note_descr: "" });
                this.loadData();
            }
        });
    }

    onDeleteRecord = (id) => {
        Axios.delete('/api/lens/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.loadData();
        });
    }

    onEditRecord = (id) => {
        if(this.state.editingModeOfRecord == id){
            this.setState({ editingModeOfRecord: -1 });
            this.setState({ note_descr: "" });
            this.loadData();
        }
        else {
            this.setState({ editingModeOfRecord: id });

            Axios.get('/api/lens/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var savedValue = response.data.result[0].seg_id;
                var countedValue = this.getSliderValue(savedValue); //ex.: from 3 do 75 (0-100)
                this.setState({ sliderValue: countedValue });
                this.setState({ note_descr: response.data.result[0].note_descr });
            });

        }
    }

    getSliderValue = (valueID) => {
        if(valueID == 91){ return 0; } // level 0
        if(valueID == 92){ return 25; } // level 1
        if(valueID == 93){ return 50; } // level 2
        if(valueID == 94){ return 75; } // level 3
        if(valueID == 95){ return 100; } // level 4
        return 0;
    }

    getSeqIdValue = (value) => {
        if(value == 0){ return 91; } // level 0
        if(value == 25){ return 92; } // level 1
        if(value == 50){ return 93; } // level 2
        if(value == 75){ return 94; } // level 3
        if(value == 100){ return 95; } // level 4
        return 91;
    }

    toggleModalExpansion = async () => {
        if(this.state.modalExpanded){
            this.setState({ modalExpanded: false });
        }
        else {
            this.setState({ modalExpanded: true });
            await new Promise(resolve => setTimeout(resolve, 100));
            this.refModalBottom.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

	render(){
		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal("JvEngagement")} isExpanded={this.state.modalExpanded}>
                    <DiagnosticModalConfirmation show={this.state.showSaveRecordConfirmation} />
                    <div className="content">
                        <div className="horizontalStack flex">
                            <div className="verticalStackCenter flex">
                                <span className="fontPoppinsSemiBold15"><b>JV Engagement<span style={{color: "var(--blue-button-link)"}}> - {this.state.mainContactName} <Moment format="M/D/YY">{this.state.lastRecordDate}</Moment></span></b></span>
                                <div>
                                    <span className="fontPoppinsRegular13Gray">This tool is designed to help you assess the level of engagement with Josiah Venture. To learn more about levels of engagement with Josiah Venture <span className="fontPoppinsSemiBold13 clickable" onClick={() => this.toggleModalExpansion()}>click here</span>.</span>
                                    <br/><br/>
                                    <span className="fontPoppinsSemiBold15">Current Level of Engagement:</span>
                                </div>
                                <div className="horizontalStackCenter flex">
                                    <div className="flex"></div>
                                        {/*<Slider
                                            style={{color: "var(--crm-yellow)", height: "83%"}}
                                            aria-label="Always visible"
                                            value={this.state.sliderValue}
                                            step={25}
                                            orientation={"vertical"}
                                            onChange={(event, value) => this.setState({ sliderValue: value })}
                                            marks={marks}
                                            valueLabelDisplay="off"
                                        />*/}

                                            {/* value 100-variable is because it is upside down */}
                                            <Slider
                                                styles={"myCustomSlider myCustomSliderVertical myCustomSliderYellow"}
                                                value={this.state.sliderValue}
                                            style={{width: this.state.sliderSize, marginTop: this.state.sliderMarginTop, marginLeft: this.state.sliderMarginLeft}}
                                                step={25}
                                                onChange={(value) => this.setState({ sliderValue: value })}
                                            />
                                            <div ref={this.refSliderSteps} className="verticalStack" style={{marginRight: 30}}>
                                                <span className="fontPoppinsSemiBold13DarkGray">{"4"}</span>
                                                <div className="flex"></div>
                                                <span className="fontPoppinsSemiBold13DarkGray">{"3"}</span>
                                                <div className="flex"></div>
                                                <span className="fontPoppinsSemiBold13DarkGray">{"2"}</span>
                                                <div className="flex"></div>
                                                <span className="fontPoppinsSemiBold13DarkGray">{"1"}</span>
                                                <div className="flex"></div>
                                                <span className="fontPoppinsSemiBold13DarkGray">{"0"}</span>
                                            </div>
                                    <div ref={this.refImage} className="verticalStack" style={{flex: 2, height: "90%"}}>
                                        <span className="fontPoppinsSemiBold15">Partnership</span>
                                        <div className="responsiveThreeDotsContainer flex">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">This church shares a vision for the work of your team in other churches and locations.</span>
                                        </div>
                                        <span className="fontPoppinsSemiBold13Gray clickable" onClick={() => {this.setState({ instructionsSectionOpened: 4 }); this.toggleModalExpansion()}}>Learn more</span>

                                        <div className="flex"></div>

                                        <span className="fontPoppinsSemiBold15">Cooperation</span>
                                        <div className="responsiveThreeDotsContainer flex">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">This church is regularly engaged through an ongoing program such as Edge, Fusion...</span>
                                        </div>
                                        <span className="fontPoppinsSemiBold13Gray clickable" onClick={() => {this.setState({ instructionsSectionOpened: 3 }); this.toggleModalExpansion()}}>Learn more</span>

                                        <div className="flex"></div>

                                        <span className="fontPoppinsSemiBold15">Participation</span>
                                        <div className="responsiveThreeDotsContainer flex">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">This church has participated in your training or outreach events or programs during...</span>
                                        </div>
                                        <span className="fontPoppinsSemiBold13Gray clickable" onClick={() => {this.setState({ instructionsSectionOpened: 2 }); this.toggleModalExpansion()}}>Learn more</span>

                                        <div className="flex"></div>

                                        <span className="fontPoppinsSemiBold15">Cultivating</span>
                                        <div className="responsiveThreeDotsContainer flex">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">You are actively building a relationship with this church, but they have not yet...</span>
                                        </div>
                                        <span className="fontPoppinsSemiBold13Gray clickable" onClick={() => {this.setState({ instructionsSectionOpened: 1 }); this.toggleModalExpansion()}}>Learn more</span>

                                        <div className="flex"></div>

                                        <span className="fontPoppinsSemiBold15">No Relationship</span>
                                    </div>
                                    <div className="flex"></div>
                                </div>
                                <div>
                                    <span className="fontPoppinsRegular13Gray">{"If you see this person exuding a different leadership type based on the diagnostic instructions below, then you can change it by moving the slider above. Take notes on the right and once you click on the ‘Save’ button below a new record will be made."}</span>
                                </div>
                                <div className="horizontalStackCenter" style={{marginTop: "20px"}}>
                                    <RoundButton title={this.state.editingModeOfRecord != -1 ? "Edit Level and Note" : "Save Level and Note"} className={"roundButtonYellow"} style={{marginRight: "10px"}} whiteText={false} onClick={this.saveNewRecord} />
                                    <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.props.closeModal("JvEngagement")} />
                                    <div className="flex"></div>
                                    <div className="clickable" onClick={this.toggleModalExpansion}>

                                        { !this.state.modalExpanded ?
                                            <div className="modalExpandButton horizontalStackCenter">
                                                <span className="modalExpandButtonTextGray fontPoppinsSemiBold13">Open Diagnostic Instructions</span>
                                                <div className="modalExpandButtonIconContainer modalExpandButtonIconBgYellow verticalStackCenter">
                                                    <div className="horizontalStack">
                                                        <div className="flex"></div>
                                                        <img className="modalExpandButtonIcon modalExpandButtonYellow" src={icon_action_down}/>
                                                        <div className="flex"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null }

                                        { this.state.modalExpanded ?
                                            <div className="modalExpandButton horizontalStackCenter">
                                                <span className="modalExpandButtonTextGray fontPoppinsSemiBold13">Close Diagnostic Instructions</span>
                                                <div className="modalExpandButtonIconContainer modalExpandButtonIconBgYellow verticalStackCenter">
                                                    <div className="horizontalStack">
                                                        <div className="flex"></div>
                                                        <img className="modalExpandButtonIcon modalExpandButtonYellow" src={icon_action_up}/>
                                                        <div className="flex"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null }

                                    </div>
                                </div>
                            </div>

                            <div style={{width: 20}}></div>

                            <div className="verticalStack" style={{minWidth: "200px", maxWidth: "350px", flex: 0.55}}>
                                <div className="horizontalStack">
                                    <div className="flex"></div>
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal("JvEngagement")}/>
                                </div>
                                <div className="verticalStack" style={{marginTop: 20}}>
                                    <span className="fontPoppinsSemiBold15Gray">History:</span>

                                    <div className="modalHistoryContainer">
                                        <div className="modalHistoryDiv verticalStack flex">

                                            {this.state.arrayOfRecords.map(function (item) {
                                                return (
                                                    <div className="verticalStack" style={{padding: 10, paddingTop: 0, backgroundColor: this.state.editingModeOfRecord == item.id ? "var(--light-crm-yellow)" : "var(--lightest-gray)"}}>
                                                        <div className="horizontalStackCenter">
                                                            <span className="fontPoppinsRegular13Blue modalHistoryDate"><Moment format="MMM. D, YYYY">{item.ins_at}</Moment></span>
                                                            <EditDeleteButton itemId={item.id} onEdit={this.onEditRecord} onDelete={this.onDeleteRecord} areYouSureText={"Are you sure want to delete this \"Church Lens\" record?"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                        </div>
                                                        <span className="fontPoppinsRegular13Blue" style={{marginTop: -9}}>{"By "}{item.cr_by}</span>
                                                        <span className="fontPoppinsRegular13"><span className="fontPoppinsBold13">{"JVE "}{item.seg_val}{" -"} </span>{item.note_descr}</span>
                                                    </div>
                                                )
                                            }.bind(this))}

                                            <div className="flex"></div>

                                        </div>
                                    </div>

                                </div>
                                <div className="verticalStack flex" style={{marginTop: "30px"}}>
                                    <span className="fontPoppinsSemiBold15">Add a Note:</span>
                                    <textarea className="modalInputAddNote" rows="9" placeholder={"Type your notes about this church's current JV Engagement level."} value={this.state.note_descr} onChange={e => this.setState({ note_descr: e.target.value })}></textarea>
                                </div>
                            </div>
                        </div>
                        <div style={{display: this.state.modalExpanded ? "inline" : "none"}}>
                            <div className="fakeEndOfModalWindow"></div>
                            <div className="verticalStack" style={{paddingTop: 20}}>
                                <span className="fontPoppinsSemiBold15">Diagnostic Instructions:</span>
                                <div className="horizontalStack" style={{marginTop: 10}}>
                                    <div className="verticalStack" style={{flexDirection: "column", marginTop: 20}}>

                                        <div className="horizontalStackCenter">
                                            <span className="fontPoppinsSemiBold13" style={{paddingLeft: 12, paddingRight: 12}}>{"Menu:"}</span>
                                            <span className="fontPoppinsSemiBold13Gray">{"Introduction"}</span>
                                        </div>

                                        <div className="flex"></div>

                                        <div className="horizontalStackCenter clickable" onClick={() => this.setState({ instructionsSectionOpened: 4 })}>
                                            <span className={this.state.instructionsSectionOpened == 4 ? "fontPoppinsSemiBold15 instructionsSectionOpenedModal" : "fontPoppinsSemiBold15Gray instructionSectionClosedModal"}>{"JVE: 4"}</span>
                                            <span className={this.state.instructionsSectionOpened == 4 ? "fontPoppinsSemiBold13" : "fontPoppinsSemiBold13Gray"}style={{paddingLeft: 10, paddingRight: 20}}>{"Partnership"}</span>
                                        </div>

                                        <div className="flex"></div>

                                        <div className="horizontalStackCenter clickable" onClick={() => this.setState({ instructionsSectionOpened: 3 })}>
                                            <span className={this.state.instructionsSectionOpened == 3 ? "fontPoppinsSemiBold15 instructionsSectionOpenedModal" : "fontPoppinsSemiBold15Gray instructionSectionClosedModal"}>{"JVE: 3"}</span>
                                            <span className={this.state.instructionsSectionOpened == 3 ? "fontPoppinsSemiBold13" : "fontPoppinsSemiBold13Gray"}style={{paddingLeft: 10, paddingRight: 20}}>{"Cooperation"}</span>
                                        </div>

                                        <div className="flex"></div>

                                        <div className="horizontalStackCenter clickable" onClick={() => this.setState({ instructionsSectionOpened: 2 })}>
                                            <span className={this.state.instructionsSectionOpened == 2 ? "fontPoppinsSemiBold15 instructionsSectionOpenedModal" : "fontPoppinsSemiBold15Gray instructionSectionClosedModal"}>{"JVE: 2"}</span>
                                            <span className={this.state.instructionsSectionOpened == 2 ? "fontPoppinsSemiBold13" : "fontPoppinsSemiBold13Gray"}style={{paddingLeft: 10, paddingRight: 20}}>{"Participation"}</span>
                                        </div>

                                        <div className="flex"></div>

                                        <div className="horizontalStackCenter clickable" onClick={() => this.setState({ instructionsSectionOpened: 1 })}>
                                            <span className={this.state.instructionsSectionOpened == 1 ? "fontPoppinsSemiBold15 instructionsSectionOpenedModal" : "fontPoppinsSemiBold15Gray instructionSectionClosedModal"}>{"JVE: 1"}</span>
                                            <span className={this.state.instructionsSectionOpened == 1 ? "fontPoppinsSemiBold13" : "fontPoppinsSemiBold13Gray"}style={{paddingLeft: 10, paddingRight: 20}}>{"Cultivating"}</span>
                                        </div>

                                        <div className="flex"></div>

                                        <div className="horizontalStackCenter clickable" onClick={() => this.setState({ instructionsSectionOpened: 0 })}>
                                            <span className={this.state.instructionsSectionOpened == 0 ? "fontPoppinsSemiBold15 instructionsSectionOpenedModal" : "fontPoppinsSemiBold15Gray instructionSectionClosedModal"}>{"JVE: 0"}</span>
                                            <span className={this.state.instructionsSectionOpened == 0 ? "fontPoppinsSemiBold13" : "fontPoppinsSemiBold13Gray"}style={{paddingLeft: 10, paddingRight: 20}}>{"No Relationship"}</span>
                                        </div>

                                    </div>
                                    {this.state.instructionsSectionOpened == 4 ? <textarea className="modalInputAddNote" rows="12" disabled>{"Parnership\nThis church shares a vision for the work of your team in other churches and locations.  They are partners in your vision to reach youth beyond their local church.  Churches with your staff located in them should be in this category if your staff are actively serving in the local ministry."}</textarea> : null }
                                    {this.state.instructionsSectionOpened == 3 ? <textarea className="modalInputAddNote" rows="12" disabled>{"Cooperation\nThis church is regularly engaged through an ongoing program such as Edge, Fusion or yearly English camps."}</textarea> : null }
                                    {this.state.instructionsSectionOpened == 2 ? <textarea className="modalInputAddNote" rows="12" disabled>{"Participation\nThis church has participated in your training or outreach events or programs during the last three years."}</textarea> : null }
                                    {this.state.instructionsSectionOpened == 1 ? <textarea className="modalInputAddNote" rows="12" disabled>{"Cultivating\nYou are actively building a relationship with this church, but they have not yet participated in your training or outreach events or programs."}</textarea> : null }
                                    {this.state.instructionsSectionOpened == 0 ? <textarea className="modalInputAddNote" rows="12" disabled>{"No Relationship\n"}</textarea> : null }
                                </div>
                            </div>
                            <div className="horizontalStackCenter clickable" style={{marginTop: 20}} onClick={this.toggleModalExpansion}>
                                <div className="flex"></div>

                                <div ref={this.refModalBottom} className="modalExpandButton horizontalStackCenter">
                                    <span className="modalExpandButtonTextGray fontPoppinsSemiBold13">Close Diagnostic Instructions</span>
                                    <div className="modalExpandButtonIconContainer modalExpandButtonIconBgYellow verticalStackCenter">
                                        <div className="horizontalStack">
                                            <div className="flex"></div>
                                            <img className="modalExpandButtonIcon modalExpandButtonYellow" src={icon_action_up}/>
                                            <div className="flex"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex"></div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
		);
	}
}

export default JvEngagementModal;