import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';

// import components
import MapComponent from '../components/MapComponent.js';

class MapTile extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="mapTile">
                <div className="verticalStack">
                    <span className="fontPoppinsSemiBold15">{"The "}{this.props.countryName}</span>
                    <div style={{height: "20px"}}></div>
                    <MapComponent saveCountry={this.props.saveCountry} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                </div>
            </div>
        );
    }
}

export default MapTile;
