import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';
import Axios from 'axios';
import { Navigate } from "react-router-dom";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

class DiscipleBox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
        };
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
    }

    deleteFromMyDiscipling = () => {
        Axios.delete('/api/my_dsc/del/', {
           data: {ContId: this.props.contId},
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.props.getDataFromApi();
        });
    }

    openConversations = (contId, isPrivate) => {
        if(isPrivate == 1) this.setState({ redirectTo: "/PeoplePrivateProfile/"+contId+"?tile=conversations" });
        else this.setState({ redirectTo: "/PeopleProfile/"+contId+"?tile=conversations" });
    }

    getInitials = (name) => {
        const nameArray = name.split(" ");
        var result = "";
        for(let item of nameArray){
            result = result+Array.from(item)[0];
        }
        return result;
    }

    openContactProfile = (contId, isPrivate) => {
        if(isPrivate == 1) this.setState({ redirectTo: "/PeoplePrivateProfile/"+contId });
        else this.setState({ redirectTo: "/PeopleProfile/"+contId });
    }

    getClasses = (isPrivate, isActive) => {
        if(isPrivate){
            if(isActive) return "discipleBoxContainerPrivateActive verticalStack clickable";
            else return "discipleBoxContainerPrivateUnactive verticalStack clickable";
        }
        else {
            if(isActive) return "discipleBoxContainerActive verticalStack clickable";
            else return "discipleBoxContainerUnactive verticalStack clickable";
        }
    }

    getHeaderClasses = (isPrivate, isActive) => {
        if(isPrivate){
            if(isActive) return "discipleBoxHeaderPrivateActive horizontalStack";
            else return "discipleBoxHeaderPrivateUnactive horizontalStack";
        }
        else {
            if(isActive) return "discipleBoxHeaderActive horizontalStack";
            else return "discipleBoxHeaderUnactive horizontalStack";
        }
    }

    render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return(
            <div className={this.getClasses(this.props.isPrivate, this.props.isActive)}>
                <div className={this.getHeaderClasses(this.props.isPrivate, this.props.isActive)} onClick={() => this.openContactProfile(this.props.contId, this.props.isPrivate)}>
                    <div className="verticalStack">
                        <div className="verticalStackCenter" style={{backgroundColor: "var(--full-black)", borderRadius: 25, height: 41, aspectRatio: "1 / 1"}}>
                            <span className="fontPoppinsMedium13White">{this.getInitials(this.props.name)}</span>
                        </div>
                    </div>
                    <div className="verticalStack" style={{marginLeft: 10, marginRight: 10, textAlign: "left"}}>
                        <span className="fontPoppinsMedium14">{this.props.name}</span>
                        {!this.props.isPrivate ? <span className="fontPoppinsRegular13White">{this.props.role}</span> : null}
                        {this.props.isPrivate ? <span className="fontPoppinsRegular13Blue">{"Private Contact"}</span> : null}
                    </div>
                </div>

                <div className="verticalStack discipleBoxHideOnHover" style={{paddingBottom: 5, paddingTop: 5}}>
                    <span className="fontPoppinsRegular13Gray">{this.props.convNum} {"Conversations"}</span>
                    <span className="fontPoppinsRegular13">{"Last Active"} {this.props.lastActive}</span>
                </div>

                <div className="verticalStack discipleBoxShowOnHover" style={{paddingBottom: 5, paddingTop: 5}}>
                    <span className="fontPoppinsRegular13Blue clickable onHoverAlsoToOrange" onClick={() => this.openConversations(this.props.contId, this.props.isPrivate)}>{"Go to Conversations"}</span>
                    <br/>
                    <span className="fontPoppinsRegular13Blue clickable onHoverAlsoToOrange" onClick={() => this.deleteFromMyDiscipling()}>{"Remove from Page"}</span>
                </div>
            </div>
        );
	}
}

export default DiscipleBox;