import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// components
import Modal from '../modals/Modal';

// import assets
import close_white from '../assets/close_white.png';
import blue_close_icon from '../assets/blue_close_icon.png';
import instructions_left from '../assets/instructions_left.png';
import instructions_right from '../assets/instructions_right.png';
import instructions_example from '../assets/instructions_example.png';
import instructions_conversations_1 from '../assets/instructions_conversations_1.png';
import instructions_conversations_2 from '../assets/instructions_conversations_2.png';
import instructions_diagnostics_1 from '../assets/instructions_diagnostics_1.png';
import instructions_diagnostics_2 from '../assets/instructions_diagnostics_2.png';
import instructions_disciples_1 from '../assets/instructions_disciples_1.png';
import instructions_disciples_2 from '../assets/instructions_disciples_2.png';
import instructions_dashboard_1 from '../assets/instructions_dashboard_1.png';

class InstructionsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // instructions
            allPagesList: ([{id: 1, title:"Easy Contact Management"},
                         {id: 2, title:"Coordination by Conversations"},
                         {id: 3, title:"Powerful Ministry Diagnostics"},
                         {id: 4, title:"Map Out Your Disciple-Making"},
                         {id: 5, title:"Dynamic Dashboard Data"}]),
            actualPage: this.props.oneInstruction.id,
        };
    }

    componentDidMount(){

    }

    getTitle = (id) => {
        for(let oneRow of this.state.allPagesList){
            if(id == oneRow.id) return oneRow.title;
        }
        return "Error";
    }

    getDescription = (id) => {
        if(id == 1){
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Introduction"}</span>
                        <span className="fontPoppinsRegular13Gray">{"Save time asking various people for their contact information and figuring out the context of their relationship with Josiah Venture. Manage all of your contacts across JV ministries in one easy-to-use location. You can quickly search and view a profile for each contact containing helpful information such as their conversations with JV, roles, relationships, personal growth, and ministry growth. You can easily create new contacts or add existing profiles to “My Contacts” and get a quick overview of your work with them. You will be able to organize and empower your team’s work as you approach your contacts with contextual wisdom and team unity."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Add a New Contact"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can add a new contact by clicking on the “Add New” button in the top menu and choosing either “People” or “Church.” You can also add a contact by clicking on various shortcut options throughout the Portal. For example, these shortcuts to “Add a New Contact” will appear when you are adding a person to your “My Disciple-Making” page or when you are adding a person to a conversation."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"Once you’ve started to add a new contact, you will need to start entering their profile information. There are only a few required fields that you must fill in order to save the new profile. However, it is best to put in as much information as you can. Also, don’t forget to try and upload a profile image if you have one."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Searching a Contact"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can do a quick search for any contact using the search bar in the top menu and click on the automatic drop-down results or hit the Enter/Return key to get a full list of results. To the left of the search bar is an “Advanced Search” button. This opens up a drop-down menu with many options that help you narrow your search results."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Adding to “My Contacts”"}</span>
                        <span className="fontPoppinsRegular13Gray">{"When you are viewing a contact’s profile page, you can simply click on the plus button located in the upper left of their contact information tool. You can also view a list of search results or click on “All Contacts” from the left menu, select the names you want to add and then click on the “Actions” button in the upper left of the results table and click “Add to My Contacts.”"}</span>
                    </div>);
        }
        else if(id == 2){
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Introduction"}</span>
                        <span className="fontPoppinsRegular13Gray">{"View your communications with other JV staff, church contacts, and those you are discipling in one place through the conversations tool. You will avoid confusing and conflicting communication experiences while trying to engage with contacts. This tool will help you make relevant offers, knowing the context of their needs through records. You can quickly let others know about your meetings, organized by the conversation category, those involved, and the date, all while using minimal required fields. Extra features include sharing notes and sending your team a notification to email addresses, helping ensure everyone is on the same page."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Add a New Conversation"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can add a new contact by clicking on the “Add New” button in the top menu and choosing “People > Conversation.” You can also do this by clicking on the large “+” button on the top right of the My Conversations page or the Conversations tool inside a contact’s profile."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"When the “Add Your Conversation” form opens, you can start entering the information. The names and dates are prefilled for you, but you can adjust them. We made the field “Categories” the only required field besides name and date, but you can use the other features in the form to add as much additional information about the conversation as you think would be helpful for your team. When you are done, you can click “Save” or “Save and Add New” to add another one immediately."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Note Taking"}</span>
                        <span className="fontPoppinsRegular13Gray">{"The “Notes” field is for you to write any key observations from the conversation that you think would be helpful for you or your team to remember. Remember that your notes will be viewable by others."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"You can also select a ministry need that they may have and write a description. This information will help your team know about their needs while also helping JV organizations with data to see the wider scope of growing needs across different ministries."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Sending an Email Notification"}</span>
                        <span className="fontPoppinsRegular13Gray">{"At the bottom of the “Add Your Conversations” form, you will see an “Add an Email” button where you can search for a contact’s name and email in the database or add a new contact. Once you add the contacts that you want to send an email notification, you can click “Save,” and it will email them about the conversation. If you open the conversation later to edit it you will see these emails in blue at the bottom to let you know who has been notified already. If you want to notify more people, simply add more emails and save the conversation again."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Overview of Conversations"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can get an overview of your conversations by viewing the “My Conversations” page, which is accessible in the left menu. You can also view all conversations relevant to a contact within their profile. To see a preview of a conversation just click on the row in the tool window. To view a preview of all of the conversations, you can expand them by clicking on the arrow in the top left of the table."}</span>
                    </div>);
        }
        else if(id == 3){
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Introduction"}</span>
                        <span className="fontPoppinsRegular13Gray">{"Don’t get overwhelmed by the complexity of organizing and tracking your ministry’s progress. Enter into discipleship and leadership training with clarity in your next steps for developing others. You can view all the core JV training principles here within each contact’s profile with the “Diagnostics Lens” tool. It is designed to help you track your ministry progress and make observations for others to see about the health and growth of your contacts. You will also benefit from the records of others and see real improvement in your work as you thoughtfully and prayerfully invite others to the challenges of Christ and his discipleship model."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Viewing the Diagnostic Lens Tools"}</span>
                        <span className="fontPoppinsRegular13Gray">{"In the bottom right-hand corner of every contact’s profile is a tile window showing the results from each “Diagnostic Lens Tool.” You can expand each of the tool categories to see each tool type. To the right of each tool is the contact’s level relative to that particular tool, such as “D3“ for the “5 Challenges of Christ” tool, etc. The level shown is based on the latest observational record made by a user for that contact. You can click on the tool name to open it and make a new observational record."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Making an Observational Record"}</span>
                        <span className="fontPoppinsRegular13Gray">{"Once you open one of the “Diagnostic Lens Tools,” you will see a chart and description of the tool, a slider that indicates the user’s level for that tool (based on the last record made), a place to take notes for your new record, and a button for opening the diagnostic tool information/instructions further at the bottom of the window. If this is your first time using this tool, we suggest you review the tool information/instructions by opening the “Diagnostic Instructions/Tool” (button at the bottom) before you make a record. Some of the tools will require you to open this and move additional sliders to make a record (these will always stay “…Tool” instead of “…Instructions” at the bottom). You can also view a history of the previous records made and their notes in the upper right."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{" To make a record move the slider to the new level you observe to be appropriate. Take notes on why you changed the tool’s level for this contact in the bottom right (it will be very helpful to other users to understand the context of the level chosen), and then click save. You can always return and edit the record you’ve made if needed."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Editing an Observational Record"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can edit any of the records you have made in an observational tool (you also cannot edit the records of other users). Open the tool and click on the “…” icon next to the date in the upper right “History” section. Then the record you are editing will be shown in green. You can change the slider/s and take new notes in the bottom right. Click the button “Edit Level and Note” on the bottom left to complete your edit. This will update your previously created record."}</span>
                    </div>);
        }
        else if(id == 4){
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Introduction"}</span>
                        <span className="fontPoppinsRegular13Gray">{"The “My Disciple-Making” page automatically shows how the people you are discipling are moving through the Ministry Map of the 5 Challenges of Christ. Thinking about where all your relationships are in their walk with Jesus can be overwhelming. This tool helps you quickly benefit from the observations of others by viewing your contacts’ progress based on the most recent records made from the “5 Challenges” tool. If you see a change needed for someone, you can make a new observation, and they will move accordingly. You can also add “Private Contacts” on this page that are not viewable by others in the system to get a complete overview."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Viewing Your Contacts Placement in the “5 Challenges”"}</span>
                        <span className="fontPoppinsRegular13Gray">{"When you click on “My Disciple-Making” in the left menu, you will see a page with a 5-column “Ministry Map” that corresponds to the “5 Challenges of Christ”. To see where your contacts are collectively on this map, you can start by adding contacts using the “+” in the upper right-hand corner. You can choose a Person in your contacts, you can add a new person’s profile that will redirect you back to this page when you are done, or you can add a “Private Contact (see below).”"}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"Once you’ve placed all the people you want to see on your page, you can use the checkboxes above the map to show or hide different types of contacts. There are three types as follows:"}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"Green: Contacts that you have had at least one conversation within the past six months. (Light green indicates that this user is a “Private Contact”.)"}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"Grey: Contacts that you have not had any conversations within the past six months."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"Blue: Contacts that you have a relationships with in the system using the “Relationships” tool located within profiles. For example, this can be someone marked as your spouse, downlink, etc."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Private Contacts"}</span>
                        <span className="fontPoppinsRegular13Gray">{"Private contacts can only be created from this “My Disciple-Making” page since their express purpose is to give the user a more exhaustive overview of their disciples. The profiles of “Private Contacts” have limited functionality and only require saving the name of the contact. This type of contact can later be upgraded to become a “Full Profile” once this person seems to be someone we want in the database for JV ministry participation. To do this, open their profile and click on the large “Create a Full Profile” button near their name."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Moving Your Contacts in the “5 Challenges”"}</span>
                        <span className="fontPoppinsRegular13Gray">{"To move any of your contacts that you are viewing on the “Ministry Map” to a different level in the “5 Challenges of Christ,” simply click on the person to open their “5 Challenges” diagnostic tool and then make a new record (See the Diagnostic Lens tool instructions for more). Once you’ve made a new record, you will be taken back to the My Disciple-Making page, where you can see their position changed to the new column on the map."}</span>
                    </div>);
        }
        else if(id == 5){
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Introduction"}</span>
                        <span className="fontPoppinsRegular13Gray">{"Get an interactive overview of your work and the work happening in churches and youth groups across your country. Instead of creating custom visuals to explain your ministry data, you can use the “Dashboard” page to filter the information as needed and display it sharply and cleanly. The charts are connected with a map of your country showing relevant location information. The “Filter by Date” feature adjusts data accordingly across the dashboard. You can also compare the various conversation types from your team and filter them from person to person. When you set up the dashboard for a report, you can quickly export a PDF."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Filtering Information in the Dashboard Tools"}</span>
                        <span className="fontPoppinsRegular13Gray">{"The “Filter” tool at the top affects the information on each of these ”Dashboard Tools.” There is a default time set to show data for the last twelve months that you can edit by clicking the ”Filter by Dates” button. You can also filter the information by different types of churches by clicking on the ”Filter by Churches” button. These global filters also affect the Conversations tool in the bottom right. Still, it includes one additional filter called ”Filter by Who” that allows you to narrow down the ”Conversations Tool” data by individuals or all conversations within the specified dates and church types."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Using the Map"}</span>
                        <span className="fontPoppinsRegular13Gray">{"As you filter information on the dashboard, you will see the locations for churches appear on the map as pin icons. You can hover over them to view the name and location. Clicking on a church's pin icon will take you to that church's contact profile. If you have permission to access the information of multiple countries, you can click on the country name at the top to choose to view dashboard information for a different country."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Exporting Data"}</span>
                        <span className="fontPoppinsRegular13Gray">{"First, adjust the filters on the dashboard to show the most relevant information. Then click ”Download PDF” in the upper right to download a PDF graphic of all the dashboard tools. You can use this to show in a presentation, a report document, etc."}</span>
                    </div>);
        }
        else{
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Introduction"}</span>
                        <span className="fontPoppinsRegular13Gray">{"Get an interactive overview of your work and the work happening in churches and youth groups across your country. Instead of creating custom visuals to explain your ministry data, you can use the “Dashboard” page to filter the information as needed and display it sharply and cleanly. The charts are connected with a map of your country showing relevant location information. The “Filter by Date” feature adjusts data accordingly across the dashboard. You can also compare the various conversation types from your team and filter them from person to person. When you set up the dashboard for a report, you can quickly export a PDF."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Filtering Information in the Dashboard Tools"}</span>
                        <span className="fontPoppinsRegular13Gray">{"The “Filter” tool at the top affects the information on each of these ”Dashboard Tools.” There is a default time set to show data for the last twelve months that you can edit by clicking the ”Filter by Dates” button. You can also filter the information by different types of churches by clicking on the ”Filter by Churches” button. These global filters also affect the Conversations tool in the bottom right. Still, it includes one additional filter called ”Filter by Who” that allows you to narrow down the ”Conversations Tool” data by individuals or all conversations within the specified dates and church types."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Using the Map"}</span>
                        <span className="fontPoppinsRegular13Gray">{"As you filter information on the dashboard, you will see the locations for churches appear on the map as pin icons. You can hover over them to view the name and location. Clicking on a church's pin icon will take you to that church's contact profile. If you have permission to access the information of multiple countries, you can click on the country name at the top to choose to view dashboard information for a different country."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Exporting Data"}</span>
                        <span className="fontPoppinsRegular13Gray">{"First, adjust the filters on the dashboard to show the most relevant information. Then click ”Download PDF” in the upper right to download a PDF graphic of all the dashboard tools. You can use this to show in a presentation, a report document, etc."}</span>
                    </div>);
        }
    }

    getImages = (id) => {
        if(id == 1){
            return (<div className="verticalStack">
                        <img className="" style={{width: "100%"}} src={instructions_example} />
                    </div>);
        }
        else if(id == 2){
            return (<div className="verticalStack">
                        <img className="" style={{width: "100%"}} src={instructions_conversations_1} />
                        <br/>
                        <img className="" style={{width: "100%"}} src={instructions_conversations_2} />
                    </div>);
        }
        else if(id == 3){
            return (<div className="verticalStack">
                        <img className="" style={{width: "100%"}} src={instructions_diagnostics_1} />
                        <br/>
                        <img className="" style={{width: "100%"}} src={instructions_diagnostics_2} />
                    </div>);
        }
        else if(id == 4){
            return (<div className="verticalStack">
                        <img className="" style={{width: "100%"}} src={instructions_disciples_1} />
                        <br/>
                        <img className="" style={{width: "100%"}} src={instructions_disciples_2} />
                    </div>);
        }
        else if(id == 5){
            return (<div className="verticalStack">
                        <img className="" style={{width: "100%"}} src={instructions_dashboard_1} />
                    </div>);
        }
        else{
            return (<div className="verticalStack">
                        <img className="" style={{width: "100%"}} src={instructions_example} />
                    </div>);
        }
    }

    openNextPage = () => {
        var newValue = this.state.actualPage+1;
        if(newValue > this.state.allPagesList.length) newValue = 1;
        this.setState({ actualPage: newValue });
    }

    openPrevPage = () => {
        var newValue = this.state.actualPage-1;
        if(newValue < 1) newValue = this.state.allPagesList.length;
        this.setState({ actualPage: newValue });
    }



	render(){
		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={this.state.modalExpanded}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{this.getTitle(this.state.actualPage)}</span>
                            </div>

                            <div className="verticalStack">
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                                    <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStackCenter" style={{padding: 12}}>
                            <img className="onHoverToOrange clickable" style={{height: "36px"}} src={instructions_left} onClick={() => this.openPrevPage()}/>

                            <div style={{width: 20}}></div>

                            <div className="flex">
                                {this.getImages(this.state.actualPage)}
                            </div>

                            <div style={{width: 40}}></div>

                            <div className="verticalStack flex" style={{ height: 500, overflowY: "scroll"}}>
                                {this.getDescription(this.state.actualPage)}
                            </div>

                            <div style={{width: 20}}></div>
                            <img className="onHoverToOrange clickable" style={{height: "36px"}} src={instructions_right} onClick={() => this.openNextPage()}/>
                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}

}

export default InstructionsModal;