import React, { useState, useEffect, useMemo } from "react";

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_action_up from '../assets/icon_action_up.png';

export function getInitials(name) {
    if(name && name.length > 0 && name != " "){
        const wordsArray = name.split(" ");
        //if(wordsArray.length)
        //alert(JSON.stringify(wordsArray));
        var initials = "";
        var index = 0;
        for(let item of wordsArray){
            if(index >= 2){break;}
            if(item.length > 0){
                initials = initials+""+Array.from(item)[0].toUpperCase();
                index = index+1;
            }
        }
        return initials;
    }
    return "";
}

export function showMobileVersion() {
    if(window.innerWidth < 1000){
        return true;
    }
    return false;
}

export function deviceWidthLowerThan(value) {
    if(window.innerWidth < value){
        return true;
    }
    return false;
}

export function showSortIcon(attribute, sortBy, sortTp) {
    if(attribute == sortBy){
        if(sortTp == "asc")return(<img style={{width: "10px", height: "6.3px", marginLeft: 3, marginBottom: 2}} src={icon_action_down}/>);
        else if(sortTp == "desc")return(<img style={{width: "10px", height: "6.3px", marginLeft: 3, marginBottom: 2}} src={icon_action_up}/>);
    }
}

export function getTodaysDate() {
    var dt = new Date();

    var year  = dt.getFullYear();
    var month = (dt.getMonth() + 1).toString().padStart(2, "0");
    var day   = dt.getDate().toString().padStart(2, "0");

    return(year + '-' + month + '-' + day);
}