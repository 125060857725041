import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';

// assets
import close_white from '../assets/close_white.png';
import icon_password_show from '../assets/icon_password_show.png';
import icon_password_hide from '../assets/icon_password_hide.png';

class ChangePasswordModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // MyDiscipling
            redirectTo: null,
            user_name: "",
            password_one: "",
            password_two: "",
            show_password_one: false,
            show_password_two: false,
        };
    }

    componentDidMount(){

        var token_object = JSON.parse(localStorage.getItem("token"));
        if(token_object){
            // try to load token and user
            this.setState({ user_name: token_object.first_name+" "+token_object.last_name || "Error" });
        }
        else if(this.props.lostToken && this.props.lostToken.length > 1){
            // here I should get the LostToken (recieved from email)
        }
        else {
            this.props.openAlertMessageTwoActions("Some error happened. Please contact our support.","OK","",null);
        }

    }

    changePasswordAction = () => {
        if(this.state.password_one == "" || this.state.password_two == ""){
            this.props.openAlertMessageTwoActions("Please fill both fields.","OK","",null);
            return;
        }
        else if(this.state.password_one.length < 8){
            this.props.openAlertMessageTwoActions("Password must be 8 characters or longer.","OK","",null);
            return;
        }
        else if(this.state.password_one != this.state.password_two){
            this.props.openAlertMessageTwoActions("Passwords don't match.","OK","",null);
            return;
        }

        var lostToken = "";
        if(this.props.lostToken && this.props.lostToken.length > 1){
            lostToken = this.props.lostToken;
        }

        Axios.post('/api/usr/pwd/reset', {
            OldPwd: "",
            NewPwd: this.state.password_one,
            ConfPwd: this.state.password_two,
            LostToken: lostToken,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            this.props.closeModal(this.state.editingMode);

            // if lostToken is not empty it means I am changing password outside of the system (redirect to login with success message)
            if(lostToken != ""){
                window.location.href = "/?reset_password=success";
            }
        });

    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode)} isExpanded={false} isExtraExpanded={false} wrapContent={true}>
                    <div className="content" style={{width: 550}}>
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{"Change Password"}</span>
                                <span className="fontPoppinsSemiBold15Orange">{(this.state.user_name && this.state.user_name != "") ? this.state.user_name : ""}</span>
                            </div>

                            <div className="verticalStack">
                                <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode)}/>
                                <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStack" style={{width: "inherit", marginTop: 10, paddingTop: 12, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 14, marginRight: 14, marginBottom: 14}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 0}}>Enter a New Password</span>
                                <span className="fontPoppinsItalic13Gray" style={{marginBottom: 8}}>(Minimum 8 characters, please make it a complex password)</span>
                                <div className="horizontalStackCenter">
                                    <input className="profileTileInputText flex" type={this.state.show_password_one ? "text" : "password"} id="new-password" name="new-password" autocomplete="new-password" value={this.state.password_one} onChange={e => this.setState({ password_one: e.target.value })}/>
                                    <img className="clickable" src={this.state.show_password_one ? icon_password_show : icon_password_hide} style={{marginLeft: 7, width: 20}} onClick={() => this.setState({ show_password_one: !this.state.show_password_one })}/>
                                </div>
                                <span className="fontPoppinsRegular13" style={{marginTop: 14, marginBottom: 8}}>Re-Enter the New Password</span>
                                <div className="horizontalStackCenter">
                                    <input className="profileTileInputText flex" type={this.state.show_password_two ? "text" : "password"} id="new-password" name="new-password" autocomplete="new-password" value={this.state.password_two} onChange={e => this.setState({ password_two: e.target.value })}/>
                                    <img className="clickable" src={this.state.show_password_two ? icon_password_show : icon_password_hide} style={{marginLeft: 7, width: 20}} onClick={() => this.setState({ show_password_two: !this.state.show_password_two })}/>
                                </div>
                            </div>

                        </div>

                        <div className="horizontalStack" style={{marginTop: 14}}>
                            <RoundButton title={"Save Password"} className={"roundButtonOrange"} style={{whiteSpace: "nowrap"}} whiteText={true} onClick={() => this.changePasswordAction()} />
                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}
}

export default ChangePasswordModal;