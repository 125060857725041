import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import { CSSTransition } from "react-transition-group";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { goToPrevPageUtilities, goToNextPageUtilities, goToFirstPageUtilities, goToLastPageUtilities } from '../utilities/PagingUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import InstructionsModal from '../modals/InstructionsModal.js';

// import assets
import instructions_left from '../assets/instructions_left.png';
import instructions_right from '../assets/instructions_right.png';
import people from '../assets/icon_people_gray.png';
import icon_glasses from '../assets/icon_glasses.png';
import conversations from '../assets/icon_conversations_gray.png';

class Instructions extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            allPagesList: ([{id: 1, title:"Easy Contact Management", avatar: require("../assets/image_conversation_people.png"), img_width: 100, btn_text: "Learn More", desc: "Save time asking various people for their contact information and figuring out the context of their relationship with Josiah Venture. Manage all of your contacts across JV ministries in one easy-to-use location. You can quickly search and view a profile for each contact containing helpful information such as their conversations with JV, roles, relationships, personal growth, and ministry growth. You can easily create new contacts or add existing profiles to “My Contacts” and get a quick overview of your work with them. You will be able to organize and empower your team’s work as you approach your contacts with contextual wisdom and team unity."},
                         {id: 2, title:"Coordination by Conversations", avatar: require("../assets/image_instructions_conversation.png"), img_width: 90, btn_text: "Learn More", desc: "View your communications with other JV staff, church contacts, and those you are discipling in one place through the conversations tool. You will avoid confusing and conflicting communication experiences while trying to engage with contacts. This tool will help you make relevant offers, knowing the context of their needs through records. You can quickly let others know about your meetings, organized by the conversation category, those involved, and the date, all while using minimal required fields. Extra features include sharing notes and sending your team a notification to email addresses, helping ensure everyone is on the same page."},
                         {id: 3, title:"Powerful Ministry Diagnostics", avatar: require("../assets/icon_glasses.png"), img_width: 150, btn_text: "Learn More", desc: "Don’t get overwhelmed by the complexity of organizing and tracking your ministry’s progress. Enter into discipleship and leadership training with clarity in your next steps for developing others. You can view all the core JV training principles here within each contact’s profile with the “Diagnostics Lens” tool. It is designed to help you track your ministry progress and make observations for others to see about the health and growth of your contacts. You will also benefit from the records of others and see real improvement in your work as you thoughtfully and prayerfully invite others to the challenges of Christ and his discipleship model."},
                         {id: 4, title:"Map Out Your Disciple-Making", avatar: require("../assets/image_instructions_map.png"), img_width: 90, btn_text: "Learn More", desc: "The “My Disciple-Making” page automatically shows how the people you are discipling are moving through the Ministry Map of the 5 Challenges of Christ. Thinking about where all your relationships are in their walk with Jesus can be overwhelming. This tool helps you quickly benefit from the observations of others by viewing your contacts’ progress based on the most recent records made from the “5 Challenges” tool. If you see a change needed for someone, you can make a new observation, and they will move accordingly. You can also add “Private Contacts” on this page that are not viewable by others in the system to get a complete overview."},
                         {id: 5, title:"Dynamic Dashboard Data", avatar: require("../assets/image_instructions_data.png"), img_width: 80, btn_text: "Learn More", desc: "Get an interactive overview of your work and the work happening in churches and youth groups across your country. Instead of creating custom visuals to explain your ministry data, you can use the “Dashboard” page to filter the information as needed and display it sharply and cleanly. The charts are connected with a map of your country showing relevant location information. The “Filter by Date” feature adjusts data accordingly across the dashboard. You can also compare the various conversation types from your team and filter them from person to person. When you set up the dashboard for a report, you can quickly export a PDF."},
                         {id: 6, title:"Remote Coaching Available", avatar: require("../assets/image_instructions_remote.png"), img_width: 110, btn_text: "Get Support", desc: "Our Ministry Portal team is eager to help you streamline your workflow. We want to reduce the complexity and stress of coordinating all your ministry work by assisting you with this tool. You can quickly send a question to the Help Desk or request a zoom session. If your team is available for coaching, we would love to plan a time to cover use cases and work with your unique needs. Each team will benefit from this tool’s core features, and we are listening to your feedback on how we can improve the system. As new features and updates come out, we will keep you updated and continue to help you use them for the context of your work."}]),
            showPagesList: ([{}]),
            recordsOnOnePage: 3,
            actualPage: 0,
            prepareToShowModalInstructions: false,
            showModalInstructions: false,
            openInstructionObject: {},
            doAnimationDown: false,
            doAnimationUp: false,
            animCenterToRight: false,
            animCenterToLeft: false,
            animRightToCenter: false,
            animLeftToCenter: false,
            versionName: "loading...",
            versionDetail: ([{id:1, label: "Some label", descr: "Some description"}])
        }

        this.itemsParent = React.createRef();

    }

    componentDidMount(){
        this.loadData();
        this.setActualSection("instructions");
    }

    loadData = () => {
        this.setState({ showPagesList: ([{}]) });

        var index = 0;
        for(let oneRow of this.state.allPagesList){

            //if(index >= this.state.recordsOnOnePage)break;

            if(index >= (this.state.actualPage*this.state.recordsOnOnePage) && index < ((this.state.actualPage+1)*this.state.recordsOnOnePage)){
                this.setState(prevState => ({
                    showPagesList: [...prevState.showPagesList, oneRow]
                }))
            }

            index++;
        }

        Axios.get('/api/vers/get?is_crnt=1').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ versionName: response.data.result[0].label });

            Axios.get('/api/versd/get?is_crnt=1').then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                this.setState({ versionDetail: response.data.result });
            });
        });
    }

    goToPrevPage = async () => {
        await this.setState({ animCenterToRight: true });
        await new Promise(resolve => setTimeout(resolve, 400));

        // if actual page is the first go to the last
        if(this.state.actualPage == goToPrevPageUtilities(this.state.actualPage)){
            await this.setState({ actualPage: goToLastPageUtilities(this.state.recordsOnOnePage, this.state.allPagesList.length) });
        }
        else {
            await this.setState({ actualPage: goToPrevPageUtilities(this.state.actualPage) });
        }
        this.loadData();

        await this.setState({ animLeftToCenter: true });
        await new Promise(resolve => setTimeout(resolve, 400));
        await this.setState({ animCenterToRight: false });
        await this.setState({ animLeftToCenter: false });
    }

    goToNextPage = async () => {
        await this.setState({ animCenterToLeft: true });
        await new Promise(resolve => setTimeout(resolve, 400));

        // if actual page is the last go to the first
        if(this.state.actualPage == goToNextPageUtilities(this.state.actualPage, this.state.recordsOnOnePage, this.state.allPagesList.length)){
            await this.setState({ actualPage: goToFirstPageUtilities(this.state.actualPage) });
        }
        else {
            await this.setState({ actualPage: goToNextPageUtilities(this.state.actualPage, this.state.recordsOnOnePage, this.state.allPagesList.length) });
        }
        this.loadData();

        await this.setState({ animRightToCenter: true });
        await new Promise(resolve => setTimeout(resolve, 400));
        await this.setState({ animCenterToLeft: false });
        await this.setState({ animRightToCenter: false });


    }

    openModal = async (modalWindow) => {
        if(modalWindow == "instructions"){
            await this.setState({ prepareToShowModalInstructions: true });
            this.setState({ showModalInstructions: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "instructions"){
            this.setState({ showModalInstructions: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalInstructions: false });
        }
    }

    openConcreteModal = async (instruction) => {
        if(instruction.id == 6){
            this.props.openAlertMessageTwoActions("This function is not working yet.","OK","",null);
            return;
        }
        await this.setState({ openInstructionObject: instruction });
        this.openModal("instructions");
    }

    openVersionDetails = () => {
        var text = "WHAT'S NEW IN VERSION "+this.state.versionName+":";
        var index = 1;
        for(let oneRow of this.state.versionDetail){
            text = text + "\n\n" + index + ". " + oneRow.label + " - " + oneRow.descr;
            index++;
        }
        alert(text);
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "auto", overflowX: "hidden"}}>
                <div className="horizontalStackCenter myPeopleTableListingBar">
                    <span className="fontPoppinsRegular13Gray">{"Instructions"}</span>
                    <div className="flex"/>
                    <span className="fontPoppinsRegular13Gray onHoverToOrange clickable">{"Download PDF"}</span>
                </div>

                <div className="universalTile flex verticalStack" style={{position: "relative", marginRight: 20, marginLeft: 20, marginBottom: 20}}>
                    <span className="fontMontserratBold25DarkBlue">{"WELCOME TO THE "}<span className="fontMontserratBold25Orange">{"JV MINISTRY PORTAL"}</span></span>
                    <span className="fontPoppinsSemiBold13" style={{width: "75%"}}>{"The JV Ministry Portal is a digital tool for staff that helps bring focus and intentionality to developing young leaders across Central and Eastern Europe while coordinating our efforts to build up the local church."}</span>

                    <div className="horizontalStackCenter" style={{height: "100%"}}>
                        <img className="onHoverToOrange clickable" style={{height: "65px"}} src={instructions_left} onClick={() => this.goToPrevPage()}/>

                        <div className="flex"></div>

                        <CSSTransition nodeRef={this.itemsParent} in={this.state.animCenterToLeft} timeout={1000} classNames="instructions-anim-center-to-left">
                        <CSSTransition nodeRef={this.itemsParent} in={this.state.animCenterToRight} timeout={1000} classNames="instructions-anim-center-to-right">
                        <CSSTransition nodeRef={this.itemsParent} in={this.state.animRightToCenter} timeout={1000} classNames="instructions-anim-right-to-center">
                        <CSSTransition nodeRef={this.itemsParent} in={this.state.animLeftToCenter} timeout={1000} classNames="instructions-anim-left-to-center">
                            <div className="horizontalStackBottom" ref={this.itemsParent} style={{width: "90%"}}>

                                {this.state.showPagesList.slice(1).map(function (item) {
                                    return (
                                        <div className="verticalStack" style={{marginLeft: 20, marginRight: 20}}>
                                            <img className="" style={{width: item.img_width}} src={item.avatar}/>
                                            <div className="responsiveThreeDotsContainer" style={{marginTop: 10, marginBottom: 10}}>
                                                <span className="fontPoppinsSemiBold15 responsiveThreeDots">{item.title}</span>
                                            </div>
                                            <span className="fontPoppinsRegular13 instructionDescription">{item.desc}</span>
                                            <div className="horizontalStack" style={{marginTop: 10}}>
                                                <RoundButton title={item.btn_text} className={"roundButtonOrange"} whiteText={true} onClick={() => this.openConcreteModal(item)} />
                                            </div>
                                        </div>
                                    )
                                }.bind(this))}

                            </div>
                        </CSSTransition>
                        </CSSTransition>
                        </CSSTransition>
                        </CSSTransition>

                        <div className="flex"></div>

                        <img className="onHoverToOrange clickable" style={{height: "65px"}} src={instructions_right} onClick={() => this.goToNextPage()}/>
                    </div>

                    <span className="fontPoppinsRegular13PurpleGray clickable onHoverToBlack" style={{position: "absolute", bottom: 14, right: 20}} onClick={() => this.openVersionDetails()}>{"Version "}{this.state.versionName}</span>

                </div>

                {this.state.prepareToShowModalInstructions ? <InstructionsModal showModal={this.state.showModalInstructions} closeModal={this.closeModal} openModal={this.openModal} actionType={"instructions"} oneInstruction={this.state.openInstructionObject} /> : null }

            </div>
        );
    }
}

export default Instructions;
