import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/modals.css';
import Axios from 'axios';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Resizer from "react-image-file-resizer";

// components
import ModalMobile from '../modals_m/ModalMobile';
import RoundButton from '../components/RoundButton.js';
import ProfileImageMobile from '../components_m/ProfileImageMobile.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// assets
import close_white from '../assets/close_white.png';
import background from '../assets/background.jpg';
import icon_expand from '../assets/icon_expand.png';
import icon_add from '../assets/icon_add.png';
import icon_done from '../assets/icon_done.png';

class ProfilePeoplePrivateModalMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectTo: null,
            editingMode: this.props.actionType, // AddProfilePeople, EditProfilePeople
            mainContactId: 0,
            is_private: 1,
            actualProfile: ([{id:1,first_name:"",last_name:"",email:"",phone:"",church:"Error",city:"",country:"",country_id:"",nick_name:"",is_staff:0}]),
            uploadedImageBase64: "",
            isMyContact: false, // default should be false
            sectionNameOpened: false,
            listOfPrefix: ([{id:1, label: "Bc."}]),
            listOfSuffix: ([{id:1, label: "Ph.D."}]),

        };

        this.hiddenFileInput = React.createRef();
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = async () => {
        var id = this.props.contactId;

        if(id != -1 && this.props.actionType == "EditProfilePeople"){
            if(id == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/People"); window.location.reload(); }
                return;
            }
            await Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_cont });
                }
            });
            this.setState({ mainContactId: id });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=avatar&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });

            this.setState({ sectionNameOpened: true });
        }
        else {
            // not editing but creating new contact
            this.setState({ sectionNameOpened: true });
        }

        // get all prefix for prefix select
        Axios.get('/api/prefix/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfPrefix: response.data.result })
        });

        // get all suffix for suffix select
        Axios.get('/api/suffix/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfSuffix: response.data.result })
        });

    }

    /* START OF METHODS FOR AVATAR IMAGE */

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    uploadImageClick = () => { this.hiddenFileInput.current.click(); };

    deleteProfileImage = () => {
        Axios.post('/api/file/delete', {
            FilePrefix: "avatar",
            ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ uploadedImageBase64: "" });
            // reload page so that the change would be shown in TopMenu
            window.location.reload();
        });
    }

    imageIsSelected = async (event) => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded.size > 5000000){
            this.props.openAlertMessage("Error! Maximum size of picture is 5 MB.","OK");
            return;
        }
        var base64 = await this.toBase64(fileUploaded);
        var prefix = fileUploaded.name.substring(fileUploaded.name.lastIndexOf(".")+1);
        //this.setState({ uploadedImageBase64: base64 });

        try {
            Resizer.imageFileResizer(fileUploaded, 300, 300, "JPEG", 100, 0,
                (uri) => {
                    //console.log(uri);
                    this.setState({ uploadedImageBase64: uri });
                    if(this.props.actionType == "EditProfilePeople"){
                        this.uploadAvatarImage(this.state.mainContactId);
                    }
                },
                "base64", 200,  200
            );
        }
        catch (err) {
            //console.log(err);
            this.props.openAlertMessage("Error while uploading image.","OK");
        }
        //var improvedBase64 = base64.substring(base64.lastIndexOf(";base64,")+8);
    };

    uploadAvatarImage = (contactId) => {
        if(this.state.uploadedImageBase64 == "")return;
        Axios.post('/api/file/upload', {
            FilePrefix: "avatar",
            ContId: contactId,
            content: this.state.uploadedImageBase64,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            // reload page so that the change would be shown in TopMenu
            //window.location.reload();
        });
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_cont/del/', {
                data: {ContId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_cont/ins/', {
                ContId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    /* END OF METHODS FOR AVATAR IMAGE */

    nameSectionWasEdited = (actualProfile) => {
        if((typeof(actualProfile.prefix_id) === "undefined" || actualProfile.prefix_id == 0)
        && (typeof(actualProfile.suffix_id) === "undefined" || actualProfile.suffix_id == 0)
        && (typeof(actualProfile.first_name) === "undefined" || actualProfile.first_name == "")
        && (typeof(actualProfile.middle_name) === "undefined" || actualProfile.middle_name == "")
        && (typeof(actualProfile.last_name) === "undefined" || actualProfile.last_name == "")
        && (typeof(actualProfile.nick_name) === "undefined" || actualProfile.nick_name == "")){
            return false;
        }
        else {
            return true;
        }
    }

    checkIfDataAreOk = () => {
        if(this.state.actualProfile[0].first_name == "" || this.state.actualProfile[0].first_name == undefined){
            this.props.openAlertMessage("You didn't fill first name.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: true });
            return false;
        }
        else if(this.state.actualProfile[0].last_name == "" || this.state.actualProfile[0].last_name == undefined){
            this.props.openAlertMessage("You didn't fill last name.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: true });
            return false;
        }

        return true;
    }

    saveAllData = () => {
        if(this.checkIfDataAreOk() == false){ return; }

        var url = '/api/cont/ins';
        if(this.props.contactId != -1 && this.props.actionType == "EditProfilePeople"){
            url = '/api/cont/upd/'+this.props.contactId;
        }

        //alert(this.state.actualProfile[0].prefix_id);
        Axios.post(url, {
            ContTpId: 1, // idType=1 is for person idType=4 is for church
            IsPrivate: this.state.is_private,
            CountryId: this.state.actualProfile[0].country_id,
            FirstName: this.state.actualProfile[0].first_name,
            MiddleName: this.state.actualProfile[0].middle_name,
            LastName: this.state.actualProfile[0].last_name,
            NickName: this.state.actualProfile[0].nick_name,
            PrefixId: this.state.actualProfile[0].prefix_id,
            SuffixId: this.state.actualProfile[0].suffix_id,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            // if setting picture for new user I need to wait until user is created and then I can upload picture
            if(this.state.uploadedImageBase64 != "" && this.props.actionType != "EditProfilePeople"){
                this.uploadAvatarImage(response.data.msg.id);
            }

            this.props.closeModal(this.state.editingMode, false);

            if(this.props.actionType != "EditProfilePeople"){
                this.setState({ redirectTo: "/PeopleProfile/"+response.data.msg.id });
            }
        });
    }

    deleteAction = () => {
        Axios.delete('/api/cont/del/'+this.state.mainContactId, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ redirectTo: "/People" });
        });
    };

    askToDelete = () => {
        this.props.openAlertMessageTwoActions("Are you sure you want to delete this profile?","Cancel","Delete",this.deleteAction);
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
		return(
            <React.Fragment>
                <ModalMobile show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={false}>
                    <div className="content">

                        <div style={{width: "100%", backgroundColor: "var(--full-black)", height: 80, position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                            <img style={{width: "100%", height: "100%", borderRadius: "20px 20px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                        </div>

                        <div className="horizontalStackCenter" style={{height: 120}}>

                            <div className="verticalStack" style={{zIndex: "0", marginTop: "0px", marginRight: "23px"}}>
                                <div className="flex"></div>

                                <ProfileImageMobile
                                    initials={getInitials(this.state.actualProfile[0].first_name+" "+this.state.actualProfile[0].last_name)}
                                    uploadedImageBase64={this.state.uploadedImageBase64}
                                    uploadImageClick={this.uploadImageClick}
                                    deleteImageClick={this.deleteProfileImage}
                                    contactType={"person"}
                                />

                                <input type="file"
                                    ref={this.hiddenFileInput}
                                    style={{display:'none'}}
                                    accept={"image/png, image/jpg, image/jpeg"}
                                    onChange={(event) => this.imageIsSelected(event)}
                                />

                                <div className="flex"></div>
                            </div>

                            <div className="verticalStack" style={{zIndex: "0", height: "80%"}}>
                                <div className="responsiveThreeDotsContainer">
                                    <span className="fontPoppinsMedium20White responsiveThreeDots">{this.state.actualProfile[0].first_name} {this.state.actualProfile[0].last_name}</span>
                                </div>
                                <div className="flex"></div>
                            </div>

                            <div className="verticalStack" style={{height: "100%"}}>
                                <div className="flex"></div>
                                <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                            </div>

                        </div>

                        <div style={{height: 10}}></div>

                        { this.state.uploadedImageBase64 == "" ?
                            <div className="horizontalStackCenter" onClick={() => this.uploadImageClick()}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Add a Profile Image"}</span>
                            </div>
                        :
                            <div className="horizontalStackCenter" onClick={() => this.uploadImageClick()}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Update Profile Image"}</span>
                            </div>
                        }

                        <div style={{height: 15}}></div>

                        { this.state.isMyContact == false ?
                            <div className="horizontalStackCenter" onClick={this.addToMyContacts}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Add to My Contacts"}</span>
                            </div>
                        :
                            <div className="horizontalStackCenter" onClick={this.addToMyContacts}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Added to My Contacts"}</span>
                            </div>
                        }

                        {/* Editing data part */}

                        <div className="flex" style={{flex: 1, position: "relative"}}>

                            <div className="verticalStack" style={{marginTop: 20, marginBottom: 20}}>

                                {/* Name */}

                                <div className="horizontalStackCenter clickable" style={{height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={ () => this.setState({ sectionNameOpened: !this.state.sectionNameOpened })}>
                                    <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.sectionNameOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>
                                    { this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Name*</span> : null }
                                    { !this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Name*</span> : null }
                                </div>

                                <div className="horizontalStack" style={{display: this.state.sectionNameOpened ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                                    <div className="verticalStack">
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Title 1:</span>
                                        <select className="profileTileInputText topMenuSelectBox" name="prefix" id="prefix" style={{width: 70}} value={this.state.actualProfile[0].prefix_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].prefix_id = e.target.value; return {actualProfile}; })}>
                                            <option value=""></option>
                                            {this.state.listOfPrefix.map(function (item) {
                                                return (
                                                    <option value={item.id}>{item.label}</option>
                                                )
                                            }.bind(this))}
                                        </select>
                                    </div>

                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12, marginRight: 12}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>First Name:*</span>
                                        <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputFirstName" name="inputFirstName" value={this.state.actualProfile[0].first_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].first_name = e.target.value; return {actualProfile}; })}/>
                                    </div>
                                </div>

                                <div className="horizontalStack" style={{display: this.state.sectionNameOpened ? "" : "none", width: "inherit", paddingTop: 5, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>
                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12, marginRight: 24}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Middle Name:</span>
                                        <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputMiddleName" name="inputMiddleName" value={this.state.actualProfile[0].middle_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].middle_name = e.target.value; return {actualProfile}; })} />
                                    </div>
                                </div>

                                <div className="horizontalStack" style={{display: this.state.sectionNameOpened ? "" : "none", width: "inherit", paddingTop: 5, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>
                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12, marginRight: 24}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Last Name:*</span>
                                        <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputLastName" name="inputLastName" value={this.state.actualProfile[0].last_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].last_name = e.target.value; return {actualProfile}; })} />
                                    </div>
                                </div>

                                <div className="horizontalStack" style={{display: this.state.sectionNameOpened ? "" : "none", width: "inherit", paddingTop: 5, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>
                                    <div className="verticalStack" style={{marginLeft: 12}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Title 2:</span>
                                        <select className="profileTileInputText topMenuSelectBox" name="suffix" id="suffix" style={{width: 70}} value={this.state.actualProfile[0].suffix_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].suffix_id = e.target.value; return {actualProfile}; })}>
                                            <option value=""></option>
                                            {this.state.listOfSuffix.map(function (item) {
                                                return (
                                                    <option value={item.id}>{item.label}</option>
                                                )
                                            }.bind(this))}
                                        </select>
                                    </div>

                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12, marginRight: 24}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Nickname:</span>
                                        <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputNickname" name="ínputNickname" value={this.state.actualProfile[0].nick_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].nick_name = e.target.value; return {actualProfile}; })} />
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="verticalStack" style={{marginTop: "12px"}}>

                            <div className="roundButton roundButtonOrange" style={{textAlign: "center", marginTop: 0}} onClick={() => this.saveAllData()}>
                                <span className="fontPoppinsRegular13White">{"Save"}</span>
                            </div>

                            <div className="roundButton roundButtonGray" style={{textAlign: "center", marginTop: 10}} onClick={() => this.props.closeModal(this.state.editingMode, false)}>
                                <span className="fontPoppinsRegular13White">{"Cancel"}</span>
                            </div>

                            {this.props.contactId != -1 && this.props.actionType == "EditProfilePeople" ?
                                <div className="roundButton roundButtonGray" style={{textAlign: "center", marginTop: 10}} onClick={() => this.askToDelete()}>
                                    <span className="fontPoppinsRegular13White">{"Delete"}</span>
                                </div>
                            : null }

                        </div>

                    </div>
                </ModalMobile>
            </React.Fragment>
		);
	}

}

export default ProfilePeoplePrivateModalMobile;