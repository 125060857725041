import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate, useLocation } from "react-router-dom";

// import components
import ProfileTileDenominationMobile from '../tiles_m/ProfileTileDenominationMobile.js';

class DenominationProfileMobile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        //this.setActualSection("people");
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return(
                <div className="verticalStack flex" style={{width: "calc(100vw - 60px)", height: "calc(var(--doc-height) - 80px)", overflowX: "hidden", overflowY: "auto"}}>

                    <div className="horizontalStackCenter myPeopleTableListingBar">
                        <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" onClick={() => this.setState({ redirectTo: -1 })}>{"Return to Search Results"}</span>
                        <div className="flex"/>
                    </div>

                    <div className="horizontalStack">

                        <div className="verticalStack flex">
                            <ProfileTileDenominationMobile openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />

                            <div style={{marginTop: "20px"}}></div>
                        </div>

                    </div>
                </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(DenominationProfileMobile);