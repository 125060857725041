import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/menus.css';
import Axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";

// import components
import RoundButton from '../components/RoundButton.js';
import SearchBox from '../components/SearchBox.js';
import GrayTextButton from '../components/GrayTextButton.js';
import ConversationsModalMobile from '../modals_m/ConversationsModalMobile.js';
import ObservationsModalMobile from '../modals_m/ObservationsModalMobile.js';
import ChangePasswordModalMobile from '../modals_m/ChangePasswordModalMobile.js';
import ProfileImageSmall from '../components/ProfileImageSmall.js';
import ProfilePeopleModalMobile from '../modals_m/ProfilePeopleModalMobile.js';
import ProfileChurchesModalMobile from '../modals_m/ProfileChurchesModalMobile.js';
import ProfileSchoolsModalMobile from '../modals_m/ProfileSchoolsModalMobile.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import person_avatar from '../assets/person_avatar.png';
import icon_add from '../assets/icon_add.png';
import icon_expand from '../assets/icon_expand.png';
import icon_close_gray from '../assets/close_gray.png';
import icon_search from '../assets/icon_search.png';

class TopMenuMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user_id: 0,
            first_name: "",
            last_name: "",
            showModalAddConversation: false,
            prepareToShowModalAddConversation: false,
            showModalAddObservation: false,
            prepareToShowModalAddObservation: false,
            showModalProfilePeople: false,
            prepareToShowModalProfilePeople: false,
            showModalProfileChurches: false,
            prepareToShowModalProfileChurches: false,
            showModalProfileSchools: false,
            prepareToShowModalProfileSchools: false,
            userIsAdmin: 0,
            showAddNewButtonMenu: false,
            showPopupUserMenu: false,
            way: 2,
            linkUrl: "", // ./NewContactPerson
            uploadedImageBase64: "",
        };

        this.refLink = React.createRef();
        this.refAddNewButton = React.createRef();
        this.refUserMenu = React.createRef();

    }

    logoutUser = () => {
        localStorage.removeItem("leftMenuOpened");
        localStorage.setItem('token', null);
        window.location.reload();
    }

    componentDidMount(){
        var token_object = JSON.parse(localStorage.getItem("token"));
        //this.setState({ way: saved_way || 1 });
        this.setState({ user_id: token_object.id || 0 });
        this.setState({ first_name: token_object.first_name || "" });
        this.setState({ last_name: token_object.last_name || "" });
        this.loadData();
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refAddNewButton.current.contains(e.target)) && this.state.showAddNewButtonMenu) {
            this.setState({ showAddNewButtonMenu: false })
        }
        if ( (!this.refUserMenu.current.contains(e.target)) && this.state.showPopupUserMenu) {
            this.setState({ showPopupUserMenu: false })
        }
    }

    loadData = async () => {
        try {
            var token_object = JSON.parse(localStorage.getItem("token"));
            this.setState({ userIsAdmin: token_object.is_admin });
        }
        catch(error){
            this.setState({ userIsAdmin: 0 });
        }

        try {
            var cont_id = token_object.cont_id;
            if(Number.isInteger(Number(cont_id))){
                // get avatar image of contact
                Axios.get('/api/file/load?file_prefix=avatar&cont_id='+cont_id).then(response => {
                    if(response.data.stat && response.data.stat == "ERR"){
                        // avatar image was not loaded
                    }
                    else this.setState({ uploadedImageBase64: response.data });
                });
            }
        }
        catch(error){
            // error
        }
    }

    actionQuickSearch = async () => {
        await this.props.setTopMenuExpanded(false);
        await this.props.setActualSection("quickSearch");
        var searchText = this.props.searchText;
        if(searchText == undefined || searchText == "")return;
        navigate("/QuickSearch?search="+searchText);
        window.location.reload();
    }

    actionCancel = () => {
        this.props.setTopMenuExpanded(false);
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddConversation"){
            await this.setState({ prepareToShowModalAddConversation: true });
            this.setState({ showModalAddConversation: true });
        }
        else if(modalWindow == "AddObservation"){
            await this.setState({ prepareToShowModalAddObservation: true });
            this.setState({ showModalAddObservation: true });
        }
        else if(modalWindow == "ChangePassword"){
            await this.setState({ prepareToShowModalChangePassword: true });
            this.setState({ showModalChangePassword: true });
        }
        else if(modalWindow == "AddProfilePeople"){
            await this.setState({ prepareToShowModalProfilePeople: true });
            this.setState({ showModalProfilePeople: true });
        }
        else if(modalWindow == "AddProfileChurches"){
            await this.setState({ prepareToShowModalProfileChurches: true });
            this.setState({ showModalProfileChurches: true });
        }
        else if(modalWindow == "AddProfileSchools"){
            await this.setState({ prepareToShowModalProfileSchools: true });
            this.setState({ showModalProfileSchools: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "AddConversation"){
            this.setState({ showModalAddConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddConversation: false });
        }
        else if(modalWindow == "AddObservation"){
            this.setState({ showModalAddObservation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddObservation: false });
        }
        else if(modalWindow == "ChangePassword"){
            this.setState({ showModalChangePassword: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalChangePassword: false });
        }
        else if(modalWindow == "AddProfilePeople"){
            this.setState({ showModalProfilePeople: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalProfilePeople: false });
        }
        else if(modalWindow == "AddProfileChurches"){
            this.setState({ showModalProfileChurches: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalProfileChurches: false });
        }
        else if(modalWindow == "AddProfileSchools"){
            this.setState({ showModalProfileSchools: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalProfileSchools: false });
        }
    }

    viewEditProfile = async () => {
        if(this.state.user_id == 0){
            this.props.openAlertMessageTwoActions("Some error occurred.","OK","",null);
            return;
        }

        // get cont_id from user_id
        Axios.get('/api/usr/get/'+this.state.user_id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            if(response.data.result[0].cont_id != undefined){
                this.clickLink("./PeopleProfile/"+response.data.result[0].cont_id);
            }
            else {
                this.props.openAlertMessageTwoActions("Some error occurred.","OK","",null);
            }
        });
    }

    clickLink = async (url) => {
        await this.setState({ linkUrl: url });
        this.refLink.current.click();
    }

    render(){
        return(
            <div className="verticalStack">
                <Link ref={this.refLink} to={this.state.linkUrl}></Link>
                <div className="mainTopMenu horizontalStackCenter">

                        {!this.state.showAddNewButtonMenu ? <SearchBox searchText={this.props.searchText} setSearchText={this.props.setSearchText} onEnter={this.actionQuickSearch} setActualSection={this.props.setActualSection} way={this.state.way} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                        {this.state.showAddNewButtonMenu ? <div className="circleButtonContainer circleButtonContainerEmpty clickable" style={{width: 30, height: 30, borderRadius: 40}} onClick={() => this.setState({ showAddNewButtonMenu: !this.state.showAddNewButtonMenu })}>
                            <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_search}/>
                        </div> : null }

                        <div className="flex"/>

                        <div className="horizontalStackCenter" ref={this.refAddNewButton} style={{border: "none", position: "relative", marginRight: "10px", marginLeft: 10}}>
                            <div className="addNewButtonDropdownVisible" style={{zIndex: 13}}> {/* I use addNewButtonDropdownVisible on another places but here I need to be even higher in zLevel */}

                                <div className="circleButtonContainer circleButtonContainerBlue clickable" style={{display: this.state.showAddNewButtonMenu ? "none" : "", width: 25, height: 25, borderRadius: 40}} onClick={() => this.setState({ showAddNewButtonMenu: !this.state.showAddNewButtonMenu })}>
                                    <img className="circleButton" style={{width: "18px", height: "18px", opacity: "0.9"}} src={icon_add}/>
                                </div>

                                <RoundButton title={"Add New"} icon={icon_add} onClick={() => this.setState({ showAddNewButtonMenu: !this.state.showAddNewButtonMenu })} style={{display: this.state.showAddNewButtonMenu ? "" : "none"}} />

                            </div>

                            <div className="verticalStackCenter addNewButtonDropdownHidden" style={{display: this.state.showAddNewButtonMenu ? "block" : "", zIndex: 12}}> {/* I use addNewButtonDropdownHidden on another places but here I need to be even higher in zLevel */}

                                <div className="verticalStackCenter" style={{padding: 16}}>

                                    <div className="addNewButtonPeopleContainer">
                                        <div className="horizontalStackCenter">
                                            <span className="fontPoppinsSemiBold15 addNewButtonPeopleText">{"People"}</span>
                                        </div>
                                        <div className="addNewButtonPeopleList">
                                            <div className="verticalStack">
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ showAddNewButtonMenu: false });this.openModal("AddProfilePeople");}}>Person</span>
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ showAddNewButtonMenu: false });this.openModal("AddConversation");}}>Conversation</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{height: 5}}></div>

                                    <div className="addNewButtonChurchesContainer">
                                        <div className="horizontalStackCenter">
                                            <span className="fontPoppinsSemiBold15 addNewButtonChurchesText">{"Churches"}</span>
                                        </div>
                                        <div className="addNewButtonChurchesList">
                                            <div className="verticalStack">
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ showAddNewButtonMenu: false });this.openModal("AddProfileChurches");}}>Church</span>
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ showAddNewButtonMenu: false });this.openModal("AddObservation");}}>Observation</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div style={{height: 5}}></div>

                                    <div className="addNewButtonChurchesContainer">
                                        <div className="horizontalStackCenter">
                                            <span className="fontPoppinsSemiBold15 addNewButtonChurchesText">{"Schools"}</span>
                                        </div>
                                        <div className="addNewButtonChurchesList">
                                            <div className="verticalStack">
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ showAddNewButtonMenu: false });this.openModal("AddProfileSchools");}}>School</span>
                                            </div>
                                        </div>
                                    </div>*/}

                                </div>

                            </div>
                        </div>

                        <div style={{width: "2px", height: "100%", backgroundColor: "var(--lightest-gray)"}}/>

                        <div className="topMenuUserButtonDropdownContainerMobile" ref={this.refUserMenu} style={{boxShadow: this.state.showPopupUserMenu ? "0px 8px 16px 0px rgba(0,0,0,0.2)" : ""}}>

                            <div className="topMenuUserButtonDropdownVisibleMobile">
                                <div className="horizontalStackCenter topMenuUserButton clickable" style={{paddingLeft: 10, paddingRight: 10}} onClick={() => this.setState({ showPopupUserMenu: !this.state.showPopupUserMenu })}>

                                    <ProfileImageSmall
                                        name={this.state.first_name+" "+this.state.last_name}
                                        uploadedImageBase64={this.state.uploadedImageBase64}
                                        size={42}
                                    />

                                </div>
                            </div>

                            <div className="topMenuUserButtonDropdownHiddenMobile" style={{display: this.state.showPopupUserMenu ? "block" : "", marginLeft: -100}}>

                                <div className="flex horizontalStackCenter topMenuUserButtonDropdownRow" onClick={() => this.viewEditProfile()}>
                                    <span className="fontPoppinsRegular13DarkGray">{"View / Edit Profile"}</span>
                                    <div className="flex"></div>
                                </div>

                                <div className="topMenuUserButtonDropdownRow" onClick={() => this.openModal("ChangePassword")}>
                                    <span className="fontPoppinsRegular13DarkGray">{"Change Password"}</span>
                                    <div className="flex"></div>
                                </div>

                                {/*<div className="topMenuUserButtonDropdownRow" style={{display: this.state.userIsAdmin == 1 ? "" : "none"}}>
                                    <Link className="link" to={"/Rights"} >
                                        <span className="fontPoppinsRegular13DarkGray">{"Admin section"}</span>
                                    </Link>
                                    <div className="flex"></div>
                                </div>*/}

                                <div className="topMenuUserButtonDropdownRow">
                                    <span className="fontPoppinsRegular13DarkGray" onClick={this.logoutUser}>{"Log Out"}</span>
                                    <div className="flex"></div>
                                </div>

                            </div>
                        </div>
                </div>

                {this.state.prepareToShowModalAddConversation ? <ConversationsModalMobile showModal={this.state.showModalAddConversation} closeModal={this.closeModal} openModal={this.openModal} contactId={-1} contactName={""} actionType={"AddConversation"} conversationId={-1} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalAddObservation ? <ObservationsModalMobile showModal={this.state.showModalAddObservation} closeModal={this.closeModal} openModal={this.openModal} contactId={-1} contactName={""} actionType={"AddObservation"} observationId={-1} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalChangePassword ? <ChangePasswordModalMobile showModal={this.state.showModalChangePassword} closeModal={this.closeModal} openModal={this.openModal} actionType={"ChangePassword"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalProfilePeople ? <ProfilePeopleModalMobile showModal={this.state.showModalProfilePeople} closeModal={this.closeModal} contactId={-1} actionType={"AddProfilePeople"} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalProfileChurches ? <ProfileChurchesModalMobile showModal={this.state.showModalProfileChurches} closeModal={this.closeModal} contactId={-1} actionType={"AddProfileChurches"} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalProfileSchools ? <ProfileSchoolsModalMobile showModal={this.state.showModalProfileSchools} closeModal={this.closeModal} contactId={-1} actionType={"AddProfileSchools"} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
            </div>
        );
	}
}

export default TopMenuMobile;