import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';

// import components
import ProfileTilePrivate from '../tiles/ProfileTilePrivate.js';
import EmptyDiagnosticTile from '../tiles/EmptyDiagnosticTile.js';
import EmptyClosedTile from '../tiles/EmptyClosedTile.js';

class NewPrivateContactPerson extends React.Component {
    setActualSection = this.props.setActualSection;

    componentDidMount(){
        this.setActualSection("people");
    }

    render() {
        return(
                <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>
                    <div className="horizontalStack" style={{paddingTop: 20}}>

                       <div className="verticalStack flex">
                            <ProfileTilePrivate openAlertMessage={this.props.openAlertMessage} />

                           <div style={{marginTop: "20px"}}></div>

                            <EmptyClosedTile />

                            <div style={{marginTop: "20px"}}></div>
                        </div>

                        <div className="verticalStack">
                            <EmptyDiagnosticTile />
                        </div>

                    </div>
                </div>
        );
	}
}

export default NewPrivateContactPerson;