import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import assets
import btn_back_gray from '../assets/btn_back_gray.png';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import ObservationsTileMobile from '../tiles_m/ObservationsTileMobile.js';

class ObservationsListMobile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            observationsList: ([]),
            totalNumberOfContacts: 0,
            numOfLoadedContacts: 20,
            showLoadingRow: false,
            contactId: -1,
            contactName: "LOADING...",
            pathname: "",
            dataAreLoadingNow: false,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.allOrMy !== this.props.allOrMy) {
            this.loadData();
        }
        if(this.state.pathname != this.props.location.pathname){
            this.setState({ contactId: -1 });
            this.setState({ contactName: "Loading..." });
            this.loadData();
        }
    }

    loadMoreData = async () => {
        // this condition should prevent to run this method more times in the same moment
        // second part of this condition should prevent to run this method when all records are already loaded
        if(!this.state.dataAreLoadingNow && (this.state.totalNumberOfContacts > this.state.numOfLoadedContacts)){
            await this.setState({ dataAreLoadingNow: true });
            await this.setState({ numOfLoadedContacts: this.state.numOfLoadedContacts+20 });
            this.loadData();
        }
    }

    loadData = () => {
        //this.setActualSection("churches");

        const pathname = this.props.location.pathname;
        this.setState({ pathname: pathname });
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){
            this.setState({ contactId: id });

            // get name of church
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                var contactName = response.data.result[0].short_name;
                contactName = contactName.toUpperCase();
                this.setState({ contactName: contactName });
            });

            // get observations of church
            Axios.get('/api/obs/get?cont_id='+id+'&limit='+this.state.numOfLoadedContacts+'&offset=0').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ observationsList: response.data.result });
                this.setState({ totalNumberOfContacts: response.data.msg.cnt });

                this.setState({ dataAreLoadingNow: false });
                if(response.data.msg.cnt > this.state.numOfLoadedContacts) this.setState({ showLoadingRow: true });
                else this.setState({ showLoadingRow: false });
            });
        }
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 80px)"}}>
                <div className="horizontalStackCenter myPeopleTableListingBar">

                    <div className="horizontalStackCenter" style={{flex: 1}}>
                        <div className="horizontalStackCenter" onClick={() => navigate(-1)}>
                            <img src={btn_back_gray} style={{height: 14}} />
                            <span style={{marginLeft: 5, marginTop: 1}} className="fontPoppinsRegular13Gray">{"Return"}</span>
                        </div>
                        <div className="flex"></div>
                        <span className="fontPoppinsSemiBold13" style={{textAlign: "right"}}>{this.state.contactName}{"'S"}  {"OBSERVATIONS"}</span>
                    </div>

                </div>
                <ObservationsTileMobile observationsArray={this.state.observationsList} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} getDataFromApi={this.loadData} loadMoreData={this.loadMoreData} showLoadingRow={this.state.showLoadingRow} />
            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(ObservationsListMobile);
