import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import FiveChallengesModal from '../modals/FiveChallengesModal.js';
import LeadershipTypeModal from '../modals/LeadershipTypeModal.js';
import JvEngagementModal from '../modals/JvEngagementModal.js';
import GroToolModal from '../modals/GroToolModal.js';
import MinistryPhasesModal from '../modals/MinistryPhasesModal.js';
import PopupButtonDescription from '../components/PopupButtonDescription.js';

// import assets
import icon_glasses from '../assets/icon_glasses.png';
import icon_expand from '../assets/icon_expand.png';

class DiagnosticTilePrivate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedRow: "null", // disciple, leader, ministry, church
            showModalFiveChallenges: false,
            prepareToShowModalFiveChallenges: false,
            openAfter: "",
            showModalGroToolModal: false,
            prepareToShowModalGroToolModal: false,
            fiveChallengesLevel: "-", // D0
            groToolLevel: "-", // GRO:00
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
            //listOfDiagnosticLenses: ([{id:1, segm_id: 1, seq_id: 5, cont_id: 1, segm_cd: "dsc", seg_val: 5, seg_cd: "D5"}]),
        }
    }

    async componentDidMount(){
        await this.loadData();
        await this.setState({ needUpdateVar: this.props.updateVar });

        const { action } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(action == "fromMyDiscipling"){
            await this.setState({ openAfter: "myDiscipling" });
            this.openModal("FiveChallenges");
        }
    }

    loadData = async () => {
        const pathname = window.location.href;
        var contact_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(contact_id.includes("?")){ contact_id = contact_id.substring(0, contact_id.indexOf("?")); }

        if(Number.isInteger(Number(contact_id))){ // if there is number received from URL

            Axios.get('/api/clens/get/'+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                var fiveChallengesLevelChanged = false;
                var groToolLevelChanged = false;

                response.data.result.forEach((row)=>{
                    if(row.segm_id == 1){ // 5 Challenges of Christ
                        this.setState({ fiveChallengesLevel: "D"+row.seg_val });
                        fiveChallengesLevelChanged = true;
                    }
                    if(row.segm_id == 2){ // GRO Health Overall
                        this.setState({ groToolLevel: "GRO:"+row.seg_cd });
                        groToolLevelChanged = true;
                    }
                })

                // set "-" to records that were not recieved
                if(!fiveChallengesLevelChanged) { this.setState({ fiveChallengesLevel: "-" }); }
                if(!groToolLevelChanged) { this.setState({ groToolLevel: "-" }); }

                //this.setState({ listOfDiagnosticLenses: response.data.result })
            });

        }
    }

    openRow = (row) => {
        if(this.state.openedRow == row){
            this.setState({ openedRow: "none" });
        }
        else {
            this.setState({ openedRow: row });
        }
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "FiveChallenges"){
            await this.setState({ prepareToShowModalFiveChallenges: true });
            this.setState({ showModalFiveChallenges: true });
        }
        else if(modalWindow == "GroTool"){
            await this.setState({ prepareToShowModalGroToolModal: true });
            this.setState({ showModalGroToolModal: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "FiveChallenges"){
            this.setState({ showModalFiveChallenges: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalFiveChallenges: false });
        }
        else if(modalWindow == "GroTool"){
            this.setState({ showModalGroToolModal: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalGroToolModal: false });
        }
        this.loadData(); // refresh data when coming back
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className="diagnosticTile">

                <div className="verticalStack">

                    <div className="horizontalStack">
                        <div className="verticalStack flex">
                            <span className="fontPoppinsSemiBold15">Diagnostic Lens Tools</span>
                            <span className="fontPoppinsRegular13Gray clickable onHoverToOrange">Learn More</span>
                        </div>
                        <div className="verticalStack" style={{alignItems: "center"}}>
                            <img className="diagnosticTileIcon" src={icon_glasses}/>
                            <div className="flex"></div>
                        </div>
                    </div>

                    <div className="verticalStack">
                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "24px"}}></div>

                        {/* DISCIPLE LENS */}

                        <div className={["horizontalStackCenter clickable diagnosticTileRow",this.state.openedRow == "disciple" ? "diagnosticTileRowOpened" : ""].join(" ")} onClick={() => this.openRow("disciple")}>
                            <img className="diagnosticLensIconExpand" src={icon_expand} style={{transform: this.state.openedRow == "disciple" ? "rotate(90deg)" : ""}}/>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "disciple" ? "var(--crm-green)" : "var(--jv-orange)", marginLeft: "9px"}}>Disciple Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "disciple" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.fiveChallengesLevel}</span></div>
                            <div style={{width: "9px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "disciple" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.groToolLevel}</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                        {/* DISCIPLE LENS ROWS */}

                        <div style={{display: this.state.openedRow == "disciple" ? "" : "none"}}>

                            <div className="horizontalStack clickable diagnosticTileSubRow" onClick={() => this.openModal("FiveChallenges")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-green)"}}>5 Challenges of Christ</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Discipleship Level:</span>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensCircle diagnosticLensCircleHoverGreen"><span className="fontPoppinsSemiBold15">{this.state.fiveChallengesLevel}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"-26px"} blackArrow={true} />
                                    </div>
                                </div>

                            </div>

                            <div className="horizontalStack clickable diagnosticTileSubRow" onClick={() => this.openModal("GroTool")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-green)"}}>GRO Health Overall</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Grow Level:</span>
                                </div>

                                <div style={{width: "9px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensOval diagnosticLensOvalHoverGreen"><span className="fontPoppinsSemiBold15">{this.state.groToolLevel}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"2px"} blackArrow={true} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                {this.state.prepareToShowModalFiveChallenges ? <FiveChallengesModal showModal={this.state.showModalFiveChallenges} closeModal={this.closeModal} openAfter={this.state.openAfter} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalGroToolModal ? <GroToolModal showModal={this.state.showModalGroToolModal} closeModal={this.closeModal} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
            </div>
        );
    }
}

export default DiagnosticTilePrivate;
