import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';

// import assets
import icon_glasses from '../assets/icon_glasses.png';

class EmptyDiagnosticTile extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="diagnosticTile">

                <div className="verticalStack">

                    <div className="horizontalStack">
                        <div className="verticalStack flex">
                            <div className="emptySpace" style={{width: 180}}></div>
                            <div className="emptySpace" style={{width: 80, marginTop: 10}}></div>
                        </div>
                        <div className="verticalStack" style={{alignItems: "center"}}>
                            <img className="diagnosticTileIcon" src={icon_glasses}/>
                            <div className="flex"></div>
                        </div>
                    </div>

                    <div className="verticalStack">
                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "24px", marginBottom: "11px"}}></div>

                        <div className="horizontalStackCenter">
                            <div className="emptySpace" style={{width: 100}}></div>
                            <div style={{width: 10}}></div>
                            <div className="circleButtonContainer circleButtonContainerEmpty clickable"></div>
                            <div style={{width: 10}}></div>
                            <div className="emptySpace" style={{width: 100}}></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "11px", marginBottom: "11px"}}></div>

                        <div className="horizontalStackCenter">
                            <div className="emptySpace" style={{width: 100}}></div>
                            <div style={{width: 10}}></div>
                            <div className="circleButtonContainer circleButtonContainerEmpty clickable"></div>
                            <div style={{width: 10}}></div>
                            <div className="emptySpace" style={{width: 100}}></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "11px", marginBottom: "11px"}}></div>

                        <div className="horizontalStackCenter">
                            <div className="emptySpace" style={{width: 100}}></div>
                            <div style={{width: 10}}></div>
                            <div className="circleButtonContainer circleButtonContainerEmpty clickable"></div>
                            <div style={{width: 10}}></div>
                            <div className="emptySpace" style={{width: 100}}></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "11px", marginBottom: "11px"}}></div>

                        <div className="horizontalStackCenter">
                            <div className="emptySpace" style={{width: 100}}></div>
                            <div style={{width: 10}}></div>
                            <div className="circleButtonContainer circleButtonContainerEmpty clickable"></div>
                            <div style={{width: 10}}></div>
                            <div className="emptySpace" style={{width: 100}}></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "11px"}}></div>

                    </div>

                </div>

            </div>
        );
    }
}

export default EmptyDiagnosticTile;
