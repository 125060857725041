import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles_m/tiles_m.css';
import '../styles/other.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import assets
import background from '../assets/background.jpg';
import icon_add_white from '../assets/icon_add_white.png';
import icon_add from '../assets/icon_add.png';
import icon_done from '../assets/icon_done.png';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';
import ProfileImageMobile from '../components_m/ProfileImageMobile.js';
import ProfilePeoplePrivateModalMobile from '../modals_m/ProfilePeoplePrivateModalMobile.js';

class ProfileTilePrivateMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            updateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
            mainContactId: 0,
            isMyContact: false, // default should be false
            actualProfile: ([{id:1,first_name:"",last_name:"",email:"",phone:"",church:"Error",city:"",country:"",country_id:"",nick_name:"",is_staff:0}]),
            uploadedImageBase64: "",
            prepareToShowModalProfilePeople: false,
            showModalProfilePeople: false,
        }
    }

    async componentDidMount(){
        this.setState({ updateVar: this.props.updateVar });
        this.loadData();
    }

    loadData = async () => {
        await this.setState({ uploadedImageBase64: "" });
        
        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            if(Number(id) == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/People"); window.location.reload(); }
                return;
            }
            await Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_cont });
                }
            });
            this.setState({ mainContactId: id });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=avatar&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });
        }
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_cont/del/', {
                data: {ContId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_cont/ins/', {
                ContId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "EditProfilePeople"){
            await this.setState({ prepareToShowModalProfilePeople: true });
            this.setState({ showModalProfilePeople: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // close the modal
        if(modalWindow == "EditProfilePeople"){
            this.setState({ showModalProfilePeople: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalProfilePeople: false });
        }
        this.loadData();
    }

    createFullProfile = async () => {
        Axios.post('/api/cont/upd2full/'+this.state.mainContactId).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ redirectTo: "/PeopleProfile/"+this.state.mainContactId });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        else if(this.state.updateVar != this.props.updateVar){
            this.setState({ updateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className="profileTileMobile">
                <div className="flex" style={{flex: 1, position: "relative"}}>

                    <div style={{width: "calc(100% + 40px)", backgroundColor: "var(--full-black)", height: "80px", position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                        <img style={{width: "100%", height: "100%", borderRadius: "25px 25px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                    </div>

                    <div className="horizontalStackCenter" style={{height: "100%"}}>

                        <div className="verticalStack" style={{zIndex: "0", marginTop: "0px", marginRight: "15px"}}>
                            <div className="flex"></div>

                            <ProfileImageMobile
                                initials={getInitials(this.state.actualProfile[0].first_name+" "+this.state.actualProfile[0].last_name)}
                                uploadedImageBase64={this.state.uploadedImageBase64}
                                uploadImageClick={null}
                                deleteImageClick={null}
                                contactType={"person"}
                            />

                            <div className="flex"></div>
                        </div>

                        <div className="verticalStack" style={{zIndex: "0", height: "100%", marginTop: -80}}>
                            <div className="responsiveThreeDotsContainer">
                                <span className="fontPoppinsMedium20White responsiveThreeDots">{this.state.actualProfile[0].first_name}<br/>{this.state.actualProfile[0].last_name}</span>
                            </div>
                            <div className="flex"></div>
                        </div>

                    </div>

                    <div>
                        <div className="verticalStack flex" style={{backgroundColor: "white", paddingTop: 10, overflowX: "visible"}}>

                            <div className="horizontalStack">
                                <RoundButton title={"Create a Full Profile"} className={"roundButtonOrange"} icon={icon_add_white} onClick={this.createFullProfile} whiteText={true} />
                            </div>

                            <div style={{height:20}}></div>

                            <div className="responsiveThreeDotsContainer">
                                <span className="fontPoppinsSemiBold15 responsiveThreeDots">Private Disciple-Making Relationship</span>
                            </div>

                            <div style={{height:10}}></div>

                            <span className="fontPoppinsRegular13">This contact is only viewable by you from the <span className="fontPoppinsRegular13">My Disciple-Making Relationships</span> tool.</span>

                        </div>

                        <div style={{height: "20px"}}></div>

                        { this.state.isMyContact == false ?
                            <div className="horizontalStackCenter" onClick={this.addToMyContacts}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Add to My Contacts"}</span>
                            </div>
                        :
                            <div className="horizontalStackCenter" onClick={this.addToMyContacts}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Added to My Contacts"}</span>
                            </div>
                        }

                        <div style={{height: "20px"}}></div>

                        <div className="horizontalStackCenter" onClick={() => this.openModal("EditProfilePeople")}>
                            <ExpandButton isOpen={false} style={{marginTop: 5}} />
                            <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Edit Contact Info"}</span>
                        </div>

                    </div>
                </div>

                {this.state.prepareToShowModalProfilePeople ? <ProfilePeoplePrivateModalMobile showModal={this.state.showModalProfilePeople} closeModal={this.closeModal} contactId={this.state.mainContactId} actionType={"EditProfilePeople"} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(ProfileTilePrivateMobile);
