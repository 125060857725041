import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import CsvDownloadButton from 'react-json-to-csv';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import utilities
import { moreRecordsOnPageUtilities, lessRecordsOnPageUtilities, goToPrevPageUtilities, goToNextPageUtilities, goToFirstPageUtilities, goToLastPageUtilities, checkIfOutOfPages } from '../utilities/PagingUtilities.js';

// import components
import MySchoolsTile from '../tiles/MySchoolsTile.js';

function storageEventHandler() {
    this.setState({ recordsOnOnePage: localStorage.getItem("recordsOnOnePage") || 25 });
}

class SchoolsList extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            schoolsData: ([]),
            recordsOnOnePage: localStorage.getItem("recordsOnOnePage") || 25, // number of schools showing on one page, 25 is default
            totalNumberOfContacts: 0,
            actualPage: 0, // starts with pageNumber=0, then 1, 2, 3,...
            sortBy: "", // name
            sortTp: "asc", // asc, desc
        }
    }

    componentDidMount(){
        this.loadData();
        window.addEventListener('storage', storageEventHandler, false);
        this.setActualSection("schools");
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.allOrMy !== this.props.allOrMy) {
            this.setState({ actualPage: 0 });
            this.loadData();
        }
    }

    loadData = () => {
        // if user lowers the rowsPerPage, goes next page and then highers the rowsPerPage it will take him to the last page
        if(checkIfOutOfPages(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts)){
            this.goToLastPage();
            return;
        }
        //var getOnlyMyContacts = this.props.searchText == "" ? 1 : 0; // If I am searching it would search in all, if not it gives me my contacts
        // now it works according to the MY/ALL switch, not if there is some searchText or not
        var getOnlyMyContacts = this.props.allOrMy == "my" ? 1 : 0;
        //Axios.get('/api/cont/get?tp=7&my='+getOnlyMyContacts+'&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage)+'&search='+this.props.searchText).then(response => {
        var url = '/api/cont/get?tp=7&my='+getOnlyMyContacts+'&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage);
        if(this.state.sortBy != ""){
            url = url + "&sort_by="+this.state.sortBy+"&sort_tp="+this.state.sortTp;
        }
        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ schoolsData: response.data.result });
            this.setState({ totalNumberOfContacts: response.data.msg.cnt });
            // save the ids array to memory
            var idsArray = response.data.result.map(a => a.id);
            localStorage.setItem('idsArray', JSON.stringify(idsArray));
        });
    }

    goToFirstPage = async () => {
        await this.setState({ actualPage: goToFirstPageUtilities(this.state.actualPage) });
        this.loadData();
    }

    goToPrevPage = async () => {
        await this.setState({ actualPage: goToPrevPageUtilities(this.state.actualPage) });
        this.loadData();
    }

    goToNextPage = async () => {
        await this.setState({ actualPage: goToNextPageUtilities(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts) });
        this.loadData();
    }

    goToLastPage = async () => {
        await this.setState({ actualPage: goToLastPageUtilities(this.state.recordsOnOnePage, this.state.totalNumberOfContacts) });
        this.loadData();
    }

    moreRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: moreRecordsOnPageUtilities(this.state.recordsOnOnePage) });
        this.loadData();
    }

    lessRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: lessRecordsOnPageUtilities(this.state.recordsOnOnePage) });
        this.loadData();
    }

    changeSorting = async (attribute) => {
        if(this.state.sortBy == attribute && this.state.sortTp == "asc") {
            await this.setState({ sortTp: "desc" });
        }
        else if(this.state.sortBy == attribute && this.state.sortTp == "desc") {
            await this.setState({ sortBy: "" });
            await this.setState({ sortTp: "desc" });
        }
        else {
            await this.setState({ sortBy: attribute });
            await this.setState({ sortTp: "asc" });
        }
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(100vh - 80px)"}}>
                <div className="horizontalStackCenter myChurchesTableListingBar">
                    <span onClick={this.goToFirstPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{"<<"}</span>
                    <span onClick={this.goToPrevPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px"}}>{"<"}</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "20px", paddingRight: "12px"}}>{this.props.allOrMy == "my" ? "My" : "All"}{" Schools List"}</span>
                    <span className="fontPoppinsSemiBold16">{(this.state.actualPage*this.state.recordsOnOnePage)+1}{"-"}{(this.state.actualPage+1)*this.state.recordsOnOnePage > this.state.totalNumberOfContacts ? this.state.totalNumberOfContacts : (this.state.actualPage+1)*this.state.recordsOnOnePage }</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "6px", paddingRight: "6px"}}>{"of"}</span>
                    <span className="fontPoppinsSemiBold16">{this.state.totalNumberOfContacts}</span>
                    <span onClick={this.goToNextPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                    <span onClick={this.goToLastPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{">>"}</span>
                    <div className="flex"/>
                    <CsvDownloadButton data={this.state.schoolsData} filename={"export_schools.csv"} className="fontPoppinsRegular13Gray clickable onHoverToOrange" style={{backgroundColor: "transparent", border: "none", paddingRight: "30px"}}>Export CSV</CsvDownloadButton>
                    <span className="fontPoppinsRegular13Gray">{"Rows Per page:"}</span>
                    <span className="fontPoppinsSemiBold16" style={{paddingLeft: "10px", paddingRight: "10px"}}>{this.state.recordsOnOnePage}</span>
                    <div className="verticalStackCenter">
                        <div onClick={this.moreRecordsOnPage} className="clickable buttonPagingUp"></div>
                        <div style={{height: "3px"}}></div>
                        <div onClick={this.lessRecordsOnPage} className="clickable buttonPagingDown"></div>
                    </div>
                </div>
                <MySchoolsTile schoolsArray={this.state.schoolsData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} loadData={this.loadData} allOrMy={this.props.allOrMy} />
            </div>
        );
    }
}

export default SchoolsList;
