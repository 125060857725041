import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';
import SearchPopup from '../components/SearchPopup.js';

// assets
import close_white from '../assets/close_white.png';

class UserDataModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // MyDiscipling
            redirectTo: null,
            user_email: "",
            user_phone: "",
            user_first_name: "",
            user_last_name:"",
            user_jv_country_id: 0,
            user_ministry_country_id: 0,
            user_password: "",
            user_password_repeat: "",
            listOfCountries: ([{"id":1,"label":"ALL"},{"id":2,"label":"Afghanistan"},{"id":3,"label":"Aland Islands"}]),
            contactId: -1,
            contactName: "+ Add a Contact",
            showPopupContact: false,
            findContactByName: "",
            foundContacts: ([]),
            perm_cntry_id: -1,
            mnry_cntry_id: -1,
        };

        this.refPopupContact = React.createRef();
        this.refInputContact = React.createRef();
    }

    componentDidMount(){
        this.loadData();
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    loadData = async () => {
        await Axios.get('/api/perm_cntry/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfCountries: response.data.result })
        });

        if(this.props.userId != undefined && this.props.userId != -1){

            Axios.get('/api/usr/get/'+this.props.userId).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                if(response.data.result[0].cont_id){
                    // get name of the contact
                    Axios.get('/api/cont/get/'+response.data.result[0].cont_id).then(response => {
                        if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                        this.setState({ contactId: response.data.result[0].id });
                        this.setState({ contactName: response.data.result[0].first_name+" "+response.data.result[0].last_name });
                    });
                }

                this.setState({ perm_cntry_id: response.data.result[0].perm_cntry_id >=0 ? response.data.result[0].perm_cntry_id : -1 });
                this.setState({ mnry_cntry_id: response.data.result[0].mnry_cntry_id >=0 ? response.data.result[0].mnry_cntry_id : -1 });
                this.setState({ user_email: response.data.result[0].email ? response.data.result[0].email : "" });
                this.setState({ user_phone: response.data.result[0].phone ? response.data.result[0].phone : "" });
                this.setState({ user_first_name: response.data.result[0].first_name ? response.data.result[0].first_name : "" });
                this.setState({ user_last_name: response.data.result[0].last_name ? response.data.result[0].last_name : "" });

            });

        }
    }

    onGlobalClick = (e) => {
        if ( (!this.refPopupContact.current.contains(e.target)) && this.state.showPopupContact) {
            this.setState({ showPopupContact: false })
        }
        if ( this.state.showPopupContact ) this.refInputContact.current.focus();
    }

    editUserAction = () => {
        if(this.props.userId == -1 || this.state.perm_cntry_id == -1 || this.state.mnry_cntry_id == -1){
            this.props.openAlertMessageTwoActions("Error.","OK","",null);
            return;
        }
        else if(this.state.user_first_name == "" || this.state.user_last_name == ""){
            this.props.openAlertMessageTwoActions("Please fill all required fields.","OK","",null);
            return;
        }

        //alert("Email: "+this.state.user_email+"\nPhone: "+this.state.user_phone+"\nFirst Name: "+this.state.user_first_name+"\nLast Name: "+this.state.user_last_name+"\nJV Country: "+this.state.user_jv_country_id+"\nMinistry Country: "+this.state.user_ministry_country_id+"\nPassword: "+this.state.user_password)

        Axios.post("/api/usr/upd/"+this.props.userId, {
            Email: this.state.user_email,
            FirstName: this.state.user_first_name,
            LastName: this.state.user_last_name,
            Phone: this.state.user_phone,
            ContId: this.state.contactId == -1 ? "" : this.state.contactId,
            PermCntryId: this.state.perm_cntry_id,
            MnryCntryId: this.state.mnry_cntry_id,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            this.props.closeModal(this.state.editingMode);
        });

    }

    searchForContacts = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=1&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundContacts: response.data.result });
            });
        }
        else {
            this.setState({ foundContacts: [] });
        }
    }

    selectFoundContact = async (contactId, contactName) => {
        this.setState({ contactId: contactId });
        this.setState({ contactName: contactName });
        this.setState({ showPopupContact: false });
        this.setState({ findContactByName: "" });
        this.setState({ foundContacts: [] });
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode)} isExpanded={false} isExtraExpanded={false} wrapContent={true}>
                    <div className="content" style={{width: 550}}>
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{"Edit User"}</span>
                                <span className="fontPoppinsRegular13Gray">{"Please fill all required fields."}</span>
                            </div>

                            <div className="verticalStack">
                                <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode)}/>
                                <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStack" style={{width: "inherit", marginTop: 10, paddingTop: 12, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                            <div className="verticalStack" style={{flex: 1, position: "relative", marginLeft: 14, marginRight: 14, marginBottom: 14}}>

                                <div className="horizontalStack">
                                    <div className="verticalStack" style={{flex: 1}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>First Name*</span>
                                        <input className="profileTileInputText" type="text" id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_first_name} onChange={e => this.setState({ user_first_name: e.target.value })}/>
                                    </div>

                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>Last Name*</span>
                                        <input className="profileTileInputText" type="text" id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_last_name} onChange={e => this.setState({ user_last_name: e.target.value })}/>
                                    </div>
                                </div>

                                <div className="horizontalStack" style={{marginTop: 14, marginBottom: 8}}>
                                    {/*<div className="verticalStack" style={{flex: 1}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>Email*</span>
                                        <input className="profileTileInputText" type="text" id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_email} onChange={e => this.setState({ user_email: e.target.value })}/>
                                    </div>*/}

                                    <div className="verticalStack" style={{flex: 1, marginLeft: 0}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>Phone</span>
                                        <input className="profileTileInputText" type="text" id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_phone} onChange={e => this.setState({ user_phone: e.target.value })}/>
                                    </div>
                                </div>

                                <div className="horizontalStackCenter" style={{marginTop: 10}}>
                                    <span className="fontPoppinsRegular13" style={{marginRight: 10}}>Connect this User Profile with Contact Profile: </span>

                                    <SearchPopup
                                        id={"searchContactId"}
                                        textName={this.state.contactName}
                                        textStyle={this.state.contactId == -1 ? "italicOrange" : "normalBlue"}
                                        value={this.state.findContactByName}
                                        changeValue={(text) => {this.setState({ findContactByName: text }); this.searchForContacts(text);}}
                                        foundRecords={this.state.foundContacts}
                                        refPopup={this.refPopupContact}
                                        refInput={this.refInputContact}
                                        showPopup={this.state.showPopupContact}
                                        showHidePopup={() => this.setState({ showPopupContact: !this.state.showPopupContact })}
                                        addContactToArray={(item) => this.selectFoundContact(item.id,item.first_name+" "+item.last_name)}
                                        showEmailStyle={false}
                                    />

                                </div>

                            </div>

                        </div>

                        <div className="horizontalStack" style={{marginTop: 14}}>
                            <RoundButton title={"Save Changes"} className={"roundButtonOrange"} style={{whiteSpace: "nowrap"}} whiteText={true} onClick={() => this.editUserAction()} />
                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}
}

export default UserDataModal;