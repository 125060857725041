import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';

// assets
import close_white from '../assets/close_white.png';
import people_white from '../assets/icon_people_white.png';
import icon_add_white from '../assets/icon_add_white.png';
import icon_search from '../assets/icon_search.png';


class MyDisciplingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // MyDiscipling
            redirectTo: null,
            foundContacts: ([]),
            showPopupFindContact: false,
        };

        this.refPopupFindContacts = React.createRef();
        this.refInputFindContacts = React.createRef();
    }

    componentDidMount(){
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupFindContacts.current.contains(e.target)) && this.state.showPopupFindContact) {
            // user clicked out of the PopupFindContact and that popup is now opened
            this.setState({ showPopupFindContact: false })
        }
        if ( this.state.showPopupFindContact ) this.refInputFindContacts.current.focus();
    }

    searchForContacts = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=1&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                this.setState({ foundContacts: response.data.result });
            });
        }
        else {
            this.setState({ foundContacts: [] });
        }
    }

    addContact = async (contactId) => {

        var d_level = "";

        await Axios.get('/api/clens/get/'+contactId).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            var results = response.data.result;
            for(let oneRow of results){
                if(oneRow.segm_id == 1){
                    d_level = oneRow.seg_val;
                }
            }

        });

        // now we want to add MY_DSC record every time and if there is no D_LEVEL web will ask user to add it
        Axios.post("/api/my_dsc/ins", {
            ContId: contactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.props.closeModal(this.state.editingMode);
        });

        if(d_level == 1 || d_level == 2 || d_level == 3 || d_level == 4 || d_level == 5){
            // we dont need to do anything here for now
        }
        else {
            this.props.closeModal(this.state.editingMode);
            //this.props.showMessageModal("Warning!","Cannot add this person, because there is no \"Discipleship level\" record for him. Please add his \"D level\".");
            this.props.showFiveChallengesModal(contactId);
            //alert("Cannot add this person, because there is no \"D level\" record for him.");
        }
        this.setState({ showPopupFindContact: false });
        this.setState({ findContactByName: "" });
        this.setState({ foundContacts: [] });
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode)} isExpanded={this.state.modalExpanded}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{"Add a Disciple-Making Relationship"}</span>
                                <span className="fontPoppinsRegular13Gray">{"Choose a “Disciple-Making Relationship” type to place on your “Ministry Map” from one of the following options."}</span>
                            </div>

                            <div className="verticalStack">
                                <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode)}/>
                                <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStack" style={{marginTop: 40}}>

                            <div className="flex"></div>
                            <div style={{width: 200}}>

                                <div className="universalPopupSelectContainer" ref={this.refPopupFindContacts}>

                                    <div className="universalPopupSelectVisible horizontalStack">
                                       <RoundButton title={"Choose a Person"} className={"roundButtonOrange"} icon={people_white} style={{whiteSpace: "nowrap"}} whiteText={true} onClick={() => this.setState({ showPopupFindContact: !this.state.showPopupFindContact })} />
                                    </div>

                                    <div className="universalPopupSelectHidden" style={{width: 300, display: this.state.showPopupFindContact ? "" : "none", marginTop: -47, marginLeft: 2}}>
                                        <div className="verticalStack">
                                            <div className="horizontalStackCenter">
                                                <img style={{width: 16, height: 16, marginRight: 5}} src={icon_search}/>
                                                <div className="horizontalStackCenter" style={{position: "relative"}}>
                                                    <input className="fontPoppinsRegular13 searchBoxPlaceholder" type="text" required="required" ref={this.refInputFindContacts} id="searchContact" name="searchContact" autoComplete="off" value={this.state.findContactByName} onChange={e => {this.setState({ findContactByName: e.target.value }); this.searchForContacts(e.target.value);}} style={{flex: 1, marginTop: 5, marginBottom: 5, paddingLeft: 0, border: "0px solid black"}}/>
                                                    <label for={this.props.id} className="fontPoppinsRegular13Gray">Search<span className="fontPoppinsItalic13PurpleGray"> {"(Type 3+ Characters Here)"}</span></label>
                                                </div>
                                            </div>

                                            {this.state.foundContacts.map(function (item, index) {
                                                if (index > 8) { return; }
                                                if (index == 8) { return ( <DropdownItem title={"And more..."} clickable={false} /> ) }
                                                return (
                                                    <DropdownItem title={item.first_name+" "+item.last_name+(item.email ? ", "+item.email : "")} onClick={() => this.addContact(item.id, item.name)} clickable={true} />
                                                )
                                            }.bind(this))}

                                        </div>
                                    </div>

                                </div>

                                <div style={{height: 10}}></div>
                                <span className="fontPoppinsRegular13Gray">{"Choose a person from either “All People” or “My People” from the system’s contacts. If no “5 Challenges” record has been made for this person then you will be directed to the tool to make a record. This person will then be displayed on your “Ministry Map” and can be removed without deleting any of their profile information."}</span>
                            </div>

                            <div style={{width: 200, marginLeft: 20, marginRight: 20}}>

                                <Link className="link" to={"/NewContactPerson?from=MyDiscipling"} >
                                    <RoundButton title={"Add a New Person"} className={"roundButtonOrange"} icon={icon_add_white} style={{whiteSpace: "nowrap"}} whiteText={true} />
                                </Link>
                                <div style={{height: 10}}></div>
                                <span className="fontPoppinsRegular13Gray">{"Start creating a new profile for someone that you want to add to the contacts. After filling out the required fields you will make a record for them in the 5 Challenges tool. This person will then be displayed on your “Ministry Map” and can be removed without deleting any of their profile information."}</span>
                            </div>

                            <div style={{width: 250, marginLeft: 20, marginRight: 20}}>

                                <Link className="link" to={"/NewPrivateContactPerson"} >
                                    <RoundButton title={"Add a New Private Contact"} className={"roundButtonOrange"} icon={icon_add_white} style={{whiteSpace: "nowrap"}} whiteText={true} />
                                </Link>
                                <div style={{height: 10}}></div>
                                <span className="fontPoppinsRegular13Gray">{"You can also add someone to your “Ministry Map” without creating a full profile in the system’s contacts. This will only require entering a name and making a record using the 5 Challenges tool. You can also record notes from a conversation using the Conversations tool which can be accessed from the My Disciple-Making Relationships page. When you delete them from your “Ministry Map” their entire private profile will be deleted from the system."}</span>
                            </div>

                            <div className="flex"></div>

                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}

}

export default MyDisciplingModal;