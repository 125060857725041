import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getTodaysDate } from '../utilities/OtherUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';
import SearchPopup from '../components/SearchPopup.js';

// assets
import close_white from '../assets/close_white.png';

class RelationshipsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // AddRelationship, EditRelationship
            redirectTo: null,
            relationshipType: "0;null", // example "1;a_b" i format "ID;RELATION" - relation could be a_b, b_a, null
            relationshipSelected: "+ Add",
            whoId: -1,
            whoName: "+ Add a Name",
            withWhom: -1, // default is -1
            withWhomName: "+ Add",
            isCurrent: "current", // current or past
            descr: "",
            findA_ContactID: "",
            findB_ContactID: "",
            foundContactsA: ([]),
            foundContactsB: ([]),
            showPopupWho: false,
            showPopupWithWhom: false,
            showPopupRelType: false,
            startDate: "2022-01-01",
            endDate: "2022-01-01",
            relInvestorTypesArray: ([{"id":1,"name":"Discipler","relation":"a_b"}]),
            relInvesteeTypesArray: ([{"id":1,"name":"Disciplee", "relation":"b_a"}]),
            relOtherTypesArray: ([{"id":1,"name":"Discipler", "relation":"null"}]),
        };

        this.refPopupWho = React.createRef();
        this.refInputWho = React.createRef();
        this.refPopupWithWhom = React.createRef();
        this.refInputWithWhom = React.createRef();
        this.refPopupRelType = React.createRef();
    }

    componentDidMount(){
        this.loadRelationshipsTypesArray();

        if(this.props.relationshipId != -1 && this.props.actionType == "EditRelationship"){
            this.loadExistingRelationship(this.props.relationshipId);
        }
        else {
            this.setState({ startDate: getTodaysDate() });
            this.setState({ endDate: getTodaysDate() });

            if(this.props.contactId != -1 && this.props.contactName != ""){
                this.setState({ whoId: this.props.contactId });
                this.setState({ whoName: this.props.contactName });
            }
        }
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupWithWhom.current.contains(e.target)) && this.state.showPopupWithWhom) {
            // user clicked out of the PopupWithWhatTeam and that popup is now opened
            this.setState({ showPopupWithWhom: false })
        }
        if ( (!this.refPopupRelType.current.contains(e.target)) && this.state.showPopupRelType) {
            this.setState({ showPopupRelType: false })
        }
        if ( (!this.refPopupWho.current.contains(e.target)) && this.state.showPopupWho) {
            this.setState({ showPopupWho: false })
        }
        if ( this.state.showPopupWho ) this.refInputWho.current.focus();
        if ( this.state.showPopupWithWhom ) this.refInputWithWhom.current.focus();
    }

    loadRelationshipsTypesArray = () => {
        Axios.get('/api/rtp/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;

            var investorArray = [];
            var investeeArray = [];
            var otherArray = [];

            for(let oneRow of results){
                if(oneRow.a_b_group == "Investor" && oneRow.b_a_group == "Investee" && oneRow.a_cont_tp_id == 1 && oneRow.b_cont_tp_id == 1){
                    investorArray.push({"id":oneRow.id, "name": oneRow.a_b_name, "relation":"a_b"});
                    investeeArray.push({"id":oneRow.id, "name": oneRow.b_a_name, "relation":"b_a"});
                }
                if(oneRow.a_b_group != "Investor" && oneRow.b_a_group != "Investee" && oneRow.a_cont_tp_id == 1 && oneRow.b_cont_tp_id == 1){
                    if(oneRow.a_b_name == "Uplink"){
                        otherArray.push({"id":oneRow.id, "name": oneRow.a_b_name, "relation":"a_b"});
                        otherArray.push({"id":oneRow.id, "name": oneRow.b_a_name, "relation":"b_a"});
                    }
                    else {
                        otherArray.push({"id":oneRow.id, "name": oneRow.a_b_name, "relation":"null"});
                    }
                }
            }

            //var finalArray = otherArray.concat(investorArray,investeeArray);
            //this.setState({ relTypesArray: finalArray });

            this.setState({ relInvestorTypesArray: investorArray });
            this.setState({ relInvesteeTypesArray: investeeArray });
            this.setState({ relOtherTypesArray: otherArray });
        });
    }

    loadExistingRelationship = async (id) => {
        await Axios.get('/api/rel/get/'+id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;
            this.setState({ descr: results[0].descr });

            if(results[0].a_cont_id == this.props.contactId){
                this.setState({ whoId: results[0].a_cont_id });
                this.setState({ whoName: results[0].a_rcg_name });

                this.setState({ withWhom: results[0].b_cont_id });
                this.setState({ withWhomName: results[0].b_rcg_name });

                //alert("a_b");
                this.setState({ relationshipSelected: results[0].a_role });
                this.setState({ relationshipType: results[0].rel_tp_id+";a_b" });
            }
            else if(results[0].b_cont_id == this.props.contactId){
                this.setState({ whoId: results[0].b_cont_id });
                this.setState({ whoName: results[0].b_rcg_name });

                this.setState({ withWhom: results[0].a_cont_id });
                this.setState({ withWhomName: results[0].a_rcg_name });

                //alert("b_a");
                this.setState({ relationshipSelected: results[0].b_role });
                this.setState({ relationshipType: results[0].rel_tp_id+";b_a" });
            }

            if(results[0].start_dt != null){
                this.setState({ startDate: results[0].start_dt });
            }

            if(results[0].end_dt != null){
                this.setState({ endDate: results[0].end_dt });
            }
            else { this.setState({ endDate: getTodaysDate() }); }

            if(results[0].is_current == 1){
                this.setState({ isCurrent: "current" });
            }
            else {
                this.setState({ isCurrent: "past" });
            }
        });
    }

    deleteRelationship = () => {
        if(this.props.relationshipId != -1 && this.props.actionType == "EditRelationship"){
            Axios.delete('/api/rel/del/'+this.props.relationshipId, {
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.props.closeModal(this.state.editingMode, false);
            });
        }
    }

    // this method works for both creating new Relationship and saving changed Relationship
    saveRelationshipAction = async (createAnotherNewAfter) => {
        if(this.state.relationshipType == "0;null"){
            this.props.openAlertMessage("You didn't fill relationship type.","OK");
            return false;
        }
        else if(this.state.withWhom == -1){
            this.props.openAlertMessage("You didn't fill with whom.","OK");
            return false;
        }
        else if(this.state.descr != undefined && this.state.descr.length > 255){
            this.props.openAlertMessage("Your description is longer than allowed. The limit is 255 characters.","OK");
            return false;
        }
        else if(this.state.isCurrent == "past" && this.state.startDate > this.state.endDate){
            this.props.openAlertMessage("Start date must be set before End date.","OK");
            return false;
        }
        // check year validity
        /*const objectStartDate = new Date(this.state.startDate);
        const objectEndDate = new Date(this.state.endDate);
        if(objectStartDate.getFullYear() < 1900){
            this.props.openAlertMessage("Year cannot be lower than 1900.","OK");
            return;
        }
        else if(objectEndDate.getFullYear() < 1900){
            this.props.openAlertMessage("Year cannot be lower than 1900.","OK");
            return;
        }*/

        var relType = this.state.relationshipType;
        var RelTpId = relType.substring(0, relType.indexOf(';'));
        var relation = relType.substring(relType.indexOf(';')+1);

        if(relation == "b_a"){
            var B_ContId = this.state.whoId;
            var A_ContId = this.state.withWhom;
        }
        else {
            var A_ContId = this.state.whoId;
            var B_ContId = this.state.withWhom;
        }
        var StartDt = this.state.startDate;
        var EndDt = this.state.endDate;
        var isCurrent = this.state.isCurrent == "current" ? 1 : 0;
        var Descr = this.state.descr;

        //alert("SAVE:\nA_ContId: "+A_ContId+"\nB_ContId: "+B_ContId+"\nisCurrent: "+isCurrent+"\nDesc: "+Descr+"\nStart date: "+StartDt+"\nEnd date: "+EndDt+"\nRelTpId: "+RelTpId+"\nRelType: "+relation);
        //return;

        var url = "/api/rel/ins";
        if(this.props.actionType == "EditRelationship"){url = "/api/rel/upd/"+this.props.relationshipId;}

        Axios.post(url, {
            A_ContId: A_ContId, // id of person to whom I am setting the relationship
            B_ContId: B_ContId, // id of person with whom the person has the relationship
            RelTpId: RelTpId, // type relationship type id
            StartDt: StartDt, // start date of the relationship
            EndDt: EndDt, // end date of the relationship
            IsCurrent: isCurrent, // boolean if the relationship is still current
            Descr: Descr,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.closeModal(this.state.editingMode, createAnotherNewAfter);
        });
    }

    searchForContactsA = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=1&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundContactsA: response.data.result })
            });
        }
    }

    searchForContactsB = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=1&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundContactsB: response.data.result })
            });
        }
    }

    selectFoundContactA = async (contactId, contactName) => {
        this.setState({ whoId: contactId });
        this.setState({ whoName: contactName });

        this.setState({ showPopupWho: false });
        this.setState({ findA_ContactID: "" });
        this.setState({ foundContactsA: [] });
    }

    selectFoundContactB = async (contactId, contactName) => {
        this.setState({ withWhom: contactId });
        this.setState({ withWhomName: contactName });

        this.setState({ showPopupWithWhom: false });
        this.setState({ findB_ContactID: "" });
        this.setState({ foundContactsB: [] });
    }

    selectRelationshipType = async (relType) => {
        this.setState({ relationshipType: relType });

        var RelTpId = relType.substring(0, relType.indexOf(';'));
        var relation = relType.substring(relType.indexOf(';')+1);

        for(let oneRow of this.state.relInvestorTypesArray){
            if(oneRow.id == RelTpId && oneRow.relation == relation){
                this.setState({ relationshipSelected: oneRow.name });
            }
        }

        for(let oneRow of this.state.relInvesteeTypesArray){
            if(oneRow.id == RelTpId && oneRow.relation == relation){
                this.setState({ relationshipSelected: oneRow.name });
            }
        }

        for(let oneRow of this.state.relOtherTypesArray){
            if(oneRow.id == RelTpId && oneRow.relation == relation){
                this.setState({ relationshipSelected: oneRow.name });
            }
        }

        this.setState({ showPopupRelType: false });
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={this.state.modalExpanded}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{this.state.editingMode == "EditRelationship" ? "Edit Relationship" : "Add Relationship"}</span>

                                <SearchPopup
                                    id={"searchContact"}
                                    textName={this.state.whoName}
                                    textStyle={this.state.whoId == -1 ? "italicOrange" : "normalBlue"}
                                    value={this.state.findA_ContactID}
                                    changeValue={(text) => {this.setState({ findA_ContactID: text }); this.searchForContactsA(text);}}
                                    foundRecords={this.state.foundContactsA}
                                    refPopup={this.refPopupWho}
                                    refInput={this.refInputWho}
                                    showPopup={this.state.showPopupWho}
                                    showHidePopup={() => this.setState({ showPopupWho: !this.state.showPopupWho })}
                                    addContactToArray={(item) => this.selectFoundContactA(item.id,item.first_name+" "+item.last_name)}
                                    showEmailStyle={false}
                                />

                            </div>

                            <div className="verticalStack">
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                                    <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 12}}>

                            <div className="horizontalStack flex">

                                <div className="universalPopupSelectContainer" ref={this.refPopupRelType}>

                                    <div className="universalPopupSelectVisible horizontalStack">
                                        <span className="fontPoppinsRegular13">Relationship Type:*</span>
                                        <div style={{width: 5}}></div>
                                        <span className="fontPoppinsRegular13 clickable" style={{color: this.state.relationshipType == "0;null" ? "var(--jv-orange)" : "var(--blue-button-link)"}} onClick={()=>this.setState({showPopupRelType: !this.state.showPopupRelType})}>{this.state.relationshipSelected}</span>
                                    </div>

                                    <div className="universalPopupSelectHidden" style={{display: this.state.showPopupRelType ? "" : "none"}}>
                                        <div className="verticalStack" onClick={() => this.setState({showPopupRelType: false})}>
                                            <span className="fontPoppinsRegular13">Select Relationship Type</span>

                                            <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                            <div className="verticalStack" onChange={event => this.selectRelationshipType(event.target.value)}>

                                                {this.state.relOtherTypesArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                            {/* if item.id+";"+item.relation is equal selected roleType OR item.relation is null THEN I would use different rule for selecting just by item.id and not item.id + item.relation */}
                                                            <input type="radio" className="clickable" id={item.id+""+item.relation+""+item.name} name="relationship_type" value={item.id+";"+item.relation} checked={item.id+";"+item.relation == this.state.relationshipType || (item.relation == "null" && item.id == this.state.relationshipType.substring(0,this.state.relationshipType.indexOf(';')) ) ? true : false}/>
                                                            <label for={item.id+""+item.relation+""+item.name} className="fontPoppinsRegular13 clickable" style={{marginLeft: 5}}>{item.name}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                                <span className="fontPoppinsRegular13">Investor</span>

                                                {this.state.relInvestorTypesArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                            <input type="radio" id={item.id+""+item.relation+""+item.name} name="relationship_type" value={item.id+";"+item.relation} checked={item.id+";"+item.relation == this.state.relationshipType ? true : false}/>
                                                            <label for={item.id+""+item.relation+""+item.name} className="fontPoppinsRegular13" style={{marginLeft: 5}}>{item.name}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                                <span className="fontPoppinsRegular13">Investee</span>

                                                {this.state.relInvesteeTypesArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                            <input type="radio" id={item.id+""+item.relation+""+item.name} name="relationship_type" value={item.id+";"+item.relation} checked={item.id+";"+item.relation == this.state.relationshipType ? true : false}/>
                                                            <label for={item.id+""+item.relation+""+item.name} className="fontPoppinsRegular13" style={{marginLeft: 5}}>{item.name}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                            </div>

                                            {/*<div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                <input type="radio" id="jv_regional_contact" name="relationship_type" value="jv_regional_contact"/>
                                                <label for="jv_regional_contact" className="fontPoppinsRegular13" style={{marginLeft: 5}}>JV Regional Contact</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                <input type="radio" id="jv_staff_contact" name="relationship_type" value="jv_staff_contact"/>
                                                <label for="jv_staff_contact" className="fontPoppinsRegular13" style={{marginLeft: 5}}>JV Staff Contact</label>
                                            </div>



                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                <input type="radio" id="investor" name="relationship_type" value="investor"/>
                                                <label for="investor" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Investor</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="discipler" name="discipler" value="discipler"/>
                                                <label for="discipler" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Discipler</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="mentor" name="mentor" value="mentor"/>
                                                <label for="mentor" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Mentor</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="coach" name="coach" value="coach"/>
                                                <label for="coach" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Coach</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="trainer" name="trainer" value="trainer"/>
                                                <label for="trainer" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Trainer</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="walking_alongside" name="walking_alongside" value="walking_alongside"/>
                                                <label for="walking_alongside" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Walking alongside</label>
                                            </div>



                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                <input type="radio" id="investee" name="relationship_type" value="investee"/>
                                                <label for="investee" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Investee</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="disciplee" name="disciplee" value="disciplee"/>
                                                <label for="disciplee" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Disciplee</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="mentee" name="mentee" value="mentee"/>
                                                <label for="mentee" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Mentee</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="coachee" name="coachee" value="coachee"/>
                                                <label for="coachee" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Coachee</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="trainee" name="trainee" value="trainee"/>
                                                <label for="trainee" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Trainee</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3, marginLeft: 20}}>
                                                <input type="checkbox" id="walking_alongside2" name="walking_alongside2" value="walking_alongside2"/>
                                                <label for="walking_alongside2" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Walking alongside</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                <input type="radio" id="uplink" name="relationship_type" value="uplink"/>
                                                <label for="uplink" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Uplink</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                <input type="radio" id="downlink" name="relationship_type" value="downlink"/>
                                                <label for="downlink" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Downlink</label>
                                            </div>

                                            <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                <input type="radio" id="spouse" name="relationship_type" value="spouse"/>
                                                <label for="spouse" className="fontPoppinsRegular13" style={{marginLeft: 5}}>Spouse</label>
                                            </div>*/}
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="horizontalStack flex">

                                <span className="fontPoppinsRegular13">With Whom:*</span>
                                <div style={{width: 5}}></div>

                                <SearchPopup
                                    id={"searchContact"}
                                    textName={this.state.withWhomName}
                                    textStyle={this.state.withWhom == -1 ? "italicOrange13" : "normalBlue13"}
                                    value={this.state.findB_ContactID}
                                    changeValue={(text) => {this.setState({ findB_ContactID: text }); this.searchForContactsB(text);}}
                                    foundRecords={this.state.foundContactsB}
                                    refPopup={this.refPopupWithWhom}
                                    refInput={this.refInputWithWhom}
                                    showPopup={this.state.showPopupWithWhom}
                                    showHidePopup={() => this.setState({ showPopupWithWhom: !this.state.showPopupWithWhom })}
                                    addContactToArray={(item) => this.selectFoundContactB(item.id,item.first_name+" "+item.last_name)}
                                    showEmailStyle={false}
                                />
                            </div>

                            <div className="horizontalStack">
                                <span className="fontPoppinsRegular13">Approximate Start Date:*</span>
                                <div style={{width: 5}}></div>
                                <input className="modalDatePicker fontPoppinsRegular13" type="date" id="start_date" name="start_date" min="1900-01-01" max="9999-01-01" value={this.state.startDate} onChange={e => this.setState({ startDate: e.target.value })}/>
                            </div>

                        </div>

                        <div className="verticalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 2}}>



                            <div className="horizontalStack">
                                <span className="fontPoppinsRegular13">Is this relationship type current or in the past?</span>
                                <div className="flex"></div>

                                <div className="horizontalStack" style={{display: this.state.isCurrent == "past" ? "" : "none"}}>
                                    <span className="fontPoppinsRegular13">Approximate End Date:*</span>
                                    <div style={{width: 5}}></div>
                                    <input className="modalDatePicker fontPoppinsRegular13" type="date" id="end_date" name="end_date" min="1900-01-01" max="9999-01-01" value={this.state.endDate} onChange={e => this.setState({ endDate: e.target.value })}/>
                                </div>

                            </div>

                            <div className="horizontalStack flex" style={{marginTop: 12}} onChange={event => this.setState({ isCurrent: event.target.value })}>
                                <input type="radio" id="current" name="relationship_time" value="current" checked={this.state.isCurrent == "current" ? true : false}/>
                                <label for="current" className="fontPoppinsRegular13">Current</label>
                                <div style={{width: 30}}></div>
                                <input type="radio" id="past" name="relationship_time" value="past" checked={this.state.isCurrent == "past" ? true : false}/>
                                <label for="past" className="fontPoppinsRegular13">In the Past</label>
                            </div>

                            <span className="fontPoppinsRegular13" style={{marginTop: 12}}>Description:</span>

                            <textarea className="modalInputAddNote" rows="8" style={{backgroundColor: "white"}} placeholder="Write here anything you think that may help clarify their relationship." id="textAreaDescr" name="textAreaDescr" value={this.state.descr} onChange={e => this.setState({ descr: e.target.value })}></textarea>

                        </div>

                        <div className="horizontalStack" style={{marginTop: "12px"}}>
                            <RoundButton title={"Save Relationship"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.saveRelationshipAction(false)} />
                            <RoundButton title={"Save and Add New"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.saveRelationshipAction(true)} />
                            {this.props.relationshipId != -1 && this.props.actionType == "EditRelationship" ? <RoundButton title={"Delete"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={this.deleteRelationship} /> : null }
                            <div className="flex"></div>
                            <span className="fontPoppinsItalic13Gray">*Required fields</span>
                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}

}

export default RelationshipsModal;