import React, { useState, useEffect } from "react";
import { Link, Redirect, useNavigate } from "react-router-dom";
import '../App.css';
import '../styles/pages.css';
import '../styles/other.css';
import Axios from 'axios';
import props from 'prop-types';
import AlertModalMessage from '../modals/AlertModalMessage.js';
import AlertModalMessageMobile from '../modals_m/AlertModalMessageMobile.js';
import { showMobileVersion } from '../utilities/OtherUtilities.js';

// import assets
import jv_logo_sign_in from '../assets/jv_logo_sign_in.png';

async function loginUser(credentials) {
    console.log(credentials);
    return fetch(Axios.defaults.baseURL+'/api/login2', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
   .then(data => data.json())

    //Using Axios is not working
    //await Axios.get('/api/login2',{credentials}).then(response => alert(response));

    /*return Axios.post('/api/login2', credentials, { withCredentials: true, credentials: 'include' })
        .then(response => alert(response));*/
}

async function sendRecoveryEmail(data) {
    console.log(data);
    return fetch(Axios.defaults.baseURL+'/api/usr/pwd/4gt', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
   .then(data => data.json())
}


function Login({ setToken, openAlertMessageTwoActions }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [logged, setLogged] = useState(false);
    const [askToRestorePassword, setAskToRestorePassword] = useState(false);

    const [prepareToShowAlertModalMessage, setPrepareToShowAlertModalMessage] = useState(false);
    const [showAlertModalMessage, setShowAlertModalMessage] = useState(false);

    const [prepareToShowAlertModalMessageMobile, setPrepareToShowAlertModalMessageMobile] = useState(false);
    const [showAlertModalMessageMobile, setShowAlertModalMessageMobile] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [alertCloseButton, setAlertCloseButton] = useState("");
    //const history = useNavigate();

    const handleSubmit = async e => {
        try{
            e.preventDefault();
            console.log(username);
            console.log(password);
            const response = await loginUser({
              username,
              password
            });

            if(response.token != undefined){
                setToken(response);
                //window.location.reload(true);
                window.location.href = "/";
            }
            else {
                openAlertMessage("Invalid login or password.","OK");
            }
        }
        catch (e){
            openAlertMessage("Invalid login or password.","OK");
        }
    }

    useEffect(() => {
        localStorage.setItem("actualPage", "sign");

        const { reset_password } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(reset_password != undefined && reset_password == "success"){
            openAlertMessage("Your password was successfully changed.","OK");
        }
    }, []);

    const openForgotPassword = async e => {
        setAskToRestorePassword(!askToRestorePassword);
    }

    const sendRestoreEmail = async e => {
        e.preventDefault();
        setAskToRestorePassword(false);

        var Email = username;
        const response = await sendRecoveryEmail({
            Email,
        });

        if(response.msg.is_ok && response.msg.is_ok == 1){
            openAlertMessage("Email with password recovery link was sent to your inbox.","OK");
        }
        else {
            openAlertMessage("Email was not sent. Please contact our support.","OK");
        }

    }

    async function openAlertMessage(message, closeButton) {
        setAlertMessage(message);
        setAlertCloseButton(closeButton);

        if(showMobileVersion()){
            setPrepareToShowAlertModalMessageMobile(true);
            setShowAlertModalMessageMobile(true);
        }
        else {
            setPrepareToShowAlertModalMessage(true);
            setShowAlertModalMessage(true);
        }
    }

    async function closeAlertMessage() {
        setShowAlertModalMessage(false);
        setShowAlertModalMessageMobile(false);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setPrepareToShowAlertModalMessage(false);
        setPrepareToShowAlertModalMessageMobile(false);
    }

	return(
        <div className="loginPage">
            <div className="verticalStack">
                <center>
                    <img style={{width: "calc(100% - 100px)", maxWidth: "800px", minWidth: "200px", paddingRight: "10px", paddingLeft: 50, paddingRight: 50}} src={jv_logo_sign_in}/>

                    { askToRestorePassword == false ?
                    <div className="verticalStack" style={{alignItems: "center", width: "calc(100% - 40px)", maxWidth: "370px", minWidth: "100px", paddingLeft: 20, paddingRight: 20}}>

                        <form onSubmit={handleSubmit} style={{width: "100%"}}>

                            <div className="loginPageEmailElement" style={{marginTop: "40px"}}>
                                <input className="loginPageEmailInput fontPoppinsRegular13" type="text" placeholder="Username" onChange={e => setUserName(e.target.value)} />
                            </div>

                            <div className="horizontalStackCenter loginPagePasswordElement" style={{marginTop: "24px"}}>
                                <input className="loginPagePasswordInput fontPoppinsRegular13" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                <button className="loginPageLoginButton clickable" type="submit"><span className="fontPoppinsRegular13">Login</span></button>
                            </div>

                        </form>

                        <div className="horizontalStackCenter" style={{width: "100%", marginTop: "43px"}}>
                            <span className="fontPoppinsRegular13White clickable onHoverToOrange" style={{paddingLeft: showMobileVersion() ? "25px" : "21px"}} onClick={() => openAlertMessage("This function is not available yet.","OK")}>Google Login</span>
                            <div className="flex"></div>
                            <span className="fontPoppinsRegular13White clickable onHoverToOrange" style={{marginRight: showMobileVersion() ? "16px" : "15px"}} onClick={() => openForgotPassword()}>Forgot Password</span>
                        </div>
                    </div>
                    :
                    <div className="verticalStack" style={{alignItems: "center", width: "calc(100% - 40px)", maxWidth: "380px", minWidth: "100px", paddingLeft: 20, paddingRight: 20}}>

                        <form onSubmit={sendRestoreEmail} style={{width: "100%"}}>

                            <div className="horizontalStackCenter loginPagePasswordElement" style={{marginTop: "40px"}}>
                                <input className="loginPagePasswordInput fontPoppinsRegular13" type="text" placeholder="Email" onChange={e => setUserName(e.target.value)} />
                                <button className="loginPageLoginButton clickable" type="submit"><span className="fontPoppinsRegular13">{"Restore"}</span></button>
                            </div>

                        </form>

                        <div className="horizontalStackCenter" style={{width: "100%", marginTop: "43px"}}>
                            <div className="flex"></div>
                            <span className="fontPoppinsRegular13White clickable onHoverToOrange" style={{paddingLeft: "15px"}} onClick={openForgotPassword}>{"Back to login"}</span>
                            <div className="flex"></div>
                        </div>
                    </div>
                    }
                </center>
            </div>

            {prepareToShowAlertModalMessage ? <AlertModalMessage showModal={showAlertModalMessage} closeModal={closeAlertMessage} message={alertMessage} closeButton={alertCloseButton} /> : null }
            {prepareToShowAlertModalMessageMobile ? <AlertModalMessageMobile showModal={showAlertModalMessageMobile} closeModal={closeAlertMessage} message={alertMessage} closeButton={alertCloseButton} /> : null }
        </div>
	);

}
export default Login;