import React, { Component } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import Chart from "chart.js/auto";
import '../styles/components.css';

Chart.defaults.font.size = 9;
Chart.defaults.font.family = "PoppinsMedium";

const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 0,
        },
    },
    plugins: {
        legend: {
            display: false,
            position: 'right',
        },
        title: {
            display: false,
            text: 'Title',
        },
    },
    scales: {
        x: {
          display: true,
          grid: {
            display: true
          },
        },
        y: {
          display: true,
          grid: {
            display: false
          },
          ticks: {
            callback: function (val, index) {
                return '      ' + this.getLabelForValue(val);
            }
          }
        }
    },
    responsive: true,
};

class BarChartTwo extends Component {
    render() {
        const data = {
            labels: this.props.labels,
            datasets: [{
                label: "Total Conversations by Type",
                backgroundColor: "#556EE6",
                borderColor: "#556EE6",
                data: this.props.values,
                barThickness: 10,
            }]
        }

        return (
            <div>
                <article className="canvas-container">
                    <Bar data={data} options={options} height={120}/>
                </article>
            </div>
        );
    }
}

export default BarChartTwo;