import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// components
import ModalMobile from '../modals_m/ModalMobile';

// import assets
import close_white from '../assets/close_white.png';
import blue_close_icon from '../assets/blue_close_icon.png';
import instructions_left_mobile from '../assets/instructions_left_mobile.png';
import instructions_right_mobile from '../assets/instructions_right_mobile.png';
import instructions_example from '../assets/instructions_example.png';
import instructions_conversations_1 from '../assets/instructions_conversations_1.png';
import instructions_conversations_2 from '../assets/instructions_conversations_2.png';
import instructions_diagnostics_1 from '../assets/instructions_diagnostics_1.png';
import instructions_diagnostics_2 from '../assets/instructions_diagnostics_2.png';
import instructions_disciples_1 from '../assets/instructions_disciples_1.png';
import instructions_disciples_2 from '../assets/instructions_disciples_2.png';
import instructions_dashboard_1 from '../assets/instructions_dashboard_1.png';

class InstructionsModalMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // instructions
            allPagesList: ([{id: 1, title:"Record & View Conversations"},
                         {id: 2, title:"Quick Profile Views"},
                         {id: 3, title:"Find, Edit or Add New Contacts"}]),
            actualPage: this.props.oneInstructionId,
        };
    }

    componentDidMount(){

    }

    getTitle = (id) => {
        for(let oneRow of this.state.allPagesList){
            if(id == oneRow.id) return oneRow.title;
        }
        return id;
        return "Error";
    }

    getDescription = (id) => {
        if(id == 1){
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Add a New Conversation:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can add a new conversation by clicking on the “+” button in the top menu and choosing “People > Conversation.” You can also do this by clicking on the “+” button next to “Conversations” inside a contact’s profile."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"When the “Add Your Conversation” form opens, you can start entering the information. The names and dates are prefilled for you, but you can adjust them. After adding the names of those involved in the conversation at the top, there are only three more required fields of data. However, you can add as much additional information about the conversation as you think would be helpful for your team. When you are done, you can click “Save” or “Save and Add New” to add another one immediately."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Note Taking:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"The “Notes” field is for you to write any key observations from the conversation that you think would be helpful for you or your team to remember. Remember that your notes will be viewable by others."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"You can also select a potential ministry need and write a description. This information will help your team know about their needs while also helping other JV teams with data that helps them see the wider scope of growing needs across different ministries."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Sending an Email Notification:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"At the bottom of the “Add Your Conversations” form, you will see an “Add an Email” button where you can search for a contact’s name and email in the database. Once you add the contacts that you want to receive an email notification, you can click “Save,” and it will email them about the conversation. If you open the conversation later you will see these emails in blue at the bottom to let you know who has been notified already. If you want to notify more people, simply add more emails and save the conversation again."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Overview of Conversations:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can get an overview of your conversations by viewing the “My Conversations” page, which is accessible in the left menu. You can also view all conversations relevant to a contact within their profile. To open a conversation just click on the row in the tool window."}</span>
                    </div>);
        }
        else if(id == 2){
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Introduction:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"Save time asking various people for their contact information and figuring out the context of their relationship with Josiah Venture. Manage all of your contacts across JV ministries all from your mobile device. You can quickly search and view a profile for each contact on-the-go. Each profile contains helpful information such as their conversations with JV, personal growth, and ministry growth."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Viewing Contacts:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can always access contacts such as People and Churches from the left menu or through searching for a specific contact from the search bar at the top. From there you can view the list of contacts with an overview of their information. By clicking on a contact you will see their mobile profile view where you can easily review and edit information about a contact or simply add a conversation."}</span>
                    </div>);
        }
        else if(id == 3){
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Add/Edit a New Contact"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can add a new contact by clicking on the ”Add New” button in the top menu and choosing either ”People” or ”Church”."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"Once you’ve started to add a new contact, you will need to start entering their profile information. There are only a few required fields that you must fill in order to save the new profile. However, it is best to put in as much information as you can. Also, don’t forget to try and upload a profile image. You can also edit after saving."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Searching a Contact"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can do a quick search for any contact using the search bar in the top menu and click on the automatic drop-down results or hit the Enter/Return key to get a full list of results."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Adding to “My Contacts”"}</span>
                        <span className="fontPoppinsRegular13Gray">{"When you are viewing a contact’s profile page, you can simply click on the “+ Add to My Contacts” button located in their profile page. You can also view a list of search results or click on “All Contacts” from the left menu, select the names you want to add and then click on the “Actions” button in the upper left of the results table and click “Add to My Contacts.”"}</span>
                    </div>);
        }
        else{
            return (<div className="verticalStack">
                        <span className="fontPoppinsBold13">{"Add a New Conversation:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can add a new conversation by clicking on the “+” button in the top menu and choosing “People > Conversation.” You can also do this by clicking on the “+” button next to “Conversations” inside a contact’s profile."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"When the “Add Your Conversation” form opens, you can start entering the information. The names and dates are prefilled for you, but you can adjust them. After adding the names of those involved in the conversation at the top, there are only three more required fields of data. However, you can add as much additional information about the conversation as you think would be helpful for your team. When you are done, you can click “Save” or “Save and Add New” to add another one immediately."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Note Taking:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"The “Notes” field is for you to write any key observations from the conversation that you think would be helpful for you or your team to remember. Remember that your notes will be viewable by others."}</span>
                        <br/>
                        <span className="fontPoppinsRegular13Gray">{"You can also select a potential ministry need and write a description. This information will help your team know about their needs while also helping other JV teams with data that helps them see the wider scope of growing needs across different ministries."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Sending an Email Notification:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"At the bottom of the “Add Your Conversations” form, you will see an “Add an Email” button where you can search for a contact’s name and email in the database. Once you add the contacts that you want to receive an email notification, you can click “Save,” and it will email them about the conversation. If you open the conversation later you will see these emails in blue at the bottom to let you know who has been notified already. If you want to notify more people, simply add more emails and save the conversation again."}</span>
                        <br/>
                        <span className="fontPoppinsBold13">{"Overview of Conversations:"}</span>
                        <span className="fontPoppinsRegular13Gray">{"You can get an overview of your conversations by viewing the “My Conversations” page, which is accessible in the left menu. You can also view all conversations relevant to a contact within their profile. To open a conversation just click on the row in the tool window."}</span>
                    </div>);
        }
    }

    getNextShortTitle = (id) => {
        if(id == 1) return ("Profile Views");

        else if(id == 2) return ("Add/Edit Contacts");
        else if(id == 3) return ("Conversations");
        else return ("Profile Views");
    }

    getPrevShortTitle = (id) => {
        if(id == 1) return ("Add/Edit Contacts");

        else if(id == 2) return ("Conversations");
        else if(id == 3) return ("Profile Views");
        else return ("Add/Edit Contacts");
    }

    openNextPage = () => {
        var newValue = this.state.actualPage+1;
        if(newValue > this.state.allPagesList.length) newValue = 1;
        this.setState({ actualPage: newValue });
    }

    openPrevPage = () => {
        var newValue = this.state.actualPage-1;
        if(newValue < 1) newValue = this.state.allPagesList.length;
        this.setState({ actualPage: newValue });
    }



	render(){
		return(
            <React.Fragment>
                <ModalMobile show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={this.state.modalExpanded}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{this.getTitle(this.state.actualPage)}</span>
                            </div>

                            <div className="verticalStack">
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                                    <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStackCenter" style={{marginTop: 15}}>
                            <div className="verticalStack flex" style={{ height: "calc(var(--doc-height) - 275px)", overflowY: "scroll"}}>
                                {this.getDescription(this.state.actualPage)}
                            </div>
                        </div>

                        <div className="horizontalStackCenter" style={{marginTop: 30}}>
                            <img style={{height: "36px"}} src={instructions_left_mobile} onClick={() => this.openPrevPage()}/>

                            <span className="fontPoppinsSemiBold13Gray" style={{marginLeft: 10}} onClick={() => this.openPrevPage()}>{this.getPrevShortTitle(this.state.actualPage)}</span>

                            <div style={{flex: 1}}></div>

                            <span className="fontPoppinsSemiBold13Gray" style={{marginRight: 10}} onClick={() => this.openNextPage()}>{this.getNextShortTitle(this.state.actualPage)}</span>

                            <img style={{height: "36px"}} src={instructions_right_mobile} onClick={() => this.openNextPage()}/>
                        </div>

                    </div>
                </ModalMobile>
            </React.Fragment>
		);
	}

}

export default InstructionsModalMobile;