import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';
import Axios from 'axios';
import { navigate } from "@reach/router";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { deviceWidthLowerThan } from '../utilities/OtherUtilities.js';

class SearchBox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            foundContacts: ([]),
        };
    }

    handleKeyPress = (e) => {
        if(e.key === 'Enter')this.props.onEnter();
    }

    onChange = (event) => {
        this.props.setSearchText(event.target.value);
        if(event.target.value.length >= 3){
            /*Axios.get('/api/cont/get?adv_op=or&way='+this.props.way+'&adv_nm='+event.target.value).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                this.setState({ foundContacts: response.data.result });
            });*/
            Axios.get('/api/cont/get?search='+event.target.value).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                this.setState({ foundContacts: response.data.result });
            });
        }
        else {
            this.setState({ foundContacts: [] });
        }
    }

    onContactClick = (event, id, type, isPrivate) => {
        if(type == 1){ //person
            this.props.setActualSection("people");

            if(isPrivate == 1){
                if(event.ctrlKey || event.metaKey){ window.open("/PeoplePrivateProfile/"+id, '_blank', 'noreferrer'); }
                else { navigate("/PeoplePrivateProfile/"+id); window.location.reload(); }
            }
            else {
                if(event.ctrlKey || event.metaKey){ window.open("/PeopleProfile/"+id, '_blank', 'noreferrer'); }
                else { navigate("/PeopleProfile/"+id); window.location.reload(); }
            }
        }
        else if(type == 4){ //church
            this.props.setActualSection("churches");
            if(event.ctrlKey || event.metaKey){ window.open("/ChurchProfile/"+id, '_blank', 'noreferrer'); }
            else { navigate("/ChurchProfile/"+id); window.location.reload(); }
        }
        else if(type == 5){ //denomination
            this.props.setActualSection("churches");
            if(event.ctrlKey || event.metaKey){ window.open("/DenominationProfile/"+id, '_blank', 'noreferrer'); }
            else { navigate("/DenominationProfile/"+id); window.location.reload(); }
        }
        else if(type == 6){ // team
            this.props.setActualSection("churches");
            if(event.ctrlKey || event.metaKey){ window.open("/TeamProfile/"+id, '_blank', 'noreferrer'); }
            else { navigate("/TeamProfile/"+id); window.location.reload(); }
        }
        else if(type == 7){ // school
            this.props.setActualSection("schools");
            if(event.ctrlKey || event.metaKey){ window.open("/SchoolProfile/"+id, '_blank', 'noreferrer'); }
            else { navigate("/SchoolProfile/"+id); window.location.reload(); }
        }
        else {
            alert("Right now I can open only people, churches, denominations, teams and schools.");
        }
    }

    timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    hideAfterDelay = async () => {
        await this.timeout(200); // when I do it immediately the click in dropdown does not work because the dropdown disappears
        this.props.setSearchText("");
    }

    render(){
        return(
            <div className="" style={{minWidth: "140px", maxWidth: "380px", width: "100%", position: "relative"}}>
                <div className="topMenuSearchBox horizontalStackCenter" style={{position: "relative", zIndex: 5}}>
                    <input type="text" className="topMenuSearchBoxInput fontPoppinsRegular13" placeholder={deviceWidthLowerThan(400) ? "Contacts..." : "Search Contacts..."} value={this.props.searchText} onChange={(event) => this.onChange(event)} onKeyUp={this.handleKeyPress} onBlur={() => this.hideAfterDelay()} />
                </div>
                <div style={{display: this.state.foundContacts.length != 0 && this.props.searchText != "" ? "" : "none", width: "100%", position: "absolute", backgroundColor: "white", border: "0px solid white", borderRadius: "0px 0px 20px 20px", zIndex: 4, marginTop: -25, paddingTop: 25, boxShadow: "2px 10px 50px -20px var(--search-box-shadow)"}}>
                    {this.state.foundContacts.map(function (item, index) {
                        if (index > 10) { return; }
                        if (index == 10) {
                            return (
                                <div className="flex verticalStack" style={{width: "100%", paddingLeft: 10, paddingRight: 10}}>
                                    {index != 0 ? <div style={{height: 1, backgroundColor: "var(--lightest-gray)", marginLeft: -10, marginRight: 10}}></div> : null }
                                    <div style={{height: 12}}></div>
                                    <span className="fontPoppinsRegular13DarkGray" style={{paddingLeft: 30}}>{"And more..."}</span>
                                    <div style={{height: 12}}></div>
                                </div>
                            )
                        }
                        return (
                            <div className="flex verticalStack clickable" style={{width: "100%", paddingLeft: 10, paddingRight: 10}} onClick={(event) => this.onContactClick(event, item.id,item.cont_tp_id,item.is_private)}>
                                {index != 0 ? <div style={{height: 1, backgroundColor: "var(--lightest-gray)", marginLeft: -10, marginRight: 10}}></div> : null }
                                <div style={{height: 12}}></div>
                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange oneLineThreeDotsText" style={{paddingLeft: 30, paddingRight: 30}}>{item.rcg_name}{item.email ? ", "+item.email : ""}</span>
                                <div style={{height: 12}}></div>
                            </div>
                        )
                    }.bind(this))}
                </div>
            </div>
        );
	}
}

export default SearchBox;