import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';

// import components
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import RolesInTeamsModal from '../modals/RolesInTeamsModal.js';
import ExpandButton from '../components/ExpandButton.js';

class RolesInTeamsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedRoleId: -1,
            mainContactId: 0,
            mainContactName: "Error",
            showModalAddRole: false,
            prepareToShowModalAddRole: false,
            showModalEditRole: false,
            prepareToShowModalEditRole: false,
            actualOpenedTeam: "",
            rolesList: ([{id: 1, role:"Cared for by", team: "Youth Group Meetings", church: "CB Brno", staff: true, yrs: 7, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"},
                                {id: 2, role:"Disciplee", team: "Youth Group Meetings", church: "CB Brno", staff: false, yrs: 10, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"},
                                {id: 6, role:"Coache", team: "Youth Group Meetings", church: "CB Brno", staff: true, yrs: 4, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"}]),
        }
    }

    componentDidMount(){
        this.getDataFromApi();
    }

    getDataFromApi = () => {
        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            Axios.get('/api/rel/get?cont_id='+id+'').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ rolesList: response.data.result })
            });
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result[0].short_name){
                    this.setState({ mainContactName: response.data.result[0].short_name })
                }
            });
            this.setState({ mainContactId: id });
        }
        this.props.update();
    }

    openCloseTeam(team){
        if(this.state.actualOpenedTeam == team){ // if sending same team again it would close it
            this.setState({ actualOpenedTeam: ""})
        }
        else {
            this.setState({ actualOpenedTeam: team})
        }
    }

    onEdit = async (id) => {
        await this.setState({ clickedRoleId: id })
        this.openModal("EditRole");
    }

    onDelete = (id) => {
        Axios.delete('/api/rel/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.getDataFromApi();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddRole"){
            await this.setState({ prepareToShowModalAddRole: true });
            this.setState({ showModalAddRole: true });
        }
        if(modalWindow == "EditRole"){
            await this.setState({ prepareToShowModalEditRole: true });
            this.setState({ showModalEditRole: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        this.getDataFromApi();

        // close the modal
        if(modalWindow == "AddRole"){
            this.setState({ showModalAddRole: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddRole: false });
        }
        if(modalWindow == "EditRole"){
            this.setState({ showModalEditRole: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalEditRole: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal("AddRole");
        }
    }

    onRoleClick = (id) => {
        //code
    }

    openCloseTile = () => {
        if(this.props.isOpen){
            this.props.setActualOpenedTile("");
        }
        else {
            this.props.setActualOpenedTile("roles");
        }
    }

    selectNewPrimary = (id) => {
        Axios.post('/api/rel/prim/'+id, {
            A_ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.getDataFromApi();
        }).then((response) => {
            this.props.update();
        });
    }

    render() {
        var amount_church = 0;
        var amount_entire_youth_ministry = 0;
        var amount_youth_group_meetings = 0;
        var amount_fusion = 0;
        var amount_edge_sports = 0;
        var amount_small_group = 0;
        var amount_worship = 0;

        this.state.rolesList.forEach((item)=>{
            if(item.team == "Church"){
                amount_church++;
            }
            if(item.team == "Entire Youth Ministry"){
                amount_entire_youth_ministry++;
            }
            if(item.team == "Youth Group Meetings"){
                amount_youth_group_meetings++;
            }
            if(item.team == "Fusion"){
                amount_fusion++;
            }
            if(item.team == "Edge Sports"){
                amount_edge_sports++;
            }
            if(item.team == "Small Group"){
                amount_small_group++;
            }
            if(item.team == "Worship"){
                amount_worship++;
            }
        })

        return (
            <div className="rolesTile">

                <div className="horizontalStack">

                    <ExpandButton isOpen={this.props.isOpen} style={{marginTop: 5}} onClick={this.openCloseTile} />

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                        <span className="fontPoppinsSemiBold15">{"Roles in Teams"}</span>
                        <span className={this.props.isOpen ? "fontPoppinsRegular13Gray oneLineThreeDotsTextDeactivated" : "fontPoppinsRegular13Gray oneLineThreeDotsText"}>{"Use this tool for remembering the roles for staff, members and teams in this church. Double click on each item below to edit."}</span>
                    </div>

                    <div style={{width: "20px"}}></div>

                    <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 5}} onClick={() => this.openModal("AddRole")}>
                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                    </div>

                </div>

                <div className="horizontalStackCenter" style={{display: this.props.isOpen ? "" : "none"}}>

                        <table className="rolesTable" style={{marginTop: 10}}>
                            <tr>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Church Ministry Team</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Role</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Approx. Duration</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Description</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Edit</td>
                            </tr>

                            {/* CHURCH LEADERSHIP */}

                            <tr className="clickable" onClick={() => this.openCloseTeam("church")}  style={{backgroundColor: this.state.actualOpenedTeam == "church" ? "var(--lightest-gray)" : ""}}>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                    <img className={this.state.actualOpenedTeam == "church" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Church Leadership</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{amount_church}</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.rolesList.map(function (item) {
                                if(item.team == "Church"){
                                    return (
                                        <tr key={item.id} onClick={() => this.onRoleClick(item.id)} style={{display: this.state.actualOpenedTeam == "church" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap"></td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.a_rcg_name}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.b_a_role}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.yrs}</td>
                                            <td className="rolesTableGeneralDiv">
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="responsiveThreeDots">{item.descr}</span>
                                                </div>
                                            </td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            }.bind(this))}

                            {/* ENTIRE YOUTH MINISTRY */}

                            <tr className="clickable" onClick={() => this.openCloseTeam("entire_youth_ministry")} style={{backgroundColor: this.state.actualOpenedTeam == "entire_youth_ministry" ? "var(--lightest-gray)" : ""}}>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                    <img className={this.state.actualOpenedTeam == "entire_youth_ministry" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Entire Youth Ministry</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{amount_entire_youth_ministry}</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.rolesList.map(function (item) {
                                if(item.team == "Entire Youth Ministry"){
                                    return (
                                        <tr key={item.id} onClick={() => this.onRoleClick(item.id)} style={{display: this.state.actualOpenedTeam == "entire_youth_ministry" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap"></td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.a_rcg_name}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.b_a_role}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.yrs}</td>
                                            <td className="rolesTableGeneralDiv">
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="responsiveThreeDots">{item.descr}</span>
                                                </div>
                                            </td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            }.bind(this))}

                            {/* YOUTH GROUP MEETINGS */}

                            <tr className="clickable" onClick={() => this.openCloseTeam("youth_group_meetings")} style={{backgroundColor: this.state.actualOpenedTeam == "youth_group_meetings" ? "var(--lightest-gray)" : ""}}>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                    <img className={this.state.actualOpenedTeam == "youth_group_meetings" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Youth Group Meetings</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{amount_youth_group_meetings}</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.rolesList.map(function (item) {
                                if(item.team == "Youth Group Meetings"){
                                    return (
                                        <tr key={item.id} onClick={() => this.onRoleClick(item.id)} style={{display: this.state.actualOpenedTeam == "youth_group_meetings" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap"></td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.a_rcg_name}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.b_a_role}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.yrs}</td>
                                            <td className="rolesTableGeneralDiv">
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="responsiveThreeDots">{item.descr}</span>
                                                </div>
                                            </td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            }.bind(this))}

                            {/* FUSION */}

                            <tr className="clickable" onClick={() => this.openCloseTeam("fusion")} style={{backgroundColor: this.state.actualOpenedTeam == "fusion" ? "var(--lightest-gray)" : ""}}>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                    <img className={this.state.actualOpenedTeam == "fusion" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Fusion</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{amount_fusion}</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.rolesList.map(function (item) {
                                if(item.team == "Fusion"){
                                    return (
                                        <tr key={item.id} onClick={() => this.onRoleClick(item.id)} style={{display: this.state.actualOpenedTeam == "fusion" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap"></td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.a_rcg_name}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.b_a_role}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.yrs}</td>
                                            <td className="rolesTableGeneralDiv">
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="responsiveThreeDots">{item.descr}</span>
                                                </div>
                                            </td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            }.bind(this))}

                            {/* EDGE SPORTS */}

                            <tr className="clickable" onClick={() => this.openCloseTeam("edge_sports")}  style={{backgroundColor: this.state.actualOpenedTeam == "edge_sports" ? "var(--lightest-gray)" : ""}}>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                    <img className={this.state.actualOpenedTeam == "edge_sports" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Edge Sports</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{amount_edge_sports}</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.rolesList.map(function (item) {
                                if(item.team == "Edge Sports"){
                                    return (
                                        <tr key={item.id} onClick={() => this.onRoleClick(item.id)} style={{display: this.state.actualOpenedTeam == "edge_sports" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap"></td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.a_rcg_name}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.b_a_role}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.yrs}</td>
                                            <td className="rolesTableGeneralDiv">
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="responsiveThreeDots">{item.descr}</span>
                                                </div>
                                            </td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            }.bind(this))}

                            {/* SMALL GROUPS */}

                            <tr className="clickable" onClick={() => this.openCloseTeam("small_groups")}  style={{backgroundColor: this.state.actualOpenedTeam == "small_groups" ? "var(--lightest-gray)" : ""}}>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                    <img className={this.state.actualOpenedTeam == "small_groups" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Small Groups</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{amount_small_group}</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.rolesList.map(function (item) {
                                if(item.team == "Small Group"){
                                    return (
                                        <tr key={item.id} onClick={() => this.onRoleClick(item.id)} style={{display: this.state.actualOpenedTeam == "small_groups" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap"></td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.a_rcg_name}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.b_a_role}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.yrs}</td>
                                            <td className="rolesTableGeneralDiv">
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="responsiveThreeDots">{item.descr}</span>
                                                </div>
                                            </td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            }.bind(this))}

                            {/* WORSHIP */}

                            <tr className="clickable" onClick={() => this.openCloseTeam("worship")} style={{backgroundColor: this.state.actualOpenedTeam == "worship" ? "var(--lightest-gray)" : ""}}>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                    <img className={this.state.actualOpenedTeam == "worship" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Worship</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{amount_worship}</td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.rolesList.map(function (item) {
                                if(item.team == "Worship"){
                                    return (
                                        <tr key={item.id} onClick={() => this.onRoleClick(item.id)} style={{display: this.state.actualOpenedTeam == "worship" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap"></td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.a_rcg_name}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.b_a_role}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.yrs}</td>
                                            <td className="rolesTableGeneralDiv">
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="responsiveThreeDots">{item.descr}</span>
                                                </div>
                                            </td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            }.bind(this))}

                        </table>

                </div>

                {/* I am hiding and showing it by this IF because when I used just showModal variable it did not update on change */}
                {this.state.prepareToShowModalAddRole ? <RolesInTeamsModal showModal={this.state.showModalAddRole} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddRole"} roleId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalEditRole ? <RolesInTeamsModal showModal={this.state.showModalEditRole} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"EditRole"} roleId={this.state.clickedRoleId} openAlertMessage={this.props.openAlertMessage} /> : null }

            </div>
        );
    }
}

export default RolesInTeamsTile;
