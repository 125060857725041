import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

// import components
import DropdownItem from '../components/DropdownItem.js';

// assets
import icon_search from '../assets/icon_search.png';

class SearchPopup extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className="universalPopupSelectContainer" ref={this.props.refPopup} style={this.props.style}>

                {!this.props.showEmailStyle ? <div className="universalPopupSelectVisible horizontalStack">
                    {this.props.textStyle == "italicOrange" ? <span className="fontPoppinsItalic15 clickable" style={{color: "var(--jv-orange)", whiteSpace: "nowrap"}} onClick={() => this.props.showHidePopup()}>{this.props.textName}</span> : null }
                    {this.props.textStyle == "normalBlue" ? <span className="fontPoppinsSemiBold15 clickable" style={{color: "var(--blue-button-link)", whiteSpace: "nowrap"}} onClick={() => this.props.showHidePopup()}>{this.props.textName}</span> : null }
                    {this.props.textStyle == "italicOrange13" ? <span className="fontPoppinsItalic13 clickable" style={{color: "var(--jv-orange)", whiteSpace: "nowrap"}} onClick={() => this.props.showHidePopup()}>{this.props.textName}</span> : null }
                    {this.props.textStyle == "normalBlue13" ? <span className="fontPoppinsRegular13 clickable" style={{color: "var(--blue-button-link)", whiteSpace: "nowrap"}} onClick={() => this.props.showHidePopup()}>{this.props.textName}</span> : null }
                    {this.props.textStyle == "insertedObject" ? this.props.insertedObject : null }
                </div> : null }

                {this.props.showEmailStyle ? <div className="universalPopupSelectVisible horizontalStack">
                    <span className="fontPoppinsRegular13 clickable" style={{marginLeft: 10, border: "1px solid var(--jv-orange)", color: "var(--jv-orange)", borderRadius: 5, paddingLeft: 5, paddingRight: 5, whiteSpace: "nowrap"}} onClick={() => this.props.showHidePopup()}>{this.props.textName}</span>
                </div> : null }

                <div className="universalPopupSelectHidden" style={{width: 300, display: this.props.showPopup ? "" : "none", marginTop: -37}}>
                    <div className="verticalStack">
                        <div className="horizontalStackCenter">
                            <img style={{width: 16, height: 16, marginRight: 5}} src={icon_search}/>
                            <div className="horizontalStackCenter" style={{position: "relative"}}>
                                <input className="fontPoppinsRegular13 searchBoxPlaceholder" type="text" required="required" ref={this.props.refInput} id={this.props.id} name={this.props.id} autoComplete="off" value={this.props.value} onChange={(e) => this.props.changeValue(e.target.value)} style={{flex: 1, marginTop: 5, marginBottom: 5, paddingLeft: 0, border: "0px solid black"}}/>
                                <label for={this.props.id} className="fontPoppinsRegular13Gray">Search<span className="fontPoppinsItalic13PurpleGray"> {"(Type 3+ Characters Here)"}</span></label>
                            </div>
                        </div>

                        {this.props.foundRecords.map(function (item, index) {
                            if (index > 10) { return; }
                            if (index == 10) { return ( <DropdownItem title={"And more..."} clickable={false} /> ) }
                            return (
                                <DropdownItem title={item.rcg_name+(item.email ? ", "+item.email : "")} onClick={() => this.props.addContactToArray(item)} clickable={true} />
                            )
                        }.bind(this))}

                    </div>
                </div>

            </div>
        );
	}
}

export default SearchPopup;