import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Moment from 'react-moment';

// import assets
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';
import five_challenges_img from '../assets/five_challenges.png';
import icon_active_conversations from '../assets/icon_active_conversations.png';
import icon_inactive_conversations from '../assets/icon_inactive_conversations.png';

// import components
import FiveChallengesModal from '../modals/FiveChallengesModal.js';
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import MyDisciplingModal from '../modals/MyDisciplingModal.js';
import DiscipleBox from '../components/DiscipleBox.js';
import MessageModal from '../modals/MessageModal.js';

import { Link } from 'react-router-dom';

class MyDisciplingTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tileIsOpened: false,
            showModalMyDiscipling: false,
            prepareToShowModalMyDiscipling: false,
            showModalFiveChallenges: false,
            prepareToShowModalFiveChallenges: false,
            contactIdFiveChallenges: 0,
            // start of message modal
            showMessageModal: false,
            prepareToShowMessageModal: false,
            messageModalTitle: "",
            messageModalDesc: "",
            // end of message modal
            filterByPeople: "null",
            showPopupFilterByPeople: false,
            filterByChurches: "null",
            showPopupFilterByChurches: false,
        }
    }

    showMessageModal = async (title, desc) => {
        await this.setState({ messageModalTitle: title });
        await this.setState({ messageModalDesc: desc });
        this.openModal("MessageModal");
    }

    showFiveChallengesModal = async (contactId) => {
        await this.setState({ contactIdFiveChallenges: contactId });
        this.openModal("FiveChallenges");
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "MyDiscipling"){
            await this.setState({ prepareToShowModalMyDiscipling: true });
            this.setState({ showModalMyDiscipling: true });
        }
        else if(modalWindow == "MessageModal"){
            await this.setState({ prepareToShowMessageModal: true });
            this.setState({ showMessageModal: true });
        }
        else if(modalWindow == "FiveChallenges"){
            await this.setState({ prepareToShowModalFiveChallenges: true });
            this.setState({ showModalFiveChallenges: true });
        }
    }

    // the not animated closing is because I hide it downthere imediatelly when the state value is set to false..so it doesnt have time to react it on the set=false inside the Modal component
    closeModal = async (modalWindow) => {
        // reload data
        this.props.getDataFromApi();

        // close the modal
        if(modalWindow == "MyDiscipling"){
            this.setState({ showModalMyDiscipling: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalMyDiscipling: false });
        }
        else if(modalWindow == "MessageModal"){
            this.setState({ showMessageModal: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowMessageModal: false });
        }
        else if(modalWindow == "FiveChallenges"){
            this.setState({ showModalFiveChallenges: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalFiveChallenges: false });
        }
    }

    selectFilterByPeople = (selected) => {
        if(selected == this.state.filterByPeople){
            this.setState({ filterByPeople: "null" });
        }
        else {
            this.setState({ filterByPeople: selected });
        }
    }

    selectFilterByChurches = (selected) => {
        if(selected == this.state.filterByChurches){
            this.setState({ filterByChurches: "null" });
        }
        else {
            this.setState({ filterByChurches: selected });
        }
    }

    switchIsPublic = async (value) => {
        if(value == 0) await this.props.setIsPublic(true);
        else await this.props.setIsPublic(false);

        this.props.getDataFromApi();
    }

    render() {
        return (
            <div style={{height: "calc(100% - 60px)", width: "100%"}}>
                <div className="myPeopleTile">
                    <div className="verticalStack" style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px - 80px)" : "calc(100% - 90px - 80px)", height: "calc(100% - 80px - 120px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}

                        <div className="horizontalStack">
                            <div className="verticalStack" style={{flex: 4}}>
                                <span className="fontMontserratBold25Green">{"MY DISCIPLE-MAKING"}</span>
                                <div style={{marginTop: 10}}></div>
                                <span className="fontPoppinsSemiBold15">{"Who am I individually helping follow the Five Challenges of Christ?"}</span>
                                <div style={{marginTop: 10}}></div>
                                <span className="fontPoppinsRegular13Gray">{"Use the orange “Add” button to add a contact to this disciple-making overview page. Then select the type of contact you want to view here. If you want to learn more about the 5 Challenges of Christ "}<span className="fontPoppinsRegular13Orange clickable">{"click here"}</span>{". You can also remove people that you’ve added to this page view by hovering over them and selecting “Remove from Page”."}</span>
                                <div className="flex"></div>
                                <div className="horizontalStack" style={{marginBottom: 5}}>

                                    {/* Filter by People select popup */}
                                    {/*<div className="universalPopupSelectContainer">

                                        <div className="universalPopupSelectVisible horizontalStack clickable" onClick={() => this.setState({ showPopupFilterByPeople: !this.state.showPopupFilterByPeople })}>
                                            <span className="fontPoppinsRegular13Gray">Filter by People v</span>
                                        </div>

                                        <div className="universalPopupSelectHidden" style={{display: this.state.showPopupFilterByPeople ? "" : "none"}}>
                                            <div className="verticalStack" onClick={() => this.setState({ showPopupFilterByPeople: false })}>
                                                <span className="fontPoppinsRegular13 clickable" onClick={() => this.selectFilterByPeople("null")}>Choose a Filter</span>

                                                <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                                <div className="verticalStack">
                                                    <div className="horizontalStack clickable" style={{marginTop: 3, marginBottom: 3}} onClick={() => this.selectFilterByPeople("my_people")}>
                                                        <span className="fontPoppinsRegular13" style={{marginLeft: 5}}>{"My People"}</span>
                                                    </div>
                                                    <div className="horizontalStack clickable" style={{marginTop: 3, marginBottom: 3}} onClick={() => this.selectFilterByPeople("my_conversations")}>
                                                        <span className="fontPoppinsRegular13" style={{marginLeft: 5}}>{"My Conversations"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex"></div>*/}

                                    {/* Filter by Churches select popup */}
                                    {/*<div className="universalPopupSelectContainer">

                                        <div className="universalPopupSelectVisible horizontalStack clickable" onClick={() => this.setState({ showPopupFilterByChurches: !this.state.showPopupFilterByChurches })}>
                                            <span className="fontPoppinsRegular13Gray">Filter by Churches v</span>
                                        </div>

                                        <div className="universalPopupSelectHidden" style={{display: this.state.showPopupFilterByChurches ? "" : "none"}}>
                                            <div className="verticalStack" onClick={() => this.setState({ showPopupFilterByChurches: false })}>
                                                <span className="fontPoppinsRegular13 clickable" onClick={() => this.selectFilterByChurches("null")}>Choose a Filter</span>

                                                <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                                <div className="verticalStack">
                                                    <div className="horizontalStack clickable" style={{marginTop: 3, marginBottom: 3}} onClick={() => this.selectFilterByChurches("church_1")}>
                                                        <span className="fontPoppinsRegular13" style={{marginLeft: 5}}>{"Church Name 1"}</span>
                                                    </div>
                                                    <div className="horizontalStack clickable" style={{marginTop: 3, marginBottom: 3}} onClick={() => this.selectFilterByChurches("church_2")}>
                                                        <span className="fontPoppinsRegular13" style={{marginLeft: 5}}>{"Church Name 2"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex"></div>*/}

                                    <div className="horizontalStack clickable" onClick={() => this.props.toggleShowActive()}>
                                        <input type="checkbox" className="clickable" checked={this.props.showActive} />
                                        <img src={icon_active_conversations} style={{width: 20, height: 20, marginLeft: 5, marginRight: 5}}/>
                                        <span className="fontPoppinsRegular13Gray">{"Active Conversations"}</span>
                                    </div>

                                    <div style={{width: 40}}></div>

                                    <div className="horizontalStack clickable" onClick={() => this.props.toggleShowUnactive()}>
                                        <input type="checkbox" className="clickable" checked={this.props.showUnactive} />
                                        <img src={icon_inactive_conversations} style={{width: 20, height: 20, marginLeft: 5, marginRight: 5}}/>
                                        <span className="fontPoppinsRegular13Gray">{"Inactive for 6+ Months"}</span>
                                    </div>

                                    <div className="flex"></div>

                                    {/*<div className="horizontalStack">
                                        <input type="checkbox" className="clickable" checked={this.props.isPublic} onClick={() => this.switchIsPublic(this.props.isPublic)} />
                                        <div style={{width: 20, height: 20, backgroundColor: "var(--light-blue)", borderRadius: 20, marginLeft: 5, marginRight: 5}}></div>
                                        <span className="fontPoppinsRegular13Gray">{"My Relationships"}</span>
                                    </div>*/}

                                </div>
                            </div>

                            <div style={{flex: 3}}>
                                <img style={{width: "100%"}} src={five_challenges_img}/>
                            </div>

                            <div className="circleButtonContainer circleButtonContainerOrange clickable" onClick={() => this.openModal("MyDiscipling")}>
                                <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                            </div>
                        </div>

                        <div className="flex verticalStack" style={{overflow: "scroll", marginTop: 10}}>
                            <table className="myDisciplingTable">
                                <tr>
                                    <td className="myDisciplingTableFirstRowTd myDisciplingTableFirstRowTdLine"><span className="fontPoppinsSemiBold13">D1 - Come and See</span></td>
                                    <td className="myDisciplingTableFirstRowTd myDisciplingTableFirstRowTdLine"><span className="fontPoppinsSemiBold13">D2 - Repent and Believe</span></td>
                                    <td className="myDisciplingTableFirstRowTd myDisciplingTableFirstRowTdLine"><span className="fontPoppinsSemiBold13">D3 - Follow Me</span></td>
                                    <td className="myDisciplingTableFirstRowTd myDisciplingTableFirstRowTdLine"><span className="fontPoppinsSemiBold13">D4 - Follow Me and Fish for Men</span></td>
                                    <td className="myDisciplingTableFirstRowTd myDisciplingTableFirstRowTdLast"><span className="fontPoppinsSemiBold13">D5 - I Am Sending You</span></td>
                                </tr>

                                <tr style={{height: "100%"}}>
                                    <td className="myDisciplingTableTd myDisciplingTableTdLine" valign="top">

                                        {this.props.disciplesArray.map(function (item) {
                                            if(item.seg_val == 1){
                                                return (
                                                    <DiscipleBox contId={item.id} name={item.rcg_name} role={item.role} convNum={item.conv_cnt ? item.conv_cnt : "0"} lastActive="1/16/22" isPrivate={item.is_private} getDataFromApi={this.props.getDataFromApi} isActive={item.rcnt_cnt == 0 ? false : true} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                )
                                            }
                                        }.bind(this))}

                                    </td>

                                    <td className="myDisciplingTableTd myDisciplingTableTdLine" valign="top">

                                        {this.props.disciplesArray.map(function (item) {
                                            if(item.seg_val == 2){
                                                return (
                                                    <DiscipleBox contId={item.id} name={item.rcg_name} role={item.role} convNum={item.conv_cnt ? item.conv_cnt : "0"} lastActive="1/16/22" isPrivate={item.is_private} getDataFromApi={this.props.getDataFromApi} isActive={item.rcnt_cnt == 0 ? false : true} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                )
                                            }
                                        }.bind(this))}

                                    </td>

                                    <td className="myDisciplingTableTd myDisciplingTableTdLine" valign="top">

                                        {this.props.disciplesArray.map(function (item) {
                                            if(item.seg_val == 3){
                                                return (
                                                    <DiscipleBox contId={item.id} name={item.rcg_name} role={item.role} convNum={item.conv_cnt ? item.conv_cnt : "0"} lastActive="1/16/22" isPrivate={item.is_private} getDataFromApi={this.props.getDataFromApi} isActive={item.rcnt_cnt == 0 ? false : true} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                )
                                            }
                                        }.bind(this))}

                                    </td>

                                    <td className="myDisciplingTableTd myDisciplingTableTdLine" valign="top">

                                        {this.props.disciplesArray.map(function (item) {
                                            if(item.seg_val == 4){
                                                return (
                                                    <DiscipleBox contId={item.id} name={item.rcg_name} role={item.role} convNum={item.conv_cnt ? item.conv_cnt : "0"} lastActive="1/16/22" isPrivate={item.is_private} getDataFromApi={this.props.getDataFromApi} isActive={item.rcnt_cnt == 0 ? false : true} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                )
                                            }
                                        }.bind(this))}

                                    </td>

                                    <td className="myDisciplingTableTd myDisciplingTableLastColumn" valign="top">

                                        {this.props.disciplesArray.map(function (item) {
                                            if(item.seg_val == 5){
                                                return (
                                                    <DiscipleBox contId={item.id} name={item.rcg_name} role={item.role} convNum={item.conv_cnt ? item.conv_cnt : "0"} lastActive="1/16/22" isPrivate={item.is_private} getDataFromApi={this.props.getDataFromApi} isActive={item.rcnt_cnt == 0 ? false : true} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                )
                                            }
                                        }.bind(this))}

                                    </td>
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>

                {this.state.prepareToShowModalMyDiscipling ? <MyDisciplingModal showModal={this.state.showModalMyDiscipling} closeModal={this.closeModal} openModal={this.openModal} actionType={"MyDiscipling"} showFiveChallengesModal={this.showFiveChallengesModal} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowMessageModal ? <MessageModal showModal={this.state.showMessageModal} closeModal={this.closeModal} openModal={this.openModal} actionType={"MessageModal"} title={this.state.messageModalTitle} desc={this.state.messageModalDesc} /> : null }
                {this.state.prepareToShowModalFiveChallenges ? <FiveChallengesModal showModal={this.state.showModalFiveChallenges} closeModal={this.closeModal} contactId={this.state.contactIdFiveChallenges} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
    }
}

export default MyDisciplingTile;
