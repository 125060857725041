import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';
import '../styles/tiles.css';

// assets
import editButtonDropdownCircle from '../assets/editButtonDropdownCircle.png';
import editButtonDropdownCircleClosed from '../assets/editButtonDropdownCircleClosed.png';

class EditDeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    deleteAction = () => {
        this.props.onDelete(this.props.itemId)
    }

    openAlertMessage = async () => {
        this.props.openAlertMessageTwoActions(this.props.areYouSureText,"Cancel","Delete",this.deleteAction);
    }

    render(){
        return(
            <div className="editDeleteButtonContainer clickable" onClick={() => this.setState({ isOpened: !this.state.isOpened })}>
                <div className="editDeleteButtonVisible" style={{display: this.state.isOpened ? "none" : "block"}}>
                    <img className="editDeleteButtonImageOpened" style={{width: "30px"}} src={editButtonDropdownCircle}/>
                    <img className="editDeleteButtonImageClosed" style={{width: "30px"}} src={editButtonDropdownCircleClosed}/>
                </div>
                <div className="editDeleteButtonVisible" style={{display: this.state.isOpened ? "block" : "none"}}>
                    <div className="horizontalStackCenter" style={{height: "100%", marginLeft: 8}}>
                        <span className="fontPoppinsRegular13DarkGray onHoverToOrange clickable" onClick={() => this.props.onEdit(this.props.itemId)}>{"Edit"}</span>
                        <div style={{width: 8}}></div>
                        <span className="fontPoppinsRegular13DarkGray onHoverToPink clickable" onClick={() => this.openAlertMessage()}>{"Delete"}</span>
                    </div>
                </div>
            </div>
        );
	}
}

export default EditDeleteButton;