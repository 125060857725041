import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';
import SearchPopup from '../components/SearchPopup.js';

// assets
import close_white from '../assets/close_white.png';
import icon_password_show from '../assets/icon_password_show.png';
import icon_password_hide from '../assets/icon_password_hide.png';

class AddUserModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // MyDiscipling
            redirectTo: null,
            user_email: "",
            user_phone: "",
            user_first_name: "",
            user_last_name:"",
            user_jv_country_id: 0,
            user_ministry_country_id: 0,
            user_password: "",
            user_password_repeat: "",
            show_password: false,
            show_password_repeat: false,
            listOfCountries: ([{"id":1,"label":"ALL"},{"id":2,"label":"Afghanistan"},{"id":3,"label":"Aland Islands"}]),
            contactId: -1,
            contactName: "+ Add a Contact",
            showPopupContact: false,
            findContactByName: "",
            foundContacts: ([]),
        };

        this.refPopupContact = React.createRef();
        this.refInputContact = React.createRef();
    }

    componentDidMount(){
        this.loadData();
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    loadData = async () => {
        await Axios.get('/api/perm_cntry/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfCountries: response.data.result })
        });
    }

    onGlobalClick = (e) => {
        if ( (!this.refPopupContact.current.contains(e.target)) && this.state.showPopupContact) {
            this.setState({ showPopupContact: false })
        }
        if ( this.state.showPopupContact ) this.refInputContact.current.focus();
    }

    createUserAction = () => {
        if(this.state.user_email == "" || this.state.user_first_name == "" || this.state.user_last_name == "" || this.state.user_password == "" || this.state.user_password_repeat == "" || this.state.user_jv_country_id == 0){
            this.props.openAlertMessageTwoActions("Please fill all required fields.","OK","",null);
            return;
        }
        else if(this.state.user_password.length < 8){
            this.props.openAlertMessageTwoActions("Password must be 8 characters or longer.","OK","",null);
            return;
        }
        else if(this.state.user_password != this.state.user_password_repeat){
            this.props.openAlertMessageTwoActions("Passwords don't match.","OK","",null);
            return;
        }
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(this.state.user_email)) {
            this.props.openAlertMessageTwoActions("Plase fill valid email address.","OK","",null);
            return;
        }

        var finalMinistryCountryId = -1;
        if(this.state.user_ministry_country_id == 0){
            finalMinistryCountryId = this.state.user_jv_country_id;
        }
        else {
            finalMinistryCountryId = this.state.user_ministry_country_id;
        }

        //alert("Email: "+this.state.user_email+"\nPhone: "+this.state.user_phone+"\nFirst Name: "+this.state.user_first_name+"\nLast Name: "+this.state.user_last_name+"\nJV Country: "+this.state.user_jv_country_id+"\nMinistry Country: "+this.state.user_ministry_country_id+"\nPassword: "+this.state.user_password)

        Axios.post("/api/usr/ins", {
            Email: this.state.user_email,
            FirstName: this.state.user_first_name,
            LastName: this.state.user_last_name,
            Phone: this.state.user_phone,
            ContId: this.state.contactId == -1 ? "" : this.state.contactId,
            PermCntryId: this.state.user_jv_country_id,
            MnryCntryId: finalMinistryCountryId,
            Password: this.state.user_password,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            this.props.closeModal(this.state.editingMode);
        });

    }

    generatePasswordAction = () => {
        const characterPool = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
        const passwordLength = 8;
        const randomNumber = new Uint8Array(1);
        var password = '';

        for(let i = 0; i < passwordLength; i++) {
            do { crypto.getRandomValues( randomNumber ); }
            while ( randomNumber[0] >= characterPool.length );
            password += characterPool[ randomNumber[0] ];
        }

        this.setState({ user_password: password });
        this.setState({ user_password_repeat: password });
        this.setState({ show_password: true });
        this.setState({ show_password_repeat: true });
    }

    searchForContacts = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=1&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundContacts: response.data.result });
            });
        }
        else {
            this.setState({ foundContacts: [] });
        }
    }

    selectFoundContact = async (contactId, contactName) => {
        this.setState({ contactId: contactId });
        this.setState({ contactName: contactName });
        this.setState({ showPopupContact: false });
        this.setState({ findContactByName: "" });
        this.setState({ foundContacts: [] });
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode)} isExpanded={false} isExtraExpanded={false} wrapContent={true}>
                    <div className="content" style={{width: 550}}>
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{"Add New User"}</span>
                                <span className="fontPoppinsRegular13Gray">{"Please fill all required fields."}</span>
                            </div>

                            <div className="verticalStack">
                                <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode)}/>
                                <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStack" style={{width: "inherit", marginTop: 10, paddingTop: 12, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                            <div className="verticalStack" style={{flex: 1, position: "relative", marginLeft: 14, marginRight: 14, marginBottom: 14}}>

                                <div className="horizontalStack">
                                    <div className="verticalStack" style={{flex: 1}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>Email*</span>
                                        <input className="profileTileInputText" type="text" id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_email} onChange={e => this.setState({ user_email: e.target.value })}/>
                                    </div>

                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>Phone</span>
                                        <input className="profileTileInputText" type="text" id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_phone} onChange={e => this.setState({ user_phone: e.target.value })}/>
                                    </div>
                                </div>

                                <div className="horizontalStack" style={{marginTop: 14, marginBottom: 8}}>
                                    <div className="verticalStack" style={{flex: 1}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>First Name*</span>
                                        <input className="profileTileInputText" type="text" id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_first_name} onChange={e => this.setState({ user_first_name: e.target.value })}/>
                                    </div>

                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>Last Name*</span>
                                        <input className="profileTileInputText" type="text" id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_last_name} onChange={e => this.setState({ user_last_name: e.target.value })}/>
                                    </div>
                                </div>

                                <div className="horizontalStack" style={{ marginBottom: 8}}>
                                    <div className="verticalStack" style={{width: 255}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>Has Access To*</span>
                                        <select className="profileTileInputText topMenuSelectBox" name="country" id="country" value={this.state.user_jv_country_id} onChange={e => this.setState({ user_jv_country_id: e.target.value })}>
                                            <option value=""></option>
                                            {this.state.listOfCountries.map(function (item) {
                                                return (
                                                    <option value={item.id} selected={this.state.user_jv_country_id == item.id ? "selected" : ""}>{item.label}</option>
                                                )
                                            }.bind(this))}
                                        </select>
                                    </div>

                                    <div className="verticalStack" style={{width: 255, marginLeft: 12}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 8}}>Ministry Country</span>
                                        <select className="profileTileInputText topMenuSelectBox" name="country" id="country" value={this.state.user_ministry_country_id} onChange={e => this.setState({ user_ministry_country_id: e.target.value })}>
                                            <option value=""></option>
                                            {this.state.listOfCountries.map(function (item) {
                                                return (
                                                    <option value={item.id} selected={this.state.user_ministry_country_id == item.id ? "selected" : ""}>{item.label}</option>
                                                )
                                            }.bind(this))}
                                        </select>
                                    </div>
                                </div>

                                <div className="horizontalStack" style={{marginBottom: 8, alignItems: "flex-end"}}>

                                    <div className="verticalStack" style={{flex: 1}}>
                                        <span className="fontPoppinsRegular13" style={{marginTop: 0, marginBottom: 8}}>Enter a New Password*</span>
                                        <div className="horizontalStackCenter">
                                            <input className="profileTileInputText flex" type={this.state.show_password ? "text" : "password"} id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_password} onChange={e => this.setState({ user_password: e.target.value })}/>
                                            <img className="clickable" src={this.state.show_password ? icon_password_show : icon_password_hide} style={{marginLeft: 7, width: 20}} onClick={() => this.setState({ show_password: !this.state.show_password })}/>
                                        </div>
                                    </div>

                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                        <span className="fontPoppinsRegular13" style={{marginTop: 0, marginBottom: 8}}>Re-Enter the New Password*</span>
                                        <div className="horizontalStackCenter">
                                            <input className="profileTileInputText flex" type={this.state.show_password_repeat ? "text" : "password"} id="new-password" name="new-password" autocomplete="new-password" value={this.state.user_password_repeat} onChange={e => this.setState({ user_password_repeat: e.target.value })}/>
                                            <img className="clickable" src={this.state.show_password_repeat ? icon_password_show : icon_password_hide} style={{marginLeft: 7, width: 20}} onClick={() => this.setState({ show_password_repeat: !this.state.show_password_repeat })}/>
                                        </div>
                                    </div>
                                </div>
                                <span className="fontPoppinsItalic13Gray" style={{marginBottom: 8}}>(Minimum 8 characters, please make it a complex password)</span>

                                <div className="horizontalStackCenter">
                                    <RoundButton title={"Generate Password"} className={"roundButtonGray"} style={{whiteSpace: "nowrap"}} whiteText={true} onClick={() => this.generatePasswordAction()} />
                                </div>

                                <div className="horizontalStackCenter" style={{marginTop: 10}}>
                                    <span className="fontPoppinsRegular13" style={{marginRight: 10}}>Connect this User Profile with Contact Profile: </span>

                                    <SearchPopup
                                        id={"searchContactId"}
                                        textName={this.state.contactName}
                                        textStyle={this.state.contactId == -1 ? "italicOrange" : "normalBlue"}
                                        value={this.state.findContactByName}
                                        changeValue={(text) => {this.setState({ findContactByName: text }); this.searchForContacts(text);}}
                                        foundRecords={this.state.foundContacts}
                                        refPopup={this.refPopupContact}
                                        refInput={this.refInputContact}
                                        showPopup={this.state.showPopupContact}
                                        showHidePopup={() => this.setState({ showPopupContact: !this.state.showPopupContact })}
                                        addContactToArray={(item) => this.selectFoundContact(item.id,item.first_name+" "+item.last_name)}
                                        showEmailStyle={false}
                                    />

                                </div>

                            </div>

                        </div>

                        <div className="horizontalStack" style={{marginTop: 14}}>
                            <RoundButton title={"Save New User"} className={"roundButtonOrange"} style={{whiteSpace: "nowrap"}} whiteText={true} onClick={() => this.createUserAction()} />
                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}
}

export default AddUserModal;