import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Resizer from "react-image-file-resizer";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// import assets
import { Link } from 'react-router-dom';
import church_avatar from '../assets/church_avatar.png';
import background from '../assets/background.jpg';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';
import icon_done_white from '../assets/icon_done_white.png';

// import components
import PopupButtonDescription from '../components/PopupButtonDescription.js';
import ExpandButton from '../components/ExpandButton.js';
import ProfileImage from '../components/ProfileImage.js';

class ProfileTeamTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            mainContactId: 0,
            updateExisting: true, // if I am in create new contact mode it would be false
            isMyContact: false, // default should be false
            toolOpened: false,
            dataCategory: "name", // name, contact, ministry_info
            actualProfile: ([{id:1,country_id:0,org_name:"",short_name:"",city:"",county:""}]),
            uploadedImageBase64: "",
        }

        this.hiddenFileInput = React.createRef();
    }

    componentDidMount(){
        //const queryParams = new URLSearchParams(window.location.search);
        //const id = queryParams.get('id');
        this.loadData();
    }

    loadData = async () => {
        this.setState({ uploadedImageBase64: "" });

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            if(Number(id) == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/Churches"); window.location.reload(); }
                return;
            }
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                //alert(JSON.stringify(response.data.result));
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_cont });
                }
            });
            this.setState({ updateExisting: true });
            this.setState({ mainContactId: id });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=avatar&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });
        }
        else {
            this.setState({ updateExisting: false });
            this.setState({ toolOpened: true });
        }

    }

    openCloseTool = () => {
        if(this.state.toolOpened){
            this.setState({ toolOpened: false });
            this.props.onCloseOrOpen("");
        }
        else {
            this.setState({ toolOpened: true });
            this.props.onCloseOrOpen("profiledata");
        }
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_cont/del/', {
                data: {ContId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_cont/ins/', {
                ContId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    changeDataCategoryToName = () => { this.setState({ dataCategory: "name" }); }
    changeDataCategoryToContact = () => { this.setState({ dataCategory: "contact" }); }
    changeDataCategoryToMinistry = () => { this.setState({ dataCategory: "ministry_info" }); }

    nameSectionWasEdited = (actualProfile) => {
        if((typeof(actualProfile.org_name) === "undefined" || actualProfile.org_name == "")
        && (typeof(actualProfile.short_name) === "undefined" || actualProfile.short_name == "")){
            return false;
        }
        else {
            return true;
        }
    }

    /* START OF METHODS FOR AVATAR IMAGE */

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    uploadImageClick = () => { this.hiddenFileInput.current.click(); };

    deleteProfileImage = () => {
        Axios.post('/api/file/delete', {
            FilePrefix: "avatar",
            ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ uploadedImageBase64: "" });
        });
    }

    imageIsSelected = async (event) => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded.size > 5000000){
            this.props.openAlertMessage("Error! Maximum size of picture is 5 MB.","OK");
            return;
        }
        var base64 = await this.toBase64(fileUploaded);
        var prefix = fileUploaded.name.substring(fileUploaded.name.lastIndexOf(".")+1);
        //this.setState({ uploadedImageBase64: base64 });

        try {
            Resizer.imageFileResizer(fileUploaded, 300, 300, "JPEG", 100, 0,
                (uri) => {
                    //console.log(uri);
                    this.setState({ uploadedImageBase64: uri });
                    if(this.state.updateExisting){
                        this.uploadAvatarImage(this.state.mainContactId);
                    }
                },
                "base64", 200,  200
            );
        }
        catch (err) {
            //console.log(err);
            this.props.openAlertMessage("Error while uploading image.","OK");
        }
        //var improvedBase64 = base64.substring(base64.lastIndexOf(";base64,")+8);
    };

    uploadAvatarImage = (contactId) => {
        if(this.state.uploadedImageBase64 == "")return;
        Axios.post('/api/file/upload', {
            FilePrefix: "avatar",
            ContId: contactId,
            content: this.state.uploadedImageBase64,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            //alert("Avatar image was uploaded.");
        });
    }

    /* END OF METHODS FOR AVATAR IMAGE */

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div className="profileTile">
                <div className="flex" style={{flex: 1, height: 203, position: "relative"}}>

                    <div style={{width: "calc(100% + 40px)", backgroundColor: "var(--full-black)", height: "calc(50% + 20px)", position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                        <img style={{width: "100%", height: "100%", borderRadius: "25px 25px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                    </div>

                    <div className="horizontalStack" style={{height: "100%"}}>

                        <div className="verticalStack" style={{zIndex: "1"}}>

                            { this.state.isMyContact == false ? <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add to My Contacts"} blackArrow={false} />
                                </div>
                            </div> :
                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Remove from My Contacts"} blackArrow={false} />
                                </div>
                            </div> }

                            <div className="flex"></div>

                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <ExpandButton isOpen={this.state.toolOpened} style={{marginTop: 5}} onClick={this.openCloseTool} />
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add and Edit Contact Info"} blackArrow={true} />
                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{zIndex: "0", marginLeft: "-10px", marginRight: "23px"}}>
                            <div className="flex"></div>

                            <ProfileImage
                                initials={getInitials(this.state.actualProfile[0].org_name)}
                                uploadedImageBase64={this.state.uploadedImageBase64}
                                toolOpened={this.state.toolOpened}
                                uploadImageClick={this.uploadImageClick}
                                deleteImageClick={this.deleteProfileImage}
                                contactType={"person"}
                            />

                            <input type="file"
                                ref={this.hiddenFileInput}
                                style={{display:'none'}}
                                accept={"image/png, image/jpg, image/jpeg"}
                                onChange={(event) => this.imageIsSelected(event)}
                            />

                            <div className="flex"></div>
                        </div>

                        <div className="verticalStack flex" style={{zIndex: "0", height: "calc(100% + 20px)"}}>
                            <div className="verticalStackCenter flex" style={{height: "100%", marginTop: "-20px"}}>
                                <div className="horizontalStack" style={{height: "100%"}}>
                                    <div className="verticalStackCenter" style={{flex: 4, minWidth: 50, height: "100%"}}>
                                        <div className="flex"></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsMedium40White responsiveThreeDots"><b>{this.state.actualProfile[0].org_name}</b></span>
                                        </div>
                                        <div className="flex"></div>
                                    </div>
                                    <div style={{width: 20}}></div>
                                    <div className="verticalStackCenter" style={{flex: 3, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold15 responsiveThreeDots" style={{color: "var(--jv-orange)"}}>{this.state.actualProfile[0].website != null ? this.state.actualProfile[0].website : "website not saved"}</span>
                                        </div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13 responsiveThreeDots" style={{color: "var(--white)"}}>facebook.com/team_name</span>
                                        </div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13 responsiveThreeDots" style={{color: "var(--white)"}}>instagram.com/team_name</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="verticalStackCenter flex">
                                <div className="horizontalStack" style={{height: "100%"}}>

                                    <div className="verticalStackCenter" style={{flex: 3, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{"Short Name: "}{this.state.actualProfile[0].short_name}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].country}</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].phone != null ? this.state.actualProfile[0].phone : "-"}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].email != null ? this.state.actualProfile[0].email : "-"}</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].website != null ? this.state.actualProfile[0].website : "-"}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">Team</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 2, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold13Gray responsiveThreeDots">{"-"}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold13DarkGray responsiveThreeDots">{"-"}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Editing data part */}

                <div className="flex" style={{flex: 1, position: "relative", display: this.state.toolOpened ? "inline" : "none"}}>

                    <div className="verticalStack" style={{marginTop: 20, marginBottom: 20}}>

                        {/* Name */}

                        <div className="horizontalStackCenter clickable" style={{height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={this.changeDataCategoryToName}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.dataCategory == "name" ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Name*</span> : null }
                            { !this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Name*</span> : null }
                        </div>

                        <div className="horizontalStack" style={{display: this.state.dataCategory == "name" ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                            <div className="verticalStack" style={{flex: 2, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Full Name:*</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputFullName" name="inputFullName" value={this.state.actualProfile[0].org_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].org_name = e.target.value; return {actualProfile}; })}/>
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Short Name:*</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputShortName" name="inputShortName" value={this.state.actualProfile[0].short_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].short_name = e.target.value; return {actualProfile}; })}/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(ProfileTeamTile);
