import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';

// import components
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import ConversationsModal from '../modals/ConversationsModal.js';
import RoundButton from '../components/RoundButton.js';

import { Link } from 'react-router-dom';

class MyConversationsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tileIsOpened: false,
            clickedConversationId: -1,
            showModalAddConversation: false,
            prepareToShowModalAddConversation: false,
            showModalEditConversation: false,
            prepareToShowModalEditConversation: false,
            actualOpenedConversation: -1,
            expandAll: false,
        }
    }

    openCloseConversation(idConversation){
        /*if(this.state.actualOpenedConversation == idConversation){ // if sending same conversation again it would close it
            this.setState({ actualOpenedConversation: ""})
        }
        else {
            this.setState({ actualOpenedConversation: idConversation})
        }*/
        var newArray = this.props.conversationsArray;
        for(let oneRow of newArray){
            if(oneRow.id == idConversation){
                oneRow.isOpen = !oneRow.isOpen;
            }
        }
        this.props.setConversationsArray(newArray);
    }

    expandCollapseAll(){
        var newArray = this.props.conversationsArray;
        for(let oneRow of newArray){
            if(this.state.expandAll){
                oneRow.isOpen = false;
            }
            else {
                oneRow.isOpen = true;
            }
        }
        this.props.setConversationsArray(newArray);
        this.setState({ expandAll: !this.state.expandAll });
    }

    onEdit = async (id) => {
        await this.setState({ clickedConversationId: id })
        this.openModal("EditConversation");
    }

    onDelete = (id) => {
        Axios.delete('/api/conv/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.props.getDataFromApi();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddConversation"){
            await this.setState({ prepareToShowModalAddConversation: true });
            this.setState({ showModalAddConversation: true });
        }
        if(modalWindow == "EditConversation"){
            await this.setState({ prepareToShowModalEditConversation: true });
            this.setState({ showModalEditConversation: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // reload data
        this.props.getDataFromApi();

        // close the modal
        if(modalWindow == "AddConversation"){
            this.setState({ showModalAddConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddConversation: false });
        }
        if(modalWindow == "EditConversation"){
            this.setState({ showModalEditConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalEditConversation: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal("AddConversation");
        }
    }

    render() {
        return (
            <div style={{height: "calc(100% - 60px)", width: "100%"}}>
                <div className="myPeopleTile">
                    <div style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px - 80px)" : "calc(100% - 90px - 80px)", height: "calc(100% - 80px - 120px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}

                        <div className="horizontalStack">
                            <div style={{width: "85%"}}>
                                <span className="fontPoppinsSemiBold15">{"My Conversations"}</span>
                                <br/>
                                <span className="fontPoppinsRegular13Gray">{"Use this tool for taking notes on conversations and notifying others about follow up needs. Click on the button on the right. Add the people involved, the date, and choose the topic of conversation. Below are all the conversations that include you."}</span>
                            </div>

                            <div className="flex"></div>

                            <div className="circleButtonContainer circleButtonContainerOrange clickable" onClick={() => this.openModal("AddConversation")}>
                                <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                            </div>
                        </div>

                        {/* I am using here styles from Roles tile */}
                        <table className="rolesTable" style={{marginTop: 30}}>
                            <tr>
                                <td className="rolesTableHeader rolesTableHeaderOther clickable" nowrap="nowrap" onClick={() => this.expandCollapseAll()}>
                                    <img className={this.state.expandAll ? "expandButtonExpanded onHoverToOrange" : "expandButton onHoverToOrange"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Created by</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">With</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">When</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Category</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Edit</td>
                            </tr>

                            {this.props.conversationsArray.map(function (item) {
                                    return (
                                        <tbody>
                                        <tr className="clickable" onClick={() => this.openCloseConversation(item.id)} style={{backgroundColor: item.isOpen ? "var(--lightest-gray)" : ""}}>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                                <img className={item.isOpen ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                            </td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.cr_by}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="responsiveThreeDots">{item.cont_names}</span>
                                                </div>
                                            </td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"><Moment format="MMMM D, YYYY">{item.dt}</Moment></td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.ctg_labels}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this conversation?"}/>
                                            </td>
                                        </tr>
                                        <div style={{display: item.isOpen ? "" : "none", height: 1}}></div>
                                        <tr style={{display: item.isOpen ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td colSpan="6" style={{padding: 10}}>
                                                <span className="fontPoppinsRegular13Gray">{"Notes:"}</span>
                                                <br/>
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{item.descr}</span>
                                                </div>
                                                <br/><br/>
                                                <span className="fontPoppinsRegular13Gray">{"What can we offer to help meet their needs?:"}</span>
                                                <br/>
                                                <span className="fontPoppinsRegular13">{item.offer_labels}. {item.need_descr}</span>
                                                <br/><br/>
                                                <div className="horizontalStack">
                                                    <div className="verticalStack flex">
                                                        <span className="fontPoppinsRegular13Gray">{"Who was sent a copy of this observation?"}</span>
                                                        <div className="horizontalStack flex" style={{overflowX: "visible", flexWrap: "wrap"}}>
                                                            {item.send_names != null && item.send_names.split(", ").map(function (secondItem) {
                                                                return (
                                                                    <span className="fontPoppinsRegular13Blue" style={{border: "1px solid var(--blue-button-link)", paddingLeft: 5, paddingRight: 5, borderRadius: 10, marginRight: 10, marginTop: 10, whiteSpace: "nowrap"}}>{secondItem}</span>
                                                                )
                                                            }.bind(this))}
                                                        </div>
                                                    </div>

                                                    <div style={{display: "flex", alignItems: "flex-end"}}>
                                                        <RoundButton title={"Edit"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.onEdit(item.id)} />
                                                    </div>
                                                    <div style={{display: "flex", alignItems: "flex-end"}}>
                                                        <RoundButton title={"Delete"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.onDelete(item.id)} />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <div style={{display: item.isOpen ? "" : "none", height: 1}}></div>
                                        </tbody>
                                    )
                            }.bind(this))}

                        </table>
                    </div>
                </div>

                {this.state.prepareToShowModalAddConversation ? <ConversationsModal showModal={this.state.showModalAddConversation} closeModal={this.closeModal} contactId={null} contactName={null} actionType={"AddConversation"} conversationId={-1} /> : null }
                {this.state.prepareToShowModalEditConversation ? <ConversationsModal showModal={this.state.showModalEditConversation} closeModal={this.closeModal} contactId={null} contactName={null} actionType={"EditConversation"} conversationId={this.state.clickedConversationId} /> : null }

            </div>
        );
    }
}

export default MyConversationsTile;
