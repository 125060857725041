import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';
import flag_1 from '../assets/flag_1.png';
import flag_2 from '../assets/flag_2.png';
import flag_3 from '../assets/flag_3.png';

// import components
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import ObservationsModal from '../modals/ObservationsModal.js';
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';

class ObservationsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedObservationId: -1,
            mainContactId: 0,
            mainContactName: "Error",
            showModalAddObservation: false,
            prepareToShowModalAddObservation: false,
            showModalEditObservation: false,
            prepareToShowModalEditObservation: false,
            actualOpenedObservation: -1,
            observationsList: ([{id: 1, cr_by: "Greg Strock", descr: "This church is amazing.", obs_dt: "June 8, 2020", category: "Discipleship", yrs: 7, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"},
                         {id: 2, cr_by: "Jiri Nemec", descr: "This church is amazing.", obs_dt: "June 8, 2020", category: "Discipleship", yrs: 10, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"},
                         {id: 3, cr_by: "Mark Howland", descr: "This church is amazing.", obs_dt: "June 8, 2020", category: "Discipleship", yrs: 4, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"}]),
        }
    }

    componentDidMount(){
        this.getDataFromApi();
    }

    getDataFromApi = () => {
        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            Axios.get('/api/obs/get?cont_id='+id+'').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ observationsList: response.data.result })
            });
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result[0].short_name){
                    this.setState({ mainContactName: response.data.result[0].short_name })
                }
            });
            this.setState({ mainContactId: id });
        }
        //this.props.update();
    }

    openCloseObservation(idObservation){
        if(this.state.actualOpenedObservation == idObservation){ // if opening same observation again it would close it
            this.setState({ actualOpenedObservation: ""})
        }
        else {
            this.setState({ actualOpenedObservation: idObservation})
        }
    }

    onEdit = async (id) => {
        await this.setState({ clickedObservationId: id })
        this.openModal("EditObservation");
    }

    onDelete = (id) => {
        Axios.delete('/api/obs/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.getDataFromApi();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddObservation"){
            await this.setState({ prepareToShowModalAddObservation: true });
            this.setState({ showModalAddObservation: true });
        }
        if(modalWindow == "EditObservation"){
            await this.setState({ prepareToShowModalEditObservation: true });
            this.setState({ showModalEditObservation: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // reload data
        this.getDataFromApi();

        // close the modal
        if(modalWindow == "AddObservation"){
            this.setState({ showModalAddObservation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddObservation: false });
        }
        if(modalWindow == "EditObservation"){
            this.setState({ showModalEditObservation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalEditObservation: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal("AddObservation");
        }
    }

    openCloseTile = () => {
        if(this.props.isOpen){
            this.props.setActualOpenedTile("");
        }
        else {
            this.props.setActualOpenedTile("observations");
        }
    }

    render() {
        return (
            <div className="observationsTile">

                <div className="horizontalStack">

                    <ExpandButton isOpen={this.props.isOpen} style={{marginTop: 5}} onClick={this.openCloseTile} />

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                        <span className="fontPoppinsSemiBold15">{"Observations"}</span>
                        <span className={this.props.isOpen ? "fontPoppinsRegular13Gray oneLineThreeDotsTextDeactivated" : "fontPoppinsRegular13Gray oneLineThreeDotsText"}>{this.props.descr}</span>
                    </div>

                    <div style={{width: "20px"}}></div>

                    <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 5}} onClick={() => this.openModal("AddObservation")}>
                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                    </div>

                </div>

                <div className="horizontalStackCenter" style={{display: this.props.isOpen ? "" : "none"}}>

                        {/* I am using here styles from Roles tile */}
                        <table className="rolesTable" style={{marginTop: 10}}>
                            <tr>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap"></td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Created by</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">When</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" style={{width: "100%"}} nowrap="nowrap">Notes</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Flag</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Edit</td>
                            </tr>

                            {this.state.observationsList.map(function (item) {
                                    return (
                                        <tbody>
                                        <tr className="clickable" onClick={() => this.openCloseObservation(item.id)} style={{backgroundColor: this.state.actualOpenedObservation == item.id ? "var(--lightest-gray)" : ""}}>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                                <img className={this.state.actualOpenedObservation == item.id ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                            </td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.cr_by}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"><Moment format="MMMM D, YYYY">{item.obs_dt}</Moment></td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther responsiveTableDiv" nowrap="nowrap" style={{textAlign: this.state.actualOpenedObservation == item.id ? "right" : "left"}}>
                                                {this.state.actualOpenedObservation == item.id ?
                                                    <div>
                                                        {item.flag == "Normal" ? <span style={{color: "gray"}}>{item.flag}</span> : null}
                                                        {item.flag == "Please Read" ? <span style={{color: "var(--crm-yellow)"}}>{item.flag}</span> : null}
                                                        {item.flag == "Please Read Now" ? <span style={{color: "var(--crm-pink)"}}>{item.flag}</span> : null}
                                                    </div>
                                                : null}
                                                {this.state.actualOpenedObservation != item.id ? item.descr : null}
                                            </td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">
                                                <div>
                                                    {item.flag == "Normal" ? <img style={{height: 20, marginLeft: 10}} src={flag_1}/> : null}
                                                    {item.flag == "Please Read" ? <img style={{height: 20, marginLeft: 10}} src={flag_2}/> : null}
                                                    {item.flag == "Please Read Now" ? <img style={{height: 20, marginLeft: 10}} src={flag_3}/> : null}
                                                </div>
                                            </td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this observation?"}/>
                                            </td>
                                        </tr>
                                        <div style={{display: this.state.actualOpenedObservation == item.id ? "" : "none", height: 1}}></div>
                                        <tr style={{display: this.state.actualOpenedObservation == item.id ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td colSpan="6" style={{padding: 10}}>
                                                <span className="fontPoppinsRegular13Gray">{"Notes:"}</span>
                                                <br/>
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{item.descr}</span>
                                                </div>
                                                <br/><br/>
                                                {/*<span className="fontPoppinsRegular13Gray">{"What can we offer to help meet their needs?:"}</span>
                                                <br/>
                                                <span className="fontPoppinsRegular13">{"NOT SURE IF THIS PART SHOULD BE HERE"}</span>
                                                <br/><br/>*/}
                                                <div className="horizontalStack">
                                                    <div className="verticalStack flex">
                                                        <span className="fontPoppinsRegular13Gray">{"Who was sent a copy of this observation?"}</span>
                                                        <div className="horizontalStack flex" style={{overflowX: "visible", flexWrap: "wrap"}}>
                                                            {item.send_names != null && item.send_names.split(", ").map(function (secondItem) {
                                                                return (
                                                                    <span className="fontPoppinsRegular13Blue" style={{border: "1px solid var(--blue-button-link)", paddingLeft: 5, paddingRight: 5, borderRadius: 10, marginRight: 10, marginTop: 10, whiteSpace: "nowrap"}}>{secondItem}</span>
                                                                )
                                                            }.bind(this))}
                                                        </div>
                                                    </div>

                                                    <div style={{display: "flex", alignItems: "flex-end"}}>
                                                        <RoundButton title={"Edit"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.onEdit(item.id)} />
                                                    </div>
                                                    <div style={{display: "flex", alignItems: "flex-end"}}>
                                                        <RoundButton title={"Delete"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.onDelete(item.id)} />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    )
                            }.bind(this))}

                        </table>

                </div>

                {this.state.prepareToShowModalAddObservation ? <ObservationsModal showModal={this.state.showModalAddObservation} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddObservation"} observationId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalEditObservation ? <ObservationsModal showModal={this.state.showModalEditObservation} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"EditObservation"} observationId={this.state.clickedObservationId} openAlertMessage={this.props.openAlertMessage} /> : null }

            </div>
        );
    }
}

export default ObservationsTile;