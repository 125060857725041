import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

class DeleteDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render(){
        return(
            <div style={{display: this.props.showHide ? "" : "none", position: "absolute", zIndex: 1000, backgroundColor: "white", borderRadius: 20, boxShadow: "0px 10px 20px var(--shadow-color)", padding: 0, marginLeft: 12, marginTop: -140, width: 300}}>
                <div className="verticalStack">
                    <div className="horizontalStack" style={{padding: 20, whiteSpace: "normal"}}>
                        <span className="fontPoppinsMedium20" style={{color: "var(--jv-orange)", textAlign: "center"}}>{this.props.text}</span>
                    </div>
                    <div className="horizontalStackCenter flex" style={{borderTop: "2px solid var(--border-light-gray)"}}>
                        <div className="horizontalStack flex clickable" style={{borderRight: "1px solid var(--border-light-gray)", paddingTop: 5, paddingBottom: 5}} onClick={this.props.onDelete}>
                            <div className="flex"></div>
                            <span className="fontPoppinsMedium20Gray onHoverToRed">Delete</span>
                            <div className="flex"></div>
                        </div>
                        <div className="horizontalStack flex clickable" style={{borderLeft: "1px solid var(--border-light-gray)", paddingTop: 5, paddingBottom: 5}} onClick={this.props.onClose}>
                            <div className="flex"></div>
                            <span className="fontPoppinsMedium20Gray onHoverToBlack">Cancel</span>
                            <div className="flex"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}

export default DeleteDialog;