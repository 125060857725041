import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import flag_1 from '../assets/flag_1.png';
import flag_2 from '../assets/flag_2.png';
import flag_3 from '../assets/flag_3.png';

// import components
import ProfileImageSmall from '../components/ProfileImageSmall.js';
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import ObservationsModalMobile from '../modals_m/ObservationsModalMobile.js';

class ObservationsTileMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            clickedObservationId: -1,
            showModalAddObservation: false,
            prepareToShowModalAddObservation: false,
            showModalEditObservation: false,
            prepareToShowModalEditObservation: false,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
    }

    openObservation = (event, id, openTile) => {
        this.onEdit(id);
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
        if(bottom){
            this.props.loadMoreData();
        }
    }

    onEdit = async (id) => {
        await this.setState({ clickedObservationId: id })
        this.openModal("EditObservation");
    }

    onDelete = (id) => {
        Axios.delete('/api/obs/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.props.getDataFromApi();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddObservation"){
            await this.setState({ prepareToShowModalAddObservation: true });
            this.setState({ showModalAddObservation: true });
        }
        if(modalWindow == "EditObservation"){
            await this.setState({ prepareToShowModalEditObservation: true });
            this.setState({ showModalEditObservation: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // reload data
        this.props.getDataFromApi();

        // close the modal
        if(modalWindow == "AddObservation"){
            this.setState({ showModalAddObservation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddObservation: false });
        }
        if(modalWindow == "EditObservation"){
            this.setState({ showModalEditObservation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalEditObservation: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal("AddObservation");
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div style={{height: "calc(100% - 50px)", width: "100%"}}>
                <div className="myPeopleTileMobile">
                    <div onScroll={this.handleScroll} style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px)" : "calc(100% - 60px)", height: "calc(100% - 130px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}
                        <table className="myPeopleTableMobile">
                            <tr>
                                <td className="myPeopleTableHeader myPeopleTableActionHeaderMobile" style={{width: 80, minWidth: 80}}>
                                    <div className="horizontalStack" style={{marginLeft: 10}}>When</div>
                                </td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderRole">Created by</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther">Flag</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther">Notes</td>
                                {/*<td className="myPeopleTableHeader myPeopleTableHeaderOther">Edit</td>*/}
                            </tr>

                            {this.props.observationsArray.map(function (item, index) {
                                return (
                                    <tr key={index} className="myPeopleTableRow">

                                        <td className="myPeopleTableFirstFixedColumnMobile" onClick={(event) => this.openObservation(event, item.id, "")}>
                                            <span className="verticalStack fontPoppinsMedium14DarkGray" style={{alignItems: "flex-start", marginLeft: 10}}>
                                                <Moment format="MMM D," style={{alignItems: "left", justifyContent: "start"}}>{item.obs_dt}</Moment>
                                                <Moment format="YYYY" style={{alignItems: "left", justifyContent: "start"}}>{item.obs_dt}</Moment>
                                            </span>
                                        </td>

                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openObservation(event, item.id, "")}>
                                            <span className="fontPoppinsMedium14DarkGray">{item.cr_by}</span>
                                        </td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap">
                                            <div className="horizontalStackCenter">
                                                <div className="horizontalStackCenter">
                                                    {item.flag == "Normal" ? <img style={{height: 20}} src={flag_1}/> : null}
                                                    {item.flag == "Please Read" ? <img style={{height: 20}} src={flag_2}/> : null}
                                                    {item.flag == "Please Read Now" ? <img style={{height: 20}} src={flag_3}/> : null}
                                                </div>
                                                <div style={{width: 10}}></div>
                                                <div className="horizontalStackCenter">
                                                    {item.flag == "Normal" ? <span style={{color: "gray"}}>{item.flag}</span> : null}
                                                    {item.flag == "Please Read" ? <span style={{color: "var(--crm-yellow)"}}>{item.flag}</span> : null}
                                                    {item.flag == "Please Read Now" ? <span style={{color: "var(--crm-pink)"}}>{item.flag}</span> : null}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" style={{maxWidth: 200, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} onClick={(event) => this.openObservation(event, item.id, "")}>{item.descr}</td>
                                        {/*<td className="myPeopleTableGeneralDiv" nowrap="nowrap">
                                            <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this observation?"}/>
                                        </td>*/}
                                    </tr>
                                )
                            }.bind(this))}

                            { this.props.showLoadingRow ? <tr>
                                <td className="myPeopleTableGeneralDiv" colspan={7}>
                                    <span>{"Loading more records..."}</span>
                                </td>
                            </tr> : null }

                        </table>
                    </div>
                </div>

                {this.state.prepareToShowModalAddObservation ? <ObservationsModalMobile showModal={this.state.showModalAddObservation} closeModal={this.closeModal} contactId={-1} contactName={""} actionType={"AddObservation"} observationId={-1} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalEditObservation ? <ObservationsModalMobile showModal={this.state.showModalEditObservation} closeModal={this.closeModal} contactId={null} contactName={null} actionType={"EditObservation"} observationId={this.state.clickedObservationId} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
    }
}

export default ObservationsTileMobile;
