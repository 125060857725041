import React, { useEffect, useRef } from 'react';
import styles from './modal.module.css';


function getStyle(isExtraExpanded ,isExpanded, wrapContent){
    if(isExpanded && isExtraExpanded){
        return styles.modalExtraExpanded;
    }
    else if(isExpanded){
        return styles.modalExpanded;
    }
    else if(wrapContent){
        return styles.modalWrapContent;
    }
    else {
        return styles.modal;
    }
}

const Modal = ({ modalStyle, children, show, onClose, isExpanded, isExtraExpanded, wrapContent, backdropStyle }) => {
    const modalRef = useRef(null);
    useEffect(
        () => {
            if(show) {
                modalRef.current.classList.add(styles.visible);
            }
            else {
                modalRef.current.classList.remove(styles.visible);
            }
        },
        [show]
    );
    return (
        <React.Fragment>
            <div ref={modalRef} style={backdropStyle} className={`${styles.modal__wrap}`}>
                <div style={modalStyle} className={getStyle(isExtraExpanded,isExpanded,wrapContent)}>
                    {children}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Modal;