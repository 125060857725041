import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import PopupButtonDescription from '../components/PopupButtonDescription.js';

// import assets
import icon_glasses from '../assets/icon_glasses.png';
import icon_expand from '../assets/icon_expand.png';

class DiagnosticTilePrivateMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedRow: "null", // disciple, leader, ministry, church
            fiveChallengesLevel: "-", // D0
            groToolLevel: "-", // GRO:00
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
        }
    }

    async componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = async () => {
        const pathname = window.location.href;
        var contact_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(contact_id.includes("?")){ contact_id = contact_id.substring(0, contact_id.indexOf("?")); }

        if(Number.isInteger(Number(contact_id))){ // if there is number received from URL

            Axios.get('/api/clens/get/'+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                var fiveChallengesLevelChanged = false;
                var groToolLevelChanged = false;

                response.data.result.forEach((row)=>{

                    if(row.segm_id == 1){ // 5 Challenges of Christ
                        this.setState({ fiveChallengesLevel: row.seg_cd });
                        fiveChallengesLevelChanged = true;
                    }
                    if(row.segm_id == 2){ // GRO Health Overall
                        this.setState({ groToolLevel: "GRO:"+row.seg_cd });
                        groToolLevelChanged = true;
                    }

                })

                // set "-" to records that were not recieved
                if(!fiveChallengesLevelChanged) { this.setState({ fiveChallengesLevel: "-" }); }
                if(!groToolLevelChanged) { this.setState({ groToolLevel: "-" }); }
            });

        }
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className="diagnosticTileMobile">

                <div className="verticalStack">

                    <div className="horizontalStack">
                        <div className="verticalStack flex">
                            <span className="fontPoppinsSemiBold15">Diagnostic Lens Tools</span>
                            <span className="fontPoppinsRegular13Gray">Learn More</span>
                        </div>
                        <div className="verticalStack" style={{alignItems: "center"}}>
                            <img className="diagnosticTileIcon" src={icon_glasses}/>
                            <div className="flex"></div>
                        </div>
                    </div>

                    <div className="verticalStack">
                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "24px"}}></div>

                        {/* DISCIPLE LENS */}

                        <div className={["horizontalStackCenter diagnosticTileRowMobile",this.state.openedRow == "disciple" ? "diagnosticTileRowOpened" : ""].join(" ")}>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "disciple" ? "var(--crm-green)" : "var(--jv-orange)"}}>Disciple Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div style={{width: 29}}>
                                <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "disciple" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.fiveChallengesLevel}</span></div>
                            </div>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "disciple" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.groToolLevel}</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                    </div>

                </div>

            </div>
        );
    }
}

export default DiagnosticTilePrivateMobile;
