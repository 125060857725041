import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

// assets
import icon_glasses from '../assets/icon_glasses.png';

class DiagnosticModalConfirmation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render(){
        if (!this.props.show) {
            return null;
        }
        return(
            <div className="horizontalStackCenter" style={{position: "absolute", width: "100%", height: "100%", backgroundColor: "white", borderRadius: 20, marginTop: -20, marginLeft: -20, zIndex: 1}}>
                <div className="flex"></div>
                <div className="verticalStack" style={{alignItems: "center"}}>
                    <img style={{width: 110}} src={icon_glasses}/>
                    <div style={{height: 11}}></div>
                    <span className="fontPoppinsSemiBold15" style={{textAlign: "center"}}>Observation Saved!</span>
                </div>
                <div className="flex"></div>
            </div>
        );
	}
}

export default DiagnosticModalConfirmation;