import React, { useState, useEffect, useMemo } from "react";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

/**********************************/
/**********************************/
/* UTILITIES FOR LIST OF CONTACTS */
/**********************************/
/**********************************/

export function goToFirstPageUtilities(actualPage) {
    return 0;
}

export function goToPrevPageUtilities(actualPage) {
    if((actualPage-1) < 0){
        return actualPage;
    }
    else {
        return actualPage-1;
    }
}

export function goToNextPageUtilities(actualPage, recordsOnOnePage, totalNumberOfContacts) {
    var fromValue = (actualPage+1)*recordsOnOnePage;
    var toValue = (actualPage+2)*recordsOnOnePage;
    if( (fromValue >= totalNumberOfContacts)  && (toValue > totalNumberOfContacts) ){
        return actualPage;
    }
    else {
        return actualPage+1;
    }
}

export function goToLastPageUtilities(recordsOnOnePage, totalNumberOfContacts) {
    var page = 0;
    while(( (page+1)*recordsOnOnePage) < totalNumberOfContacts){
        page = page+1;
    }
    return page;
}

export function moreRecordsOnPageUtilities(actualValue) {
    /*if(actualValue == 5){
        saveRecordsOnOnePageValue(25);
        return 25;
    }*/
    if(actualValue == 25){
        saveRecordsOnOnePageValue(50);
        return 50;
    }
    else if(actualValue == 50){
        saveRecordsOnOnePageValue(100);
        return 100;
    }
    else if(actualValue == 100){
        saveRecordsOnOnePageValue(250);
        return 250;
    }
    else if(actualValue == 250){
        saveRecordsOnOnePageValue(500);
        return 500;
    }
    else if(actualValue == 500){
        saveRecordsOnOnePageValue(1000);
        return 1000;
    }
    else if(actualValue == 1000){
        saveRecordsOnOnePageValue(1000);
        return 1000;
    }
    saveRecordsOnOnePageValue(25);
    return 25;
}

export function lessRecordsOnPageUtilities(actualValue) {
    if(actualValue == 1000){
        saveRecordsOnOnePageValue(500);
        return 500;
    }
    else if(actualValue == 500){
        saveRecordsOnOnePageValue(250);
        return 250;
    }
    else if(actualValue == 250){
        saveRecordsOnOnePageValue(100);
        return 100;
    }
    else if(actualValue == 100){
        saveRecordsOnOnePageValue(50);
        return 50;
    }
    else if(actualValue == 50){
        saveRecordsOnOnePageValue(25);
        return 25;
    }
    /*else if(actualValue == 25){
        saveRecordsOnOnePageValue(5);
        return 5;
    }
    else if(actualValue == 5){
        saveRecordsOnOnePageValue(5);
        return 5;
    }*/
    saveRecordsOnOnePageValue(25);
    return 25;
}

function saveRecordsOnOnePageValue(value){
    localStorage.setItem("recordsOnOnePage", value);
}

export function checkIfOutOfPages(actualPage, recordsOnOnePage, totalNumberOfContacts){
    var lastPage = goToLastPageUtilities(recordsOnOnePage, totalNumberOfContacts);
    if((((actualPage*recordsOnOnePage)+1) > totalNumberOfContacts) && actualPage != lastPage && totalNumberOfContacts != 0){
        return true;
    }
    return false;
}

/**********************************************/
/**********************************************/
/* UTILITIES FOR GOING TO NEXT, PREV CONTACTS */
/**********************************************/
/**********************************************/

export function goToFirstContactUtilities(position, array) {
    openContactUtilities(array[0]);
}

export function goToPrevContactUtilities(position, array) {
    if((position-1) < 0){
        openContactUtilities(array[0]);
    }
    else {
        openContactUtilities(array[position-1]);
    }
}

export function goToNextContactUtilities(position, array) {
    if((position+1) >= array.length){
        openContactUtilities(array[array.length-1]);
    }else {
        openContactUtilities(array[position+1]);
    }
}

export function goToLastContactUtilities(position, array) {
    openContactUtilities(array[array.length-1]);
}

function openContactUtilities(contId){
    Axios.get('/api/cont/get/'+contId).then(response => {
        //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
        if(response.data.result.length == 0){ this.props.openAlertMessage("You don't have rights to this contact.","OK");navigate(-1); }
        else {
            var contType = response.data.result[0].cont_tp_id;
            var isPrivate = response.data.result[0].is_private;
            openProfilePage(contId, contType, isPrivate);
        }
    });
}

function openProfilePage(id, cont_type, is_private){
        if(cont_type == 4){
            navigate("/ChurchProfile/"+id); window.location.reload();
        }
        else if(cont_type == 5){
            navigate("/DenominationProfile/"+id); window.location.reload();
        }
        else if(cont_type == 6){ // team
            navigate("/TeamProfile/"+id); window.location.reload();
        }
        else if(cont_type == 7){ // school
            navigate("/SchoolProfile/"+id); window.location.reload();
        }
        else if(cont_type == 1){

            if(is_private == 1){
                navigate("/PeoplePrivateProfile/"+id); window.location.reload();
            }
            else {
                navigate("/PeopleProfile/"+id); window.location.reload();
            }
        }
        else {
            alert("Error");
        }
}