import React from 'react';
import '../styles/modals.css';
//import Slider from '@material-ui/core/Slider';
import { Navigate } from "react-router-dom";
import Axios from 'axios';
import Moment from 'react-moment';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import EditDeleteButton from '../components/EditDeleteButton.js';
import Slider from '../components/Slider.js';
import DiagnosticModalConfirmation from '../components/DiagnosticModalConfirmation.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// assets
import close_white from '../assets/close_white.png';
import five_challenges_img from '../assets/five_challenges.png';
import icon_action_down from '../assets/icon_action_down.png';
import icon_action_up from '../assets/icon_action_up.png';

const marks = [
  { value: 0, label: '1', },
  { value: 25, label: '2', },
  { value: 50, label: '3', },
  { value: 75, label: '4', },
  { value: 100, label: '5', },
];

function valuetext(value) {
  return `${value}°C`;
}

class FiveChallengesModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectTo: null,
            modalExpanded: false,
            mainContactId: -1,
            mainContactName: "Loading...",
            editingModeOfRecord: -1,
            sliderValue: 50, // 0, 25, 50, 75, 100
            lastRecordDate: "",
            note_descr: "",
            arrayOfRecords: ([]),
            showSaveRecordConfirmation: false,
        };

        this.refModalBottom = React.createRef();
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = () => {
        var id = 0;
        if(this.props.contactId != undefined && this.props.contactId > 0){
            id = this.props.contactId;
        }
        else {
            const pathname = window.location.href;
            id = pathname.substring(pathname.lastIndexOf("/")+1);
            if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }
        }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            Axios.get('/api/lens/get?cont_id='+id+'&segm_id=1').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                if(response.data.result.length > 0){
                    response.data.result = response.data.result.reverse();
                    var savedValue = response.data.result[0].seg_id;
                    var countedValue = this.getSliderValue(savedValue); //ex.: from 3 do 50 (0-100)
                    this.setState({ sliderValue: countedValue });
                    this.setState({ lastRecordDate: response.data.result[0].ins_at });
                    this.setState({ arrayOfRecords: response.data.result });
                }
                else {
                    this.setState({ sliderValue: 50 });
                    this.setState({ lastRecordDate: "" });
                    this.setState({ arrayOfRecords: [] });
                }
            });
            this.setState({ mainContactId: id });
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var user_name = response.data.result[0].first_name + " " + response.data.result[0].last_name;
                this.setState({ mainContactName: user_name });
            });
        }
    }

    saveNewRecord = () => {
        //alert("ContId: "+this.state.mainContactId+"\nSegmId: 1\nSegId: "+((this.state.sliderValue/25)+1)+"\nDescr: "+this.state.note_descr);
        //alert(this.state.editingModeOfRecord);
        //return;

        var url = "/api/lens/ins";

        if(this.state.editingModeOfRecord != -1){
            url = "/api/lens/upd/"+this.state.editingModeOfRecord;
        }

        Axios.post(url, {
            ContId: this.state.mainContactId, // id of person to whom I am setting the record
            SegmId: 1, // that means I add it to Five Challenges lens
            SegId: this.getSeqIdValue(this.state.sliderValue), // this is the ID of level I am saving
            NoteDescr: this.state.note_descr,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            if(this.state.editingModeOfRecord == -1){
                //this.props.openAlertMessageTwoActions("The record was saved.","OK","",null);
                this.setState({ showSaveRecordConfirmation: true });

                setTimeout(() => {
                    if(this.props.openAfter != undefined && this.props.openAfter == "myDiscipling"){
                        this.props.closeModal("FiveChallenges");
                        this.setState({ redirectTo: "/Discipling" });
                    }
                    else {
                        this.props.closeModal("FiveChallenges");
                    }
                }, 2000);

            }
            else {
                this.setState({ editingModeOfRecord: -1 });
                this.setState({ note_descr: "" });
                this.loadData();
            }
        });
    }

    onDeleteRecord = (id) => {
        Axios.delete('/api/lens/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.loadData();
        });
    }

    onEditRecord = (id) => {
        if(this.state.editingModeOfRecord == id){
            this.setState({ editingModeOfRecord: -1 });
            this.setState({ note_descr: "" });
            this.loadData();
        }
        else {

            this.setState({ editingModeOfRecord: id });

            Axios.get('/api/lens/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var savedValue = response.data.result[0].seg_id;
                var countedValue = this.getSliderValue(savedValue); //ex.: from 3 do 50 (0-100)
                this.setState({ sliderValue: countedValue });
                this.setState({ note_descr: response.data.result[0].note_descr });
            });

        }
    }

    getSliderValue = (valueID) => {
        if(valueID == 1){ return 0; }
        if(valueID == 2){ return 25; }
        if(valueID == 3){ return 50; }
        if(valueID == 4){ return 75; }
        if(valueID == 5){ return 100; }
        return 0;
    }

    getSeqIdValue = (value) => {
        if(value == 0){ return 1; }
        if(value == 25){ return 2; }
        if(value == 50){ return 3; }
        if(value == 75){ return 4; }
        if(value == 100){ return 5; }
        return 1;
    }

    toggleModalExpansion = async () => {
        if(this.state.modalExpanded){
            this.setState({ modalExpanded: false });
        }
        else {
            this.setState({ modalExpanded: true });
            await new Promise(resolve => setTimeout(resolve, 100));
            this.refModalBottom.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal("FiveChallenges")} isExpanded={this.state.modalExpanded}>
                    <DiagnosticModalConfirmation show={this.state.showSaveRecordConfirmation} />
                    <div className="content">
                        <div className="horizontalStack flex">
                            <div className="verticalStackCenter flex">
                                <span className="fontPoppinsSemiBold15">5 Challenges of Christ Tool<span style={{color: "var(--blue-button-link)"}}> - {this.state.mainContactName} <Moment format="M/D/YY">{this.state.lastRecordDate}</Moment></span></span>
                                <div>
                                    <span className="fontPoppinsRegular13Gray">This tool is designed to help you access the stages of growth in discipleship. To learn more about the 5 Challenges of Christ <span className="fontPoppinsSemiBold13 clickable" onClick={() => this.toggleModalExpansion()}>click here</span>.</span>
                                </div>
                                <div className="flex">
                                    <img style={{width: "100%"}} src={five_challenges_img}/>
                                </div>
                                <div style={{marginLeft: "50px", marginRight: "50px"}}>
                                    <center><span className="fontPoppinsSemiBold15">Current Discipleship Level:</span></center>
                                    <br/>

                                    {/*<Slider
                                        style={{color: "var(--crm-green)"}}
                                        aria-label="Always visible"
                                        value={this.state.sliderValue}
                                        getAriaValueText={valuetext}
                                        step={25}
                                        onChange={(event, value) => this.setState({ sliderValue: value })}
                                        marks={marks}
                                        valueLabelDisplay="off"
                                    />*/}

                                    {/*<input
                                        className={"myCustomSlider myCustomSliderGreen"}
                                        style={{width: "100%"}}
                                        type="range"
                                        min="0"
                                        max="100"
                                        step="25"
                                        value={this.state.sliderValue}
                                        onChange={(value) => this.setState({ sliderValue: value })}/>*/}

                                    <Slider
                                        styles={"myCustomSlider myCustomSliderGreen"}
                                        value={this.state.sliderValue}
                                        style={{width: "100%"}}
                                        step={25}
                                        onChange={(value) => this.setState({ sliderValue: value })}
                                    />

                                    <div className="horizontalStack" style={{marginLeft: 18, marginRight: 18, marginTop: 20}}>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"1"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"2"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"3"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"4"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"5"}</span>
                                    </div>

                                    <br/>

                                </div>
                                <div>
                                    <span className="fontPoppinsRegular13Gray">{"This slider indicates the person's current discipleship level. If you see this person at a different level based on the diagnostic instructions below, then you can change it by moving the slider above. Take notes on the right and once you click on the 'Save' button below a new record will be made."}</span>
                                </div>
                                <div className="horizontalStackCenter" style={{marginTop: "20px"}}>
                                    <RoundButton title={this.state.editingModeOfRecord != -1 ? "Edit Level and Note" : "Save Level and Note"} className={"roundButtonGreen"} style={{marginRight: "10px"}} whiteText={true} onClick={this.saveNewRecord} />
                                    <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.props.closeModal("FiveChallenges")} />
                                    <div className="flex"></div>
                                    <div className="clickable" onClick={this.toggleModalExpansion}>

                                        { !this.state.modalExpanded ?
                                            <div className="modalExpandButton horizontalStackCenter">
                                                <span className="modalExpandButtonTextGreen fontPoppinsSemiBold13">Open Diagnostic Instructions</span>
                                                <div className="modalExpandButtonIconContainer modalExpandButtonIconBgGreen verticalStackCenter">
                                                    <div className="horizontalStack">
                                                        <div className="flex"></div>
                                                        <img className="modalExpandButtonIcon modalExpandButtonGreen" src={icon_action_down}/>
                                                        <div className="flex"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null }

                                        { this.state.modalExpanded ?
                                            <div className="modalExpandButton horizontalStackCenter">
                                                <span className="modalExpandButtonTextGreen fontPoppinsSemiBold13">Close Diagnostic Instructions</span>
                                                <div className="modalExpandButtonIconContainer modalExpandButtonIconBgGreen verticalStackCenter">
                                                    <div className="horizontalStack">
                                                        <div className="flex"></div>
                                                        <img className="modalExpandButtonIcon modalExpandButtonGreen" src={icon_action_up}/>
                                                        <div className="flex"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null }

                                    </div>
                                </div>
                            </div>

                            <div style={{width: 20}}></div>

                            <div className="verticalStack" style={{width: "200px"}}>
                                <div className="horizontalStack">
                                    <div className="flex"></div>
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal("FiveChallenges")}/>
                                </div>
                                <div className="verticalStack" style={{marginTop: 20}}>
                                    <span className="fontPoppinsSemiBold15Gray">History:</span>

                                    <div className="modalHistoryContainer">
                                        <div className="modalHistoryDiv verticalStack">

                                            {this.state.arrayOfRecords.map(function (item) {
                                                return (
                                                    <div className="verticalStack flex" style={{padding: 10, paddingTop: 0, backgroundColor: this.state.editingModeOfRecord == item.id ? "var(--lightest-crm-green)" : "var(--lightest-gray)"}}>
                                                        <div className="horizontalStackCenter">
                                                            <span className="fontPoppinsRegular13Blue modalHistoryDate"><Moment format="MMM. D, YYYY">{item.ins_at}</Moment></span>
                                                            <EditDeleteButton itemId={item.id} onEdit={this.onEditRecord} onDelete={this.onDeleteRecord} areYouSureText={"Are you sure want to delete this \"Disciple Lens\" record?"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                        </div>
                                                        <span className="fontPoppinsRegular13Blue" style={{marginTop: -9}}>{"By "}{item.cr_by}</span>
                                                        <span className="fontPoppinsRegular13"><span className="fontPoppinsBold13">{"D"}{item.seg_val}{" -"} </span>{item.note_descr}</span>
                                                    </div>
                                                )
                                            }.bind(this))}

                                            <div className="flex"></div>

                                        </div>
                                    </div>

                                </div>
                                <div className="verticalStack flex" style={{marginTop: "30px"}}>
                                    <span className="fontPoppinsSemiBold15">Add a Note:</span>
                                    <textarea className="modalInputAddNote" placeholder={"Type your notes about this person's current discipleship level."} value={this.state.note_descr} onChange={e => this.setState({ note_descr: e.target.value })}></textarea>
                                </div>
                            </div>
                        </div>
                        <div style={{display: this.state.modalExpanded ? "inline" : "none"}}>
                            <div className="fakeEndOfModalWindow"></div>
                            <div className="verticalStack" style={{paddingTop: 20}}>
                                <span className="fontPoppinsSemiBold15">Diagnostic Instructions:</span>

                                <div className="modalInputAddNote modalInputAddNoteBottom verticalStack">
                                    <span>{"Jesus issued 5 challenges to his disciples, which help us as we walk with people along the disciple-making path. When we recognize where people are on the path, we can challenge them appropriately to take the next step as they follow with Jesus. This simple diagnostic will help you discern where they are. If most of the descriptions of a stage describe a person, they might be ready for their next challenge in following Jesus."}</span>

                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"Challenge 1: Come and See"}</span>
                                    <span className="fontPoppinsItalic13">{"I’m in Challenge 1 if:"}</span>
                                    <ul>
                                        <li>{"I'm:"}
                                            <ul>
                                                <li>{"Seeking or curious, like Peter (John 1:35-39) - (I need to be brought close so I can hear more)"}</li>
                                                <li>{"Disinterested, or not seeking like the Samaritan woman (John 4) - (I need you to believe I need the gospel, even if I don’t look interested)"}</li>
                                                <li>{"Religious and lost, like Nicodemus (John 3) - (I need you to mildly shock my categories and push my thinking)"}</li>
                                                <li>{"Opposed and lost, like the Jews in the temple area (John 10:22-39) - (I need you to continue to graciously tell me the truth, even if I don’t look like I want to hear it)"}</li>
                                            </ul>
                                        </li>
                                        <li>{"I don’t yet know enough or have enough exposure to respond to Jesus"}</li>
                                    </ul>

                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"Challenge 2: Repent and Believe"}</span>
                                    <span className="fontPoppinsItalic13">{"I’m in Challenge 1 and need Challenge 2 if:"}</span>
                                    <ul>
                                        <li>{"I’ve been in your life or part of the group for some time, but I haven’t made a decision about Jesus yet."}</li>
                                        <li>{"I know ‘enough’ to respond to Jesus and the gospel, but haven’t been presented with a clear opportunity and challenge to repent and believe."}</li>
                                        <li>{"I have been raised in the church but am not showing signs of spiritual growth or maturity."}</li>
                                    </ul>

                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"Challenge 3: Follow me"}</span>
                                    <span className="fontPoppinsItalic13">{"I’m in Challenge 3 if:"}</span>
                                    <ul>
                                        <li>{"I’ve clearly heard and understood the whole gospel. I know and believe:"}
                                            <ul>
                                                <li>{"I’m created by God, for God, and without him I’m thirsty"}</li>
                                                <li>{"I’m sinful and separated from God by my sin"}</li>
                                                <li>{"Jesus is the only solution to my sin. He died on the cross for my sin and rose from the dead."}</li>
                                                <li>{"I have confessed, believed, and accepted Jesus to start my relationship with God"}</li>
                                            </ul>
                                        </li>
                                        <li>{"I’ve repented and believed, and Jesus is now the Savior of my soul and the leader of my life."}</li>
                                    </ul>
                                    <span className="fontPoppinsItalic13">{"I’m in Challenge 3 and need Challenge 4 if:"}</span>
                                    <ul>
                                        <li>{"Like a growing child I’ve learned basic skills, including how to:"}
                                            <ul>
                                                <li>{"Clean up my messes. I can confess sin and I’m learning to walk in obedience."}</li>
                                                <li>{"Feed myself. I have habits of relational Bible reading and prayer, and I’m no longer a baby and completely dependent on older believers."}</li>
                                                <li>{"Talk. I am a witness to others about Christ in me, and my life is visibly changed."}</li>
                                                <li>{"Walk. Everyday, I seek to walk in step with the Spirit on this journey of faith."}</li>
                                                <li>{"Relate to the family. I’m using my gifts to love and serve the body of Christ."}</li>
                                            </ul>
                                        </li>
                                        <li>{"I’m living out the 10 key issues a growing Christian needs to understand and master:"}
                                            <ul>
                                                <li>{"Rooted in Christ"}
                                                    <ul>
                                                        <li>{"Family of God"}</li>
                                                        <li>{"Word of God—Bible"}</li>
                                                        <li>{"Spirit of God"}</li>
                                                        <li>{"Prayer"}</li>
                                                    </ul>
                                                </li>
                                                <li>{"Christ in you"}
                                                    <ul>
                                                        <li>{"Witness"}</li>
                                                        <li>{"Obedience"}</li>
                                                        <li>{"Worship"}</li>
                                                        <li>{"Serve others in love"}</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"Challenge 4: Follow Me and Fish for Men"}</span>
                                    <span className="fontPoppinsItalic13">{"I’m in Challenge 4 if:"}</span>
                                    <ul>
                                        <li>{"I see the non-Christians around me, and I want them to come to know Jesus. I understand this is my responsibility, though I may need someone to walk alongside me as begin to actively share my faith."}</li>
                                        <li>{"I’m learning how to share my testimony—how to hear your story, share my story, and tell God’s story."}</li>
                                    </ul>
                                    <span className="fontPoppinsItalic13">{"I’m in Challenge 4 and need Challenge 5 if:"}</span>
                                    <ul>
                                        <li>{"I see the non-Christians around me, and I’m passionate for them to come to know Jesus."}</li>
                                        <li>{"I’ve learned to share Jesus in a variety of situations: at church, with my family, in my neighborhood, with strangers, with outcasts, and with classmates/coworkers (Mark 1-2)"}</li>
                                        <li>{"I’m learning how to shepherd the body of Christ. Jesus’ disciples baptized people and cared for the crowds under his direction. Like them, I’m learning how to care for the body of Christ."}</li>
                                    </ul>

                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"Challenge 5: I am Sending You"}</span>
                                    <span className="fontPoppinsItalic13">{"I’m a mature, Challenge 5 disciple-maker if:"}</span>
                                    <ul>
                                        <li>{"I’m ready to make disciples on my own. Although I welcome your ongoing coaching, I’m able to sustain a disciple-making lifestyle without constant help and direction. It’s part of my life and spiritual DNA."}</li>
                                        <li>{"You could take me to a brand-new context and I would begin to make disciples without previous structure or help."}</li>
                                        <li>{"I ‘own’ the disciple-making process. It’s part of who I am and who I will be for the rest of my life. Everywhere I go and in everything I do, I think and strategize as a disciple-maker."}</li>
                                    </ul>
                                </div>
                                {/*
                                <textarea className="modalInputAddNote" rows="12" disabled>{"Jesus issued 5 challenges to his disciples, which help us as we walk with people along the disciple-making path. When we recognize where people are on the path, we can challenge them appropriately to take the next step as they follow with Jesus. This simple diagnostic will help you discern where they are. If most of the descriptions of a stage describe a person, they might be ready for their next challenge in following Jesus.\n\nChallenge 1: Come and See\n\n• Seeking or curious, like Peter (John 1:35-39)\n - (I need to be brought close so I can hear more)\n• Disinterested, or not seeking like the Samaritan woman (John 4)\n- (I need you to believe I need the gospel, even if I don't look interested)\n• Religious and lost, like Nicodemus (John 3)\n- (I need you to mildly shock my categories and push my thinking)\n• Religious and lost, like Nicodemus (John 3)\n- (I need you to mildly shock my categories and push my thinking)\n• Religious and lost, like Nicodemus (John 3)\n- (I need you to mildly shock my categories and push my thinking)\n• Religious and lost, like Nicodemus (John 3)\n- (I need you to mildly shock my categories and push my thinking)\n• Religious and lost, like Nicodemus (John 3)\n- (I need you to mildly shock my categories and push my thinking)\n• Religious and lost, like Nicodemus (John 3)\n- (I need you to mildly shock my categories and push my thinking)\n• Religious and lost, like Nicodemus (John 3)\n- (I need you to mildly shock my categories and push my thinking)\n• Religious and lost, like Nicodemus (John 3)\n- (I need you to mildly shock my categories and push my thinking)"}</textarea>
                                */}
                            </div>
                            <div className="horizontalStackCenter clickable" style={{marginTop: 20}} onClick={this.toggleModalExpansion}>
                                <div className="flex"></div>

                                <div ref={this.refModalBottom} className="modalExpandButton horizontalStackCenter">
                                    <span className="modalExpandButtonTextGreen fontPoppinsSemiBold13">Close Diagnostic Instructions</span>
                                    <div className="modalExpandButtonIconContainer modalExpandButtonIconBgGreen verticalStackCenter">
                                        <div className="horizontalStack">
                                            <div className="flex"></div>
                                            <img className="modalExpandButtonIcon modalExpandButtonGreen" src={icon_action_up}/>
                                            <div className="flex"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex"></div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
		);
	}
}

export default FiveChallengesModal;