import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

// assets
import icon_person_profile_bg from '../assets/icon_person_profile_bg.png';
import icon_person_profile_hover_bg from '../assets/icon_person_profile_hover_bg.png';
import icon_church_profile_bg from '../assets/icon_church_profile_bg.png';
import icon_church_profile_hover_bg from '../assets/icon_church_profile_hover_bg.png';

class ProfileImageMobile extends React.Component {

    constructor(props) {
        super(props);
    }

    showInitialsBackground = (type) => {
        if(type == "person"){
            return <img className="avatarImageMobile" src={icon_person_profile_bg}/>;
        }
        else if(type == "church"){
            return <img className="avatarImageMobile" src={icon_church_profile_bg}/>;
        }
        else {
            return <img className="avatarImageMobile" src={icon_person_profile_bg}/>;
        }
    }

    showInitialsHoverBackground = (type) => {
        if(type == "person"){
            return <img className="avatarImageMobile" src={icon_person_profile_hover_bg}/>;
        }
        else if(type == "church"){
            return <img className="avatarImageMobile" src={icon_church_profile_hover_bg}/>;
        }
        else {
            return <img className="avatarImageMobile" src={icon_person_profile_hover_bg}/>;
        }
    }

    render(){
        return(
            <div>
                {/* Picture IS NOT saved && tile IS NOT opened */}
                { this.props.uploadedImageBase64 == "" ?
                    <div style={{position: "relative"}}>
                        {/* image */}
                        {this.showInitialsBackground(this.props.contactType)}

                        {/* text */}
                        <div className="avatarHoverTextContainerMobile verticalStack" style={{alignItems: "center", justifyContent: "center"}}>
                            <span className="fontPoppinsMedium40White">{this.props.initials}</span>
                        </div>
                    </div>
                : null }

                {/* Picture IS saved && tile IS NOT opened */}
                { this.props.uploadedImageBase64 != "" ?
                    <img className="avatarImageMobile" src={this.props.uploadedImageBase64}/>
                : null }
            </div>
        );
	}
}

export default ProfileImageMobile;