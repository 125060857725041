import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

class DropdownItem extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className={this.props.clickable ? "clickable" : ""} style={{marginLeft: -15, marginRight: -15}} onClick={this.props.onClick}>
                <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 5, marginBottom: 5}}></div>
                <div style={{height: 7}}></div>
                <span className={this.props.clickable ? "fontPoppinsRegular13 clickable onHoverToOrange oneLineThreeDotsText" : "fontPoppinsRegular13 oneLineThreeDotsText"} style={{paddingLeft: 15, paddingRight: 15}}>{this.props.title}</span>
                <div style={{height: 7}}></div>
            </div>
        );
	}
}

export default DropdownItem;