import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate, useLocation } from "react-router-dom";
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import ProfileTileSchoolMobile from '../tiles_m/ProfileTileSchoolMobile.js';
import ConversationsTile from '../tiles/ConversationsTile.js';
import ClosedTile from '../tiles/ClosedTile.js';
import ConversationsModalMobile from '../modals_m/ConversationsModalMobile.js';
import ObservationsModalMobile from '../modals_m/ObservationsModalMobile.js';
import SchoolInfoTile from '../tiles/SchoolInfoTile.js';

class SchoolProfileMobile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);
        this.refConvScroll = React.createRef();

        this.state = {
            actualOpenedTile: "", //profiledata, conversations, diagnosticlens
            updateProfileTileVar: true, // to update change its value
            updateConversationsTileVar: true, // to update change its value
            updateSchoolInfoTileVar: true, // to update change its value
            redirectTo: null,
            tileMenuOpened: false,
            prepareToShowModalAddConversation: false,
            showModalAddConversation: false,
            prepareToShowModalAddObservation: false,
            showModalAddObservation: false,
            mainContactId: 0,
            mainContactName: "Error",
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        //this.setActualSection("people");

        const { tile } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(tile == "conversations"){ this.openTile("conversations"); }

        this.loadData();
    }

    loadData = () => {
        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result[0].first_name && response.data.result[0].last_name){
                    this.setState({ mainContactName: response.data.result[0].first_name+" "+response.data.result[0].last_name })
                }
                else if(response.data.result[0].short_name){
                    this.setState({ mainContactName: response.data.result[0].short_name })
                }
            });
            this.setState({ mainContactId: id });
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.location.pathname !== prevProps.location.pathname){
            this.updateProfileTile();
            this.updateConversationsTile();
            this.updateSchoolInfoTile();
        }
    }

    setActualOpenedTile = (tile) => {
        this.setState({ actualOpenedTile: tile });
    }

    updateSchoolInfoTile = () => {
        if(this.state.updateSchoolInfoTileVar)this.setState({ updateSchoolInfoTileVar: false });
        else this.setState({ updateSchoolInfoTileVar: true });
    }

    updateProfileTile = () => {
        if(this.state.updateProfileTileVar)this.setState({ updateProfileTileVar: false });
        else this.setState({ updateProfileTileVar: true });
    }

    updateConversationsTile = () => {
        this.setState({ updateConversationsTileVar: !this.state.updateConversationsTileVar });
    }

    openTile = async (tile) => {
        await this.setActualOpenedTile(tile);
        await new Promise(resolve => setTimeout(resolve, 100));
        if(tile == "conversations"){ this.refConvScroll.current.scrollIntoView({ behavior: "smooth", block: "start" }); }
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddConversation"){
            await this.setState({ prepareToShowModalAddConversation: true });
            this.setState({ showModalAddConversation: true });
        }
        else if(modalWindow == "AddObservation"){
            await this.setState({ prepareToShowModalAddObservation: true });
            this.setState({ showModalAddObservation: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // close the modal
        if(modalWindow == "AddConversation"){
            this.setState({ showModalAddConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddConversation: false });
        }
        else if(modalWindow == "AddObservation"){
            this.setState({ showModalAddObservation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddObservation: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal(modalWindow);
        }
    }

    openConversationTile = () => {
        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))) this.setState({ redirectTo: "/MyConversations/"+id+"?contTp=7" });
        else this.props.openAlertMessage("Error while loading.","OK");
    }

    openObservationTile = () => {
        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))) this.setState({ redirectTo: "/ObservationsList/"+id });
        else this.props.openAlertMessage("Error while loading.","OK");
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return(
                <div className="verticalStack flex" style={{width: "calc(100vw - 60px)", height: "calc(var(--doc-height) - 80px)", overflowX: "hidden", overflowY: "auto"}}>

                    <div className="horizontalStackCenter myPeopleTableListingBar">
                        <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" onClick={() => this.setState({ redirectTo: -1 })}>{"Return to Search Results"}</span>
                        <div className="flex"/>
                    </div>

                    <div className="horizontalStack">

                        <div className="verticalStack flex">
                            <ProfileTileSchoolMobile openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} updateVar={this.state.updateProfileTileVar} />

                            <div style={{marginTop: "20px"}}></div>

                            <div ref={this.refConvScroll} className="verticalStack">

                                <div style={{marginRight: 10}}>
                                    <ClosedTile title={"Observations"} onExpand={() => this.openObservationTile()} onAdd={() => this.openModal("AddObservation")} />
                                </div>

                                <div style={{marginTop: "20px"}}></div>

                                <div style={{marginRight: 10}}>
                                    <ClosedTile title={"Conversations"} onExpand={() => this.openConversationTile()} onAdd={() => this.openModal("AddConversation")} />
                                </div>

                                <div style={{marginTop: "20px"}}></div>

                                <SchoolInfoTile mode={"school"} updateVar={this.state.updateSchoolInfoTileVar} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} deviceType={"mobile"} />

                                <div style={{marginTop: "20px"}}></div>

                            </div>
                        </div>

                    </div>

                    {this.state.prepareToShowModalAddConversation ? <ConversationsModalMobile showModal={this.state.showModalAddConversation} closeModal={this.closeModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddConversation"} conversationId={-1} contactMode={"person"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                    {this.state.prepareToShowModalAddObservation ? <ObservationsModalMobile showModal={this.state.showModalAddObservation} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddObservation"} observationId={-1} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

                </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(SchoolProfileMobile);