import React from 'react';
import '../styles/slider.css';

class Slider extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div>
                <input className={this.props.styles} style={this.props.style} type="range" value={this.props.value} min="0" max="100" step={this.props.step}
                    list="steplist" onChange={(e) => this.props.onChange(e.target.value)}
                />
                {/* I deleted: orient:"vertical". Not sure why was that there, but it was creating problems in Firefox */}

                {/*<datalist id="steplist">
                    <option>0</option>
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                </datalist>*/}
            </div>
        )
    }

    static onChange(value){ console.log('value', value); }
}

export default Slider;