import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import MyDisciplingTile from '../tiles/MyDisciplingTile.js';

class MyDiscipling extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            disciplesArray: ([]),
            /*disciplesArray: ([{"id":14,"rcg_name":"Antonín Dvořák","conv_cnt":null,"conv_last_dt":null,"seg_val":1 ,"seg_cd":null,"role":"Leader","team":"Worship","church_name":"HC Liptákov"},
                            {"id":14,"rcg_name":"Antonín Dvořák","conv_cnt":null,"conv_last_dt":null,"seg_val":2 ,"seg_cd":null,"role":"Leader","team":"Worship","church_name":"HC Liptákov"},
                            {"id":14,"rcg_name":"Antonín Dvořák","conv_cnt":null,"conv_last_dt":null,"seg_val":3 ,"seg_cd":null,"role":"Leader","team":"Worship","church_name":"HC Liptákov"},
                            {"id":14,"rcg_name":"Antonín Dvořák","conv_cnt":null,"conv_last_dt":null,"seg_val":4 ,"seg_cd":null,"role":"Leader","team":"Worship","church_name":"HC Liptákov"},
                            {"id":14,"rcg_name":"Antonín Dvořák","conv_cnt":null,"conv_last_dt":null,"seg_val":5 ,"seg_cd":null,"role":"Leader","team":"Worship","church_name":"HC Liptákov"}]),*/
            recordsOnOnePage: 5, // number of conversations showing on one page, 25 would be default, 5 is for testing
            actualPage: 0, // starts with pageNumber=0, then 1, 2, 3,...
            isPublic: 0,
            showActive: true,
            showUnactive: true,
        }

    }

    componentDidMount(){
        this.loadData();
        this.setActualSection("discipling");
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.searchText !== this.props.searchText) {
            this.setState({ actualPage: 0 });
            this.loadData();
        }
    }

    loadData = () => {
        /*Axios.get('/api/conv/get?limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage)+'&search='+this.props.searchText).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ conversationsData: response.data.result })
        });*/
        var showActiveNum = this.state.showActive ? 1 : 0;
        var showUnactiveNum = this.state.showUnactive ? 1 : 0;

        Axios.get('/api/my_dsc/get?is_public='+this.state.isPublic+'&is_active='+showActiveNum+'&is_inactive='+showUnactiveNum).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var results = response.data.result;
            var otherArray = [];
            this.setState({ disciplesArray: results });
        });
    }

    setIsPublic = (value) => {
        if(value) this.setState({ isPublic: 1 });
        else this.setState({ isPublic: 0 });
    }

    toggleShowActive = async () => {
        await this.setState({ showActive: !this.state.showActive });
        this.loadData();
    }

    toggleShowUnactive = async () => {
        await this.setState({ showUnactive: !this.state.showUnactive });
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(100vh - 80px)"}}>
                <div className="horizontalStackCenter myConversationsTableListingBar">
                    <span className="fontPoppinsRegular13Gray">{"My Disciple-Making Relationships"}</span>
                    <div className="flex"/>
                    <span className="fontPoppinsRegular13Gray">{"Download PDF"}</span>
                </div>
                <MyDisciplingTile disciplesArray={this.state.disciplesArray} getDataFromApi={this.loadData} isPublic={this.state.isPublic} setIsPublic={this.setIsPublic} leftMenuOpened={this.props.leftMenuOpened} showActive={this.state.showActive} toggleShowActive={this.toggleShowActive} showUnactive={this.state.showUnactive} toggleShowUnactive={this.toggleShowUnactive} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
            </div>
        );
    }
}

export default MyDiscipling;
