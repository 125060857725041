import React from 'react';
import { Link } from "react-router-dom";
import '../styles/modals.css';
//import Slider from '@material-ui/core/Slider';
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import EditDeleteButton from '../components/EditDeleteButton.js';
import Slider from '../components/Slider.js';
import DiagnosticModalConfirmation from '../components/DiagnosticModalConfirmation.js';

// assets
import close_white from '../assets/close_white.png';
import leadership_type_img from '../assets/leadership_type.png';
import icon_action_down from '../assets/icon_action_down.png';
import icon_action_up from '../assets/icon_action_up.png';

const marks = [
  { value: 0, label: '0', },
  { value: 20, label: '1', },
  { value: 40, label: '2', },
  { value: 60, label: '3', },
  { value: 80, label: '4', },
  { value: 100, label: '5', },
];

class LeadershipTypeModal extends React.Component {
    savedHeight = null;

    constructor(props) {
        super(props);
        this.state = {
            modalExpanded: false,
            mainContactId: -1,
            mainContactName: "Loading...",
            editingModeOfRecord: -1,
            sliderValue: 60, // 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100
            lastRecordDate: "",
            note_descr: "",
            arrayOfRecords: ([]),
            sliderSize: 330,
            sliderMarginTop: -173,
            sliderMarginLeft: -380,
            showSaveRecordConfirmation: false,
        };

        this.refSlider = React.createRef();
        this.refSliderSteps = React.createRef();
        this.refImage = React.createRef();
        this.refModalBottom = React.createRef();
    }

    async componentDidMount(){
        this.loadData();
        window.addEventListener('resize', this.resizeSliderAndSteps);

        //this.resizeSliderAndSteps();
        //await this.timeout(50);

        setTimeout(() => {
            this.resizeSliderAndSteps();
        }, 50);

        setTimeout(() => {
            this.resizeSliderAndSteps();
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeSliderAndSteps);
    }

    componentDidUpdate() { /* another way of real-time updating size of slider */
        if(this.savedHeight !== this.refSliderSteps.current.style.height) {
            this.resizeSliderAndSteps();
        }
        this.savedHeight = this.refSliderSteps.current.style.height;
    }

    timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    resizeSliderAndSteps = () => {
        // if image height is not defined yet dont calculate with that
        if(!this.refImage.current){return;}
        const refSliderSteps = this.refSliderSteps.current;
        const refImage = this.refImage.current;
        // setting max and min to variable I am calculating with
        var variableToCalculate = this.refImage.current.offsetHeight;
        if(variableToCalculate < 218) variableToCalculate = 218;
        else if(variableToCalculate > 600) variableToCalculate = 600;

        refSliderSteps.style.height = `${variableToCalculate-(0.12*variableToCalculate)}px`;

        this.setState({ sliderMarginLeft: -variableToCalculate-(0.06*variableToCalculate) });
        this.setState({ sliderMarginTop: -variableToCalculate/2+7 });
        this.setState({ sliderSize: variableToCalculate-(0.065*variableToCalculate) });
    }


    loadData = () => {
        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            Axios.get('/api/lens/get?cont_id='+id+'&segm_id=3').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                if(response.data.result.length > 0){
                    response.data.result = response.data.result.reverse();
                    var savedValue = response.data.result[0].seg_id;
                    var countedValue = this.getSliderValue(savedValue); //ex.: from 3 do 60 (0-100)
                    this.setState({ sliderValue: countedValue });
                    this.setState({ lastRecordDate: response.data.result[0].ins_at });
                    this.setState({ arrayOfRecords: response.data.result });
                }
                else {
                    this.setState({ sliderValue: 60 });
                    this.setState({ lastRecordDate: "" });
                    this.setState({ arrayOfRecords: [] });
                }
            });
            this.setState({ mainContactId: id });
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var user_name = response.data.result[0].first_name + " " + response.data.result[0].last_name;
                this.setState({ mainContactName: user_name });
            });
        }
    }

    saveNewRecord = () => {
        //alert("ContId: "+this.state.mainContactId+"\nSegmId: 3\nSegId: "+((this.state.sliderValue/20))+"\nDescr: "+this.state.note_descr);
        //alert(this.state.editingModeOfRecord);
        //return;

        var url = "/api/lens/ins";

        if(this.state.editingModeOfRecord != -1){
            url = "/api/lens/upd/"+this.state.editingModeOfRecord;
        }

        Axios.post(url, {
            ContId: this.state.mainContactId, // id of person to whom I am setting the record
            SegmId: 3, // 3 is Leader Type lens
            SegId: this.getSeqIdValue(this.state.sliderValue), // this is the ID of level I am saving
            NoteDescr: this.state.note_descr,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            if(this.state.editingModeOfRecord == -1){
                //this.props.openAlertMessageTwoActions("The record was saved.","OK","",null);
                this.setState({ showSaveRecordConfirmation: true });

                setTimeout(() => {
                    this.props.closeModal("LeadershipType");
                }, 2000);
            }
            else {
                this.setState({ editingModeOfRecord: -1 });
                this.setState({ note_descr: "" });
                this.loadData();
            }
        });
    }

    onDeleteRecord = (id) => {
        Axios.delete('/api/lens/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.loadData();
        });
    }

    onEditRecord = (id) => {
        if(this.state.editingModeOfRecord == id){
            this.setState({ editingModeOfRecord: -1 });
            this.setState({ note_descr: "" });
            this.loadData();
        }
        else {
            this.setState({ editingModeOfRecord: id });

            Axios.get('/api/lens/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var savedValue = response.data.result[0].seg_id;
                var countedValue = this.getSliderValue(savedValue); //ex.: from 3 do 60 (0-100)
                this.setState({ sliderValue: countedValue });
                this.setState({ note_descr: response.data.result[0].note_descr });
            });

        }
    }

    getSliderValue = (valueID) => {
        if(valueID == 11){ return 0; }
        if(valueID == 141){ return 10; } // 0-1
        if(valueID == 12){ return 20; }
        if(valueID == 142){ return 30; } // 1-2
        if(valueID == 13){ return 40; }
        if(valueID == 143){ return 50; } // 2-3
        if(valueID == 14){ return 60; }
        if(valueID == 144){ return 70; } // 3-4
        if(valueID == 15){ return 80; }
        if(valueID == 145){ return 90; } // 4-5
        if(valueID == 16){ return 100; }
        return 0;
    }

    getSeqIdValue = (valueID) => {
        if(valueID == 0){ return 11; }
        if(valueID == 10){ return 141; } // 0-1
        if(valueID == 20){ return 12; }
        if(valueID == 30){ return 142; } // 1-2
        if(valueID == 40){ return 13; }
        if(valueID == 50){ return 143; } // 2-3
        if(valueID == 60){ return 14; }
        if(valueID == 70){ return 144; } // 3-4
        if(valueID == 80){ return 15; }
        if(valueID == 90){ return 145; } // 4-5
        if(valueID == 100){ return 16; }
        return 11;
    }

    toggleModalExpansion = async () => {
        if(this.state.modalExpanded){
            this.setState({ modalExpanded: false });
        }
        else {
            this.setState({ modalExpanded: true });
            await new Promise(resolve => setTimeout(resolve, 100));
            this.refModalBottom.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

	render(){
		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal("LeadershipType")} isExpanded={this.state.modalExpanded}>
                    <DiagnosticModalConfirmation show={this.state.showSaveRecordConfirmation} />
                    <div className="content">
                        <div className="horizontalStack flex">
                            <div className="verticalStackCenter flex">
                                <span className="fontPoppinsSemiBold15">Leader Type<span style={{color: "var(--blue-button-link)"}}> - {this.state.mainContactName} <Moment format="M/D/YY">{this.state.lastRecordDate}</Moment></span></span>
                                <div>
                                    <span className="fontPoppinsRegular13Gray">This tool is designed to help you assess the leadership type of this person. To learn more about leadership types <span className="fontPoppinsSemiBold13 clickable" onClick={() => this.toggleModalExpansion()}>click here</span>.</span>
                                    <br/><br/>
                                    <span className="fontPoppinsSemiBold15">Current Leadership Type:</span>
                                </div>
                                <div className="horizontalStackCenter flex">
                                    <div className="flex"></div>
                                    <div className="verticalStack" style={{height: "100%"}}>
                                        <div className="flex"></div>
                                        {/*<Slider
                                            style={{color: "var(--blue-button-link)", height: "83%"}}
                                            aria-label="Always visible"
                                            value={this.state.sliderValue}
                                            step={20}
                                            orientation={"vertical"}
                                            onChange={(event, value) => this.setState({ sliderValue: value })}
                                            marks={marks}
                                            valueLabelDisplay="off"
                                        />*/}
                                        <div className="horizontalStack" style={{height: "95%", position: "relative"}}>


                                        </div>
                                        <div className="flex"></div>
                                    </div>
                                            {/* value 100-variable is because it is upside down */}
                                    <Slider
                                        styles={"myCustomSlider myCustomSliderVertical myCustomSliderBlue"}
                                        value={this.state.sliderValue}
                                        style={{width: this.state.sliderSize, marginTop: this.state.sliderMarginTop, marginLeft: this.state.sliderMarginLeft}}
                                        step={10}
                                        onChange={(value) => this.setState({ sliderValue: value })}
                                    />
                                    <div ref={this.refSliderSteps} className="verticalStack" style={{alignItems: "center"}}>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"5"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13LightGray">{"4-5"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"4"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13LightGray">{"3-4"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"3"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13LightGray">{"2-3"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"2"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13LightGray">{"1-2"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"1"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13LightGray">{"0-1"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"0"}</span>
                                    </div>
                                    <img ref={this.refImage} style={{width: "40%"}} src={leadership_type_img}/>
                                    <div className="flex"></div>
                                </div>
                                <div>
                                    <span className="fontPoppinsRegular13Gray">{"If you see this person exuding a different leadership type based on the diagnostic instructions below, then you can change it by moving the slider above. Take notes on the right and once you click on the ‘Save’ button below a new record will be made."}</span>
                                </div>
                                <div className="horizontalStackCenter" style={{marginTop: "20px"}}>
                                    <RoundButton title={this.state.editingModeOfRecord != -1 ? "Edit Level and Note" : "Save Level and Note"} className={"roundButtonBlue"} style={{marginRight: "10px"}} whiteText={true} onClick={this.saveNewRecord} />
                                    <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.props.closeModal("LeadershipType")} />
                                    <div className="flex"></div>
                                    <div className="clickable" onClick={this.toggleModalExpansion}>

                                        { !this.state.modalExpanded ?
                                            <div className="modalExpandButton horizontalStackCenter">
                                                <span className="modalExpandButtonTextBlue fontPoppinsSemiBold13">Open Diagnostic Instructions</span>
                                                <div className="modalExpandButtonIconContainer modalExpandButtonIconBgBlue verticalStackCenter">
                                                    <div className="horizontalStack">
                                                        <div className="flex"></div>
                                                        <img className="modalExpandButtonIcon modalExpandButtonBlue" src={icon_action_down}/>
                                                        <div className="flex"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null }

                                        { this.state.modalExpanded ?
                                            <div className="modalExpandButton horizontalStackCenter">
                                                <span className="modalExpandButtonTextBlue fontPoppinsSemiBold13">Close Diagnostic Instructions</span>
                                                <div className="modalExpandButtonIconContainer modalExpandButtonIconBgBlue verticalStackCenter">
                                                    <div className="horizontalStack">
                                                        <div className="flex"></div>
                                                        <img className="modalExpandButtonIcon modalExpandButtonBlue" src={icon_action_up}/>
                                                        <div className="flex"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null }

                                    </div>
                                </div>
                            </div>

                            <div style={{width: 20}}></div>

                            <div className="verticalStack" style={{minWidth: "200px", maxWidth: "350px", flex: 0.55}}>
                                <div className="horizontalStack">
                                    <div className="flex"></div>
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal("LeadershipType")}/>
                                </div>
                                <div className="verticalStack" style={{marginTop: 20}}>
                                    <span className="fontPoppinsSemiBold15Gray">History:</span>

                                    <div className="modalHistoryContainer">
                                        <div className="modalHistoryDiv verticalStack flex">

                                            {this.state.arrayOfRecords.map(function (item) {
                                                return (
                                                    <div className="verticalStack" style={{padding: 10, paddingTop: 0, backgroundColor: this.state.editingModeOfRecord == item.id ? "var(--light-blue)" : "var(--lightest-gray)"}}>
                                                        <div className="horizontalStackCenter">
                                                            <span className="fontPoppinsRegular13Blue modalHistoryDate"><Moment format="MMM. D, YYYY">{item.ins_at}</Moment></span>
                                                            <EditDeleteButton itemId={item.id} onEdit={this.onEditRecord} onDelete={this.onDeleteRecord} areYouSureText={"Are you sure want to delete this \"Leader Lens\" record?"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                        </div>
                                                        <span className="fontPoppinsRegular13Blue" style={{marginTop: -9}}>{"By "}{item.cr_by}</span>
                                                        <span className="fontPoppinsRegular13"><span className="fontPoppinsBold13">{item.seg_cd}{" -"} </span>{item.note_descr}</span>
                                                    </div>
                                                )
                                            }.bind(this))}

                                            <div className="flex"></div>

                                        </div>
                                    </div>

                                </div>
                                <div className="verticalStack flex" style={{marginTop: "30px"}}>
                                    <span className="fontPoppinsSemiBold15">Add a Note:</span>
                                    <textarea className="modalInputAddNote" rows="9" placeholder={"Type your notes about this person's current leadership level."} value={this.state.note_descr} onChange={e => this.setState({ note_descr: e.target.value })}></textarea>
                                </div>
                            </div>
                        </div>
                        <div style={{display: this.state.modalExpanded ? "inline" : "none"}}>
                            <div className="fakeEndOfModalWindow"></div>
                            <div className="verticalStack" style={{paddingTop: 20}}>
                                <span className="fontPoppinsSemiBold15">Diagnostic Instructions:</span>

                                <div className="modalInputAddNote modalInputAddNoteBottom verticalStack">
                                    <span>{"The purpose of the L-lens is to help identify a leader’s “growing edge”, specifically what they need to focus on in order to increase their spiritual leadership capacity.  Leadership capacity is not a measure of value or spiritual maturity.  Some people are gifted by God with natural leadership potential, while others are given a set of gifts that express themselves more in individual contribution."}</span>
                                    <span style={{marginTop: 20}}>{"Knowing at what level a leader is currently functioning can help you invest in them to grow the skills and abilities needed for that level, as well as look forward to the leadership growth that would increase their capacity, if that is part of God’s call."}</span>
                                    <span style={{marginTop: 20}}>{"When using the L-lens it is important to note that we cannot judge a person’s future leadership potential.  Our role is to focus on the next steps of growth needed for the challenges they currently face, and allow God to shape them for his future plan."}</span>
                                    <span style={{marginTop: 20}}>{"Use the descriptions below to help you evaluate a person’s current leadership level."}</span>

                                    {/* POTENTIAL LEADER */}
                                    <span className="fontPoppinsSemiBold15" style={{marginTop: 20}}>{"Lp - Potential leader"}</span>
                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"This person..."}</span>
                                    <span>{"I don't yet know this person's leadership potential because:"}</span>
                                    <ol>
                                        <li>{"They have not yet been in any roles of leadership."}</li>
                                        <li>{"They have not yet demonstrated a desire to lead."}</li>
                                        <li>{"They do not yet have the character, personal stability or spiritual maturity to lead others."}</li>
                                    </ol>

                                    <span className="fontPoppinsSemiBold13">{"I see potential in them because..."}</span>
                                    <ol>
                                        <li>{"They care about others and want them to grow spiritually."}</li>
                                        <li>{"They seem to have natural influence on those around them."}</li>
                                        <li>{"They show an openness to learn and grow."}</li>
                                    </ol>

                                    <span className="fontPoppinsSemiBold13">{"Next steps"}</span>
                                    <ol type="a">
                                        <li>{"Give them small and “time bound” opportunities to lead others. Shepherd them with the specific instructions, support and feedback that will make them successful."}</li>
                                        <li>{"Encourage them by communicating the potential you see in them."}</li>
                                        <li>{"Focus on helping them grow in the character, personal stability, and spiritual maturity to lead others."}</li>
                                    </ol>

                                    {/* L1 growing leader - can lead a "family" */}
                                    <span className="fontPoppinsSemiBold15" style={{marginTop: 20}}>{"L1 - growing leader - can lead a \"family\""}</span>
                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"This person..."}</span>
                                    <ol>
                                        <li>{"Is demonstrating the ability to shepherd a small group of people, either one by one, or all together."}</li>
                                        <li>{"Is demonstrating a desire to care for the needs of others in an ongoing way."}</li>
                                        <li>{"Is demonstrating the ability to lead a discussion, initiate a spiritual conversation, and invest spiritually in others."}</li>
                                        <li>{"Is demonstrating the ability to see and respond to needs around them with prayer, encouragement, teaching, exhortation, and direction."}</li>
                                        <li>{"Is demonstrating the ability to help others grow spiritually."}</li>
                                        <li>{"Is demonstrating the ability to be a spiritual “older brother” or “older sister” to others who are learning to follow Jesus."}</li>
                                    </ol>

                                    <span className="fontPoppinsSemiBold13">{"Next steps"}</span>
                                    <ol type="a">
                                        <li>{"Encourage them and support them as they invest in others."}</li>
                                        <li>{"Help them learn to deal with the hurts, disappointments and failures that will inevitably come."}</li>
                                        <li>{"Help them improve their teaching, shepherding and discussion leading skills."}</li>
                                        <li>{"Show them tools and resources that will help them be successful."}</li>
                                        <li>{"Coach them through the problems they are encountering, using the places where they get stuck to increase their understanding and abilities."}</li>
                                        <li>{"As you look forward, help them see how they could grow in their skills so that they could impact more than a small group in the future."}</li>
                                    </ol>

                                    {/* L2 - Competent leader - can lead a "flock" */}
                                    <span className="fontPoppinsSemiBold15" style={{marginTop: 20}}>{"L2 - competent leader - can lead a \"flock\""}</span>
                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"This person has the characteristics of an L1 leader, plus…"}</span>
                                    <ol>
                                        <li>{"Is demonstrating the ability to shepherd and lead larger groups of people, numbering from 8 to 35 *"}</li>
                                        <li>{"When giving instructions or direction, groups of people respond and follow this person."}</li>
                                        <li>{"Is demonstrating the ability to organize and plan in order to lead events and activities that help groups of people to spiritually grow."}</li>
                                        <li>{"Can communicate clearly and confidently to larger groups."}</li>
                                        <li>{"Is demonstrating an ability to discipline and correct in a way that people feel cared for and loved."}</li>
                                        <li>{"Is demonstrating an ability to communicate vision and direction for the future."}</li>
                                        <li>{"Is demonstrating the ability to bring spiritual nourishment through the Word, prayer, and shepherding that helps a “flock” of people to grow."}</li>
                                    </ol>

                                    <span className="fontPoppinsSemiBold13">{"Next steps"}</span>
                                    <ol type="a">
                                        <li>{"Help them develop practical skills of planning, organization and communication."}</li>
                                        <li>{"Help them see further into the future so that they are not just planning events, but shepherding their flock toward a destination."}</li>
                                        <li>{"Coach them through the problems they are encountering, using the places where they get stuck to increase their understanding and abilities."}</li>
                                        <li>{"Help them see and understand the different needs of people in their flock and design the overall ministry to meet these varied needs."}</li>
                                        <li>{"Help them receive and radiate God’s Father heart into the flock under their care."}</li>
                                        <li>{"Teach them to bring regular spiritual nourishment through the Word, prayer, encouragement and shepherding."}</li>
                                        <li>{"Help them learn to shepherd like the Good Shepherd, knowing the names of their sheep, going after the lost, binding up wounds, feeding and caring for those under their care, and willingly sacrificing for the protection and redemption of others."}</li>
                                        <li>{"Help them grow in character and walk with God so they are an inspiration and example to those they lead."}</li>
                                        <li>{"As you look forward, help them see how they could grow in their skills so that they could impact more people in the future, by equipping others and developing a ministry team that serves and shepherds with them."}</li>
                                    </ol>

                                    {/* L3 - Equipping leader - can lead a ministry through a team */}
                                    <span className="fontPoppinsSemiBold15" style={{marginTop: 20}}>{"L3 - Equipping leader - can lead a ministry through a team"}</span>
                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"This person has the characteristics of an L1 and L2 leader, plus…"}</span>
                                    <ol>
                                        <li>{"Is able to spiritually care for a community of 35 to over 100* through their ability to lead a ministry through a team."}</li>
                                        <li>{"Is demonstrating the ability to build and care for a team that serves with them."}</li>
                                        <li>{"Is demonstrating the ability to see potential in people around them, challenge them forward, and invest in them so they too can lead and serve."}</li>
                                        <li>{"Is demonstrating the ability to motivate and equip others with ongoing care, specific instructions, entrusting them with responsibility and authority and supporting them as they learn."}</li>
                                        <li>{"Is demonstrating the ability to divide and organize the ministry so that others have clearly defined roles, yet work together effectively as a team."}</li>
                                        <li>{"Is demonstrating the ability to invest deeply in a few in order to reach many."}</li>
                                        <li>{"Is demonstrating the ability to keep the big picture and maintain direction as a ministry grows beyond their ability to do all the work themselves."}</li>
                                    </ol>

                                    <span className="fontPoppinsSemiBold13">{"Next steps"}</span>
                                    <ol type="a">
                                        <li>{"Help them develop practical skills of planning, organization and communication."}</li>
                                        <li>{"Help them see further into the future so that they are not just planning events, but shepherding their flock toward a destination."}</li>
                                        <li>{"Coach them through the problems they are encountering, using the places where they get stuck to increase their understanding and abilities."}</li>
                                        <li>{"Help them see and understand the different needs of people in their flock and design the ministry to meet them."}</li>
                                        <li>{"Conflict??"}</li>
                                        <li>{"As you look forward, help them see that God could use them to impact even more people in the future, if they learn to lead in a way that brings exponential growth."}</li>
                                        <li>{"Being aware of weaknesses and supplementing???"}</li>
                                        <li>{"Team giftings and serving according to gifts???"}</li>
                                    </ol>

                                    {/* L4 - Strategic leader - can grow ministry 5 - 10X */}
                                    <span className="fontPoppinsSemiBold15" style={{marginTop: 20}}>{"L4 - Strategic leader - can grow ministry 5 - 10X"}</span>
                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"This person has the characteristics of an L1 – L3 leader, plus…"}</span>
                                    <ol>
                                        <li>{"Is demonstrating faith and vision for how the ministry could expand not just 5-10%, but 5-10X."}</li>
                                        <li>{"Through God’s eyes they can see outside what currently exists to untapped opportunities and potential around them."}</li>
                                        <li>{"Is demonstrating the ability to turn that vision into concrete steps that fuel and support ministry expansion."}</li>
                                        <li>{"Is demonstrating the ability to scale by building systems, rhythms and teams that accelerate growth without sacrificing health."}</li>
                                        <li>{"Is demonstrating the ability to prune what is unhealthy, and say no to what is less fruitful in order to focus energy and attention on the key places where God is moving."}</li>
                                        <li>{"Is demonstrating the ability to maintain personal health and vitality in the midst of rapidly increasing demands."}</li>
                                        <li>{"Is demonstrating the ability to bring inspiring leadership that energizes and motivates everyone around them."}</li>
                                        <li>{"Is demonstrating the ability to equip, focus and release the efforts of others to keep pace with ministry growth."}</li>
                                    </ol>

                                    <span className="fontPoppinsSemiBold13">{"Next steps"}</span>
                                    <ol type="a">
                                        <li>{"Help them learn to preserve space for prayer, reflection, waiting on the Lord, and spiritual refreshment so that their vision of the future remains clear."}</li>
                                        <li>{"Help them learn to say “no”, to prune and focus in the midst of many expectations from others and a wide range of opportunities."}</li>
                                        <li>{"Help them think in terms of exponential rather than incremental growth, and ask God for the breakthroughs that will make this happen."}</li>
                                        <li>{"Take a field trip to the future with them and imagine that their current ministry impact has doubled or increased 5X. What would it look like? What kind of team and structure would it take to support it? Then work backwards to the present and ask God to show you the path that leads there."}</li>
                                        <li>{"Ask often about personal health and vitality, the health of their walk with God, spiritual and physical heath, the heath of their marriage and family. Make sure these are not being compromised as the burden of leadership and ministry grows."}</li>
                                        <li>{"Help them increase their faith and reliance on God and sensitivity to the leading of the Spirit.  The one who continuously abides in Christ bears “much fruit”."}</li>
                                        <li>{"Make sure they continue to personally make disciples as the leadership demands of the ministry increase and organizational issues compete for their attention."}</li>
                                        <li>{"Help them identify and begin to train someone who could take their place"}</li>
                                    </ol>

                                    {/* L5 – Multiplying leader – can multiply leaders and ministries */}
                                    <span className="fontPoppinsSemiBold15" style={{marginTop: 20}}>{"L5 – Multiplying leader – can multiply leaders and ministries"}</span>
                                    <span className="fontPoppinsSemiBold13" style={{marginTop: 20}}>{"This person has the characteristics of an L1 – L4 leader, plus…"}</span>
                                    <ol>
                                        <li>{"Is demonstrating the ability to not just equip workers and grow their ministry but multiply leaders and ministries that grow and flourish without them."}</li>
                                        <li>{"Is demonstrating the ability to grow leaders who are not just skilled, but rich in character and deep in their walks with God"}</li>
                                        <li>{"Is demonstrating the ability to engage in and solve difficult problems in a way that brings protection and health to the work of God."}</li>
                                        <li>{"Is sending out leaders who launch and grow new ministries and multiply leaders around them."}</li>
                                        <li>{"Is demonstrating a Kingdom mindset that energizes and invests in the growth of God’s movement outside the bounds of their own ownership and control."}</li>
                                        <li>{"Is demonstrating joy in the success and impact of other leaders, and drawing focus to them and to the Lord rather than themselves."}</li>
                                        <li>{"Is embodying the values that they proclaim in powerful ways that inspire, motivate and shape the actions of others."}</li>
                                        <li>{"Is known by their love for God and the power of his presence in their lives."}</li>
                                    </ol>

                                    <span className="fontPoppinsSemiBold13">{"Next steps"}</span>
                                    <ol type="a">
                                        <li>{"Each stage of leadership multiplication requires a certain “death” on the part of the leader.  Help them identify and embrace the “death” that is currently before them."}</li>
                                        <li>{"Leadership multiplication is rarified air, and they may not have many examples around them.  Point them to books, personal encounters and conversations with other L5 leaders to shape their thinking and inspire them."}</li>
                                        <li>{"Have them deeply study the life of Christ as he multiplied leaders, as well as the ministry of Paul as he multiplied churches and leaders in the first century."}</li>
                                        <li>{"Study movements of God in history and other parts of the world to understand the role of leadership in these periods of multiplication."}</li>
                                    </ol>

                                    <span style={{marginTop: 20}}>{"Note: The leadership levels roughly correspond to the leadership skills needed to lead a ministry through the 5 phases of ministry development we observe from the life of Christ."}</span>

                                    <span style={{marginTop: 20}}>{"*Specific numbers are significantly influenced by the cultural context.  In some cultures the numbers are larger because of the typical size of churches and the way people gather in social or family contexts.  Other cultures have smaller families, churches, and circles of relationships.  Look for cultural cues to help you norm the typical size of families, flocks, ministries and movements in your context."}</span>

                                </div>


                                {/*
                                <textarea className="modalInputAddNote" rows="12" disabled>{"The purpose of the L-lens is to help identify a leader’s “growing edge”, specifically what they need to focus on in order to increase their leadership capacity.  Leadership capacity is not a measure of value or spiritual maturity.  Some people are gifted by God with natural leadership potential, while others are given a set of gifts that express themselves more in individual contribution.\n\nWhile everyone can grow in their leadership skills, not everyone has the gifting, desire or calling to lead larger groups of people. However, since leaders are used by God to enable and release others, developing those who have this gift is important for the work of God to grow.\n\nKnowing at what level a leader is currently functioning can help you invest in them to grow the skills and abilities needed for that level, as well as look forward to the leadership growth that would increase their capacity, if that is part of God’s call.\n\nWhen using the L-lens it is important to note that we cannot judge a person’s future leadership potential.  Our role is to focus on the next steps of growth needed for the challenges they currently face, and allow God to shape them for his future plan."}</textarea>
                                */}
                            </div>
                            <div className="horizontalStackCenter clickable" style={{marginTop: 20}} onClick={this.toggleModalExpansion}>
                                <div className="flex"></div>

                                <div ref={this.refModalBottom} className="modalExpandButton horizontalStackCenter">
                                    <span className="modalExpandButtonTextBlue fontPoppinsSemiBold13">Close Diagnostic Instructions</span>
                                    <div className="modalExpandButtonIconContainer modalExpandButtonIconBgBlue verticalStackCenter">
                                        <div className="horizontalStack">
                                            <div className="flex"></div>
                                            <img className="modalExpandButtonIcon modalExpandButtonBlue" src={icon_action_up}/>
                                            <div className="flex"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex"></div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
		);
	}
}

export default LeadershipTypeModal;