import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';

class NotFoundPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }

    componentDidMount(){

    }

    render() {
        return (
            <div class="horizontalStack" style={{height: "calc(var(--doc-height) - 80px)"}}>
                <div className="flex"></div>
                <div className="verticalStack">
                    <div className="flex"></div>
                    <span className="fontPoppinsRegular27">Page not found</span>
                    <div className="flex"></div>
                </div>
                <div className="flex"></div>
            </div>
        );
    }
}

export default NotFoundPage;