import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';
import { Link } from "react-router-dom";
import { navigate } from "@reach/router";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_church from '../assets/icon_schools_gray.png';
import icon_church_info_up from '../assets/icon_church_info_up.png';
import icon_church_info_down from '../assets/icon_church_info_down.png';

// import components
import JvEngagementModal from '../modals/JvEngagementModal.js';

class SchoolInfoTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            schoolProfile: ([{cont_nm:""}]),
            schoolId: -1,
            showJvEngagementModal: false,
            prepareToShowModalJvEngagement: false,
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
        }
    }

    componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = async () => {
        const pathname = window.location.href;
        var contact_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(contact_id.includes("?")){ contact_id = contact_id.substring(0, contact_id.indexOf("?")); }

        if(Number.isInteger(Number(contact_id))){ // if there is number received from URL

            var school_id = -1;

            if(this.props.mode == "person"){
                await Axios.get('/api/cont/get/'+contact_id).then(response => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                    school_id = response.data.result[0].school_id;
                    this.setState({ schoolId: response.data.result[0].school_id });
                });
            }
            else {
                school_id = contact_id;
            }

            if(school_id == -1 || school_id == null){ return; }

            this.setState({ schoolId: school_id });

            /*Axios.get('/api/cprof/get/'+school_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                //alert(response.data.result[0].cont_nm);
                this.setState({ schoolProfile: response.data.result })
            });*/

        }

    }

    openModal = async (modalWindow) => {
        if(modalWindow == "JvEngagement"){
            await this.setState({ prepareToShowModalJvEngagement: true });
            this.setState({ showJvEngagementModal: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "JvEngagement"){
            this.setState({ showJvEngagementModal: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalJvEngagement: false });
        }
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className={this.props.deviceType == "mobile" ? "churchInfoTileMobile" : "churchInfoTile"}>

                <div className="horizontalStack">
                    <div className="verticalStack flex">
                        <span className="fontPoppinsSemiBold15 clickable">School: <span style={{color: "var(--blue-button-link)"}} onClick={()=> { navigate("/SchoolProfile/"+this.state.schoolProfile[0].cont_id); window.location.reload(); }}>{this.state.schoolProfile[0].cont_nm}</span></span>

                        <div style={{height: "20px"}}></div>

                        {this.state.schoolProfile.slice(1).map(function (item) {
                            return (
                                <span className="fontPoppinsRegular13DarkGray">{item.team} {item.role}: <span className="clickable" style={{color: "var(--blue-button-link)"}} onClick={()=> { navigate("/PeopleProfile/"+item.cont_id); window.location.reload(); }}>{item.cont_nm}{item.dsc_cd == null && item.ldr_cd == null ? "" : ","} {item.dsc_cd}{item.dsc_cd == null || item.ldr_cd == null ? "" : "/"}{item.ldr_cd}</span></span>
                            )
                        }.bind(this))}

                        <div style={{height: "20px"}}></div>

                        <div className="horizontalStack">
                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13DarkGray">30-40 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_up}/> <span className="fontPoppinsRegular13Gray">Church Size</span></span>
                                <span className="fontPoppinsRegular13DarkGray">20-30 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_down}/> <span className="fontPoppinsRegular13Gray">Youth Ministry</span></span>
                                <span className="fontPoppinsRegular13DarkGray">35 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_down}/> <span className="fontPoppinsRegular13Gray">Youth Group</span></span>
                            </div>
                            <div className="verticalStack" style={{marginLeft: "15px"}}>
                                <span className="fontPoppinsRegular13DarkGray">5 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_up}/> <span className="fontPoppinsRegular13Gray">Edge</span></span>
                            <span className="fontPoppinsRegular13DarkGray">25 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_down}/> <span className="fontPoppinsRegular13Gray">Fusion</span></span>
                            </div>
                        </div>

                    </div>
                    <div className="verticalStack" style={{alignItems: "center"}}>
                        <img className="churchInfoTileIcon" src={icon_church}/>
                        <div className="flex"></div>
                        {/*<Link className="link" to={"../SchoolProfile/"+this.state.churchId} >
                            <div className="threeDotsButton clickable verticalStackCenter"><span>···</span></div>
                        </Link>*/}
                    </div>
                </div>
                {this.state.prepareToShowModalJvEngagement ? <JvEngagementModal showModal={this.state.showJvEngagementModal} closeModal={this.closeModal} mode={this.props.mode} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
            </div>
        );
    }
}

export default SchoolInfoTile;
