import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import MapComponent from '../components/MapComponent.js';
import Select, { StylesConfig } from 'react-select';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getDeviceType, getOperatingSystem, getBrowser } from '../utilities/DeviceDataUtilities.js';


const customStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid var(--border-light-gray)',
        backgroundColor: "white",
        fontFamily: "PoppinsRegular",
        color: 'black',
        fontSize: 13,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 15,
        paddingRight: 15,
        ':hover': {
            backgroundColor: "white",
            color: 'var(--jv-orange)',
            cursor: "pointer",
        },
        ':last-child': {
            borderBottom: '0px solid var(--border-light-gray)',
        },
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 250,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
    }),
    multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "white",
          borderRadius: 5,
          padding: 0,
          paddingLeft: 1,
          paddingRight: 5,
          border: "1px solid black",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        fontFamily: "PoppinsRegular",
        padding: 0,
        color: 'black',
        fontSize: 13,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "black",
        padding: 0,
        ':hover': {
            backgroundColor: data.color,
            color: 'var(--jv-orange)',
            cursor: "pointer",
        },
    }),
    input: (styles) => ({
        ...styles,
        backgroundColor: "white",
        fontFamily: "PoppinsRegular",
        color: 'black',
        fontSize: 13,
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontFamily: "PoppinsRegular",
        color: 'black',
        fontSize: 13,
    }),
    valueContainer: (styles) => ({ /* container where chosen items are shown */
        ...styles,
        backgroundColor: "white",
        padding: 5,
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "white",
        marginTop: 1,
        borderRadius: "0px",
        border: "0px solid black",
    }),
    menuList: (styles) => ({
        ...styles,
        backgroundColor: "white",
        borderRadius: "0px",
        border: "0px solid black",
    }),
    menuPortal: (styles) => ({
        ...styles,
        backgroundColor: "white",
        borderRadius: 0,
        border: "0px solid black",
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: 0,
        height: 0,
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        backgroundColor: "var(--light-purple-gray)",
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        color: "white",
    }),
    clearIndicator: (styles) => ({
        ...styles,
        display: "none",
    }),
}


class Testing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            address: "",
            response: "nothing",
            selectArray: ([{value:'1',label:'One'},{value:'2',label:'Two'},{value:'3',label:'Three'},{value:'4',label:'Four'},{value:'5',label:'Five'}]),
            listOfCountries: ([{"value":1,"label":"ALL"},{"value":2,"label":"Afghanistan"},{"value":3,"label":"Aland Islands"}]),
            listOfSelectedCountries: ([]),
        }

    }

    componentDidMount(){
        this.loadData();
    }

    loadData = async () => {
        //Axios.get('/api/cont/get?tp=1&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage)+'&search='+this.props.searchText).then(response => {
        //    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
        //    this.setState({ peopleData: response.data.result })
        //});

        await Axios.get('/api/perm_cntry/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            var listOfCountries = [];
            for(let oneRow of response.data.result){ listOfCountries.push({"value":oneRow.id, "label": oneRow.descr}); }
            this.setState({ listOfCountries: listOfCountries })
        });

        this.getDataFromUser();
    }

    getDataFromUser = async () => {
        this.setState({ windowHeight: window.innerHeight });
        this.setState({ windowWidth: window.innerWidth });

        /*const response = await fetch('https://geolocation-db.com/json/');
        // country code, country name, city, longitude, latitude, IPv4 (for Jihlava it gives me that I am in Brno)
        const locationData = await response.json();
        this.setState({ locationData: JSON.stringify(locationData) });*/

        const response = await fetch('https://ipapi.co/json/');
        const locationData = await response.json();
        this.setState({ ipAddress: locationData.ip });
        this.setState({ city: locationData.city });
        this.setState({ region: locationData.region });
        this.setState({ country: locationData.country });
        this.setState({ organisation: locationData.org });
        //alert(JSON.stringify(locationData.ip));


        //var effectiveBandwidth = navigator.connection.downlink; // if faster than 10MBit it still shows 10MBit

        // informations about architecture, bitness, OS, OS version (not supported by Firefox and Safari)
        //var platformDetails = await navigator.userAgentData.getHighEntropyValues(["architecture", "platform", "platformVersion", "model", "bitness", "uaFullVersion"]);
        //alert(JSON.stringify(platformDetails));

    }

    onClick = async () => {
        /*var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {

            var myObject = JSON.parse(xhr.responseText);
            var array = myObject.results[0].address_components;

            for(let oneComponent of array){
                for(let oneType of oneComponent.types){
                    if(oneType == "administrative_area_level_1"){
                        this.setState({ response: oneComponent.long_name });
                    }
                }
            }
            //console.log(myObject.results[0]);

        });
        xhr.open('GET', 'https://maps.googleapis.com/maps/api/geocode/json?address='+this.state.address+'&key=AIzaSyCzFI1LkEV1yumNDTBf6_uBn3MnmgVFqyM');
        xhr.send();*/
        alert("action");
    }

    getSelected = () => {
        var list = "";

        for(let oneRow of this.state.listOfSelectedCountries){
            list = list + oneRow.label + ", ";
        }

        alert(list);
    }

    render() {
        return (
            <div>
                <span style={{fontSize: "30px"}}>Simple testing page</span>

                <br/><br/>

                <span><b>{"Browser: "}</b>{getBrowser(navigator.userAgent)}</span>
                <br/>
                <span><b>{"Window Height: "}</b>{this.state.windowHeight}{"px"}</span>
                <br/>
                <span><b>{"Window Width: "}</b>{this.state.windowWidth}{"px"}</span>
                <br/>
                <span><b>{"IP address: "}</b>{this.state.ipAddress}</span>
                <br/>
                <span><b>{"City: "}</b>{this.state.city}</span>
                <br/>
                <span><b>{"Region: "}</b>{this.state.region}</span>
                <br/>
                <span><b>{"Country: "}</b>{this.state.country}</span>
                <br/>
                <span><b>{"Provider: "}</b>{this.state.organisation}</span>
                <br/>
                <span><b>{"Device type: "}</b>{getDeviceType(navigator.userAgent)}</span>
                <br/>
                <span><b>{"Operating System: "}</b>{getOperatingSystem(navigator.userAgent)}</span>
                <br/>
                <span>{"* pro získání IP a lokalizace používám IPAPI kde je limit 1000 requestů za den (jinak se to platí) *"}</span>
                <br/>
                <span>{"* ale IPAPI je nejpřesnější, některé ostatní API mi ukazovali, že jsem v Brně, či v jiném městě *"}</span>

                <div class="horizontalStack" style={{margin: 10}}>
                    <Select
                        options={this.state.listOfCountries}
                        onChange={(e) => this.setState({ listOfSelectedCountries: e })}
                        isMulti
                        placeholder={""}
                        styles={customStyles}
                        className=""/>

                    <div style={{width: 50}}></div>

                    <button onClick={() => this.getSelected()}>
                        <span>{"Get selected"}</span>
                    </button>
                </div>


                {/*<br/>
                <span>{"Address:"}</span>
                <input type="text" id="address" name="address" value={this.state.address} onChange={e => this.setState({ address: e.target.value })} />
                <input type="submit" value="Search" onClick={() => this.onClick()} />
                <br/>
                <span>{"Response: "}{this.state.response}</span>*/}

                <div style={{ height: '300px', width: '500px' }}>
                    {/*<MapComponent />*/}
                </div>

                <div style={{ height: '300px', width: '500px' }}>
                    <MapComponent />
                </div>
            </div>
        );
    }
}

export default Testing;