import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

// assets
import icon_person_profile_bg from '../assets/icon_person_profile_bg.png';
import icon_person_profile_hover_bg from '../assets/icon_person_profile_hover_bg.png';
import icon_church_profile_bg from '../assets/icon_church_profile_bg.png';
import icon_church_profile_hover_bg from '../assets/icon_church_profile_hover_bg.png';

class ProfileImage extends React.Component {

    constructor(props) {
        super(props);
    }

    showInitialsBackground = (type) => {
        if(type == "person"){
            return <img className="avatarImage" src={icon_person_profile_bg}/>;
        }
        else if(type == "church"){
            return <img className="avatarImage" src={icon_church_profile_bg}/>;
        }
        else {
            return <img className="avatarImage" src={icon_person_profile_bg}/>;
        }
    }

    showInitialsHoverBackground = (type) => {
        if(type == "person"){
            return <img className="avatarImage" src={icon_person_profile_hover_bg}/>;
        }
        else if(type == "church"){
            return <img className="avatarImage" src={icon_church_profile_hover_bg}/>;
        }
        else {
            return <img className="avatarImage" src={icon_person_profile_hover_bg}/>;
        }
    }

    render(){
        return(
            <div>
                {/* Picture IS NOT saved && tile IS NOT opened */}
                { this.props.uploadedImageBase64 == "" && !this.props.toolOpened ? <div className="avatarHoverContainer">
                    <div className="avatarHoverVisible">
                        {/* image */}
                        {this.showInitialsBackground(this.props.contactType)}

                        {/* text */}
                        <div className="avatarHoverTextContainer verticalStack" style={{alignItems: "center", justifyContent: "center"}}>
                            <span className="fontPoppinsMedium60White">{this.props.initials}</span>
                        </div>
                    </div>
                    <div className="avatarHoverHidden">
                        {/* image */}
                        {this.showInitialsHoverBackground(this.props.contactType)}

                        {/* text */}
                        <div className="avatarHoverTextContainer verticalStack clickable" style={{alignItems: "center", justifyContent: "center"}} onClick={() => this.props.uploadImageClick()}>
                            <span className="fontPoppinsItalic13White" style={{textAlign: "center"}}>{"+Add a New"}</span>
                            <span className="fontPoppinsItalic13White" style={{textAlign: "center"}}>{"Profile Image"}</span>
                        </div>
                    </div>
                </div> : null }


                {/* Picture IS NOT saved && tile IS opened */}
                { this.props.uploadedImageBase64 == "" && this.props.toolOpened ? <div className="avatarHoverContainer">
                    <div className="avatarHoverVisible">
                        {/* image */}
                        {this.showInitialsBackground(this.props.contactType)}

                        {/* text */}
                        <div className="avatarHoverTextContainer verticalStack clickable" style={{alignItems: "center", justifyContent: "center"}}>
                            <span className="fontPoppinsItalic13Orange" style={{textAlign: "center"}}>{"+Add a New"}</span>
                            <span className="fontPoppinsItalic13Orange" style={{textAlign: "center"}}>{"Profile Image"}</span>
                        </div>
                    </div>
                    <div className="avatarHoverHidden">
                        {/* image */}
                        {this.showInitialsHoverBackground(this.props.contactType)}

                        {/* text */}
                        <div className="avatarHoverTextContainer verticalStack clickable" style={{alignItems: "center", justifyContent: "center"}} onClick={() => this.props.uploadImageClick()}>
                            <span className="fontPoppinsItalic13White" style={{textAlign: "center"}}>{"+Add a New"}</span>
                            <span className="fontPoppinsItalic13White" style={{textAlign: "center"}}>{"Profile Image"}</span>
                        </div>
                    </div>
                </div> : null }


                {/* Picture IS saved && tile IS NOT opened */}
                { this.props.uploadedImageBase64 != "" && !this.props.toolOpened ? <div className="avatarHoverContainer">
                    <div className="avatarHoverVisible">
                        {/* image */}
                        <img className="avatarImage" src={this.props.uploadedImageBase64}/>
                    </div>
                    <div className="avatarHoverHidden">
                        {/* image */}
                        <img className="avatarImage" src={this.props.uploadedImageBase64}/>
                        <div className="avatarImageOnHover"></div>

                        {/* text */}
                        <div className="avatarHoverTextContainer verticalStack" style={{alignItems: "center", justifyContent: "center"}}>

                            <div className="horizontalStackCenter">
                                <span className="fontPoppinsRegular13White clickable onHoverToBlack" onClick={() => this.props.uploadImageClick()}>{"Change"}</span>
                                <span className="fontPoppinsRegular13White">&nbsp;{"/"}&nbsp;</span>
                                <span className="fontPoppinsRegular13White clickable onHoverToBlack" onClick={() => this.props.deleteImageClick()}>{"Delete"}</span>
                            </div>
                            <span className="fontPoppinsRegular13White" style={{textAlign: "center"}}>{"Profile Image"}</span>
                        </div>
                    </div>
                </div> : null }


                {/* Picture IS saved && tile IS opened */}
                { this.props.uploadedImageBase64 != "" && this.props.toolOpened ? <div className="avatarHoverContainer">
                    <div className="avatarHoverVisible">
                        {/* image */}
                        <img className="avatarImage" src={this.props.uploadedImageBase64}/>
                        <div className="avatarImageOnHover" style={{backgroundColor: "black", opacity: 0.6}}></div>

                        {/* text */}
                        <div className="avatarHoverTextContainer verticalStack" style={{alignItems: "center", justifyContent: "center"}}>

                            <div className="horizontalStackCenter">
                                <span className="fontPoppinsRegular13White">{"Change"}</span>
                                <span className="fontPoppinsRegular13White">&nbsp;{"/"}&nbsp;</span>
                                <span className="fontPoppinsRegular13White">{"Delete"}</span>
                            </div>
                            <span className="fontPoppinsRegular13White" style={{textAlign: "center"}}>{"Profile Image"}</span>
                        </div>

                    </div>
                    <div className="avatarHoverHidden">
                        {/* image */}
                        <img className="avatarImage" src={this.props.uploadedImageBase64}/>
                        <div className="avatarImageOnHover"></div>

                        {/* text */}
                        <div className="avatarHoverTextContainer verticalStack" style={{alignItems: "center", justifyContent: "center"}}>

                            <div className="horizontalStackCenter">
                                <span className="fontPoppinsRegular13White clickable onHoverToBlack" onClick={() => this.props.uploadImageClick()}>{"Change"}</span>
                                <span className="fontPoppinsRegular13White">&nbsp;{"/"}&nbsp;</span>
                                <span className="fontPoppinsRegular13White clickable onHoverToBlack" onClick={() => this.props.deleteImageClick()}>{"Delete"}</span>
                            </div>
                            <span className="fontPoppinsRegular13White" style={{textAlign: "center"}}>{"Profile Image"}</span>
                        </div>
                    </div>
                </div> : null }
            </div>
        );
	}
}

export default ProfileImage;