import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Resizer from "react-image-file-resizer";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// import assets
import { Link } from 'react-router-dom';
import church_avatar from '../assets/church_avatar.png';
import background from '../assets/background.jpg';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';
import icon_done_white from '../assets/icon_done_white.png';

// import components
import PopupButtonDescription from '../components/PopupButtonDescription.js';
import ExpandButton from '../components/ExpandButton.js';
import ProfileImage from '../components/ProfileImage.js';

class ProfileDenominationTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            mainContactId: 0,
            updateExisting: true, // if I am in create new contact mode it would be false
            isMyContact: false, // default should be false
            toolOpened: false,
            dataCategory: "name", // name, contact, ministry_info
            actualProfile: ([{id:1,first_name:"",last_name:"",email:"",phone:"",church:"Error",city:"",country:"Error",nickname:""}]),
            fullAddress: "",
            listOfCountries: ([{"id":1,"label":"ALL"},{"id":2,"label":"Afghanistan"},{"id":3,"label":"Aland Islands"}]),
            array_email_types: ([{id:1, label: "Loading..."}]),
            array_phone_locations: ([{id:1, label: "Loading..."}]),
            array_website_type: ([{id:1, label: "Loading..."}]),
            array_social_media_platform: ([{id:1, label: "Loading..."}]),
            uploadedImageBase64: "",
        }

        this.hiddenFileInput = React.createRef();
    }

    componentDidMount(){
        //const queryParams = new URLSearchParams(window.location.search);
        //const id = queryParams.get('id');
        this.loadData();
    }

    loadData = async () => {
        this.setState({ uploadedImageBase64: "" });

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            if(Number(id) == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/Churches"); window.location.reload(); }
                return;
            }
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_cont });
                }
            });
            this.setState({ updateExisting: true });
            this.setState({ mainContactId: id });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=avatar&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });
        }
        else {
            this.setState({ updateExisting: false });
            this.setState({ toolOpened: true });
        }

        // get all countries for country select
        Axios.get('/api/cntry/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfCountries: response.data.result })
        });

        // get email types
        Axios.get('/api/email_tp/get?cont_tp=3').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_email_types: response.data.result })
        });

        // get phone locations
        Axios.get('/api/phone_tp/get?cont_tp=3').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_phone_locations: response.data.result })
        });

        // get website types
        Axios.get('/api/website_tp/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_website_type: response.data.result })
        });

        // get social media platforms
        Axios.get('/api/platform/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_social_media_platform: response.data.result })
        });
    }

    openCloseTool = () => {
        if(this.state.toolOpened){
            this.setState({ toolOpened: false });
            this.props.onCloseOrOpen("");
        }
        else {
            this.setState({ toolOpened: true });
            this.props.onCloseOrOpen("profiledata");
        }
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_cont/del/', {
                data: {ContId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_cont/ins/', {
                ContId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    changeDataCategoryToName = () => { this.setState({ dataCategory: "name" }); }
    changeDataCategoryToContact = () => { this.setState({ dataCategory: "contact" }); }
    changeDataCategoryToMinistry = () => { this.setState({ dataCategory: "ministry_info" }); }

    changeCountryNameById = (id) => {
        var countryName = "";

        // find name of country by country_id
        this.state.listOfCountries.forEach((row)=>{
            if(row.id == id){
                countryName = row.label;
            }
        })

        // change country name in the object
        this.setState(prevState => {
            let actualProfile = Object.assign({}, prevState.actualProfile);
            actualProfile[0].country_id = id;
            actualProfile[0].country = countryName;
            return {actualProfile}; }
        )

        // delete selected region (because country was changed)
        this.setState(prevState => {
            let actualProfile = Object.assign({}, prevState.actualProfile);
            actualProfile[0].region_id = "";
            return {actualProfile};
        });
        this.changeRegionNameById(-1);

        this.loadRegionsForCountry();
    }

    nameSectionWasEdited = (actualProfile) => {
        if((typeof(actualProfile.org_name) === "undefined" || actualProfile.org_name == "")
        && (typeof(actualProfile.short_name) === "undefined" || actualProfile.short_name == "")){
            return false;
        }
        else {
            return true;
        }
    }

    contactSectionWasEdited = (actualProfile, fullAddress) => {
        if((typeof(actualProfile.email) === "undefined" || actualProfile.email == "")
        && (typeof(actualProfile.phone) === "undefined" || actualProfile.phone == "")){
            return false;
        }
        else {
            return true;
        }
    }

    ministrySectionWasEdited = (actualProfile) => {
        if((typeof(actualProfile.country_id) === "undefined" || actualProfile.country_id == 0)){
            return false;
        }
        else {
            return true;
        }
    }

    /* START OF METHODS FOR AVATAR IMAGE */

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    uploadImageClick = () => { this.hiddenFileInput.current.click(); };

    deleteProfileImage = () => {
        Axios.post('/api/file/delete', {
            FilePrefix: "avatar",
            ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ uploadedImageBase64: "" });
        });
    }

    imageIsSelected = async (event) => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded.size > 5000000){
            this.props.openAlertMessage("Error! Maximum size of picture is 5 MB.","OK");
            return;
        }
        var base64 = await this.toBase64(fileUploaded);
        var prefix = fileUploaded.name.substring(fileUploaded.name.lastIndexOf(".")+1);
        //this.setState({ uploadedImageBase64: base64 });

        try {
            Resizer.imageFileResizer(fileUploaded, 300, 300, "JPEG", 100, 0,
                (uri) => {
                    //console.log(uri);
                    this.setState({ uploadedImageBase64: uri });
                    if(this.state.updateExisting){
                        this.uploadAvatarImage(this.state.mainContactId);
                    }
                },
                "base64", 200,  200
            );
        }
        catch (err) {
            //console.log(err);
            this.props.openAlertMessage("Error while uploading image.","OK");
        }
        //var improvedBase64 = base64.substring(base64.lastIndexOf(";base64,")+8);
    };

    uploadAvatarImage = (contactId) => {
        if(this.state.uploadedImageBase64 == "")return;
        Axios.post('/api/file/upload', {
            FilePrefix: "avatar",
            ContId: contactId,
            content: this.state.uploadedImageBase64,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            //alert("Avatar image was uploaded.");
        });
    }

    /* END OF METHODS FOR AVATAR IMAGE */

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div className="profileTile">
                <div className="flex" style={{flex: 1, height: 203, position: "relative"}}>

                    <div style={{width: "calc(100% + 40px)", backgroundColor: "var(--full-black)", height: "calc(50% + 20px)", position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                        <img style={{width: "100%", height: "100%", borderRadius: "25px 25px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                    </div>

                    <div className="horizontalStack" style={{height: "100%"}}>

                        <div className="verticalStack" style={{zIndex: "1"}}>

                            { this.state.isMyContact == false ? <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add to My Contacts"} blackArrow={false} />
                                </div>
                            </div> :
                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Remove from My Contacts"} blackArrow={false} />
                                </div>
                            </div> }

                            <div className="flex"></div>

                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <ExpandButton isOpen={this.state.toolOpened} style={{marginTop: 5}} onClick={this.openCloseTool} />
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add and Edit Contact Info"} blackArrow={true} />
                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{zIndex: "0", marginLeft: "-10px", marginRight: "23px"}}>
                            <div className="flex"></div>

                            <ProfileImage
                                initials={getInitials(this.state.actualProfile[0].org_name)}
                                uploadedImageBase64={this.state.uploadedImageBase64}
                                toolOpened={this.state.toolOpened}
                                uploadImageClick={this.uploadImageClick}
                                deleteImageClick={this.deleteProfileImage}
                                contactType={"church"}
                            />

                            <input type="file"
                                ref={this.hiddenFileInput}
                                style={{display:'none'}}
                                accept={"image/png, image/jpg, image/jpeg"}
                                onChange={(event) => this.imageIsSelected(event)}
                            />

                            <div className="flex"></div>
                        </div>

                        <div className="verticalStack flex" style={{zIndex: "0", height: "calc(100% + 20px)"}}>
                            <div className="verticalStackCenter flex" style={{height: "100%", marginTop: "-20px"}}>
                                <div className="horizontalStack" style={{height: "100%"}}>
                                    <div className="verticalStackCenter" style={{flex: 4, minWidth: 50, height: "100%"}}>
                                        <div className="flex"></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsMedium40White responsiveThreeDots"><b>{this.state.actualProfile[0].org_name}</b></span>
                                        </div>
                                        <div className="flex"></div>
                                    </div>
                                    <div style={{width: 20}}></div>
                                    <div className="verticalStackCenter" style={{flex: 3, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold15 responsiveThreeDots" style={{color: "var(--jv-orange)"}}>{this.state.actualProfile[0].website != null ? this.state.actualProfile[0].website : "website not saved"}</span>
                                        </div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13 responsiveThreeDots" style={{color: "var(--white)"}}>facebook.com/denomination_name</span>
                                        </div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13 responsiveThreeDots" style={{color: "var(--white)"}}>instagram.com/denomination_name</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="verticalStackCenter flex">
                                <div className="horizontalStack" style={{height: "100%"}}>

                                    <div className="verticalStackCenter" style={{flex: 3, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{"Short Name: "}{this.state.actualProfile[0].short_name}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].country}</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].phone != null ? this.state.actualProfile[0].phone : "-"}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].email != null ? this.state.actualProfile[0].email : "-"}</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].website != null ? this.state.actualProfile[0].website : "-"}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">Denomination</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 2, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold13Gray responsiveThreeDots">{"-"}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold13DarkGray responsiveThreeDots">{"-"}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Editing data part */}

                <div className="flex" style={{flex: 1, position: "relative", display: this.state.toolOpened ? "inline" : "none"}}>

                    <div className="verticalStack" style={{marginTop: 20, marginBottom: 20}}>

                        {/* Name */}

                        <div className="horizontalStackCenter clickable" style={{height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={this.changeDataCategoryToName}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.dataCategory == "name" ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Name*</span> : null }
                            { !this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Name*</span> : null }
                        </div>

                        <div className="horizontalStack" style={{display: this.state.dataCategory == "name" ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                            <div className="verticalStack" style={{flex: 2, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Full Name:*</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputFullName" name="inputFullName" value={this.state.actualProfile[0].org_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].org_name = e.target.value; return {actualProfile}; })}/>
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Short Name:*</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputShortName" name="inputShortName" value={this.state.actualProfile[0].short_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].short_name = e.target.value; return {actualProfile}; })}/>
                            </div>

                        </div>

                        {/* Contact details */}

                        <div className="horizontalStackCenter clickable" style={{marginTop: 1, height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={this.changeDataCategoryToContact}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.dataCategory == "contact" ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.contactSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Contact Details</span> : null }
                            { !this.contactSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Contact Details</span> : null }
                        </div>

                        <div className="horizontalStack" style={{display: this.state.dataCategory == "contact" ? "" : "none",width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)"}}>

                            <div className="flex">
                                <table style={{width: "100%"}}>
                                    <tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Email:</span>
                                            {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Type:</span>
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            <span className="fontPoppinsRegular13">Primary</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStackCenter" style={{height: "100%"}}>
                                            <input className="profileTileInputText flex" type="text" id="inputEmail1" name="inputEmail1" value={this.state.actualProfile[0].email} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].email = e.target.value; return {actualProfile}; })} />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" name="title2" id="title2" style={{width: "100%"}}>
                                                <option value=""></option>
                                                {this.state.array_email_types.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioEmail" name="radioEmail" value="kk" checked disabled/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStackCenter" style={{height: "100%"}}>
                                            <input className="profileTileInputText flex" type="text" id="inputEmail1" name="inputEmail1" />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" name="title2" id="title2" style={{width: "100%"}}>
                                                <option value=""></option>
                                                {this.state.array_email_types.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioEmail" name="radioEmail" value="kk" disabled/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Website:</span>
                                            {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Type:</span>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="inputEmail1" name="inputEmail1" value={this.state.actualProfile[0].website} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].website = e.target.value; return {actualProfile}; })} />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" name="title2" id="title2" style={{width: "100%"}}>
                                                <option value=""></option>
                                                {this.state.array_website_type.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="inputEmail1" name="inputEmail1" />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" name="title2" id="title2" style={{width: "100%"}}>
                                                <option value=""></option>
                                                {this.state.array_website_type.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div className="verticalStack flex">
                                <table style={{width: "100%"}}>
                                    <tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Phone:</span>
                                            {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Location:</span>
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            <span className="fontPoppinsRegular13">Primary</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="inputPhone1" name="inputPhone1" value={this.state.actualProfile[0].phone} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].phone = e.target.value; return {actualProfile}; })} />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" name="title2" id="title2" style={{width: "100%"}}>
                                                <option value=""></option>
                                                {this.state.array_phone_locations.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioPhone" name="radioPhone" value="kk" checked disabled/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="inputPhone1" name="inputPhone1" />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" name="title2" id="title2" style={{width: "100%"}}>
                                                <option value=""></option>
                                                {this.state.array_phone_locations.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioPhone" name="radioPhone" value="kk" disabled/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Social Media:</span>
                                            {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Platform:</span>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text"/>
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" name="title2" id="title2" style={{width: "100%"}}>
                                                <option value=""></option>
                                                {this.state.array_social_media_platform.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text"/>
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" name="title2" id="title2" style={{width: "100%"}}>
                                                <option value=""></option>
                                                {this.state.array_social_media_platform.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>

                        {/* Ministry */}

                        <div className="horizontalStackCenter clickable" style={{marginTop: 1, height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={this.changeDataCategoryToMinistry}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.dataCategory == "ministry_info" ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.ministrySectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Ministry Information</span> : null }
                            { !this.ministrySectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Ministry Information</span> : null }
                        </div>

                        <div className="verticalStack" style={{display: this.state.dataCategory == "ministry_info" ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)"}}>

                            <div className="horizontalStack">
                                <div className="verticalStack" style={{flex: 1, marginLeft: 0}}>
                                    <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Country:</span>
                                    <select className="profileTileInputText topMenuSelectBox" name="country" id="country" value={this.state.actualProfile[0].country_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].country_id = e.target.value; this.changeCountryNameById(e.target.value); return {actualProfile}; })}>
                                        <option value=""></option>
                                        {this.state.listOfCountries.map(function (item) {
                                            return (
                                                <option value={item.id}>{item.label}</option>
                                            )
                                        }.bind(this))}
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(ProfileDenominationTile);
