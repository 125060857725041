import React, { useState, useEffect } from "react";
import '../App.css';
import { Link } from "react-router-dom";
import '../styles/menus.css';

// import components
import LeftMenuItemClosed from './LeftMenuItemClosed.js';

// import assets
import jv_logo_small from '../assets/jv_logo_small.png';
import conversations from '../assets/icon_conversations_gray.png';
import conversations_white from '../assets/icon_conversations_white.png';
import events from '../assets/icon_events_programs_gray.png';
import events_white from '../assets/icon_events_programs_white.png';
import people from '../assets/icon_people_gray.png';
import people_white from '../assets/icon_people_white.png';
import churches from '../assets/icon_churches_gray.png';
import churches_white from '../assets/icon_churches_white.png';
import schools from '../assets/icon_schools_gray.png';
import schools_white from '../assets/icon_schools_white.png';
import right from '../assets/icon_right.png';
import closeLeftMenuButton from '../assets/closeLeftMenuButton.png';
import discipling from '../assets/icon_discipling_gray.png';
import discipling_white from '../assets/icon_discipling_white.png';
import dashboard from '../assets/icon_dashboard.png';
import dashboard_white from '../assets/icon_dashboard_white.png';
import instructions from '../assets/icon_instructions.png';
import instructions_white from '../assets/icon_instructions_white.png';

function storageEventHandler() {
    this.setState({ actualPage: localStorage.getItem("actualPage") || "" });
}

class LeftMenu extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    isDashboardActive(actualSection){
        if(actualSection == "dashboard"){ return true; }
        else { return false; }
    }

    isInstructionsActive(actualSection){
        if(actualSection == "instructions"){ return true; }
        else { return false; }
    }

    isChurchesActive(actualSection){
        if(actualSection == "churches"){ return true; }
        else { return false; }
    }

    isSchoolsActive(actualSection){
        if(actualSection == "schools")return true;
        return false;
    }

    isPeopleActive(actualSection){
        if(actualSection == "people"){ return true; }
        else { return false; }
    }

    isDisciplingActive(actualSection){
        if(actualSection == "discipling"){ return true; }
        else { return false; }
    }

    isConversationsActive(actualSection){
        if(actualSection == "conversations"){ return true; }
        else { return false; }
    }

    isEventsActive(actualSection){
        if(actualSection == "events"){ return true; }
        else { return false; }
    }

    componentDidMount(){
        this.setState({ actualPage: localStorage.getItem("actualPage") || "" });
        window.addEventListener('storage', storageEventHandler, false);
    }

    render(){
        return(
            <div className="mainLeftMenuClosed">
                <div className="verticalStackCenter" style={{alignItems: "center"}}>
                    <Link className="link" to={"./"} >
                        <img style={{width: "70px"}} src={jv_logo_small}/>
                    </Link>

                    <div className="verticalStack hideVerticalScrollBar" style={{alignItems: "center", position: "relative", overflowY: "scroll", height: "calc(100vh - 27px - 35px - 17px - 10px - 60px)"}}>

                        <div style={{marginTop: "45px"}}>
                            <span className="menuLeftSectionTitle">{"OVERVIEW"}</span>
                        </div>

                        <div onClick={() => this.setActualSection("instructions")}>
                            <LeftMenuItemClosed title={"Instructions"} link="./Instructions" icon={this.isInstructionsActive(this.props.actualSection) ? instructions_white : instructions} active={this.isInstructionsActive(this.props.actualSection)} iconStyle={{height: 23, marginTop: 1}} />
                        </div>

                        <div onClick={() => this.setActualSection("dashboard")}>
                            <LeftMenuItemClosed title={"Dashboard"} link="./" icon={this.isDashboardActive(this.props.actualSection) ? dashboard_white : dashboard} active={this.isDashboardActive(this.props.actualSection)} iconStyle={{height: 21, marginTop: 1}} />
                        </div>

                        <div style={{marginTop: "38px"}}>
                            <span className="menuLeftSectionTitle">{"MINISTRY"}</span>
                        </div>

                        <div onClick={() => this.setActualSection("conversations")}>
                            <LeftMenuItemClosed title={"My Conversations - CLOSED"} link="./MyConversations" icon={this.isConversationsActive(this.props.actualSection) ? conversations_white : conversations} active={this.isConversationsActive(this.props.actualSection)} iconStyle={{height: 17, marginLeft: 0}} />
                        </div>

                        <div onClick={() => this.setActualSection("events")}>
                            <LeftMenuItemClosed title={"My Events & Programs"} link="./" icon={this.isEventsActive(this.props.actualSection) ? events_white : events} active={this.isEventsActive(this.props.actualSection)} iconStyle={{height: 17, marginLeft: 0, marginRight: 2}} />
                        </div>

                        <div onClick={() => this.setActualSection("discipling")}>
                            <LeftMenuItemClosed title={"My Disciple-Making"} link="./Discipling" icon={this.isDisciplingActive(this.props.actualSection) ? discipling_white : discipling} active={this.isDisciplingActive(this.props.actualSection)} iconStyle={{height: 16, marginLeft: 0}} />
                        </div>

                        <div style={{marginTop: "38px"}}>
                            <span className="menuLeftSectionTitle">{"CONTACTS"}</span>
                        </div>

                        <div onClick={() => this.setActualSection("people")}>
                            <LeftMenuItemClosed title={"My People"} link="./People" icon={this.isPeopleActive(this.props.actualSection) ? people_white : people} active={this.isPeopleActive(this.props.actualSection)} iconStyle={{height: 20, marginLeft: 0}}/>
                        </div>

                        <div onClick={() => this.setActualSection("churches")}>
                            <LeftMenuItemClosed title={"My Churches"} link="./Churches" icon={this.isChurchesActive(this.props.actualSection) ? churches_white : churches} active={this.isChurchesActive(this.props.actualSection)} iconStyle={{height: 18, marginLeft: 0, marginTop: -2}} />
                        </div>

                        {/*<div onClick={() => this.setActualSection("schools")}>
                            <LeftMenuItemClosed title={"My Schools"} link="./Schools" icon={this.isSchoolsActive(this.props.actualSection) ? schools_white : schools} active={this.isSchoolsActive(this.props.actualSection)} iconStyle={{height: 18, marginLeft: 0, marginTop: -2}} />
                        </div>*/}

                        <div onClick={() => this.props.setLeftMenuOpened("true")}>
                            <div className="verticalStackCenter" style={{alignItems: "center"}}>
                                <div className="openLeftMenuButton"></div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
	}
}

export default LeftMenu;