import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import MapComponent from '../components/MapComponent.js';

// import assets
import jv_logo_sign_in from '../assets/jv_logo_sign_in.png';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getDeviceType, getOperatingSystem, getBrowser } from '../utilities/DeviceDataUtilities.js';

import ChangePasswordModalMobile from '../modals_m/ChangePasswordModalMobile.js';
import AlertModalMessageTwoButtonMobile from '../modals_m/AlertModalMessageTwoButtonMobile.js';
import AlertModalMessageMobile from '../modals_m/AlertModalMessageMobile.js';


class RecoverPasswordPageMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            prepareToShowModalChangePassword: false,
            showModalChangePassword: false,
            prepareToShowAlertModalMessageTwoButton: false,
            showAlertModalMessageTwoButton: false,
            prepareToShowAlertModalMessage: false,
            showAlertModalMessage: false,
            lostToken: "",
            alertMessage: "Message",
            alertCloseButton: "OK",
            alertActionButton: "OK",
            alertOnAction: {},
        }

    }

    componentDidMount(){
        this.loadData();
    }

    loadData = async () => {
        const { reset_password_token } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(reset_password_token != undefined){
            // check validity of token
            await Axios.get('/api/usr/reset_token/check/'+reset_password_token).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

                if(response.data.msg.code == "err_reset_invalid"){
                    this.openAlertMessage("This reset link is no longer valid. Please request a new one and try again.","OK");
                }
                else {
                    this.setState({ lostToken: reset_password_token });
                    this.openModal("ChangePassword");
                }
            });

        }else {
            this.openAlertMessage("Error. Please contact our support.","OK");
        }

    }

    openModal = async (modalWindow) => {
        if(modalWindow == "ChangePassword"){
            await this.setState({ prepareToShowModalChangePassword: true });
            this.setState({ showModalChangePassword: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "ChangePassword"){
            this.setState({ showModalChangePassword: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalChangePassword: false });
        }
    }

    openAlertMessageTwoActions = async (message, closeButton, actionButton, onAction) => {
        if((actionButton == null || actionButton == "") && onAction == null){
            this.openAlertMessage(message,closeButton);
        }
        else {
            await this.setState({ alertMessage: message });
            await this.setState({ alertCloseButton: closeButton });
            await this.setState({ alertActionButton: actionButton });
            await this.setState({ alertOnAction: onAction });
            await this.setState({ prepareToShowAlertModalMessageTwoButton: true });
            this.setState({ showAlertModalMessageTwoButton: true });
        }
    }

    openAlertMessage = async (message, closeButton) => {
        await this.setState({ alertMessage: message });
        await this.setState({ alertCloseButton: closeButton });
        await this.setState({ prepareToShowAlertModalMessage: true });
        this.setState({ showAlertModalMessage: true });
    }

    closeAlertMessage = async () => {
        this.setState({ showAlertModalMessage: false });
        this.setState({ showAlertModalMessageTwoButton: false });
        await new Promise(resolve => setTimeout(resolve, 1000));
        await this.setState({ prepareToShowAlertModalMessage: false });
        await this.setState({ prepareToShowAlertModalMessageTwoButton: false });
        // on this page all alert messages are "error messages" so after closing them it takes user to login page
        if(!this.state.showModalChangePassword){ // do not redirect if ModalChangePassword is shown
            window.location.href = "/";
        }
    }

    render() {
        return (
            <div className="loginPage">
                <div className="verticalStack">
                    <center>
                        <img style={{maxWidth: "50%", minWidth: "350px", paddingRight: "10px"}} src={jv_logo_sign_in}/>
                    </center>
                </div>

                {this.state.prepareToShowModalChangePassword ? <ChangePasswordModalMobile showModal={this.state.showModalChangePassword} closeModal={this.closeModal} openModal={this.openModal} actionType={"ChangePassword"} openAlertMessageTwoActions={this.openAlertMessageTwoActions} lostToken={this.state.lostToken} /> : null }
                {this.state.prepareToShowAlertModalMessage ? <AlertModalMessageMobile showModal={this.state.showAlertModalMessage} closeModal={this.closeAlertMessage} message={this.state.alertMessage} closeButton={this.state.alertCloseButton} /> : null }
                {this.state.prepareToShowAlertModalMessageTwoButton ? <AlertModalMessageTwoButtonMobile showModal={this.state.showAlertModalMessageTwoButton} closeModal={this.closeAlertMessage} message={this.state.alertMessage} closeButton={this.state.alertCloseButton} actionButton={this.state.alertActionButton} onActionClick={this.state.alertOnAction} /> : null }

            </div>
        );
    }
}

export default RecoverPasswordPageMobile;