import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/modals.css';

// components
import AlertModal from '../modals/AlertModal';

class AlertModalMessageTwoButton extends React.Component {

    constructor(props) {
        super(props);
    }

	render(){
		return(
            <React.Fragment>
                <AlertModal show={this.props.showModal}>
                    <div className="content">

                        <div className="verticalStack">

                            <span className="fontPoppinsMedium20Orange" style={{textAlign: "center", paddingLeft: 30, paddingRight: 30}}>{this.props.message}</span>

                            <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 15, marginBottom: 15, marginLeft: -20, marginRight: -20}}></div>

                            <div className="horizontalStack" style={{height: 60, alignItems: "center", borderRadius: "0px 0px 20px 20px", marginLeft: -20, marginRight: -20, marginBottom: -20, marginTop: -20}}>

                                <div className="flex horizontalStackCenter clickable alertMessageButtonOnHoverTextToRed" style={{height: 60}} onClick={() => {this.props.closeModal();this.props.onActionClick()}}>
                                    <span className="fontPoppinsMedium20Gray" style={{flex: 1, textAlign: "center"}}>{this.props.actionButton}</span>
                                </div>

                                <div style={{width: 1, marginTop: 0, height: 60, backgroundColor: "var(--border-light-gray)"}}></div>

                                <div className="flex horizontalStackCenter clickable alertMessageButtonOnHoverTextToBlack" style={{height: 60}} onClick={() => this.props.closeModal()}>
                                    <span className="fontPoppinsMedium20Gray" style={{flex: 1, textAlign: "center"}}>{this.props.closeButton}</span>
                                </div>

                            </div>
                        </div>

                    </div>
                </AlertModal>
            </React.Fragment>
		);
	}
}

export default AlertModalMessageTwoButton;