import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

// utilities
import { getInitials } from '../utilities/OtherUtilities.js';

class ProfileImageSmall extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div>
                {/* Picture received */}
                { this.props.uploadedImageBase64 == "" && this.props.size ?
                    <div className="myPeopleAvatarImage avatarImageInitials" style={{width: this.props.size, height: this.props.size, borderRadius: this.props.size}}>
                        <span className="fontPoppinsMedium13White" style={{color: "white"}}>{getInitials(this.props.name)}</span>
                    </div>
                : null }
                { this.props.uploadedImageBase64 == "" && !this.props.size ?
                    <div className="myPeopleAvatarImage avatarImageInitials">
                        <span className="fontPoppinsMedium13White" style={{color: "white"}}>{getInitials(this.props.name)}</span>
                    </div>
                : null }

                {/* Picture NOT received */}
                { this.props.uploadedImageBase64 != "" && this.props.size ?
                    <img className="myPeopleAvatarImage avatarImageInitials" style={{width: this.props.size, height: this.props.size, borderRadius: this.props.size}} src={this.props.uploadedImageBase64}/>
                : null }
                { this.props.uploadedImageBase64 != "" && !this.props.size ?
                    <img className="myPeopleAvatarImage avatarImageInitials" src={this.props.uploadedImageBase64}/>
                : null }

            </div>
        );
	}
}

export default ProfileImageSmall;