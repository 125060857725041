import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials, showSortIcon } from '../utilities/OtherUtilities.js';

// import assets
import icon_message from '../assets/icon_message.png';
import icon_action_down from '../assets/icon_action_down.png';
import person_avatar from '../assets/person_avatar.png';

// import components
import ProfileImageSmall from '../components/ProfileImageSmall.js';

class QuickTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            selectedItems: [],
            showPopupTableActions: false,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
    }

    openProfilePage = (event, id, cont_type, is_private, open_tile) => {
        var openTileText = "";
        if(open_tile != ""){
            openTileText = "?tile="+open_tile;
        }

        if(cont_type == 4){
            if(event.ctrlKey || event.metaKey){ window.open("/ChurchProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/ChurchProfile/"+id+""+openTileText }); }
        }
        else if(cont_type == 5){
            if(event.ctrlKey || event.metaKey){ window.open("/DenominationProfile/"+id, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/DenominationProfile/"+id }); }
        }
        else if(cont_type == 6){ // team
            if(event.ctrlKey || event.metaKey){ window.open("/TeamProfile/"+id, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/TeamProfile/"+id }); }
        }
        else if(cont_type == 7){ // school
            if(event.ctrlKey || event.metaKey){ window.open("/SchoolProfile/"+id, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/SchoolProfile/"+id }); }
        }
        else if(cont_type == 1){

            if(is_private == 1){
                if(event.ctrlKey || event.metaKey){ window.open("/PeoplePrivateProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
                else { this.setState({ redirectTo: "/PeoplePrivateProfile/"+id+""+openTileText }); }
            }
            else {
                if(event.ctrlKey || event.metaKey){ window.open("/PeopleProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
                else { this.setState({ redirectTo: "/PeopleProfile/"+id+""+openTileText }); }
            }
        }
        else {
            this.props.openAlertMessage("Error.","OK");
        }
    }

    openConversationsOfContact = (event, id, cont_type, is_private) => {
        this.openProfilePage(event, id, cont_type, is_private, "conversations");
    }

    selectUnselectItem = (id) => {
        if(this.isRowSelected(id)){
            // delete
            let remainingItems = this.state.selectedItems.filter((item) => {return item !== id});
            this.setState({ selectedItems: remainingItems });
        }
        else {
            // add
            this.setState({
                selectedItems: [...this.state.selectedItems, id]
            })
        }
    }

    isRowSelected = (id) => {
        for(let oneRow of this.state.selectedItems){
            if(oneRow == id){
                return true;
            }
        }
        return false;
    }

    tableActionSelectAll = () => {
        var workArray = [];
        for(let oneRow of this.props.resultData){
            workArray.push(oneRow.id);
        }
        this.setState({ selectedItems: workArray });
    }

    tableActionDeselectAll = () => {
        this.setState({ selectedItems: [] });
    }

    addSelectedToMyContacts = () => {
        if(this.state.selectedItems.length <= 0){ return; }

        Axios.post('/api/my_cont/ins/', {
            ContId: this.state.selectedItems,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.openAlertMessage("Contacts were added to \"My Contacts\".","OK");
            this.props.loadData();
        });
    }

    removeSelectedFromMyContacts = () => {
        if(this.state.selectedItems.length <= 0){ return; }

        Axios.delete('/api/my_cont/del/', {
            data: {ContId: this.state.selectedItems},
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.openAlertMessage("Contacts were removed from \"My Contacts\".","OK");
            this.props.loadData();
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div style={{height: "calc(100% - 60px)", width: "100%"}}>
                <div className="myPeopleTile">
                    <div style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px - 80px)" : "calc(100% - 90px - 80px)", height: "calc(100% - 80px - 120px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}
                        <table className="myPeopleTable">
                            <tr>
                                <td className="myPeopleTableHeader myPeopleTableActionHeader">

                                    <div className="universalPopupSelectContainer">
                                        <div className="universalPopupSelectVisible" onClick={() => this.setState({ showPopupTableActions: !this.state.showPopupTableActions })}>
                                            <span>Action</span>
                                            <img className="myPeopleAvatarImage" style={{width: "10px", height: "6.3px"}} src={icon_action_down}/>
                                        </div>

                                        <div className="universalPopupSelectHidden" style={{display: this.state.showPopupTableActions ? "" : "none", position: "fixed", backgroundColor: "white", borderRadius: 20, height: "auto", marginLeft: -50, marginTop: -35, padding: 15, boxShadow: "0px 10px 20px var(--shadow-color)"}}>
                                            <div className="verticalStack" style={{alignItems: "flex-end"}}>
                                                <span className="fontPoppinsItalic13Orange" onClick={() => this.setState({ showPopupTableActions: false })}>Action</span>
                                                <div style={{width: "calc(100% + 30px)", height: 1, marginLeft: -15, marginRight: -15, backgroundColor: "var(--border-light-gray)", marginTop: 5, marginBottom: 10}}></div>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginBottom: 2}} onClick={() => this.tableActionSelectAll()}>Select All</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.tableActionDeselectAll()}>Deselect All</span>
                                                { this.props.allOrMy != "my" ? <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.addSelectedToMyContacts()}>Add to My Contacts</span> : null }
                                                { this.props.allOrMy == "my" ? <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.removeSelectedFromMyContacts()}>Remove from My Contacts</span> : null }
                                                {/*<span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Add Conversation</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Send Email</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>View on Map</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>View on Dashboard</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Merge</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2}} onClick={() => alert("This function is coming soon.")}>Export & Download</span>*/}
                                                <div className="flex"></div>
                                            </div>
                                        </div>

                                    </div>

                                </td>
                                <td className="myPeopleTableHeader myPeopleTableNameHeader" onClick={() => this.props.changeSorting("nm")}>Name {showSortIcon("nm",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("em")}>Email {showSortIcon("em",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("addr")}>Address {showSortIcon("addr",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("ph")}>Phone {showSortIcon("ph",this.props.sortBy,this.props.sortTp)}</td>
                            </tr>

                            {this.props.resultData.map(function (item, index) {
                                return (
                                    <tr key={index} className="myPeopleTableRow">

                                        <td className="myPeopleTableFirstFixedColumn">
                                            <div className="horizontalStackCenter centerAll">
                                                <input type="checkbox" checked={this.isRowSelected(item.id) ? "checked" : ""} onClick={() => this.selectUnselectItem(item.id)} />
                                                <div style={{width: "15px"}}></div>
                                                <div className="verticalStackCenter horizontalStackCenter myPeopleMessageButton" onClick={(event) => this.openConversationsOfContact(event, item.id, item.cont_tp_id, item.is_private)}>
                                                    <img className="myPeopleMessageImage" src={icon_message}/>
                                                </div>
                                            </div>
                                        </td>

                                        <td className="horizontalStackCenter myPeopleTableName myPeopleTableNameDiv myPeopleTableSecondFixedColumn" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}>
                                                {/* cell.row.original.avatar.default */}
                                                {/*<img className="myPeopleAvatarImage" src={item.avatar}/>*/}
                                                {/*<img className="myPeopleAvatarImage" src={person_avatar}/>*/}

                                                <ProfileImageSmall
                                                    name={item.rcg_name}
                                                    uploadedImageBase64={""}
                                                />

                                                {/*<Link to={`/PeopleProfile/${item.id}`}><span style={{paddingLeft: "25px"}}>{item.first_name} {item.last_name}</span></Link>*/}
                                                <span style={{paddingLeft: "25px", paddingRight: "25px"}}>{item.rcg_name}</span>
                                        </td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}>{item.email}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}>{item.city}{item.city == null || item.addr_country == null ? "" : ", "}{item.addr_country}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}>{item.phone}</td>
                                    </tr>
                                )
                            }.bind(this))}

                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuickTable;
