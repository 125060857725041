import React, { useState, useEffect } from "react";
import '../App.css';
import { Link } from "react-router-dom";
import '../styles/menus.css';

// import components
import LeftMenuItem from '../menus/LeftMenuItem.js';

// import assets
import jv_logo from '../assets/jv_logo.png';
import conversations from '../assets/icon_conversations_gray.png';
import conversations_white from '../assets/icon_conversations_white.png';
import events from '../assets/icon_events_programs_gray.png';
import events_white from '../assets/icon_events_programs_white.png';
import people from '../assets/icon_people_gray.png';
import people_white from '../assets/icon_people_white.png';
import churches from '../assets/icon_churches_gray.png';
import churches_white from '../assets/icon_churches_white.png';
import schools from '../assets/icon_schools_gray.png';
import schools_white from '../assets/icon_schools_white.png';
import discipling from '../assets/icon_discipling_gray.png';
import discipling_white from '../assets/icon_discipling_white.png';
import dashboard from '../assets/icon_dashboard.png';
import dashboard_white from '../assets/icon_dashboard_white.png';
import instructions from '../assets/icon_instructions.png';
import instructions_white from '../assets/icon_instructions_white.png';
import left from '../assets/icon_left.png';

class LeftMenuMobile extends React.Component {
    setActualSection = this.props.setActualSection;
    setLeftMenuOpened = this.props.setLeftMenuOpened;

    constructor(props) {
        super(props);
    }

    isDashboardActive(actualSection){
        if(actualSection == "dashboard")return true;
        return false;
    }

    isInstructionsActive(actualSection){
        if(actualSection == "instructions")return true;
        return false;
    }

    isChurchesActive(actualSection){
        if(actualSection == "churches")return true;
        return false;
    }

    isSchoolsActive(actualSection){
        if(actualSection == "schools")return true;
        return false;
    }

    isPeopleActive(actualSection){
        if(actualSection == "people")return true;
        return false;
    }

    isDisciplingActive(actualSection){
        if(actualSection == "discipling")return true;
        return false;
    }

    isConversationsActive(actualSection){
        if(actualSection == "conversations")return true;
        return false;
    }

    isEventsActive(actualSection){
        if(actualSection == "events")return true;
        return false;
    }

    render(){
        return(
            <div className="mainLeftMenu" style={{height: "var(--doc-height)"}}>
                <div className="verticalStackCenter" style={{alignItems: "center"}}>
                    <Link className="link" to={"./"} >
                        <img style={{width: "185px"}} src={jv_logo}/>
                    </Link>
                </div>

                <div className="verticalStack hideVerticalScrollBar" style={{position: "relative", overflowY: "scroll", marginTop: 10, height: "calc(var(--doc-height) - 60px - 35px - 25px)"}}>

                    <div className="flex"></div>
                    <div className="flex"></div>

                    <div onClick={() => {this.setLeftMenuOpened(false); this.setActualSection("instructions")}}>
                        <LeftMenuItem title={"Instructions"} link="./Instructions" icon={this.isInstructionsActive(this.props.actualSection) ? instructions_white : instructions} active={this.isInstructionsActive(this.props.actualSection)} iconStyle={{height: 23}} />
                    </div>

                    <div className="flex"></div>

                    <div onClick={() => {this.setLeftMenuOpened(false); this.setActualSection("conversations")}}>
                        <LeftMenuItem title={"My Conversations"} link="./MyConversations" icon={this.isConversationsActive(this.props.actualSection) ? conversations_white : conversations} active={this.isConversationsActive(this.props.actualSection)} iconStyle={{height: 17, marginLeft: 3}} />
                    </div>

                    <div className="flex"></div>

                    <div onClick={() => {this.setLeftMenuOpened(false); this.setActualSection("people")}}>
                        <LeftMenuItem title={this.props.allOrMy == "my" ? "My People" : "All People"} link="./People" icon={this.isPeopleActive(this.props.actualSection) ? people_white : people} active={this.isPeopleActive(this.props.actualSection)} iconStyle={{height: 20, marginLeft: 2}}/>
                    </div>

                    <div className="flex"></div>

                    <div onClick={() => {this.setLeftMenuOpened(false); this.setActualSection("churches")}}>
                        <LeftMenuItem title={this.props.allOrMy == "my" ? "My Churches" : "All Churches"} link="./Churches" icon={this.isChurchesActive(this.props.actualSection) ? churches_white : churches} active={this.isChurchesActive(this.props.actualSection)} iconStyle={{height: 18, marginLeft: 3}} />
                    </div>

                    {/*<div className="flex"></div>

                    <div onClick={() => {this.setLeftMenuOpened(false); this.setActualSection("schools")}}>
                        <LeftMenuItem title={this.props.allOrMy == "my" ? "My Schools" : "All Schools"} link="./Schools" icon={this.isSchoolsActive(this.props.actualSection) ? schools_white : schools} active={this.isSchoolsActive(this.props.actualSection)} iconStyle={{height: 18, marginLeft: 3}} />
                    </div>*/}

                    <div className="flex"></div>
                    <div className="flex"></div>
                </div>

                <div onClick={() => this.props.setLeftMenuOpened("false")}>
                    <div className="" style={{alignItems: "center", marginLeft: 10}}>
                        <div className="closeLeftMenuButtonMobile"></div>
                    </div>
                </div>
            </div>
        );
	}
}

export default LeftMenuMobile;