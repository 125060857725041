import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';

// import components
import AddUserModal from '../modals/AddUserModal.js';
import EditUserModal from '../modals/EditUserModal.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_add_white from '../assets/icon_add_white.png';

class Rights extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            usersPermArray: ([{}]), // id, usr_name, email, rcg_name, role, perm_id, perm_name
            countryArray: ([{id: 0, name: ""}]),
            userId: -1,
            prepareToShowModalAddUser: false,
            showModalAddUser: false,
            prepareToShowModalEditUser: false,
            showModalEditUser: false,
        }

    }

    componentDidMount(){
        this.loadData();
        this.setActualSection("rights");
    }

    loadData = () => {

        Axios.get('/api/perm/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            this.setState({ usersPermArray: response.data.result });
            //alert(JSON.stringify(response.data.result, null, 4));

        });

        Axios.get('/api/perm_cntry/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var countriesArray = [];
            for(let oneRow of response.data.result){ countriesArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ countryArray: countriesArray });
        });
    }


    saveChange = (user_id, country_id) => {
        Axios.post("/api/perm/upd/"+user_id, {
            PermId: country_id,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.loadData();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddUser"){
            await this.setState({ prepareToShowModalAddUser: true });
            this.setState({ showModalAddUser: true });
        }
        else if(modalWindow == "EditUser"){
            await this.setState({ prepareToShowModalEditUser: true });
            this.setState({ showModalEditUser: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "AddUser"){
            this.setState({ showModalAddUser: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddUser: false });
        }
        else if(modalWindow == "EditUser"){
            this.setState({ showModalEditUser: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalEditUser: false });
        }
        this.loadData();
    }

    editUserClick = async (userId) => {
        await this.setState({ userId: userId });
        this.openModal("EditUser");
    }

    deleteUserClick = async (userId) => {
        await this.setState({ userId: userId });
        this.props.openAlertMessageTwoActions("Are you sure you want to delete this user? This action is permanent!","Cancel","Delete",this.deleteUser);
    }

    deleteUser = () => {
        Axios.delete('/api/usr/del/'+this.state.userId, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.loadData();
        });
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>
                <div className="horizontalStackCenter myPeopleTableListingBar">
                    <span className="fontPoppinsRegular13Gray onHoverToOrange clickable">{"Rights and privileges"}</span>
                    <div className="flex"/>
                    <span className="fontPoppinsRegular13Gray onHoverToOrange clickable">{"Download PDF"}</span>
                </div>

                <div className="universalTile flex verticalStack" style={{marginRight: 20, marginLeft: 20, marginBottom: 20}}>
                    <div className="horizontalStack">
                        <div className="verticalStack flex">
                            <span className="fontPoppinsBold25">{"RIGHTS AND "}<span className="fontPoppinsBold25Orange">{"PRIVILEGES"}</span></span>
                            <span className="fontPoppinsSemiBold13" style={{width: "75%"}}>{"You have rights of admin and you can change what users have access to what rights."}</span>
                        </div>

                        <div className="circleButtonContainer circleButtonContainerOrange clickable" onClick={() => this.openModal("AddUser")}>
                            <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                        </div>
                    </div>
                    <br/>

                    <table className="rolesTable">

                        <tr>
                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Name</td>
                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Username</td>
                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Email</td>
                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Role</td>
                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Has access to</td>
                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Change country:</td>
                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Actions</td>
                        </tr>

                        {this.state.usersPermArray.map(function (itemUser) {
                            return (
                                <tr>
                                    <td className="rolesTableGeneralDiv" nowrap="nowrap">{itemUser.rcg_name}</td>
                                    <td className="rolesTableGeneralDiv" nowrap="nowrap">{itemUser.usr_name}</td>
                                    <td className="rolesTableGeneralDiv">
                                        <div className="responsiveThreeDotsContainer flex">
                                            <span className="fontPoppinsRegular13 responsiveThreeDots">{itemUser.email}</span>
                                        </div>
                                    </td>
                                    <td className="rolesTableGeneralDiv" nowrap="nowrap">{itemUser.role}</td>
                                    <td className="rolesTableGeneralDiv" nowrap="nowrap">{itemUser.perm_name}</td>
                                    <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                        <select className="profileTileInputText topMenuSelectBox" name={"countrySelect"+itemUser.id} id={"countrySelect"+itemUser.id} style={{width: "100%", backgroundColor: "#d5d0e1", border: "0px solid black"}} onChange={e => this.saveChange(itemUser.id, e.target.value)}>
                                            <option value=""></option>
                                            {this.state.countryArray.map(function (item) {
                                                return ( <option value={item.id} selected={item.id == itemUser.perm_id ? "selected" : ""}>{item.name}</option> )
                                            }.bind(this))}
                                        </select>
                                    </td>
                                    <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                        <span className="fontPoppinsRegular13 clickable onHoverToOrange" onClick={() => this.editUserClick(itemUser.id)}>{"Edit"}</span>
                                        <div style={{width: 10}}></div>
                                        <span className="fontPoppinsRegular13 clickable onHoverToOrange" onClick={() => this.deleteUserClick(itemUser.id)}>{"Delete"}</span>
                                    </td>
                                </tr>
                            )
                        }.bind(this))}
                    </table>

                </div>

                {this.state.prepareToShowModalAddUser ? <AddUserModal showModal={this.state.showModalAddUser} closeModal={this.closeModal} openModal={this.openModal} actionType={"AddUser"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalEditUser ? <EditUserModal showModal={this.state.showModalEditUser} closeModal={this.closeModal} openModal={this.openModal} actionType={"EditUser"} userId={this.state.userId} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
    }
}

export default Rights;
