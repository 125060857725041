import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { Bar, defaults } from "react-chartjs-2";

Chart.defaults.font.size = 9;
Chart.defaults.font.family = "PoppinsMedium";

const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 0,
        },
    },
    plugins: {
        legend: {
            display: false,
            position: 'right',
        },
        title: {
            display: false,
            text: 'Title',
        },
    },
    scales: {
        x: {
          display: true,
          grid: {
            display: true
          },
        },
        y: {
          display: true,
          grid: {
            display: false
          },
          ticks: {
            callback: function (val, index) {
                return '      ' + this.getLabelForValue(val);
            }
          }
        }
    },
    responsive: true,
};

const BarChart = () => {
    const labels = ["Relationship Building", "Evangelism", "Discipleship", "Leadership Dev.", "Coaching", "Counseling", "Administrative", "Uplink"];
    const data = {
        labels: labels,
        datasets: [{
            label: "Total Conversations by Type",
            backgroundColor: "#556EE6",
            borderColor: "#556EE6",
            data: [7.5, 8, 9, 11, 13.5, 19, 24, 20.5],
            barThickness: 10,
            //barPercentage: 0.5,
            //categoryPercentage: 0.5,
        }],
    };
    return (
        <div style={{marginLeft: 0}}>
            <Bar data={data} options={options} height={110} />
        </div>
    );
};

export default BarChart;