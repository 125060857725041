import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';

// import assets
import icon_add_white from '../assets/icon_add_white.png';

// import components
import ExpandButton from '../components/ExpandButton.js';

class ClosedTile extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="closedTile">

                <div className="horizontalStackCenter">
                    <ExpandButton isOpen={false} style={{marginTop: 5}} onClick={() => this.props.onExpand(this.props.type)} />

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                        <span className="fontPoppinsSemiBold15">{this.props.title}</span>
                        <span className="fontPoppinsRegular13Gray oneLineThreeDotsText">{this.props.description}</span>
                    </div>

                    <div style={{width: "20px"}}></div>

                    <div className="circleButtonContainer circleButtonContainerOrange clickable" onClick={() => this.props.onAdd(this.props.type)}>
                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                    </div>

                </div>

            </div>
        );
    }
}

export default ClosedTile;
