import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/modals.css';
import '../styles_m/modals_m.css';
import Axios from 'axios';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Resizer from "react-image-file-resizer";

// components
import ModalMobile from '../modals_m/ModalMobile';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';
import ProfileImageMobile from '../components_m/ProfileImageMobile.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// assets
import close_white from '../assets/close_white.png';
import background from '../assets/background.jpg';
import icon_expand from '../assets/icon_expand.png';
import icon_add from '../assets/icon_add.png';
import icon_done from '../assets/icon_done.png';

class ProfileSchoolsModalMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectTo: null,
            editingMode: this.props.actionType, // AddProfileSchools, EditProfileSchools
            mainContactId: 0,
            actualProfile: ([{id:1,short_name:"",org_name:"",email:"",phone:"",church:"Error",city:"",country:"",addr:"",country_id:"",nick_name:"",is_staff:0}]),
            actualProfileEmails: ([{email: "", email_tp_id: 0, is_primary: 1},{email: "", email_tp_id: 0, is_primary: 0}]),
            actualProfilePhones: ([{phone: "", phone_tp_id: 0, is_primary: 1},{phone: "", phone_tp_id: 0, is_primary: 0}]),
            actualProfileWebsites: ([{website: "", website_tp_id: 0},{website: "", website_tp_id: 0}]),
            actualProfileSocnets: ([{socnet: "", platform_id: 0},{socnet: "", platform_id: 0}]),
            uploadedImageBase64: "",
            isMyContact: false, // default should be false
            listOfCountries: ([{"id":1,"label":"ALL"},{"id":2,"label":"Afghanistan"},{"id":3,"label":"Aland Islands"}]),
            fullAddress: "",
            googleDataFullAddress: "unknown",
            googleDataRegion: "unknown",
            listOfRegions: ([{"id":1,"label":"East"}]),
            sectionNameOpened: false,
            sectionContactDetailsOpened: false,
            sectionLocationOpened: false,
            array_email_types: ([{id:1, label: "Loading..."}]),
            array_phone_locations: ([{id:1, label: "Loading..."}]),
            array_website_type: ([{id:1, label: "Loading..."}]),
            array_social_media_platform: ([{id:1, label: "Loading..."}]),

        };

        this.hiddenFileInput = React.createRef();
        this.refBirthDay = React.createRef();
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = async () => {
        // I need to load this before loading school, because than I am setting the default country when creating new school
        // get all countries for country select
        await Axios.get('/api/perm_cntry/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfCountries: response.data.result });
        });

        var id = this.props.contactId;

        if(id != -1 && this.props.actionType == "EditProfileSchools"){
            if(id == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/Schools"); window.location.reload(); }
                return;
            }
            await Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_cont });
                }
            });
            this.setState({ mainContactId: id });
            this.loadRegionsForCountry();

            // get full address for user
            Axios.get('/api/addr/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(typeof(response.data.result[0].addr) === "undefined")return;
                this.setState({ fullAddress: response.data.result[0].addr });
                this.setState({ googleDataRegion: response.data.result[0].adm_lvl_1 });
                this.setState({ googleDataFullAddress: response.data.result[0].fmtd_addr });
            });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=avatar&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });

            // get array of emails
            Axios.get('/api/email/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 1});
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 0});
                }
                this.setState({ actualProfileEmails: response.data.result })
            });

            // get array of phones
            Axios.get('/api/phone/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 1});
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 0});
                }
                this.setState({ actualProfilePhones: response.data.result })
            });

            // get array of websites
            Axios.get('/api/website/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({website: "", website_tp_id: 0});
                    response.data.result.push({website: "", website_tp_id: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({website: "", website_tp_id: 0});
                }
                this.setState({ actualProfileWebsites: response.data.result })
            });

            // get array of social networks
            Axios.get('/api/socnet/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({socnet: "", platform_id: 0});
                    response.data.result.push({socnet: "", platform_id: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({socnet: "", platform_id: 0});
                }
                this.setState({ actualProfileSocnets: response.data.result })
            });

            this.setState({ sectionNameOpened: true });
        }
        else {
            // not editing but creating new contact
            this.setState({ sectionNameOpened: true });
            this.setState({ sectionLocationOpened: true });

            await Axios.get('/api/perm_cntry/get/').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                var permissionCountryId = response.data.result[0].id;
                if(permissionCountryId == 1)permissionCountryId = 61; // if has right to ALL, set Czech republic
                this.changeCountryNameById(permissionCountryId);
            });
        }

        // get email types
        Axios.get('/api/email_tp/get?cont_tp=4').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_email_types: response.data.result })
        });

        // get phone locations
        Axios.get('/api/phone_tp/get?cont_tp=4').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_phone_locations: response.data.result })
        });

        // get website types
        Axios.get('/api/website_tp/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_website_type: response.data.result })
        });

        // get social media platforms
        Axios.get('/api/platform/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_social_media_platform: response.data.result })
        });
    }

    loadRegionsForCountry = () => {
        // get all countries for selected country
        var countryId = this.state.actualProfile[0].country_id;
        var url = "/api/region/get/";
        if(countryId != 0 && countryId > 0){url = "/api/region/get?cntry_id="+countryId;}

        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var workingList = [];
            for(let oneRow of response.data.result){ workingList.push({"id":oneRow.id, "label": oneRow.descr}); }
            this.setState({ listOfRegions: workingList });
        });
    }

    /* START OF METHODS FOR AVATAR IMAGE */

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    uploadImageClick = () => { this.hiddenFileInput.current.click(); };

    deleteProfileImage = () => {
        Axios.post('/api/file/delete', {
            FilePrefix: "avatar",
            ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ uploadedImageBase64: "" });
            // reload page so that the change would be shown in TopMenu
            window.location.reload();
        });
    }

    imageIsSelected = async (event) => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded.size > 5000000){
            this.props.openAlertMessage("Error! Maximum size of picture is 5 MB.","OK");
            return;
        }
        var base64 = await this.toBase64(fileUploaded);
        var prefix = fileUploaded.name.substring(fileUploaded.name.lastIndexOf(".")+1);
        //this.setState({ uploadedImageBase64: base64 });

        try {
            Resizer.imageFileResizer(fileUploaded, 300, 300, "JPEG", 100, 0,
                (uri) => {
                    //console.log(uri);
                    this.setState({ uploadedImageBase64: uri });
                    if(this.props.actionType == "EditProfileSchools"){
                        this.uploadAvatarImage(this.state.mainContactId);
                    }
                },
                "base64", 200,  200
            );
        }
        catch (err) {
            //console.log(err);
            this.props.openAlertMessage("Error while uploading image.","OK");
        }
        //var improvedBase64 = base64.substring(base64.lastIndexOf(";base64,")+8);
    };

    uploadAvatarImage = (contactId) => {
        if(this.state.uploadedImageBase64 == "")return;
        Axios.post('/api/file/upload', {
            FilePrefix: "avatar",
            ContId: contactId,
            content: this.state.uploadedImageBase64,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            // reload page so that the change would be shown in TopMenu
            //window.location.reload();
        });
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_cont/del/', {
                data: {ContId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_cont/ins/', {
                ContId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    changeCountryNameById = (id) => {
        var countryName = "";

        // find name of country by country_id
        this.state.listOfCountries.forEach((row)=>{
            if(row.id == id){
                countryName = row.label;
            }
        })

        // change country name in the object
        this.setState(prevState => {
            let actualProfile = Object.assign({}, prevState.actualProfile);
            actualProfile[0].country_id = id;
            actualProfile[0].country = countryName;
            return {actualProfile}; }
        )

        // delete selected region (because country was changed)
        this.setState(prevState => {
            let actualProfile = Object.assign({}, prevState.actualProfile);
            actualProfile[0].region_id = "";
            return {actualProfile};
        });
        this.changeRegionNameById(-1);

        this.loadRegionsForCountry();
    }

    changeRegionNameById = (id) => {
        var regionName = "";

        // find name of country by country_id
        this.state.listOfRegions.forEach((row)=>{
            if(row.id == id){
                regionName = row.label;
            }
        })

        // change regionName name in the object
        this.setState(prevState => {
            let actualProfile = Object.assign({}, prevState.actualProfile);
            actualProfile[0].region = regionName;
            return {actualProfile}; }
        )
    }

    /* END OF METHODS FOR AVATAR IMAGE */

    nameSectionWasEdited = (actualProfile) => {
        if((typeof(actualProfile.org_name) === "undefined" || actualProfile.org_name == "")
        && (typeof(actualProfile.short_name) === "undefined" || actualProfile.short_name == "")){
            return false;
        }
        else {
            return true;
        }
    }

    contactSectionWasEdited = (emails, phones, websites, socnets) => {
        var wasEdited = false;

        for(let oneRow of emails){ if(oneRow.email != "")wasEdited = true; }
        for(let oneRow of phones){ if(oneRow.phone != "")wasEdited = true; }
        for(let oneRow of websites){ if(oneRow.website != "")wasEdited = true; }
        for(let oneRow of socnets){ if(oneRow.socnet != "")wasEdited = true; }

        return wasEdited;
    }

    locationSectionWasEdited = (actualProfile, fullAddress) => {
        if((typeof(actualProfile.country_id) === "undefined" || actualProfile.country_id == 0)
        && (typeof(actualProfile.region_id) === "undefined" || actualProfile.region_id == 0)
        && (typeof(actualProfile.city) === "undefined" || actualProfile.city == "")
        && (typeof(fullAddress) === "undefined" || fullAddress == "")){
            return false;
        }
        else {
            return true;
        }
    }

    /* Methods for array of emails */

    updateOneEmail = (event , index) => {
        let profileEmails = this.state.actualProfileEmails;
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(i == index)profileEmails[i].email = event.target.value;
        }
        this.setState({ actualProfileEmails: profileEmails });
    }

    updateOneEmailType = (event , index) => {
        let profileEmails = this.state.actualProfileEmails;
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(i == index)profileEmails[i].email_tp_id = event.target.value;
        }
        this.setState({ actualProfileEmails: profileEmails });
    }

    updateOneEmailPrimary = (index) => {
        let profileEmails = this.state.actualProfileEmails;
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(i == index)profileEmails[i].is_primary = 1;
            else profileEmails[i].is_primary = 0;
        }
        this.setState({ actualProfileEmails: profileEmails });
    }

    getPrimaryEmail = () => {
        let primaryEmail = "";
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(this.state.actualProfileEmails[i].is_primary == 1){
                primaryEmail = this.state.actualProfileEmails[i].email;
            }
        }
        return primaryEmail;
    }

    /* Methods for array of phones */

    updateOnePhone = (event , index) => {
        let profilePhones = this.state.actualProfilePhones;
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(i == index)profilePhones[i].phone = event.target.value;
        }
        this.setState({ actualProfilePhones: profilePhones });
    }

    updateOnePhoneType = (event , index) => {
        let profilePhones = this.state.actualProfilePhones;
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(i == index)profilePhones[i].phone_tp_id = event.target.value;
        }
        this.setState({ actualProfilePhones: profilePhones });
    }

    updateOnePhonePrimary = (index) => {
        let profilePhones = this.state.actualProfilePhones;
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(i == index)profilePhones[i].is_primary = 1;
            else profilePhones[i].is_primary = 0;
        }
        this.setState({ actualProfilePhones: profilePhones });
    }

    getPrimaryPhone = () => {
        let primaryPhone = "";
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(this.state.actualProfilePhones[i].is_primary == 1){
                primaryPhone = this.state.actualProfilePhones[i].phone;
            }
        }
        return primaryPhone;
    }

    /* Methods for array of phones */

    updateOneWebsite = (event , index) => {
        let profileWebsites = this.state.actualProfileWebsites;
        for(var i = 0; i < this.state.actualProfileWebsites.length; i++) {
            if(i == index)profileWebsites[i].website = event.target.value;
        }
        this.setState({ actualProfileWebsites: profileWebsites });
    }

    updateOneWebsiteType = (event , index) => {
        let profileWebsites = this.state.actualProfileWebsites;
        for(var i = 0; i < this.state.actualProfileWebsites.length; i++) {
            if(i == index)profileWebsites[i].website_tp_id = event.target.value;
        }
        this.setState({ actualProfileWebsites: profileWebsites });
    }

    /* Methods for array of socnets */

    updateOneSocnet = (event , index) => {
        let profileSocnets = this.state.actualProfileSocnets;
        for(var i = 0; i < this.state.actualProfileSocnets.length; i++) {
            if(i == index)profileSocnets[i].socnet = event.target.value;
        }
        this.setState({ actualProfileSocnets: profileSocnets });
    }

    updateOneSocnetType = (event , index) => {
        let profileSocnets = this.state.actualProfileSocnets;
        for(var i = 0; i < this.state.actualProfileSocnets.length; i++) {
            if(i == index)profileSocnets[i].platform_id = event.target.value;
        }
        this.setState({ actualProfileSocnets: profileSocnets });
    }

    checkIfDataAreOk = () => {
        if(this.state.actualProfile[0].org_name == "" || this.state.actualProfile[0].org_name == undefined){
            this.props.openAlertMessage("You didn't fill full name.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: true });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionMinistryInfoOpened: false });
            return false;
        }
        else if(this.state.actualProfile[0].short_name == "" || this.state.actualProfile[0].short_name == undefined){
            this.props.openAlertMessage("You didn't fill short name.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: true });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionMinistryInfoOpened: false });
            return false;
        }
        else if(this.state.actualProfile[0].city == "" || this.state.actualProfile[0].city == undefined){
            this.props.openAlertMessage("You didn't fill city.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: false });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionMinistryInfoOpened: true });
            return false;
        }
        else if(this.state.actualProfile[0].country_id == "" || this.state.actualProfile[0].country_id == undefined){
            this.props.openAlertMessage("You didn't fill country.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: false });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionMinistryInfoOpened: true });
            return false;
        }
        return true;
    }

    saveAllData = () => {
        if(this.checkIfDataAreOk() == false){ return; }

        // prepare array of emails
        var finalEmailsArray = [];
        for(let oneRow of this.state.actualProfileEmails){
            if(oneRow.email == "")continue;
            finalEmailsArray.push({"Email":oneRow.email, "EmailTpId": oneRow.email_tp_id, "IsPrimary": oneRow.is_primary});
        }

        // prepare array of phones
        var finalPhonesArray = [];
        for(let oneRow of this.state.actualProfilePhones){
            if(oneRow.phone == "")continue;
            finalPhonesArray.push({"Phone":oneRow.phone, "PhoneTpId": oneRow.phone_tp_id, "IsPrimary": oneRow.is_primary});
        }

        // prepare array of websites
        var finalWebsitesArray = [];
        for(let oneRow of this.state.actualProfileWebsites){
            if(oneRow.website == "")continue;
            finalWebsitesArray.push({"Website":oneRow.website, "WebsiteTpId": oneRow.website_tp_id});
        }

        // prepare array of social networks
        var finalSocnetsArray = [];
        for(let oneRow of this.state.actualProfileSocnets){
            if(oneRow.socnet == "")continue;
            finalSocnetsArray.push({"SocNet":oneRow.socnet, "PlatformId": oneRow.platform_id});
        }

        var url = '/api/cont/ins';
        if(this.props.contactId != -1 && this.props.actionType == "EditProfileSchools"){
            url = '/api/cont/upd/'+this.props.contactId;
        }

        //alert(this.state.actualProfile[0].prefix_id);
        Axios.post(url, {
            ContTpId: 7, // idType=1 is for person idType=4 is for church, idType=7 is for schools
            OrgName: this.state.actualProfile[0].org_name,
            ShortName: this.state.actualProfile[0].short_name,
            Email: finalEmailsArray,
            Phone:  finalPhonesArray,
            Website: finalWebsitesArray,
            SocNet: finalSocnetsArray,
            City:  this.state.actualProfile[0].city,
            CountryId: this.state.actualProfile[0].country_id,
            RegionId: this.state.actualProfile[0].region_id,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            if(this.state.fullAddress != undefined && this.state.fullAddress != ""){
                var contactId = this.props.actionType == "EditProfileSchools" ? this.state.mainContactId : response.data.msg.id;
                Axios.post('/api/addr/inup', {
                    ContId: contactId,
                    Addr: this.state.fullAddress,
                }).then((response) => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                    this.loadData();
                });
            }

            // if setting picture for new user I need to wait until user is created and then I can upload picture
            if(this.state.uploadedImageBase64 != "" && this.props.actionType != "EditProfileSchools"){
                this.uploadAvatarImage(response.data.msg.id);
            }

            this.props.closeModal(this.state.editingMode, false);

            if(this.props.actionType != "EditProfileSchools"){
                this.setState({ redirectTo: "/SchoolProfile/"+response.data.msg.id });
            }
        });
    }

    deleteAction = () => {
        Axios.delete('/api/cont/del/'+this.state.mainContactId, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ redirectTo: "/Schools" });
        });
    };

    askToDelete = () => {
        this.props.openAlertMessageTwoActions("Are you sure you want to delete this profile?","Cancel","Delete",this.deleteAction);
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
		return(
            <React.Fragment>
                <ModalMobile show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={false}>
                    <div className="content">

                        <div style={{width: "100%", backgroundColor: "var(--full-black)", height: 80, position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                            <img style={{width: "100%", height: "100%", borderRadius: "20px 20px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                        </div>

                        <div className="horizontalStackCenter" style={{height: 120}}>

                            <div className="verticalStack" style={{zIndex: "0", marginTop: "0px", marginRight: "23px"}}>
                                <div className="flex"></div>

                                <ProfileImageMobile
                                    initials={getInitials(this.state.actualProfile[0].org_name)}
                                    uploadedImageBase64={this.state.uploadedImageBase64}
                                    uploadImageClick={this.uploadImageClick}
                                    deleteImageClick={this.deleteProfileImage}
                                    contactType={"school"}
                                />

                                <input type="file"
                                    ref={this.hiddenFileInput}
                                    style={{display:'none'}}
                                    accept={"image/png, image/jpg, image/jpeg"}
                                    onChange={(event) => this.imageIsSelected(event)}
                                />

                                <div className="flex"></div>
                            </div>

                            <div className="verticalStack" style={{zIndex: "0", height: "90%"}}>
                                <div className="responsiveThreeDotsContainer">
                                    <span className="fontPoppinsMedium20White responsiveThreeDots">{this.state.actualProfile[0].short_name}</span>
                                </div>
                                <div className="flex"></div>
                            </div>

                            <div className="verticalStack" style={{height: "100%"}}>
                                <div className="flex"></div>
                                <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                            </div>

                        </div>

                        <div style={{height: 10}}></div>

                        { this.state.uploadedImageBase64 == "" ?
                            <div className="horizontalStackCenter" onClick={() => this.uploadImageClick()}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Add a Profile Image"}</span>
                            </div>
                        :
                            <div className="horizontalStackCenter" onClick={() => this.uploadImageClick()}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Update Profile Image"}</span>
                            </div>
                        }

                        <div style={{height: 15}}></div>

                        { this.state.isMyContact == false ?
                            <div className="horizontalStackCenter" onClick={this.addToMyContacts}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Add to My Contacts"}</span>
                            </div>
                        :
                            <div className="horizontalStackCenter" onClick={this.addToMyContacts}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Added to My Contacts"}</span>
                            </div>
                        }

                        {/* Editing data part */}

                        <div className="flex" style={{flex: 1, position: "relative"}}>

                            <div className="verticalStack" style={{marginTop: 20, marginBottom: 20}}>

                                {/* Name */}

                                <div className="horizontalStackCenter clickable" style={{height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={ () => this.setState({ sectionNameOpened: !this.state.sectionNameOpened })}>
                                    <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.sectionNameOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>
                                    { this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Name*</span> : null }
                                    { !this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Name*</span> : null }
                                </div>

                                <div className="horizontalStack" style={{display: this.state.sectionNameOpened ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>
                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12, marginRight: 16}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Full Name:*</span>
                                        <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputFullName" name="inputFullName" value={this.state.actualProfile[0].org_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].org_name = e.target.value; return {actualProfile}; })}/>
                                    </div>
                                </div>

                                <div className="horizontalStack" style={{display: this.state.sectionNameOpened ? "" : "none", width: "inherit", paddingTop: 5, paddingBottom: 15, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                                    <div className="verticalStack" style={{flex: 1, marginLeft: 12, marginRight: 16}}>
                                        <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Short Name:*</span>
                                        <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputShortName" name="inputShortName" value={this.state.actualProfile[0].short_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].short_name = e.target.value; return {actualProfile}; })}/>
                                    </div>
                                </div>

                                {/* Location */}

                                <div className="horizontalStackCenter clickable" style={{marginTop: 1, height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={ () => this.setState({ sectionLocationOpened: !this.state.sectionLocationOpened })}>
                                    <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.sectionLocationOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>
                                    { this.locationSectionWasEdited(this.state.actualProfile[0],this.state.fullAddress) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Location*</span> : null }
                                    { !this.locationSectionWasEdited(this.state.actualProfile[0],this.state.fullAddress) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Location*</span> : null }
                                </div>

                                <div className="verticalStack" style={{display: this.state.sectionLocationOpened ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)"}}>

                                    <div className="horizontalStack" style={{alignItems: "flex-start"}}>
                                        <div className="verticalStack" style={{flex: 1, marginRight: 12}}>
                                            <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>City:*</span>
                                            <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputCity" name="inputCity" value={this.state.actualProfile[0].city} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].city = e.target.value; return {actualProfile}; })}/>
                                            <span className="fontPoppinsItalic13Gray" style={{marginTop: 10, marginBottom: 10}}>{"Type in the City that this school is in."}</span>
                                        </div>
                                    </div>

                                    <div className="horizontalStack" style={{marginTop: 0, alignItems: "flex-end"}}>
                                        <div className="verticalStack" style={{flex: 1, marginLeft: 0}}>
                                            <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>JV Country Visibility:*</span>
                                            <select className="profileTileInputText topMenuSelectBox" name="country" id="country" style={{width: "100%"}}  value={this.state.actualProfile[0].country_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].country_id = e.target.value; this.changeCountryNameById(e.target.value); return {actualProfile}; })}>
                                                <option value=""></option>
                                                {this.state.listOfCountries.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={this.state.actualProfile[0].country_id == item.id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                            <span className="fontPoppinsItalic13Gray" style={{marginTop: 10, marginBottom: 10}}>{"Select the country teams who will be able to view this profile."}</span>
                                        </div>
                                    </div>

                                    <div className="horizontalStack" style={{marginTop: 0, alignItems: "flex-end"}}>
                                        <div className="verticalStack" style={{flex: 1, marginLeft: 0}}>
                                            <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>JV Region:</span>
                                            <select className="profileTileInputText topMenuSelectBox" name="region" id="region" style={{width: "100%"}}  value={this.state.actualProfile[0].region_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].region_id = e.target.value; this.changeRegionNameById(e.target.value); return {actualProfile}; })}>
                                                <option value=""></option>
                                                {this.state.listOfRegions.map(function (item) {
                                                    return (
                                                        <option value={item.id}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                            <span className="fontPoppinsItalic13Gray" style={{marginTop: 10, marginBottom: 10}}>{"Select the JV Ministry Region that this school is in."}</span>
                                        </div>
                                    </div>

                                    <div className="horizontalStack" style={{marginTop: 0, alignItems: "flex-end"}}>
                                        <div className="verticalStack" style={{flex: 1, marginLeft: 0, marginRight: 12}}>
                                            <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Full Address:</span>
                                            <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputFullAddress" name="inputFullAddress" value={this.state.fullAddress} onChange={e => this.setState({ fullAddress: e.target.value })} />
                                        </div>
                                    </div>

                                    <div className="horizontalStack" style={{marginTop: 10}}>
                                        <span className="fontPoppinsRegular13Gray" style={{marginBottom: 10}}>{"Region: "+this.state.googleDataRegion}</span>
                                        <div style={{width: 20}}></div>
                                        <span className="fontPoppinsRegular13Gray" style={{marginBottom: 10}}>{"Google's address: "+this.state.googleDataFullAddress}</span>
                                    </div>

                                </div>

                                {/* Contact details */}

                                <div className="horizontalStackCenter clickable" style={{marginTop: 1, height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={ () => this.setState({ sectionContactDetailsOpened: !this.state.sectionContactDetailsOpened })}>
                                    <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.sectionContactDetailsOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>
                                    { this.contactSectionWasEdited(this.state.actualProfileEmails,this.state.actualProfilePhones,this.state.actualProfileWebsites,this.state.actualProfileSocnets) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Contact Details</span> : null }
                                    { !this.contactSectionWasEdited(this.state.actualProfileEmails,this.state.actualProfilePhones,this.state.actualProfileWebsites,this.state.actualProfileSocnets) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Contact Details</span> : null }
                                </div>

                                <div className="verticalStack" style={{display: this.state.sectionContactDetailsOpened ? "" : "none",width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)"}}>

                                    <div className="horizontalStack flex">
                                        <table className="tableContactDetailsMobile">
                                            <tr>
                                                <td className="flex">
                                                    <span className="fontPoppinsRegular13">Email:</span>
                                                    {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                                </td>
                                                <td>
                                                    <span className="fontPoppinsRegular13">Type:</span>
                                                </td>
                                                <td style={{textAlign: "center"}}>
                                                    <span className="fontPoppinsRegular13">Primary</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="horizontalStackCenter" style={{}}>
                                                    <input className="profileTileInputText flex" type="text" id="searchInputEmail1" name="searchInputEmail1" autoComplete="something-random" value={this.state.actualProfileEmails[0].email} onChange={(e) => this.updateOneEmail(e,0)} />
                                                </td>
                                                <td>
                                                    <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneEmailType(e,0)}>
                                                        <option value=""></option>
                                                        {this.state.array_email_types.map(function (item) {
                                                            return (
                                                                <option value={item.id} selected={item.id == this.state.actualProfileEmails[0].email_tp_id ? "selected" : ""}>{item.label}</option>
                                                            )
                                                        }.bind(this))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <div className="profileTileContactRadioContainer">
                                                        <input className="profileTileContactRadioButton" type="radio" id="radioEmail" name="radioEmail" value="kk" checked={this.state.actualProfileEmails[0].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOneEmailPrimary(0)} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="horizontalStackCenter" style={{}}>
                                                    <input className="profileTileInputText flex" type="text" id="searchInputEmail2" name="searchInputEmail2" autoComplete="something-random" value={this.state.actualProfileEmails[1].email} onChange={(e) => this.updateOneEmail(e,1)}  />
                                                </td>
                                                <td>
                                                    <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneEmailType(e,1)}>
                                                        <option value=""></option>
                                                        {this.state.array_email_types.map(function (item) {
                                                            return (
                                                                <option value={item.id} selected={item.id == this.state.actualProfileEmails[1].email_tp_id ? "selected" : ""}>{item.label}</option>
                                                            )
                                                        }.bind(this))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <div className="profileTileContactRadioContainer">
                                                        <input className="profileTileContactRadioButton" type="radio" id="radioEmail" name="radioEmail" value="kk" checked={this.state.actualProfileEmails[1].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOneEmailPrimary(1)} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="flex">
                                                    <span className="fontPoppinsRegular13">Website:</span>
                                                    {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                                </td>
                                                <td>
                                                    <span className="fontPoppinsRegular13">Type:</span>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="horizontalStack">
                                                    <input className="profileTileInputText flex" type="text" id="searchInputWebsite1" name="searchInputWebsite1" autoComplete="something-random" value={this.state.actualProfileWebsites[0].website} onChange={(e) => this.updateOneWebsite(e,0)}  />
                                                </td>
                                                <td>
                                                    <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneWebsiteType(e,0)}>
                                                        <option value=""></option>
                                                        {this.state.array_website_type.map(function (item) {
                                                            return (
                                                                <option value={item.id} selected={item.id == this.state.actualProfileWebsites[0].website_tp_id ? "selected" : ""}>{item.label}</option>
                                                            )
                                                        }.bind(this))}
                                                    </select>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="horizontalStack">
                                                    <input className="profileTileInputText flex" type="text" id="searchInputWebsite2" name="searchInputWebsite2" autoComplete="something-random" value={this.state.actualProfileWebsites[1].website} onChange={(e) => this.updateOneWebsite(e,1)}  />
                                                </td>
                                                <td>
                                                    <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneWebsiteType(e,1)}>
                                                        <option value=""></option>
                                                        {this.state.array_website_type.map(function (item) {
                                                            return (
                                                                <option value={item.id} selected={item.id == this.state.actualProfileWebsites[1].website_tp_id ? "selected" : ""}>{item.label}</option>
                                                            )
                                                        }.bind(this))}
                                                    </select>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className="horizontalStack flex">
                                        <table className="tableContactDetailsMobile">
                                            <tr>
                                                <td className="flex">
                                                    <span className="fontPoppinsRegular13">Phone:</span>
                                                    {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                                </td>
                                                <td>
                                                    <span className="fontPoppinsRegular13">Location:</span>
                                                </td>
                                                <td style={{textAlign: "center"}}>
                                                    <span className="fontPoppinsRegular13">Primary</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="horizontalStack">
                                                    <input className="profileTileInputText flex" type="text" id="searchInputPhone1" name="searchInputPhone1" autoComplete="something-random" value={this.state.actualProfilePhones[0].phone} onChange={(e) => this.updateOnePhone(e,0)}  />
                                                </td>
                                                <td>
                                                    <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOnePhoneType(e,0)}>
                                                        <option value=""></option>
                                                        {this.state.array_phone_locations.map(function (item) {
                                                            return (
                                                                <option value={item.id} selected={item.id == this.state.actualProfilePhones[0].phone_tp_id ? "selected" : ""}>{item.label}</option>
                                                            )
                                                        }.bind(this))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <div className="profileTileContactRadioContainer">
                                                        <input className="profileTileContactRadioButton" type="radio" id="radioPhone" name="radioPhone" value="kk" checked={this.state.actualProfilePhones[0].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOnePhonePrimary(0)} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="horizontalStack">
                                                    <input className="profileTileInputText flex" type="text" id="searchInputPhone2" name="searchInputPhone2" autoComplete="something-random" value={this.state.actualProfilePhones[1].phone} onChange={(e) => this.updateOnePhone(e,1)}  />
                                                </td>
                                                <td>
                                                    <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOnePhoneType(e,1)}>
                                                        <option value=""></option>
                                                        {this.state.array_phone_locations.map(function (item) {
                                                            return (
                                                                <option value={item.id} selected={item.id == this.state.actualProfilePhones[1].phone_tp_id ? "selected" : ""}>{item.label}</option>
                                                            )
                                                        }.bind(this))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <div className="profileTileContactRadioContainer">
                                                        <input className="profileTileContactRadioButton" type="radio" id="radioPhone" name="radioPhone" value="kk" checked={this.state.actualProfilePhones[1].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOnePhonePrimary(1)} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="flex">
                                                    <span className="fontPoppinsRegular13">Social Media:</span>
                                                    {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                                </td>
                                                <td>
                                                    <span className="fontPoppinsRegular13">Platform:</span>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="horizontalStack">
                                                    <input className="profileTileInputText flex" type="text" id="searchInputSocnet1" name="searchInputSocnet1" autoComplete="something-random" value={this.state.actualProfileSocnets[0].socnet} onChange={(e) => this.updateOneSocnet(e,0)}  />
                                                </td>
                                                <td>
                                                    <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneSocnetType(e,0)}>
                                                        <option value=""></option>
                                                        {this.state.array_social_media_platform.map(function (item) {
                                                            return (
                                                                <option value={item.id} selected={item.id == this.state.actualProfileSocnets[0].platform_id ? "selected" : ""}>{item.label}</option>
                                                            )
                                                        }.bind(this))}
                                                    </select>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="horizontalStack">
                                                    <input className="profileTileInputText flex" type="text" id="searchInputSocnet2" name="searchInputSocnet2" autoComplete="something-random" value={this.state.actualProfileSocnets[1].socnet} onChange={(e) => this.updateOneSocnet(e,1)}  />
                                                </td>
                                                <td>
                                                    <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneSocnetType(e,1)}>
                                                        <option value=""></option>
                                                        {this.state.array_social_media_platform.map(function (item) {
                                                            return (
                                                                <option value={item.id} selected={item.id == this.state.actualProfileSocnets[1].platform_id ? "selected" : ""}>{item.label}</option>
                                                            )
                                                        }.bind(this))}
                                                    </select>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="verticalStack" style={{marginTop: "12px"}}>

                            <div className="roundButton roundButtonOrange" style={{textAlign: "center", marginTop: 0}} onClick={() => this.saveAllData()}>
                                <span className="fontPoppinsRegular13White">{"Save"}</span>
                            </div>

                            <div className="roundButton roundButtonGray" style={{textAlign: "center", marginTop: 10}} onClick={() => this.props.closeModal(this.state.editingMode, false)}>
                                <span className="fontPoppinsRegular13White">{"Cancel"}</span>
                            </div>

                            {this.props.contactId != -1 && this.props.actionType == "EditProfileSchools" ?
                                <div className="roundButton roundButtonGray" style={{textAlign: "center", marginTop: 10}} onClick={() => this.askToDelete()}>
                                    <span className="fontPoppinsRegular13White">{"Delete"}</span>
                                </div>
                            : null }

                        </div>

                    </div>
                </ModalMobile>
            </React.Fragment>
		);
	}

}

export default ProfileSchoolsModalMobile;