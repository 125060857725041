import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import InstructionsModalMobile from '../modals_m/InstructionsModalMobile.js';

// import assets
import checkmark from '../assets/checkmark.png';

class InstructionsMobile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            openInstruction: 1,
            prepareToShowModalInstructions: false,
            showModalInstructions: false,
            versionName: "loading...",
            versionDetail: ([{id:1, label: "Some label", descr: "Some description"}])
        }

    }

    componentDidMount(){
        this.loadData();
        this.setActualSection("instructions");
    }

    loadData = () => {

        Axios.get('/api/vers/get?is_crnt=1').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ versionName: response.data.result[0].label });

            Axios.get('/api/versd/get?is_crnt=1').then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                this.setState({ versionDetail: response.data.result });
            });
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "instructions"){
            await this.setState({ prepareToShowModalInstructions: true });
            this.setState({ showModalInstructions: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "instructions"){
            this.setState({ showModalInstructions: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalInstructions: false });
        }
    }

    openConcreteModal = async (id) => {
        await this.setState({ openInstruction: id });
        this.openModal("instructions");
    }

    openVersionDetails = () => {
        var text = "WHAT'S NEW IN VERSION "+this.state.versionName+":";
        var index = 1;
        for(let oneRow of this.state.versionDetail){
            text = text + "\n\n" + index + ". " + oneRow.label + " - " + oneRow.descr;
            index++;
        }
        alert(text);
    }

    render() {
        return ( /* width - 60px is because LeftMenuMobile is 60px in width */
            <div className="verticalStack flex" style={{width: "calc(100vw - 60px)", height: "calc(var(--doc-height) - 80px)", overflowY: "auto", overflowX: "hidden"}}>

                <div className="universalTile flex verticalStack" style={{position: "relative", margin: 20}}>
                    <span className="fontMontserratBold18">{"WELCOME TO THE MOBILE "}<span className="fontMontserratBold18Orange">{"JV MINISTRY PORTAL"}</span></span>
                    <span className="fontPoppinsRegular13Gray" style={{width: "100%", marginTop: 15}}>{"The JV Ministry Portal is a digital tool for staff that helps bring focus and intentionality to developing young leaders across Central and Eastern Europe while coordinating our efforts to build up the local church."}</span>

                    <span className="fontPoppinsBold15" style={{marginTop: 15}}>{"KEY MOBILE FEATURES"}</span>
                    <div className="horizontalStackCenter" style={{marginTop: 10}} onClick={() => this.openConcreteModal(1)}>
                        <img src={checkmark} style={{width: 16}} />
                        <span className="fontPoppinsSemiBold15Orange" style={{marginLeft: 5}}>{"Record & View Conversations"}</span>
                    </div>
                    <div className="horizontalStackCenter" style={{marginTop: 10}} onClick={() => this.openConcreteModal(2)}>
                        <img src={checkmark} style={{width: 16}} />
                        <span className="fontPoppinsSemiBold15Orange" style={{marginLeft: 5}}>{"Quick Profile Views"}</span>
                    </div>
                    <div className="horizontalStackCenter" style={{marginTop: 10}} onClick={() => this.openConcreteModal(3)}>
                        <img src={checkmark} style={{width: 16}} />
                        <span className="fontPoppinsSemiBold15Orange" style={{marginLeft: 5}}>{"Find, Edit or Add New Contacts"}</span>
                    </div>

                    <div style={{height: 10}}></div>

                    <span className="fontPoppinsSemiBold15" style={{marginLeft: 25}}>{"• People"}</span>
                    <span className="fontPoppinsSemiBold15" style={{marginLeft: 25}}>{"• Churches"}</span>
                    <span className="fontPoppinsSemiBold15" style={{marginLeft: 25}}>{"• Schools"}</span>

                    <div style={{height: 15}}></div>

                    <RoundButton title={"View the Mobile Instructions"} style={{justifyContent: "center"}} onClick={() => this.openConcreteModal(1)} />

                    <div style={{height: 10}}></div>

                    <RoundButton title={"Email the Help Desk"} style={{justifyContent: "center"}} onClick={() => window.location="mailto:khennhofer@kam.cz"} />

                    <div style={{height: 15}}></div>

                    <div className="horizontalStack">
                        <div className="flex"></div>
                        <span className="fontPoppinsRegular13LightPurpleGray clickable onHoverToBlack" style={{bottom: 14, right: 20}} onClick={() => this.openVersionDetails()}>{"Version "}{this.state.versionName}</span>
                        <div className="flex"></div>
                    </div>

                    <div className="flex"></div>

                </div>

                {this.state.prepareToShowModalInstructions ? <InstructionsModalMobile showModal={this.state.showModalInstructions} closeModal={this.closeModal} openModal={this.openModal} actionType={"instructions"} oneInstructionId={this.state.openInstruction} /> : null }

            </div>
        );
    }
}

export default InstructionsMobile;
