import React, { useState, useEffect } from "react";
import {BrowserRouter, Routes, Route, Link } from "react-router-dom";
//import './App.css';
import './styles/other.css';

//utilities
import { showMobileVersion } from './utilities/OtherUtilities.js';

//components
import TopMenu from "./menus/TopMenu.js";
import LeftMenu from "./menus/LeftMenu.js";
import LeftMenuClosed from "./menus/LeftMenuClosed.js";
import TopMenuMobile from "./menus_m/TopMenuMobile.js";
import LeftMenuMobile from "./menus_m/LeftMenuMobile.js";
import LeftMenuClosedMobile from "./menus_m/LeftMenuClosedMobile.js";
import AlertModalMessage from './modals/AlertModalMessage.js';
import AlertModalMessageTwoButton from './modals/AlertModalMessageTwoButton.js';
import AlertModalMessageMobile from './modals_m/AlertModalMessageMobile.js';
import AlertModalMessageTwoButtonMobile from './modals_m/AlertModalMessageTwoButtonMobile.js';

//pages
import TestingPage from "./pages/TestingPage.js";
import Login from "./pages/Login.js";
import PeopleList from "./pages/PeopleList.js";
import PeopleListMobile from "./pages_m/PeopleListMobile.js";
import ChurchesList from "./pages/ChurchesList.js";
import ChurchesListMobile from "./pages_m/ChurchesListMobile.js";
import SchoolsList from "./pages/SchoolsList.js";
import SchoolsListMobile from "./pages_m/SchoolsListMobile.js";
import PeopleProfile from "./pages/PeopleProfile.js";
import PeopleProfileMobile from "./pages_m/PeopleProfileMobile.js";
import ChurchProfile from "./pages/ChurchProfile.js";
import ChurchProfileMobile from "./pages_m/ChurchProfileMobile.js";
import DenominationProfile from "./pages/DenominationProfile.js";
import DenominationProfileMobile from "./pages_m/DenominationProfileMobile.js";
import TeamProfile from "./pages/TeamProfile.js";
import TeamProfileMobile from "./pages_m/TeamProfileMobile.js";
import SchoolProfile from "./pages/SchoolProfile.js";
import SchoolProfileMobile from "./pages_m/SchoolProfileMobile.js";
import NewContactPerson from "./pages/NewContactPerson.js";
import NewContactChurch from "./pages/NewContactChurch.js";
import NewContactSchool from "./pages/NewContactSchool.js";
import MyConversations from "./pages/MyConversations.js";
import ObservationsListMobile from "./pages_m/ObservationsListMobile.js";
import MyConversationsMobile from "./pages_m/MyConversationsMobile.js";
import MyDiscipling from "./pages/MyDiscipling.js";
import AdvancedSearch from "./pages/AdvancedSearch.js";
import QuickSearch from "./pages/QuickSearch.js";
import QuickSearchMobile from "./pages_m/QuickSearchMobile.js";
import Dashboard from "./pages/Dashboard.js";
import Instructions from "./pages/Instructions.js";
import InstructionsMobile from "./pages_m/InstructionsMobile.js";
import NewPrivateContactPerson from "./pages/NewPrivateContactPerson.js";
import PeoplePrivateProfile from "./pages/PeoplePrivateProfile.js";
import PeoplePrivateProfileMobile from "./pages_m/PeoplePrivateProfileMobile.js";
import Rights from "./pages/Rights.js";
import RecoverPasswordPage from "./pages/RecoverPasswordPage.js";
import RecoverPasswordPageMobile from "./pages_m/RecoverPasswordPageMobile.js";
import NotFoundPage from "./pages/NotFoundPage.js";

function setToken(userToken) {
    localStorage.setItem('token', JSON.stringify(userToken));
};

function getToken() {
    try {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token
        // return tokenString;
        console.log(userToken);
    }
    catch(err){
        return null;
    }
};

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            actualSection: "none", // for leftMenu to activate buttons (people, churches, conversations, events)
            leftMenuOpened: "true",
            searchText: "",
            allOrMy: "my",
            topMenuExpanded: false, // default is: false
            prepareToShowAlertModalMessage: false,
            showAlertModalMessage: false,
            prepareToShowAlertModalMessageTwoButton: false,
            showAlertModalMessageTwoButton: false,
            prepareToShowAlertModalMessageMobile: false,
            showAlertModalMessageMobile: false,
            prepareToShowAlertModalMessageTwoButtonMobile: false,
            showAlertModalMessageTwoButtonMobile: false,
            alertMessage: "Message",
            alertCloseButton: "OK",
            alertActionButton: "OK",
            alertOnAction: {},
        };
    }


    componentDidMount(){
        this.setState({ actualSection: localStorage.getItem("actualSection") || "none" });
        this.setState({ searchText: localStorage.getItem("searchText") || "" });
        if(localStorage.getItem("leftMenuOpened")){
            this.setState({ leftMenuOpened: localStorage.getItem("leftMenuOpened") });
        }
        else {
            if(window.innerWidth <= 1200){
                this.setState({ leftMenuOpened: "false" });
            }
            else{
                this.setState({ leftMenuOpened: "true" });
            }
        }

        // on every click, gesture or resizing the resizing method is called to adjust the height of the website
        window.addEventListener('resize', this.documentHeight(0));
        window.addEventListener('mouseup', this.documentHeight(500));
        window.addEventListener('touchend', this.documentHeight(500));
        // listener for orientation change on mobile devices
        //window.addEventListener('orientationchange', () => this.documentHeight(0), false); // this one is deprecated
        window.screen.orientation.addEventListener("change", (event) => {
            //const type = event.target.type;
            //const angle = event.target.angle;
            //console.log(`ScreenOrientation change: ${type}, ${angle} degrees.`);
            this.documentHeight(0);
        });
        this.documentHeight(0);

    }

    documentHeight = async (time) => {
        // for some gesture it is better to wait some time before making resizing method
        await new Promise(resolve => setTimeout(resolve, time));

        //const doc = document.documentElement;
        //doc.style.setProperty('--doc-height', window.innerHeight+"px");

        // getting value that will be used as height size
        const heights = [window.innerHeight];
        if(window.screen?.height)heights.push(window.screen?.height);
        const height = Math.min(...heights);
        const doc = document.documentElement;

        // if user zoomed on the website then do not change the height using javascript and set it to 100vh
        if(window.visualViewport.scale > 0.8 && window.visualViewport.scale < 1.2){
            doc.style.setProperty('--doc-height', height+"px");
        }
        else {
            doc.style.setProperty('--doc-height', "100vh");
        }

    }

    setActualSection = (newActualSection) => {
        if(newActualSection != "advancedSearch" && newActualSection != "quickSearch"){ this.setSearchText(""); }
        this.setTopMenuExpanded(false);
        if(newActualSection == "events"){
            this.openAlertMessage("My Events & Programs is not available yet.", "OK");
            newActualSection = "dashboard";
        }

        this.setState({ actualSection: newActualSection });
        localStorage.setItem("actualSection", newActualSection);
    }

    setLeftMenuOpened = (isOpened) => {
        this.setState({ leftMenuOpened: isOpened });
        localStorage.setItem("leftMenuOpened", isOpened);
    }

    logoutUser = () => {
        setToken(null);
        window.location.reload();
    }

    setSearchText = (textToSearch) => {
        this.setState({ searchText: textToSearch });
        localStorage.setItem("searchText", textToSearch);
    }

    setAllOrMy = (newValue) => {
        if(newValue == "all"){
            this.setState({ allOrMy: "all" });
        }else {
            this.setState({ allOrMy: "my" });
        }
    }

    setTopMenuExpanded = (value) => {
        this.setState({ topMenuExpanded: value });
    }

    openAlertMessage = async (message, closeButton) => {
        await this.setState({ alertMessage: message });
        await this.setState({ alertCloseButton: closeButton });

        // show mobile alertMessage or PC alertMessage
        if(showMobileVersion()){
            await this.setState({ prepareToShowAlertModalMessageMobile: true });
            this.setState({ showAlertModalMessageMobile: true });
        }
        else {
            await this.setState({ prepareToShowAlertModalMessage: true });
            this.setState({ showAlertModalMessage: true });
        }
    }

    openAlertMessageTwoActions = async (message, closeButton, actionButton, onAction) => {
        if((actionButton == null || actionButton == "") && onAction == null){
            this.openAlertMessage(message,closeButton);
        }
        else {
            await this.setState({ alertMessage: message });
            await this.setState({ alertCloseButton: closeButton });
            await this.setState({ alertActionButton: actionButton });
            await this.setState({ alertOnAction: onAction });

            // show mobile alertMessage or PC alertMessage
            if(showMobileVersion()){
                await this.setState({ prepareToShowAlertModalMessageTwoButtonMobile: true });
                this.setState({ showAlertModalMessageTwoButtonMobile: true });
            }
            else {
                await this.setState({ prepareToShowAlertModalMessageTwoButton: true });
                this.setState({ showAlertModalMessageTwoButton: true });
            }
        }
    }

    closeAlertMessage = async () => {
        this.setState({ showAlertModalMessage: false });
        this.setState({ showAlertModalMessageTwoButton: false });
        await new Promise(resolve => setTimeout(resolve, 1000));
        await this.setState({ prepareToShowAlertModalMessage: false });
        await this.setState({ prepareToShowAlertModalMessageTwoButton: false });
    }

    closeAlertModalMessageMobile = async () => {
        this.setState({ showAlertModalMessageMobile: false });
        await new Promise(resolve => setTimeout(resolve, 1000));
        await this.setState({ prepareToShowAlertModalMessageMobile: false });
    }

    closeAlertModalMessageTwoButtonMobile = async () => {
        this.setState({ showAlertModalMessageTwoButtonMobile: false });
        await new Promise(resolve => setTimeout(resolve, 1000));
        await this.setState({ prepareToShowAlertModalMessageTwoButtonMobile: false });
    }

    render() {
        const token = getToken();
        if(window.location.pathname == "/password/reset") {
            if(showMobileVersion()){
                return <RecoverPasswordPageMobile />
            }
            else {
                return <RecoverPasswordPage />
            }
        }
        if(!token) { return <Login setToken={setToken} /> }
        console.log(token);

        return (
            <BrowserRouter>
                <div className="horizontalStack" style={{height: "100%", height: "var(--doc-height)", minHeight: "100vh", minHeight: "var(--doc-height)", backgroundColor: "var(--full-black)"}}>

                    {/* LEFT MENU */}
                    {showMobileVersion() ?
                        <div>
                            {this.state.leftMenuOpened == "true" ? <LeftMenuMobile actualSection={this.state.actualSection} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} setLeftMenuOpened={this.setLeftMenuOpened} allOrMy={this.state.allOrMy} setAllOrMy={this.setAllOrMy} />
                            : <LeftMenuClosedMobile actualSection={this.state.actualSection} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} setLeftMenuOpened={this.setLeftMenuOpened} /> }
                        </div>
                    :   <div>
                            {this.state.leftMenuOpened == "true" ? <LeftMenu actualSection={this.state.actualSection} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} setLeftMenuOpened={this.setLeftMenuOpened} allOrMy={this.state.allOrMy} setAllOrMy={this.setAllOrMy} />
                            : <LeftMenuClosed actualSection={this.state.actualSection} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} setLeftMenuOpened={this.setLeftMenuOpened} /> }
                        </div>}

                    <div className="flex" style={{height: "100%", height: "var(--doc-height)", minHeight: "100vh", minHeight: "var(--doc-height)", backgroundColor: "var(--lightest-gray)"}}>

                        {/* TOP MENU */}
                        {showMobileVersion() ?
                            <TopMenuMobile searchText={this.state.searchText} setSearchText={this.setSearchText} setActualSection={this.setActualSection} topMenuExpanded={this.state.topMenuExpanded} setTopMenuExpanded={this.setTopMenuExpanded} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />
                        :
                            <TopMenu searchText={this.state.searchText} setSearchText={this.setSearchText} setActualSection={this.setActualSection} topMenuExpanded={this.state.topMenuExpanded} setTopMenuExpanded={this.setTopMenuExpanded} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />
                        }

                        {showMobileVersion() ?
                            <Routes>
                                {/*
                                <Route exact path="/Testing" element={<TestingPage openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/People" element={<PeopleList searchText={this.state.searchText} allOrMy={this.state.allOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Churches" element={<ChurchesList searchText={this.state.searchText} allOrMy={this.state.allOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/MyConversations" element={<MyConversations setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                */}
                                <Route exact path="/" element={<InstructionsMobile setActualSection={this.setActualSection} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Instructions" element={<InstructionsMobile setActualSection={this.setActualSection} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/PeopleProfile/:personId" element={<PeopleProfileMobile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/PeoplePrivateProfile/:personId" element={<PeoplePrivateProfileMobile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/People" element={<PeopleListMobile searchText={this.state.searchText} allOrMy={this.state.allOrMy} setAllOrMy={this.setAllOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Churches" element={<ChurchesListMobile searchText={this.state.searchText} allOrMy={this.state.allOrMy} setAllOrMy={this.setAllOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Schools" element={<SchoolsListMobile searchText={this.state.searchText} allOrMy={this.state.allOrMy} setAllOrMy={this.setAllOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/ChurchProfile/:churchId" element={<ChurchProfileMobile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/SchoolProfile/:schoolId" element={<SchoolProfileMobile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/DenominationProfile/:denominationId" element={<DenominationProfileMobile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/TeamProfile/:teamId" element={<TeamProfileMobile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/MyConversations" element={<MyConversationsMobile searchText={this.state.searchText} allOrMy={this.state.allOrMy} setAllOrMy={this.setAllOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/ObservationsList/:churchId" element={<ObservationsListMobile searchText={this.state.searchText} allOrMy={this.state.allOrMy} setAllOrMy={this.setAllOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/MyConversations/:personId" element={<MyConversationsMobile searchText={this.state.searchText} allOrMy={this.state.allOrMy} setAllOrMy={this.setAllOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/QuickSearch" element={<QuickSearchMobile searchText={this.state.searchText} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="*" element={<NotFoundPage />} />
                            </Routes>
                        :
                            <Routes>
                                <Route exact path="/" element={<Dashboard setActualSection={this.setActualSection} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Instructions" element={<Instructions setActualSection={this.setActualSection} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Testing" element={<TestingPage openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/People" element={<PeopleList searchText={this.state.searchText} allOrMy={this.state.allOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Churches" element={<ChurchesList searchText={this.state.searchText} allOrMy={this.state.allOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Schools" element={<SchoolsList searchText={this.state.searchText} allOrMy={this.state.allOrMy} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/PeopleProfile/:personId" element={<PeopleProfile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/PeoplePrivateProfile/:personId" element={<PeoplePrivateProfile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/ChurchProfile/:churchId" element={<ChurchProfile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/DenominationProfile/:denominationId" element={<DenominationProfile setActualSection={this.setActualSection} />} />
                                <Route exact path="/TeamProfile/:teamId" element={<TeamProfile setActualSection={this.setActualSection} />} />
                                <Route exact path="/SchoolProfile/:schoolId" element={<SchoolProfile setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/NewContactPerson" element={<NewContactPerson setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} />} />
                                <Route exact path="/NewContactChurch" element={<NewContactChurch setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} />} />
                                <Route exact path="/NewContactSchool" element={<NewContactSchool setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} />} />
                                <Route exact path="/NewPrivateContactPerson" element={<NewPrivateContactPerson setActualSection={this.setActualSection} openAlertMessage={this.openAlertMessage} />} />
                                <Route exact path="/MyConversations" element={<MyConversations setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Discipling" element={<MyDiscipling setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/AdvancedSearch" element={<AdvancedSearch searchText={this.state.searchText} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/QuickSearch" element={<QuickSearch searchText={this.state.searchText} setActualSection={this.setActualSection} leftMenuOpened={this.state.leftMenuOpened} openAlertMessage={this.openAlertMessage} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="/Rights" element={<Rights setActualSection={this.setActualSection} openAlertMessageTwoActions={this.openAlertMessageTwoActions} />} />
                                <Route exact path="*" element={<NotFoundPage />} />
                            </Routes>
                        }

                        {this.state.prepareToShowAlertModalMessage ? <AlertModalMessage showModal={this.state.showAlertModalMessage} closeModal={this.closeAlertMessage} message={this.state.alertMessage} closeButton={this.state.alertCloseButton} /> : null }
                        {this.state.prepareToShowAlertModalMessageTwoButton ? <AlertModalMessageTwoButton showModal={this.state.showAlertModalMessageTwoButton} closeModal={this.closeAlertMessage} message={this.state.alertMessage} closeButton={this.state.alertCloseButton} actionButton={this.state.alertActionButton} onActionClick={this.state.alertOnAction} /> : null }

                        {/* Alert modals for mobile devices */}
                        {this.state.prepareToShowAlertModalMessageMobile ? <AlertModalMessageMobile showModal={this.state.showAlertModalMessageMobile} closeModal={this.closeAlertModalMessageMobile} message={this.state.alertMessage} closeButton={this.state.alertCloseButton} /> : null }
                        {this.state.prepareToShowAlertModalMessageTwoButtonMobile ? <AlertModalMessageTwoButtonMobile showModal={this.state.showAlertModalMessageTwoButtonMobile} closeModal={this.closeAlertModalMessageTwoButtonMobile} message={this.state.alertMessage} closeButton={this.state.alertCloseButton} actionButton={this.state.alertActionButton} onActionClick={this.state.alertOnAction} /> : null }

                    </div>

                </div>
            </BrowserRouter>
        );
    }
}

export default App;
