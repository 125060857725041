import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Resizer from "react-image-file-resizer";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// import assets
import { Link } from 'react-router-dom';
import person_avatar from '../assets/person_avatar.png';
import background from '../assets/background.jpg';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';
import icon_done_white from '../assets/icon_done_white.png';

// import components
import RoundButton from '../components/RoundButton.js';
import PopupButtonDescription from '../components/PopupButtonDescription.js';
import DeleteDialog from '../components/DeleteDialog.js';
import ExpandButton from '../components/ExpandButton.js';
import ProfileImage from '../components/ProfileImage.js';

class ProfileTilePrivate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            mainContactId: 0,
            updateExisting: true, // if I am in create new contact mode it would be false
            isMyContact: false, // default should be false
            toolOpened: false,
            showDeleteDialog: false,
            is_private: 1,
            dataCategory: "name", // name, contact, address
            actualProfile: ([{id:1,first_name:"",last_name:"",email:"",phone:"",church:"Error",city:"",country:"Error",nick_name:""}]),
            listOfPrefix: ([{id:1, label: "Bc."}]),
            listOfSuffix: ([{id:1, label: "Ph.D."}]),
            uploadedImageBase64: "",
        }

        this.hiddenFileInput = React.createRef();
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = async () => {
        this.setState({ uploadedImageBase64: "" });

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            if(Number(id) == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/People"); window.location.reload(); }
                return;
            }
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_cont });
                }
            });
            this.setState({ updateExisting: true });
            this.setState({ mainContactId: id });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=avatar&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });
        }
        else {
            this.setState({ updateExisting: false });
            this.setState({ toolOpened: true });
        }

        // get all prefix for prefix select
        Axios.get('/api/prefix/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfPrefix: response.data.result })
        });

        // get all suffix for suffix select
        Axios.get('/api/suffix/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfSuffix: response.data.result })
        });
    }

    createFullProfile = async () => {
        //await this.setState({ is_private: 0 });
        //this.saveChangesAction();

        Axios.post('/api/cont/upd2full/'+this.state.mainContactId).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ redirectTo: "/PeopleProfile/"+this.state.mainContactId });
        });
    }

    createNewAction = async () => {
        if(this.checkIfDataAreOk() == false){ return; }

        Axios.post('/api/cont/ins', {
            ContTpId: 1, // idType=1 is for person idType=4 is for church
            IsPrivate: this.state.is_private,
            FirstName: this.state.actualProfile[0].first_name,
            MiddleName: this.state.actualProfile[0].middle_name,
            LastName: this.state.actualProfile[0].last_name,
            NickName: this.state.actualProfile[0].nick_name,
            PrefixId: this.state.actualProfile[0].prefix_id,
            SuffixId: this.state.actualProfile[0].suffix_id,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            // add created contact to the MY_DSC
            Axios.post("/api/my_dsc/ins", {
                ContId: response.data.msg.id,
            }).then((responseTwo) => {
                if(checkForErrorsInRequest(responseTwo.data.msg, this.props.openAlertMessage) == 1){ return; }
                // show profile of created contact where user can add D_LEVEL
                if(this.state.is_private == 1){
                    this.setState({ redirectTo: "/PeoplePrivateProfile/"+response.data.msg.id+"?action=fromMyDiscipling" });
                }
                else if(this.state.is_private == 0){
                    this.setState({ redirectTo: "/PeopleProfile/"+response.data.msg.id+"?action=fromMyDiscipling" });
                }

                if(this.state.uploadedImageBase64 != ""){
                    this.uploadAvatarImage(response.data.msg.id);
                }
            });
        });
    };

    saveChangesAction = () => {
        if(this.checkIfDataAreOk() == false){ return; }

        //alert(this.state.actualProfile[0].prefix_id);
        Axios.post('/api/cont/upd/'+this.state.mainContactId, {
            ContTpId: 1, // idType=1 is for person idType=4 is for church
            IsPrivate: this.state.is_private,
            CountryId: this.state.actualProfile[0].country_id,
            FirstName: this.state.actualProfile[0].first_name,
            MiddleName: this.state.actualProfile[0].middle_name,
            LastName: this.state.actualProfile[0].last_name,
            NickName: this.state.actualProfile[0].nick_name,
            PrefixId: this.state.actualProfile[0].prefix_id,
            SuffixId: this.state.actualProfile[0].suffix_id,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.openCloseTool();
            this.loadData();
            //this.setState({ redirectTo: "/Discipling" });
            if(this.state.is_private == 0){
                this.setState({ redirectTo: "/PeopleProfile/"+this.state.mainContactId });
            }
        });
    };

    checkIfDataAreOk = () => {
        if(this.state.actualProfile[0].first_name == "" || this.state.actualProfile[0].first_name == undefined){
            this.props.openAlertMessage("You didn't fill first name.","OK");
            this.setState({ toolOpened: true });
            this.setState({ dataCategory: "name" });
            return false;
        }
        else if(this.state.actualProfile[0].last_name == "" || this.state.actualProfile[0].last_name == undefined){
            this.props.openAlertMessage("You didn't fill last name.","OK");
            this.setState({ toolOpened: true });
            this.setState({ dataCategory: "name" });
            return false;
        }
        return true;
    }

    deleteAction = () => {
        Axios.delete('/api/cont/del/'+this.state.mainContactId, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ redirectTo: "/People" });
        });
    };

    askToDelete = () => {
        this.setState({ showDeleteDialog: true });
    }

    cancelDeleting = () => {
        this.setState({ showDeleteDialog: false });
    }

    openCloseTool = () => {
        if(this.state.toolOpened){
            this.setState({ toolOpened: false });
            this.props.onCloseOrOpen("");
        }
        else {
            this.setState({ toolOpened: true });
            this.props.onCloseOrOpen("profiledata");
        }
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_cont/del/', {
                data: {ContId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_cont/ins/', {
                ContId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    /* START OF METHODS FOR AVATAR IMAGE */

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    uploadImageClick = () => { this.hiddenFileInput.current.click(); };

    deleteProfileImage = () => {
        Axios.post('/api/file/delete', {
            FilePrefix: "avatar",
            ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ uploadedImageBase64: "" });
        });
    }

    imageIsSelected = async (event) => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded.size > 5000000){
            this.props.openAlertMessage("Error! Maximum size of picture is 5 MB.","OK");
            return;
        }
        var base64 = await this.toBase64(fileUploaded);
        var prefix = fileUploaded.name.substring(fileUploaded.name.lastIndexOf(".")+1);
        //this.setState({ uploadedImageBase64: base64 });

        try {
            Resizer.imageFileResizer(fileUploaded, 300, 300, "JPEG", 100, 0,
                (uri) => {
                    //console.log(uri);
                    this.setState({ uploadedImageBase64: uri });
                    if(this.state.updateExisting){
                        this.uploadAvatarImage(this.state.mainContactId);
                    }
                },
                "base64", 200,  200
            );
        }
        catch (err) {
            //console.log(err);
            this.props.openAlertMessage("Error while uploading image.","OK");
        }
        //var improvedBase64 = base64.substring(base64.lastIndexOf(";base64,")+8);
    };

    uploadAvatarImage = (contactId) => {
        if(this.state.uploadedImageBase64 == "")return;
        Axios.post('/api/file/upload', {
            FilePrefix: "avatar",
            ContId: contactId,
            content: this.state.uploadedImageBase64,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            //alert("Avatar image was uploaded.");
        });
    }

    /* END OF METHODS FOR AVATAR IMAGE */

    changeDataCategoryToName = () => { this.setState({ dataCategory: "name" }); }

    nameSectionWasEdited = (actualProfile) => {
        if((typeof(actualProfile.prefix_id) === "undefined" || actualProfile.prefix_id == 0)
        && (typeof(actualProfile.suffix_id) === "undefined" || actualProfile.suffix_id == 0)
        && (typeof(actualProfile.first_name) === "undefined" || actualProfile.first_name == "")
        && (typeof(actualProfile.middle_name) === "undefined" || actualProfile.middle_name == "")
        && (typeof(actualProfile.last_name) === "undefined" || actualProfile.last_name == "")
        && (typeof(actualProfile.nick_name) === "undefined" || actualProfile.nick_name == "")){
            return false;
        }
        else {
            return true;
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div className="profileTile">
                <div className="flex" style={{flex: 1, height: 203, position: "relative"}}>

                    <div style={{width: "calc(100% + 40px)", backgroundColor: "var(--full-black)", height: "calc(50% + 20px)", position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                        <img style={{width: "100%", height: "100%", borderRadius: "25px 25px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                    </div>

                    <div className="horizontalStack" style={{height: "100%"}}>

                        <div className="verticalStack" style={{zIndex: "1"}}>

                            { this.state.isMyContact == false ? <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add to My Contacts"} blackArrow={false} />
                                </div>
                            </div> :
                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Remove from My Contacts"} blackArrow={false} />
                                </div>
                            </div> }

                            <div className="flex"></div>

                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <ExpandButton isOpen={this.state.toolOpened} style={{marginTop: 5}} onClick={this.openCloseTool} />
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add and Edit Contact Info"} blackArrow={true} />
                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{zIndex: "0", marginLeft: "-10px", marginRight: "23px"}}>
                            <div className="flex"></div>

                            <ProfileImage
                                initials={getInitials(this.state.actualProfile[0].first_name+" "+this.state.actualProfile[0].last_name)}
                                uploadedImageBase64={this.state.uploadedImageBase64}
                                toolOpened={this.state.toolOpened}
                                uploadImageClick={this.uploadImageClick}
                                deleteImageClick={this.deleteProfileImage}
                                contactType={"person"}
                            />

                            <input type="file"
                                ref={this.hiddenFileInput}
                                style={{display:'none'}}
                                accept={"image/png, image/jpg, image/jpeg"}
                                onChange={(event) => this.imageIsSelected(event)}
                            />

                            <div className="flex"></div>
                        </div>

                        <div className="verticalStack flex" style={{zIndex: "0", height: "calc(100% + 20px)"}}>
                            <div className="verticalStackCenter flex" style={{height: "100%", marginTop: "-20px"}}>
                                <div className="horizontalStack" style={{height: "100%"}}>
                                    <div className="verticalStack" style={{flex: 1, height: "100%"}}>
                                        <div className="flex"></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsMedium40White responsiveThreeDots"><b>{this.state.actualProfile[0].first_name} {this.state.actualProfile[0].last_name}</b></span>
                                        </div>
                                        <div className="flex"></div>
                                    </div>
                                    <div style={{width: 20}}></div>
                                    <div className="verticalStackCenter">
                                        {this.state.updateExisting ? <RoundButton title={"Create a Full Profile"} className={"roundButtonOrange"} icon={icon_add_white} onClick={this.createFullProfile} whiteText={true} /> : null }
                                        {/*<span className="fontPoppinsSemiBold15" style={{color: "var(--jv-orange)"}}><b>{this.state.actualProfile[0].prim_role} {this.state.actualProfile[0].other_roles > 0 ? "+"+this.state.actualProfile[0].other_roles: ""}</b></span>
                                        <div style={{height: "12px"}}></div>
                                        <span className="fontPoppinsRegular13" style={{color: "var(--white)"}}>{this.state.actualProfile[0].rel_yrs} Years {this.state.actualProfile[0].rel_start_dt} {this.state.actualProfile[0].rel_end_dt != null ? " to "+this.state.actualProfile[0].rel_end_dt : " till now"}</span>
                                        */}
                                    </div>
                                </div>
                            </div>

                            <div className="verticalStackCenter flex">
                                <div className="horizontalStack" style={{height: "100%"}}>

                                    <div className="verticalStackCenter" style={{flex: 3, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold15 responsiveThreeDots">Private Disciple-Making Relationship</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <span className="fontPoppinsRegular13">This contact is only viewable by you from the <span className="fontPoppinsRegular13Orange clickable">My Disciple-Making Relationships</span> tool.</span>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Editing data part */}

                <div className="flex" style={{flex: 1, position: "relative", display: this.state.toolOpened ? "inline" : "none"}}>

                    <div className="verticalStack" style={{marginTop: 20, marginBottom: 20}}>

                        {/* Name */}

                        <div className="horizontalStackCenter clickable" style={{height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={this.changeDataCategoryToName}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.dataCategory == "name" ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Name*</span> : null }
                            { !this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Name*</span> : null }
                        </div>

                        <div className="horizontalStack" style={{display: this.state.dataCategory == "name" ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Title 1:</span>
                                <select className="profileTileInputText topMenuSelectBox" name="prefix" id="prefix" style={{width: 70}} value={this.state.actualProfile[0].prefix_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].prefix_id = e.target.value; return {actualProfile}; })}>
                                    <option value=""></option>
                                    {this.state.listOfPrefix.map(function (item) {
                                        return (
                                            <option value={item.id}>{item.label}</option>
                                        )
                                    }.bind(this))}
                                </select>
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>First Name:*</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputFirstName" name="inputFirstName" value={this.state.actualProfile[0].first_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].first_name = e.target.value; return {actualProfile}; })}/>
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Middle Name:</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputMiddleName" name="inputMiddleName" value={this.state.actualProfile[0].middle_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].middle_name = e.target.value; return {actualProfile}; })} />
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Last Name:*</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputLastName" name="inputLastName" value={this.state.actualProfile[0].last_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].last_name = e.target.value; return {actualProfile}; })} />
                            </div>

                            <div className="verticalStack" style={{marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Title 2:</span>
                                <select className="profileTileInputText topMenuSelectBox" name="suffix" id="suffix" style={{width: 70}} value={this.state.actualProfile[0].suffix_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].suffix_id = e.target.value; return {actualProfile}; })}>
                                    <option value=""></option>
                                    {this.state.listOfSuffix.map(function (item) {
                                        return (
                                            <option value={item.id}>{item.label}</option>
                                        )
                                    }.bind(this))}
                                </select>
                            </div>


                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Nickname:</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputNickname" name="ínputNickname" value={this.state.actualProfile[0].nick_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].nick_name = e.target.value; return {actualProfile}; })} />
                            </div>
                        </div>
                    </div>

                    <div className="horizontalStack">
                        {!this.state.updateExisting ? <RoundButton title={"Save"} className={"roundButtonOrange"} style={{color: "white"}} onClick={this.createNewAction} whiteText={true} /> : null }
                        {this.state.updateExisting ? <RoundButton title={"Save"} className={"roundButtonOrange"} style={{color: "white"}} onClick={this.saveChangesAction} whiteText={true} /> : null }
                        {this.state.updateExisting ? <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginLeft: 12}} onClick={() => this.openCloseTool()} whiteText={true} /> : null }
                        {!this.state.updateExisting ? <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginLeft: 12}} onClick={() => navigate(-1)} whiteText={true} /> : null }
                        {this.state.updateExisting ? <div style={{border: "none", overflow: "hidden"}}>
                            <div className="horizontalStack" style={{position: "relative"}}>
                                <RoundButton title={"Delete"} className={"roundButtonGray"} style={{marginLeft: 12}} onClick={this.askToDelete} whiteText={true} />
                            </div>
                            <DeleteDialog showHide={this.state.showDeleteDialog} onDelete={this.deleteAction} deleteValue={""} onClose={this.cancelDeleting} text={"Are you sure want to delete this person?"} />
                        </div> : null }
                    </div>

                </div>
            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(ProfileTilePrivate);
