import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';
import '../styles/tiles.css';

import btn_expand from '../assets/btn_expand.png';
import btn_expand_light from '../assets/btn_expand_light.png';

class ExpandButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            onHover: false,
        };
    }

    render(){
        return(
            <span onClick={this.props.onClick}>
                <img
                    className={this.props.isOpen ? "expandButtonExpandedNew clickable" : "expandButtonNew clickable"}
                    style={this.props.style}
                    src={this.state.onHover ? btn_expand_light : btn_expand}
                    onClick={this.openCloseTile}
                    onMouseEnter={() => { this.setState({ onHover: true }) }}
                    onMouseOut={() => { this.setState({ onHover: false}) }}
                />
            </span>
        );
	}
}

export default ExpandButton;