import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';

// import assets
import icon_church from '../assets/icon_church.png';

class EmptyChurchInfoTile extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="churchInfoTile">

                <div className="horizontalStack">
                    <div className="verticalStack flex">
                        <div className="emptySpace" style={{width: 130}}></div>

                        <div style={{height: "22px"}}></div>

                        <div className="emptySpace" style={{width: 220}}></div>
                        <div className="emptySpace" style={{width: 220, marginTop: 10}}></div>

                        <div style={{height: "22px"}}></div>

                        <div className="emptySpace" style={{width: 250}}></div>
                        <div className="emptySpace" style={{width: 250, marginTop: 10}}></div>
                        <div className="emptySpace" style={{width: 250, marginTop: 10}}></div>

                        <div style={{height: "22px"}}></div>

                        <div className="emptySpace" style={{width: 220}}></div>
                    </div>
                    <div className="verticalStack" style={{alignItems: "center"}}>
                        <img className="churchInfoTileIcon" src={icon_church}/>
                        <div className="flex"></div>
                        <div className="circleButtonContainer circleButtonContainerEmpty clickable"></div>
                    </div>
                </div>

            </div>
        );
    }
}

export default EmptyChurchInfoTile;
