import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';
import SearchPopup from '../components/SearchPopup.js';

// assets
import close_white from '../assets/close_white.png';

class MinistryStatisticsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // AddStatistics, EditStatistics
            redirectTo: null,
            whoId: -1,
            whoName: "+ Add a Church",
            withWhatTeam: -1,
            withWhatTeamName: "+ Add",
            selectedSchoolYearId: -1,
            selectedSchoolYearName: "+ Add",
            createdBy: "Unknown",
            ministryName: "",
            believersNum: "",
            conversionsNum: "",
            attendanceNum: "",
            unbelieversNum: "",
            baptismsNum: "",
            findChurchId: "",
            foundChurches: ([]),
            showPopupWho: false,
            showPopupWithWhatTeam: false,
            showPopupSchoolYear: false,
            teamTypesArray: ([{"id":1,"name":"Church"}]),
            schoolYearsArray: ([{"id":1,"label":"2021-2022"}]),
        };

        this.refPopupWho = React.createRef();
        this.refInputWho = React.createRef();
        this.refPopupWithWhatTeam = React.createRef();
        this.refPopupSchoolYear = React.createRef();
    }

    componentDidMount(){
        this.loadTeamTypesArray();
        this.loadSchoolYearsArray();

        if(this.props.statisticsId != -1 && this.props.actionType == "EditStatistics"){
            this.loadExistingStatistics(this.props.statisticsId);
        }
        else {
            // add email of signed user to the list of emails
            var token_object = JSON.parse(localStorage.getItem("token"));
            var signedUserId = token_object.id;
            var signedUserEmail = token_object.email;
            var signedUserName = token_object.first_name + " " + token_object.last_name;
            this.setState({ createdBy: signedUserName || "" });

            if(this.props.contactId != -1 && this.props.contactName != ""){
                this.setState({ whoId: this.props.contactId });
                this.setState({ whoName: this.props.contactName });
            }
        }
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupWithWhatTeam.current.contains(e.target)) && this.state.showPopupWithWhatTeam) {
            // user clicked out of the PopupWithWhatTeam and that popup is now opened
            this.setState({ showPopupWithWhatTeam: false })
        }
        if ( (!this.refPopupSchoolYear.current.contains(e.target)) && this.state.showPopupSchoolYear) {
            this.setState({ showPopupSchoolYear: false })
        }
        if ( (!this.refPopupWho.current.contains(e.target)) && this.state.showPopupWho) {
            this.setState({ showPopupWho: false })
        }
        if ( this.state.showPopupWho ) this.refInputWho.current.focus();
    }

    loadTeamTypesArray = () => {
        Axios.get('/api/team/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;

            var teamsArray = [];

            for(let oneRow of results){
                teamsArray.push({"id":oneRow.id, "name": oneRow.label});
            }
            this.setState({ teamTypesArray: teamsArray });
        });
    }

    loadSchoolYearsArray = () => {
        Axios.get('/api/sch_yr/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;

            var schoolYearsArray = [];

            for(let oneRow of results){
                schoolYearsArray.push({"id":oneRow.id, "label": oneRow.label});
            }
            this.setState({ schoolYearsArray: schoolYearsArray });
        });
    }

    loadExistingStatistics = async (id) => {
        await Axios.get('/api/mnry_stat/get/'+id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;

            this.setState({ createdBy: results[0].cr_by }); // need to receive this in API

            this.setState({ whoId: results[0].cont_id });
            this.setState({ whoName: results[0].cont_nm });

            this.setState({ withWhatTeam: results[0].team_id });
            this.setState({ withWhatTeamName: results[0].team_nm });

            this.setState({ selectedSchoolYearId: results[0].school_year_id });
            this.setState({ selectedSchoolYearName: results[0].school_year });

            this.setState({ ministryName: results[0].mnry_name });

            this.setState({ attendanceNum: results[0].attn_cnt });
            this.setState({ believersNum: results[0].blv_cnt });
            this.setState({ unbelieversNum: results[0].unb_cnt });
            this.setState({ conversionsNum: results[0].conv_cnt });
            this.setState({ baptismsNum: results[0].bapt_cnt });

        });
    }

    action = () => {
        this.props.openAlertMessage("This function is not working yet.", "OK");
    }

    // this method works for both creating new Statistics and saving changed Statistics
    saveStatisticsAction = async (createAnotherNewAfter) => {

        var ContId = this.state.whoId;
        var TeamId = this.state.withWhatTeam;
        var SchYrId = this.state.selectedSchoolYearId;
        var MnryName = this.state.ministryName;
        var AttnCnt = this.state.attendanceNum == "" ? 0 : this.state.attendanceNum;
        var BlvCnt = this.state.believersNum == "" ? 0 : this.state.believersNum;
        var UnbCnt = this.state.unbelieversNum == "" ? 0 : this.state.unbelieversNum;
        var ConvCnt = this.state.conversionsNum == "" ? 0 : this.state.conversionsNum;
        var BaptCnt = this.state.baptismsNum == "" ? 0 : this.state.baptismsNum;

        //alert("SAVE:"+BaptCnt);
        //return;

        if(TeamId == -1 || SchYrId == -1){
            this.props.openAlertMessage("You need to fill all required fields.", "OK");
            return;
        }
        if(AttnCnt < 0 || BlvCnt < 0 || UnbCnt < 0 || ConvCnt < 0 || BaptCnt < 0){
            this.props.openAlertMessage("You cannot fill values  lower than zero.", "OK");
            return;
        }

        var url = "/api/mnry_stat/ins";
        if(this.props.actionType == "EditStatistics"){url = "/api/mnry_stat/upd/"+this.props.statisticsId;}

        Axios.post(url, {
            ContId: ContId,
            TeamId: TeamId,
            SchYrId: SchYrId,
            MnryName: MnryName,
            AttnCnt: AttnCnt,
            BlvCnt: BlvCnt,
            UnbCnt: UnbCnt,
            ConvCnt: ConvCnt,
            BaptCnt: BaptCnt,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.closeModal(this.state.editingMode, createAnotherNewAfter);
        });
    }

    searchForChurches = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=4&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundChurches: response.data.result });
            });
        }
    }

    selectFoundChurch = async (contactId, contactName) => {
        this.setState({ whoId: contactId });
        this.setState({ whoName: contactName });
        this.setState({ showPopupWho: false });
        this.setState({ find_ChurchID: "" });
        this.setState({ foundChurches: [] });
    }

    selectTeamType = async (teamId) => {
        this.setState({ withWhatTeam: teamId });

        for(let oneRow of this.state.teamTypesArray){
            if(oneRow.id == teamId){
                this.setState({ withWhatTeamName: oneRow.name });
            }
        }

        this.setState({ showPopupWithWhatTeam: false });
    }

    selectSchoolYear = async (yearId) => {
        this.setState({ selectedSchoolYearId: yearId });

        for(let oneRow of this.state.schoolYearsArray){
            if(oneRow.id == yearId){
                this.setState({ selectedSchoolYearName: oneRow.label });
            }
        }

        this.setState({ showPopupSchoolYear: false });
    }

    deleteConversation = () => {
        if(this.props.statisticsId != -1 && this.props.actionType == "EditStatistics"){
            Axios.delete('/api/mnry_stat/del/'+this.props.statisticsId, {
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.props.closeModal(this.state.editingMode, false);
            });
        }
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={this.state.modalExpanded}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{this.state.editingMode == "EditStatistics" ? "Edit Ministry Statistics" : "Add Ministry Statistics"}</span>

                                <div className="horizontalStackCenter">

                                    <SearchPopup
                                        id={"searchContact"}
                                        textName={this.state.whoName}
                                        textStyle={this.state.whoId == -1 ? "italicOrange" : "normalBlue"}
                                        value={this.state.find_ChurchID}
                                        changeValue={(text) => {this.setState({ find_ChurchID: text }); this.searchForChurches(text);}}
                                        foundRecords={this.state.foundChurches}
                                        refPopup={this.refPopupWho}
                                        refInput={this.refInputWho}
                                        showPopup={this.state.showPopupWho}
                                        showHidePopup={() => this.setState({ showPopupWho: !this.state.showPopupWho })}
                                        addContactToArray={(item) => this.selectFoundChurch(item.id,item.short_name)}
                                        showEmailStyle={false}
                                    />

                                </div>

                            </div>

                            <div className="verticalStack">
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                                    <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 12}}>

                            <div className="horizontalStack flex">

                                <div className="universalPopupSelectContainer" ref={this.refPopupWithWhatTeam}>

                                    <div className="universalPopupSelectVisible horizontalStack">
                                        <span className="fontPoppinsRegular13">Ministry/Program:*</span>
                                        <div style={{width: 5}}></div>
                                        <span className="fontPoppinsRegular13 clickable" style={{color: this.state.withWhatTeam == -1 ? "var(--jv-orange)" : "var(--blue-button-link)"}} onClick={()=>this.setState({showPopupWithWhatTeam: !this.state.showPopupWithWhatTeam})}>{this.state.withWhatTeamName}</span>
                                    </div>

                                    <div className="universalPopupSelectHidden" style={{display: this.state.showPopupWithWhatTeam ? "" : "none"}}>
                                        <div className="verticalStack" onClick={() => this.setState({showPopupWithWhatTeam: false})}>
                                            <span className="fontPoppinsRegular13">Select a Ministry/Program</span>

                                            <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                            <div className="verticalStack" onChange={event => this.selectTeamType(event.target.value)}>

                                                {this.state.teamTypesArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                            <input className="clickable" type="radio" id={item.id+""+item.name} name="ministry_type" value={item.id} checked={item.id == this.state.withWhatTeam ? true : false}/>
                                                            <label for={item.id+""+item.name} className="fontPoppinsRegular13 clickable" style={{marginLeft: 5}}>{item.name}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack flex">

                                <div className="universalPopupSelectContainer" ref={this.refPopupSchoolYear}>

                                    <div className="universalPopupSelectVisible horizontalStack">
                                        <span className="fontPoppinsRegular13">School Year:*</span>
                                        <div style={{width: 5}}></div>
                                        <span className="fontPoppinsRegular13 clickable" style={{color: this.state.selectedSchoolYearId == -1 ? "var(--jv-orange)" : "var(--blue-button-link)"}} onClick={()=>this.setState({showPopupSchoolYear: !this.state.showPopupSchoolYear})}>{this.state.selectedSchoolYearName}</span>
                                    </div>

                                    <div className="universalPopupSelectHidden" style={{display: this.state.showPopupSchoolYear ? "" : "none"}}>
                                        <div className="verticalStack" onClick={() => this.setState({showPopupSchoolYear: false})}>
                                            <span className="fontPoppinsRegular13">Select a School Year</span>

                                            <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                            <div className="verticalStack" onChange={event => this.selectSchoolYear(event.target.value)}>

                                                {this.state.schoolYearsArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                            <input className="clickable" type="radio" id={item.id+""+item.label} name="school_year" value={item.id}  checked={item.id == this.state.selectedSchoolYearId ? true : false}/>
                                                            <label for={item.id+""+item.label} className="fontPoppinsRegular13 clickable" style={{marginLeft: 5}}>{item.label}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack flex">
                                <span className="fontPoppinsRegular13">Created by:*</span>
                                <div style={{width: 5}}></div>
                                <span className="fontPoppinsRegular13" style={{color: "var(--blue-button-link)"}}>{this.state.createdBy}</span>
                            </div>

                        </div>

                        <div className="verticalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 2}}>

                            <div className="horizontalStack">
                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">{"Ministry\'s Secondary Name (if it has one)?"}</span>
                                    <input className="profileTileInputText" type="text" style={{width: "80%", minWidth: 220, marginTop: 12}} id="searchMinistry" name="searchMinistry" value={this.state.ministryName} onChange={e => this.setState({ ministryName: e.target.value })} />
                                </div>

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">{"How Many Believers?"}</span>
                                    <input className="profileTileInputText" type="number" style={{width: "25%", minWidth: 50, marginTop: 12}} id="inputBelievers" name="inputBelievers" value={this.state.believersNum} onChange={e => this.setState({ believersNum: e.target.value })} min={0} />
                                </div>

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">{"How Many Conversions?"}</span>
                                    <input className="profileTileInputText" type="number" style={{width: "25%", minWidth: 50, marginTop: 12}} id="inputConversions" name="inputConversions" value={this.state.conversionsNum} onChange={e => this.setState({ conversionsNum: e.target.value })} min={0} />
                                </div>
                            </div>

                            <div style={{height: 12}}/>

                            <div className="horizontalStack">
                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">{"Attendance:"}</span>
                                    <input className="profileTileInputText" type="number" style={{width: "50%", minWidth: 120, marginTop: 12}} id="inputAttendance" name="inputAttendance" value={this.state.attendanceNum} onChange={e => this.setState({ attendanceNum: e.target.value })} min={0} />
                                </div>

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">{"How Many Unbelievers?"}</span>
                                    <input className="profileTileInputText" type="number" style={{width: "25%", minWidth: 50, marginTop: 12}} id="inputUbelievers" name="inputUbelievers" value={this.state.unbelieversNum} onChange={e => this.setState({ unbelieversNum: e.target.value })} min={0} />
                                </div>

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">{"How Many Baptisms?"}</span>
                                    <input className="profileTileInputText" type="number" style={{width: "25%", minWidth: 50, marginTop: 12}} id="inputBaptisms" name="inputBaptisms" value={this.state.baptismsNum} onChange={e => this.setState({ baptismsNum: e.target.value })} min={0} />
                                </div>
                            </div>

                        </div>

                        <div className="horizontalStack" style={{marginTop: "12px"}}>
                            <RoundButton title={"Save Data"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.saveStatisticsAction(false)} />
                            <RoundButton title={"Save and Add New Program Data"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.saveStatisticsAction(true)} />
                            {this.props.statisticsId != -1 && this.props.actionType == "EditStatistics" ? <RoundButton title={"Delete"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.deleteConversation()} /> : null }
                            <div className="flex"></div>
                            <span className="fontPoppinsItalic13Gray">*Required fields</span>
                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}

}

export default MinistryStatisticsModal;