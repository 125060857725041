import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';
import '../styles/tiles.css';

// assets
import editButtonDropdownCircle from '../assets/editButtonDropdownCircle.png';
import editButtonDropdownCircleClosed from '../assets/editButtonDropdownCircleClosed.png';

class EditButtonDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showDelete: false,
        };
    }

    deleteItem = () => {
        this.setState({ showDelete: false });
        this.props.onDelete(this.props.itemId)
    }

    render(){
        return(
            <div className="editButtonDropdownContainer">
                <div className="editButtonDropdownVisible"><img style={{width: "30px"}} src={editButtonDropdownCircleClosed}/></div>

                {!this.state.showDelete ? <div className="editButtonDropdownHidden">
                    <div className="flex horizontalStackCenter editButtonDropdownRow clickable" onClick={() => this.props.onEdit(this.props.itemId)}>
                        <span className="fontPoppinsRegular13">Edit</span>
                        <div className="flex"></div>

                        <img style={{width: "30px"}} src={editButtonDropdownCircle}/>

                        {/*<div style={{width:"30px", height: "30px", backgroundColor: "var(--jv-orange)", borderRadius: "20px", textAlign: "center"}}><label style={{color:"white",fontSize: 13}}>•••</label></div>*/}
                    </div>
                    <div className="editButtonDropdownRow clickable" onClick={() => this.setState({ showDelete: true })}>
                        <span className="fontPoppinsRegular13">Delete</span>
                        <div className="flex"></div>
                    </div>
                </div> : null }

                {this.state.showDelete ? <div className="editButtonDropdownDeleteHidden">
                    <div className="flex horizontalStackCenter clickable" onClick={() => this.props.onEdit(this.props.itemId)}>
                        <div className="flex"></div>
                        <img style={{width: "30px", marginTop: 8, marginRight: 15}} src={editButtonDropdownCircle}/>

                        {/*<div style={{width:"30px", height: "30px", backgroundColor: "var(--jv-orange)", borderRadius: "20px", textAlign: "center", marginTop: 15, marginRight: 15}}><label style={{color:"white"}}>•••</label></div>*/}
                    </div>
                    <div className="flex" style={{padding: 20, paddingTop: 0, whiteSpace: "normal", textAlign: "center"}}>
                        <span style={{fontFamily: "PoppinsMedium", fontSize: 20, color: "var(--jv-orange)", textAlign: "center", flex: 1}}>{this.props.text}</span>
                    </div>
                    <div className="horizontalStackCenter flex" style={{borderTop: "2px solid var(--border-light-gray)"}}>
                        <div className="horizontalStack flex clickable" style={{borderRight: "1px solid var(--border-light-gray)", paddingTop: 5, paddingBottom: 5}} onClick={() => this.deleteItem()}>
                            <div className="flex"></div>
                            <span className="editButtonDelete">Delete</span>
                            <div className="flex"></div>
                        </div>
                        <div className="horizontalStack flex clickable" style={{borderLeft: "1px solid var(--border-light-gray)", paddingTop: 5, paddingBottom: 5}} onClick={() => this.setState({ showDelete: false })}>
                            <div className="flex"></div>
                            <span className="editButtonCancel">Cancel</span>
                            <div className="flex"></div>
                        </div>
                    </div>
                </div> : null }
            </div>
        );
	}
}

export default EditButtonDropdown;