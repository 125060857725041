import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import JvEngagementModal from '../modals/JvEngagementModal.js';
import MinistryPhasesModal from '../modals/MinistryPhasesModal.js';
import PopupButtonDescription from '../components/PopupButtonDescription.js';

// import assets
import icon_glasses from '../assets/icon_glasses.png';
import icon_expand from '../assets/icon_expand.png';

class DiagnosticTileChurch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedRow: "null", // disciple, leader, ministry, church
            showJvEngagementModal: false,
            prepareToShowModalJvEngagement: false,
            showModalMinistryPhases: false,
            prepareToShowModalMinistryPhases: false,
            ministryLensLeveL: "-", // M0
            jvEngagementLevel: "-", // JVE:0
        }
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = async () => {
        const pathname = window.location.href;
        var contact_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(contact_id.includes("?")){ contact_id = contact_id.substring(0, contact_id.indexOf("?")); }

        if(Number.isInteger(Number(contact_id))){ // if there is number received from URL

            Axios.get('/api/clens/get/'+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                var ministryLensLeveLChanged = false;
                var jvEngagementLevelChanged = false;

                response.data.result.forEach((row)=>{
                    if(row.segm_id == 5){ // Ministry Phases
                        this.setState({ ministryLensLeveL: row.seg_cd });
                        ministryLensLeveLChanged = true;
                    }
                    if(row.segm_id == 8){ // JV Engagement
                        this.setState({ jvEngagementLevel: row.seg_cd });
                        jvEngagementLevelChanged = true;
                    }
                })

                // set "-" to records that were not recieved
                if(!ministryLensLeveLChanged) { this.setState({ ministryLensLeveL: "-" }); }
                if(!jvEngagementLevelChanged) { this.setState({ jvEngagementLevel: "-" }); }

                //this.setState({ listOfDiagnosticLenses: response.data.result })
            });

        }
    }

    openRow = (row) => {
        if(this.state.openedRow == row){
            this.setState({ openedRow: "none" });
        }
        else {
            this.setState({ openedRow: row });
        }
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "JvEngagement"){
            await this.setState({ prepareToShowModalJvEngagement: true });
            this.setState({ showJvEngagementModal: true });
        }
        else if(modalWindow == "MinistryPhases"){
            await this.setState({ prepareToShowModalMinistryPhases: true });
            this.setState({ showModalMinistryPhases: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "JvEngagement"){
            this.setState({ showJvEngagementModal: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalJvEngagement: false });
        }
        else if(modalWindow == "MinistryPhases"){
            this.setState({ showModalMinistryPhases: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalMinistryPhases: false });
        }
        this.loadData(); // refresh data when coming back
    }

    render() {
        return (
            <div className="diagnosticTile">

                <div className="verticalStack">

                    <div className="horizontalStack">
                        <div className="verticalStack flex">
                            <span className="fontPoppinsSemiBold15">Diagnostic Lens Tools</span>
                            <span className="fontPoppinsRegular13Gray clickable onHoverToOrange">Learn More</span>
                        </div>
                        <div className="verticalStack" style={{alignItems: "center"}}>
                            <img className="diagnosticTileIcon" src={icon_glasses}/>
                            <div className="flex"></div>
                        </div>
                    </div>

                    <div className="verticalStack">
                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "24px"}}></div>

                        {/* MINISTRY LENS */}

                        <div className={["horizontalStackCenter clickable diagnosticTileRow",this.state.openedRow == "ministry" ? "diagnosticTileRowOpened" : ""].join(" ")} onClick={() => this.openRow("ministry")}>
                            <img className="diagnosticLensIconExpand" src={icon_expand} style={{transform: this.state.openedRow == "ministry" ? "rotate(90deg)" : ""}}/>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "ministry" ? "var(--crm-pink)" : "var(--jv-orange)", marginLeft: "9px"}}>Ministry Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "ministry" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.ministryLensLeveL}</span></div>
                            <div style={{width: "9px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "ministry" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>-</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                        {/* MINISTRY LENS ROWS */}

                        <div style={{display: this.state.openedRow == "ministry" ? "" : "none"}}>

                            <div className="horizontalStack clickable diagnosticTileSubRow" onClick={() => this.openModal("MinistryPhases")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-pink)"}}>Ministry Phases</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Phase of Ministry:</span>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensCircle diagnosticLensCircleHoverPink"><span className="fontPoppinsSemiBold15">{this.state.ministryLensLeveL}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"-26px"} blackArrow={true} />
                                    </div>
                                </div>

                            </div>

                            <div className="horizontalStack clickable diagnosticTileSubRow">
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-pink)"}}>Ministry Map</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Map Level:</span>
                                </div>

                                <div style={{width: "9px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensOval diagnosticLensOvalHoverPink"><span className="fontPoppinsSemiBold15">-</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Coming Soon"} from={"top"} marginLeft={"-10px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* CHURCH LENS */}

                        <div className={["horizontalStackCenter clickable diagnosticTileRow",this.state.openedRow == "church" ? "diagnosticTileRowOpened" : ""].join(" ")} onClick={() => this.openRow("church")}>
                            <img className="diagnosticLensIconExpand" src={icon_expand} style={{transform: this.state.openedRow == "church" ? "rotate(90deg)" : ""}}/>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "church" ? "var(--crm-yellow)" : "var(--jv-orange)", marginLeft: "9px"}}>Church Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "church" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>-</span></div>
                            <div style={{width: "9px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "church" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.jvEngagementLevel}</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                        {/* CHURCH LENS ROWS */}

                        <div style={{display: this.state.openedRow == "church" ? "" : "none"}}>

                            <div className="horizontalStack clickable diagnosticTileSubRow">
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-yellow)"}}>7 Charasteristics</span>
                                    <span className="fontPoppinsRegular13Gray" >Current Level:</span>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensCircle diagnosticLensCircleHoverYellow"><span className="fontPoppinsSemiBold15">-</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Coming Soon"} from={"top"} marginLeft={"-38px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack clickable diagnosticTileSubRow" onClick={() => this.openModal("JvEngagement")}>
                                <div className="verticalStackCenter">
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--crm-yellow)"}}>JV Engagement</span>
                                    <span className="fontPoppinsRegular13Gray" >Curret Level:</span>
                                </div>

                                <div style={{width: "9px"}}></div>

                                <div className="verticalStackCenter popupButtonDescriptionFromTopContainer">
                                    <div className="popupButtonDescriptionFromTopVisible">
                                        <div className="diagnosticLensOval diagnosticLensOvalHoverYellow"><span className="fontPoppinsSemiBold15">{this.state.jvEngagementLevel}</span></div>
                                    </div>

                                    <div className="popupButtonDescriptionFromTopHidden">
                                        <PopupButtonDescription title={"Open Tool"} from={"top"} marginLeft={"1px"} blackArrow={true} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {this.state.prepareToShowModalJvEngagement ? <JvEngagementModal showModal={this.state.showJvEngagementModal} closeModal={this.closeModal} mode={"church"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalMinistryPhases ? <MinistryPhasesModal showModal={this.state.showModalMinistryPhases} closeModal={this.closeModal} mode={"church"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
    }
}

export default DiagnosticTileChurch;
