import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import PopupButtonDescription from '../components/PopupButtonDescription.js';

// import assets
import icon_glasses from '../assets/icon_glasses.png';
import icon_expand from '../assets/icon_expand.png';

class DiagnosticTileChurchMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedRow: "null", // disciple, leader, ministry, church
            ministryLensLeveL: "-", // M0
            jvEngagementLevel: "-", // JVE:0
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
        }
    }

    async componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = async () => {
        const pathname = window.location.href;
        var contact_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(contact_id.includes("?")){ contact_id = contact_id.substring(0, contact_id.indexOf("?")); }

        if(Number.isInteger(Number(contact_id))){ // if there is number received from URL

            Axios.get('/api/clens/get/'+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                var ministryLensLeveLChanged = false;
                var jvEngagementLevelChanged = false;

                response.data.result.forEach((row)=>{

                    if(row.segm_id == 5){ // Ministry Phases
                        this.setState({ ministryLensLeveL: row.seg_cd });
                        ministryLensLeveLChanged = true;
                    }
                    if(row.segm_id == 8){ // JV Engagement
                        this.setState({ jvEngagementLevel: row.seg_cd });
                        jvEngagementLevelChanged = true;
                    }

                })

                // set "-" to records that were not recieved
                if(!ministryLensLeveLChanged) { this.setState({ ministryLensLeveL: "-" }); }
                if(!jvEngagementLevelChanged) { this.setState({ jvEngagementLevel: "-" }); }

                //this.setState({ listOfDiagnosticLenses: response.data.result })
            });

        }
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className="diagnosticTileMobile">

                <div className="verticalStack">

                    <div className="horizontalStack">
                        <div className="verticalStack flex">
                            <span className="fontPoppinsSemiBold15">Diagnostic Lens Tools</span>
                            <span className="fontPoppinsRegular13Gray">Learn More</span>
                        </div>
                        <div className="verticalStack" style={{alignItems: "center"}}>
                            <img className="diagnosticTileIcon" src={icon_glasses}/>
                            <div className="flex"></div>
                        </div>
                    </div>

                    <div className="verticalStack">
                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)", marginTop: "24px"}}></div>

                        {/* MINISTRY LENS */}

                        <div className={["horizontalStackCenter diagnosticTileRowMobile",this.state.openedRow == "ministry" ? "diagnosticTileRowOpened" : ""].join(" ")}>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "ministry" ? "var(--crm-pink)" : "var(--jv-orange)"}}>Ministry Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div style={{width: 29}}>
                                <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "ministry" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.ministryLensLeveL}</span></div>
                            </div>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "ministry" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>-</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                        {/* CHURCH LENS */}

                        <div className={["horizontalStackCenter diagnosticTileRowMobile",this.state.openedRow == "church" ? "diagnosticTileRowOpened" : ""].join(" ")}>
                            <span className="fontPoppinsSemiBold15" style={{color: this.state.openedRow == "church" ? "var(--crm-yellow)" : "var(--jv-orange)"}}>Church Lens</span>
                            <div style={{width: "15px"}}></div>
                            <div style={{width: 29}}>
                                <div className="diagnosticLensCircle" style={{visibility: this.state.openedRow == "church" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>-</span></div>
                            </div>
                            <div style={{width: "15px"}}></div>
                            <div className="diagnosticLensOval" style={{visibility: this.state.openedRow == "church" ? "hidden" : "visible"}}><span className="fontPoppinsSemiBold15" style={{color: "var(--dark-gray)"}}>{this.state.jvEngagementLevel}</span></div>
                        </div>

                        <div style={{height: "1px", width: "100%", backgroundColor: "var(--border-light-gray)"}}></div>

                    </div>

                </div>

            </div>
        );
    }
}

export default DiagnosticTileChurchMobile;
