import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';

class EmptyClosedTile extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="closedTile">

                <div className="horizontalStackCenter">

                    <div className="circleButtonContainer circleButtonContainerEmpty clickable"></div>

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex">
                        <div className="emptySpace" style={{width: 120}}></div>
                        <div style={{height: "18px"}}></div>
                        <div className="emptySpace" style={{marginRight: 20}}></div>
                    </div>

                    <div className="circleButtonContainer circleButtonContainerEmpty clickable"></div>

                </div>

            </div>
        );
    }
}

export default EmptyClosedTile;
