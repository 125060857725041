import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getTodaysDate } from '../utilities/OtherUtilities.js';

// components
import ModalMobile from '../modals_m/ModalMobile';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';
import SearchPopup from '../components/SearchPopup.js';

// assets
import close_white from '../assets/close_white.png';
import flag_1 from '../assets/flag_1.png';
import flag_2 from '../assets/flag_2.png';
import flag_3 from '../assets/flag_3.png';

class ObservationsModalMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // AddObservation, EditObservation
            redirectTo: null,
            whoId: -1,
            whoName: "+ Add a Church",
            descr: "",
            flagId: 1,
            date: "2022-01-01",
            find_ChurchID: "",
            findEmailByName: "",
            foundEmails: ([]),
            foundChurches: ([]),
            showPopupWho: false,
            showPopupEmails: false,
            /*emailsArray: ([{"id":2 ,"name":"Frank Kirk", "email":"frank@skote.com"}]),*/
            emailsArray: ([]),
            alreadySentEmailsArray: ([]),
        };

        this.refPopupWho = React.createRef();
        this.refInputWho = React.createRef();
        this.refPopupEmails = React.createRef();
        this.refInputEmails = React.createRef();
    }

    componentDidMount(){
        // Edit
        if(this.props.observationId != -1 && this.props.actionType == "EditObservation"){
            this.loadExistingObservation(this.props.observationId);
        }
        // Add
        else {
            this.setState({ date: getTodaysDate() });

            if(this.props.contactId != -1 && this.props.contactName != ""){
                this.setState({ whoId: this.props.contactId });
                this.setState({ whoName: this.props.contactName });
            }

            var token_object = JSON.parse(localStorage.getItem("token"));
            var signedUserId = token_object.id;
            var signedUserEmail = token_object.email;
            var signedUserName = token_object.first_name + " " + token_object.last_name;
            this.setState({ createdBy: signedUserName || "" });
            this.addEmailContact(signedUserId, signedUserName, signedUserEmail);
        }
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupEmails.current.contains(e.target)) && this.state.showPopupEmails) {
            // user clicked out of the PopupEmails and that popup is now opened
            this.setState({ showPopupEmails: false })
        }
        if ( (!this.refPopupWho.current.contains(e.target)) && this.state.showPopupWho) {
            this.setState({ showPopupWho: false })
        }
        if ( this.state.showPopupWho ) this.refInputWho.current.focus();
        if ( this.state.showPopupEmails ) this.refInputEmails.current.focus();
    }

    loadExistingObservation = async (id) => {
        await Axios.get('/api/obs/get/'+id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;
            this.setState({ descr: results[0].descr });
            this.setState({ date: results[0].obs_dt });
            this.setState({ flagId: results[0].flag_id });

            this.setState({ whoId: results[0].cont_id });
            this.setState({ whoName: results[0].cont_name });

            var sentEmailsArray = [];
            for(let oneRow of response.data.resultSend){
                sentEmailsArray.push({id: oneRow.send_id, name: oneRow.rcg_name, email: oneRow.email, sent: true})
            }
            this.setState({ alreadySentEmailsArray: sentEmailsArray });

            if(results[0].dt != null){ this.setState({ date: results[0].dt }); }
        });
    }

    // this method works for both creating new Observation and saving changed Observation
    saveObservationAction = async (createAnotherNewAfter) => {
        // check year validity
        const objectDate = new Date(this.state.date);
        if(objectDate.getFullYear() < 1900){
            this.props.openAlertMessage("Year cannot be lower than 1900.","OK");
            return;
        }
        //alert("SAVE:\nContId: "+this.props.contactId+"\nDesc: "+this.state.descr+"\nFlag: "+this.state.flagId+"\nDate: "+this.state.date);
        //return;

        var url = "/api/obs/ins";
        if(this.props.actionType == "EditObservation"){url = "/api/obs/upd/"+this.props.observationId;}

        // create final list of emails to send
        var finalEmailsArray = [];
        for(let oneRow of this.state.alreadySentEmailsArray){
            finalEmailsArray.push(oneRow.id);
        }
        for(let oneRow of this.state.emailsArray){
            finalEmailsArray.push(oneRow.id);
        }

        //alert(finalEmailsArray);
        //return;

        Axios.post(url, {
            ContId: this.state.whoId,
            FlagId: this.state.flagId, // 1=Normal, 2=Please read, 3=Please read now
            ObsDt: this.state.date,
            Descr: this.state.descr,
            SendId: finalEmailsArray,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.closeModal(this.state.editingMode, createAnotherNewAfter);
        });
    }

    askToDelete = () => {
        this.props.openAlertMessageTwoActions("Are you sure you want to delete this observation?","Cancel","Delete",this.deleteObservation);
    }

    deleteObservation = () => {
        if(this.props.observationId != -1 && this.props.actionType == "EditObservation"){
            Axios.delete('/api/obs/del/'+this.props.observationId, {
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.props.closeModal(this.state.editingMode, false);
            });
        }
    }

    searchForEmails = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/usr/get?search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundEmails: response.data.result });
            });
        }
        else {
            this.setState({ foundEmails: [] });
        }
    }

    searchForChurches = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=4&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundChurches: response.data.result });
            });
        }
    }

    selectFoundChurch = async (contactId, contactName) => {
        this.setState({ whoId: contactId });
        this.setState({ whoName: contactName });

        this.setState({ showPopupWho: false });
        this.setState({ find_ChurchID: "" });
        this.setState({ foundChurches: [] });
    }

    addEmailContact = async (contactId, contactName, contactEmail) => {
        // check if it is not already added
        for(let oneRow of this.state.emailsArray){
            if(oneRow.id == contactId){
                this.setState({ showPopupEmails: false });
                return;
            }
        }

        this.setState({
            emailsArray: [...this.state.emailsArray, {"id": contactId ,"name": contactName, "email": contactEmail, "sent": false}]
        });

        this.setState({ showPopupEmails: false });
        this.setState({ findEmailByName: "" });
        this.setState({ foundEmails: [] });
    }

    removeEmailFromList = (contactId) => {
        var newEmailsArray = [];

        for(let oneRow of this.state.emailsArray){
            if(oneRow.id != contactId){
                newEmailsArray.push({id: oneRow.id, name: oneRow.name, email: oneRow.email, sent: oneRow.sent})
            }
        }

        this.setState({ emailsArray: newEmailsArray });
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <ModalMobile show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={this.state.modalExpanded}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{this.state.editingMode == "EditObservation" ? "Edit an Observation" : "Add an Observation"}</span>

                                <div className="horizontalStackCenter">

                                    <SearchPopup
                                        id={"searchContact"}
                                        textName={this.state.whoName}
                                        textStyle={this.state.whoId == -1 ? "italicOrange" : "normalBlue"}
                                        value={this.state.find_ChurchID}
                                        changeValue={(text) => {this.setState({ find_ChurchID: text }); this.searchForChurches(text);}}
                                        foundRecords={this.state.foundChurches}
                                        refPopup={this.refPopupWho}
                                        refInput={this.refInputWho}
                                        showPopup={this.state.showPopupWho}
                                        showHidePopup={() => this.setState({ showPopupWho: !this.state.showPopupWho })}
                                        addContactToArray={(item) => this.selectFoundChurch(item.id,item.short_name)}
                                        showEmailStyle={false}
                                    />

                                </div>

                            </div>

                            <div className="verticalStack">
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                                    <div className="flex"></div>
                            </div>
                        </div>

                        <div className="verticalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 12}}>

                            <div className="horizontalStack">
                                <span className="fontPoppinsRegular13">Date:*</span>
                                <div style={{width: 5}}></div>
                                <input className="modalDatePicker fontPoppinsRegular13" type="date" id="start_date" name="start_date" min="1900-01-01" max="9999-01-01" value={this.state.date} onChange={e => this.setState({ date: e.target.value })}/>
                                <div className="flex"></div>
                                <span className="fontPoppinsItalic13Gray" style={{marginLeft: 5}}>*Required fields</span>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{whiteSpace: "nowrap"}}>{"Flag for Reading Priority:*"}</span>

                                <div className="verticalStack flex" onChange={event => this.setState({ flagId: event.target.value })}>

                                    <div style={{height: 5}}></div>

                                    <div className="horizontalStackCenter">
                                        <input type="radio" id="current" name="relationship_time" value="1" style={{marginTop: 0}} checked={this.state.flagId == 1 ? true : false}/>
                                        <span className="fontPoppinsRegular13 clickable" style={{whiteSpace: "nowrap", marginLeft: 5}} onClick={() => this.setState({ flagId: 1 })}>Normal</span>
                                        <img className="clickable" style={{height: 20, marginLeft: 10}} src={flag_1} onClick={() => this.setState({ flagId: 1 })}/>
                                    </div>

                                    <div style={{height: 5}}></div>

                                    <div className="horizontalStackCenter">
                                        <input type="radio" id="past" name="relationship_time" value="2" style={{marginTop: 0}} checked={this.state.flagId == 2 ? true : false}/>
                                        <span className="fontPoppinsRegular13 clickable" style={{whiteSpace: "nowrap", marginLeft: 5}} onClick={() => this.setState({ flagId: 2 })}>Please Read</span>
                                        <img className="clickable" style={{height: 20, marginLeft: 10}} src={flag_2} onClick={() => this.setState({ flagId: 2 })}/>
                                    </div>

                                    <div style={{height: 5}}></div>

                                    <div className="horizontalStackCenter">
                                        <input type="radio" id="past" name="relationship_time" value="3" style={{marginTop: 0}} checked={this.state.flagId == 3 ? true : false}/>
                                        <span className="fontPoppinsRegular13 clickable" style={{whiteSpace: "nowrap", marginLeft: 5}} onClick={() => this.setState({ flagId: 3 })}>Please Read Now!</span>
                                        <img className="clickable" style={{height: 20, marginLeft: 10}} src={flag_3} onClick={() => this.setState({ flagId: 3 })}/>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 2}}>

                            <span className="fontPoppinsRegular13">Notes:</span>

                            <textarea className="modalInputAddNote" rows="8" style={{backgroundColor: "white"}} placeholder="Write here any observations that you think that may help other workers in their relationship with this church community." id="textAreaDescr" name="textAreaDescr" value={this.state.descr} onChange={e => this.setState({ descr: e.target.value })}></textarea>

                            <span className="fontPoppinsRegular13" style={{marginTop: 10}}>Who do you want to send a copy of this observation to? Blue indicates that they were already notified.</span>

                            <div style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                                <div className="horizontalStack flex" style={{backgroundColor: "white", marginTop: 10, paddingTop: 10, width: "100%", overflowX: "hidden", flexWrap: "wrap"}}>

                                    {this.state.alreadySentEmailsArray.map(function (item) {
                                        return (
                                            <label className="fontPoppinsRegular13" style={{marginLeft: 10, marginRight: 10, marginBottom: 10, border: "1px solid var(--blue-button-link)", color: "var(--blue-button-link)", whiteSpace: "nowrap", borderRadius: 5, paddingLeft: 5, paddingRight: 5}}>{item.name} {item.email}</label>
                                        )
                                    }.bind(this))}

                                    {this.state.emailsArray.map(function (item) {
                                        return (
                                            <label className="fontPoppinsRegular13 clickable" onClick={() => this.removeEmailFromList(item.id)} style={{marginLeft: 10, marginRight: 10, marginBottom: 10, border: "1px solid var(--jv-orange)", color: "var(--jv-orange)", whiteSpace: "nowrap", borderRadius: 5, paddingLeft: 5, paddingRight: 5}}>{"x"} {item.name} {item.email}</label>
                                        )
                                    }.bind(this))}

                                    <SearchPopup
                                        id={"searchEmail"}
                                        textName={"+ Add an Email"}
                                        textStyle={"italicOrange"}
                                        value={this.state.findEmailByName}
                                        changeValue={(text) => {this.setState({ findEmailByName: text }); this.searchForEmails(text);}}
                                        foundRecords={this.state.foundEmails}
                                        refPopup={this.refPopupEmails}
                                        refInput={this.refInputEmails}
                                        showPopup={this.state.showPopupEmails}
                                        showHidePopup={() => this.setState({ showPopupEmails: !this.state.showPopupEmails })}
                                        addContactToArray={(item) => this.addEmailContact(item.id,item.rcg_name, item.email)}
                                        showEmailStyle={true}
                                        style={{marginBottom: 10}}
                                    />

                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{marginTop: "12px"}}>
                            <div className="roundButton roundButtonOrange" style={{textAlign: "center", marginTop: 0}} onClick={() => this.saveObservationAction(false)}>
                                <span className="fontPoppinsRegular13White">{"Save Observation"}</span>
                            </div>

                            <div className="roundButton roundButtonGray" style={{textAlign: "center", marginTop: 10}} onClick={() => this.saveObservationAction(true)}>
                                <span className="fontPoppinsRegular13White">{"Save and Add New"}</span>
                            </div>

                            {this.props.observationId != -1 && this.props.actionType == "EditObservation" ?
                                <div className="roundButton roundButtonGray" style={{textAlign: "center", marginTop: 10}} onClick={this.askToDelete}>
                                    <span className="fontPoppinsRegular13White">{"Delete"}</span>
                                </div>
                            : null }
                        </div>

                    </div>
                </ModalMobile>
            </React.Fragment>
		);
	}

}

export default ObservationsModalMobile;