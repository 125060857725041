import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// components
import Modal from '../modals/Modal';

// assets
import close_white from '../assets/close_white.png';


class MessageModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // MyDiscipling
            redirectTo: null,
            foundContacts: ([]),
            showPopupFindContact: false,
        };
    }

    componentDidMount(){

    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode)} isExpanded={this.state.modalExpanded}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{this.props.title}</span>
                                <span className="fontPoppinsRegular13Gray">{this.props.desc}</span>
                            </div>

                            <div className="verticalStack">
                                <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode)}/>
                                <div className="flex"></div>
                            </div>
                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}

}

export default MessageModal;