import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles_m/tiles_m.css';
import '../styles/other.css';
import Axios from 'axios';
import { Link } from "react-router-dom";
import { navigate } from "@reach/router";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_church from '../assets/icon_church.png';
import icon_church_info_up from '../assets/icon_church_info_up.png';
import icon_church_info_down from '../assets/icon_church_info_down.png';

// import components
import JvEngagementModal from '../modals/JvEngagementModal.js';
import RolesModal from '../modals/RolesModal.js';

class ChurchInfoTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            churchProfile: ([{cont_nm:"", jve_cd: "-"}]),
            churchId: -1,
            showJvEngagementModal: false,
            prepareToShowModalJvEngagement: false,
            showModalAddRole: false,
            prepareToShowModalAddRole: false,
            mainContactId: 0,
            mainContactName: "",
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
        }
    }

    componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = async () => {
        await this.setState({ churchId: -1 });
        await this.setState({ churchProfile: [{cont_nm:"", jve_cd: "-"}] });
        await this.setState({ mainContactId: 0 });
        await this.setState({ mainContactName: "" });

        const pathname = window.location.href;
        var contact_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(contact_id.includes("?")){ contact_id = contact_id.substring(0, contact_id.indexOf("?")); }

        if(Number.isInteger(Number(contact_id))){ // if there is number received from URL

            var church_id = -1;

            if(this.props.mode == "person"){
                await Axios.get('/api/cont/get/'+contact_id).then(response => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                    church_id = response.data.result[0].church_id;
                    this.setState({ churchId: response.data.result[0].church_id });
                    this.setState({ mainContactId: response.data.result[0].id });
                    this.setState({ mainContactName: response.data.result[0].first_name + " " + response.data.result[0].last_name });
                });
            }
            else {
                church_id = contact_id;
            }

            if(church_id == -1 || church_id == null){ return; }

            this.setState({ churchId: church_id });

            Axios.get('/api/cprof/get/'+church_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                //alert(response.data.result[0].cont_nm);
                this.setState({ churchProfile: response.data.result })
            });

        }

    }

    openModal = async (modalWindow) => {
        if(modalWindow == "JvEngagement"){
            await this.setState({ prepareToShowModalJvEngagement: true });
            this.setState({ showJvEngagementModal: true });
        }
        else if(modalWindow == "AddRole"){
            await this.setState({ prepareToShowModalAddRole: true });
            this.setState({ showModalAddRole: true });
        }
    }

    closeModal = async (modalWindow) => {

        this.loadData();
        this.props.updateRolesTile();

        if(modalWindow == "JvEngagement"){
            this.setState({ showJvEngagementModal: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalJvEngagement: false });
        }
        else if(modalWindow == "AddRole"){
            this.setState({ showModalAddRole: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddRole: false });
        }
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className={this.props.deviceType == "mobile" ? "churchInfoTileMobile" : "churchInfoTile"}>

                <div className="horizontalStack">
                    <div className="verticalStack flex">
                        {this.state.churchProfile[0].cont_nm != "" ? <span className="fontPoppinsSemiBold15 clickable">Church: <span style={{color: "var(--blue-button-link)"}} onClick={()=> { navigate("/ChurchProfile/"+this.state.churchProfile[0].cont_id); window.location.reload(); }}>{this.state.churchProfile[0].cont_nm}</span></span> : null }
                        {this.state.churchProfile[0].cont_nm == "" && this.props.deviceType != "mobile" ? <span className="fontPoppinsSemiBold15">Church: <span className="fontPoppinsItalic15Orange clickable onHoverToBlue" onClick={() => this.openModal("AddRole")}>{"+ Add a Church Role"}</span></span> : null }
                        {this.state.churchProfile[0].cont_nm == "" && this.props.deviceType == "mobile" ? <span className="fontPoppinsSemiBold15">Church: <span className="fontPoppinsItalic15Orange">{"+ Add a Church Role"}</span> <span className="fontPoppinsItalic15DarkGray">{"(must be added in desktop version)"}</span></span> : null }

                        <div style={{height: "20px"}}></div>

                        {this.state.churchProfile.slice(1).map(function (item) {
                            return (
                                <span className="fontPoppinsRegular13DarkGray">{item.team} {item.role}: <span className="clickable" style={{color: "var(--blue-button-link)"}} onClick={()=> { navigate("/PeopleProfile/"+item.cont_id); window.location.reload(); }}>{item.cont_nm}{item.dsc_cd == null && item.ldr_cd == null ? "" : ","} {item.dsc_cd}{item.dsc_cd == null || item.ldr_cd == null ? "" : "/"}{item.ldr_cd}</span></span>

                            )
                        }.bind(this))}

                        {/*<div style={{height: "20px"}}></div>

                        <div className="horizontalStack">
                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13DarkGray">30-40 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_up}/> <span className="fontPoppinsRegular13Gray">Church Size</span></span>
                                <span className="fontPoppinsRegular13DarkGray">20-30 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_down}/> <span className="fontPoppinsRegular13Gray">Youth Ministry</span></span>
                                <span className="fontPoppinsRegular13DarkGray">35 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_down}/> <span className="fontPoppinsRegular13Gray">Youth Group</span></span>
                            </div>
                            <div className="verticalStack" style={{marginLeft: "15px"}}>
                                <span className="fontPoppinsRegular13DarkGray">5 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_up}/> <span className="fontPoppinsRegular13Gray">Edge</span></span>
                            <span className="fontPoppinsRegular13DarkGray">25 <img className="churchInfoTileIcon" style={{width: "11px"}} src={icon_church_info_down}/> <span className="fontPoppinsRegular13Gray">Fusion</span></span>
                            </div>
                        </div>*/}

                        <div style={{height: "20px"}}></div>

                        {this.props.deviceType != "mobile" ? <span className="fontPoppinsSemiBold13Gray clickable jvEngagementButton">JV Engagement: <span className="fontPoppinsSemiBold13DarkGray jvEngagementValue" onClick={() => this.openModal("JvEngagement")}>{this.state.churchProfile[0].jve_cd}</span></span> : null }
                        {this.props.deviceType == "mobile" ? <span className="fontPoppinsSemiBold13Gray">JV Engagement: <span className="fontPoppinsSemiBold13DarkGray">{this.state.churchProfile[0].jve_cd}</span></span> : null }
                    </div>
                    <div className="verticalStack" style={{alignItems: "center"}}>
                        <img className="churchInfoTileIcon" src={icon_church}/>
                        <div className="flex"></div>
                        {/*<Link className="link" to={"../ChurchProfile/"+this.state.churchId} >
                            <div className="threeDotsButton clickable verticalStackCenter"><span>···</span></div>
                        </Link>*/}
                    </div>
                </div>
                {this.state.prepareToShowModalJvEngagement ? <JvEngagementModal showModal={this.state.showJvEngagementModal} closeModal={this.closeModal} mode={this.props.mode} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalAddRole ? <RolesModal showModal={this.state.showModalAddRole} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddRole"} roleId={-1} openAlertMessage={this.props.openAlertMessageTwoActions} /> : null }
            </div>
        );
    }
}

export default ChurchInfoTile;
