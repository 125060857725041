import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

class RoundButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div>
                {this.props.className == null ?
                <div className="roundButton horizontalStackCenter clickable" style={this.props.style} onClick={this.props.onClick}>
                    <img style={{height: "16px"}} src={this.props.icon}/>
                    <span className="fontPoppinsRegular13" style={{marginLeft: this.props.icon != null ? "10px" : "4px", color: this.props.whiteText ? "white" : "black", whiteSpace: "nowrap"}}>{this.props.title}</span>
                </div>
                : null }

                {this.props.className != null && this.props.className == "roundButtonOrange" ?
                <div className="roundButton roundButtonOrange horizontalStackCenter clickable" style={this.props.style} onClick={this.props.onClick}>
                    <img style={{height: "16px"}} src={this.props.icon}/>
                    <span className="fontPoppinsRegular13" style={{marginLeft: this.props.icon != null ? "10px" : "4px", color: this.props.whiteText ? "white" : "black", whiteSpace: "nowrap"}}>{this.props.title}</span>
                </div>
                : null }

                {this.props.className != null && this.props.className == "roundButtonGray" ?
                <div className="roundButton roundButtonGray horizontalStackCenter clickable" style={this.props.style} onClick={this.props.onClick}>
                    <img style={{height: "16px"}} src={this.props.icon}/>
                    <span className="fontPoppinsRegular13" style={{marginLeft: this.props.icon != null ? "10px" : "4px", color: this.props.whiteText ? "white" : "black", whiteSpace: "nowrap"}}>{this.props.title}</span>
                </div>
                : null }

                {this.props.className != null && this.props.className == "roundButtonGreen" ?
                <div className="roundButton roundButtonGreen horizontalStackCenter clickable" style={this.props.style} onClick={this.props.onClick}>
                    <img style={{height: "16px"}} src={this.props.icon}/>
                    <span className="fontPoppinsRegular13" style={{marginLeft: this.props.icon != null ? "10px" : "4px", color: this.props.whiteText ? "white" : "black", whiteSpace: "nowrap"}}>{this.props.title}</span>
                </div>
                : null }

                {this.props.className != null && this.props.className == "roundButtonYellow" ?
                <div className="roundButton roundButtonYellow horizontalStackCenter clickable" style={this.props.style} onClick={this.props.onClick}>
                    <img style={{height: "16px"}} src={this.props.icon}/>
                    <span className="fontPoppinsRegular13" style={{marginLeft: this.props.icon != null ? "10px" : "4px", color: this.props.whiteText ? "white" : "black", whiteSpace: "nowrap"}}>{this.props.title}</span>
                </div>
                : null }

                {this.props.className != null && this.props.className == "roundButtonBlue" ?
                <div className="roundButton roundButtonBlue horizontalStackCenter clickable" style={this.props.style} onClick={this.props.onClick}>
                    <img style={{height: "16px"}} src={this.props.icon}/>
                    <span className="fontPoppinsRegular13" style={{marginLeft: this.props.icon != null ? "10px" : "4px", color: this.props.whiteText ? "white" : "black", whiteSpace: "nowrap"}}>{this.props.title}</span>
                </div>
                : null }

                {this.props.className != null && this.props.className == "roundButtonPink" ?
                <div className="roundButton roundButtonPink horizontalStackCenter clickable" style={this.props.style} onClick={this.props.onClick}>
                    <img style={{height: "16px"}} src={this.props.icon}/>
                    <span className="fontPoppinsRegular13" style={{marginLeft: this.props.icon != null ? "10px" : "4px", color: this.props.whiteText ? "white" : "black", whiteSpace: "nowrap"}}>{this.props.title}</span>
                </div>
                : null }
            </div>
        );
	}
}

export default RoundButton;