import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate } from "react-router-dom";
import '../styles/modals.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getTodaysDate } from '../utilities/OtherUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';
import SearchPopup from '../components/SearchPopup.js';

// assets
import close_white from '../assets/close_white.png';

class RolesModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // AddRole, EditRole
            redirectTo: null,
            roleType: "0;null", // example "1;a_b" i format "ID;RELATION" - relation could be a_b, b_a, null
            roleSelected: "+ Add",
            whoId: -1,
            whoName: "+ Add a Name",
            withWhom: -1, // default is -1
            withWhomName: "+ Add a Church",
            withWhatTeam: -1,
            withWhatTeamName: "+ Add",
            isPrimary: "0", // 0 or 1
            isCurrent: "current", // current or past
            descr: "",
            findA_ContactID: "",
            findB_ContactID: "",
            findTeam: "",
            foundPeople: ([]),
            foundContacts: ([]),
            showPopupWho: false,
            showPopupWithWhom: false,
            showPopupWithWhatTeam: false,
            showPopupRoleType: false,
            startDate: "2022-01-01",
            endDate: "2022-01-01",
            roleTypesArray: ([{"id":1,"name":"Discipler", "relation":"null"}]),
            teamTypesArray: ([{"id":1,"name":"JV Regional Contact"}]),
        };

        this.refPopupWho = React.createRef();
        this.refInputWho = React.createRef();
        this.refPopupWithWhom = React.createRef();
        this.refInputWithWhom = React.createRef();
        this.refPopupWithWhatTeam = React.createRef();
        this.refPopupRoleType = React.createRef();
    }

    componentDidMount(){
        this.loadRolesTypesArray();
        this.loadTeamTypesArray();

        if(this.props.roleId != -1 && this.props.actionType == "EditRole"){
            this.loadExistingRole(this.props.roleId);
        }
        else {
            this.setState({ startDate: getTodaysDate() });
            this.setState({ endDate: getTodaysDate() });

            if(this.props.contactId != -1 && this.props.contactName != ""){
                this.setState({ whoId: this.props.contactId });
                this.setState({ whoName: this.props.contactName });

                // check if this user has already some role - if not then select checkbox_primary=true
                Axios.get('/api/rel/get?cont_id='+this.props.contactId+'&a_tp=1&b_tp=4').then(response => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                    var hasPrimary = false;
                    for(let oneRow of response.data.result){
                        if(oneRow.is_primary == 1)hasPrimary = true;
                    }
                    if(!hasPrimary)this.setState({ isPrimary: "1" });
                });
            }
        }
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupWithWhatTeam.current.contains(e.target)) && this.state.showPopupWithWhatTeam) {
            // user clicked out of the PopupWithWhatTeam and that popup is now opened
            this.setState({ showPopupWithWhatTeam: false })
        }
        if ( (!this.refPopupWithWhom.current.contains(e.target)) && this.state.showPopupWithWhom) {
            this.setState({ showPopupWithWhom: false })
        }
        if ( (!this.refPopupWho.current.contains(e.target)) && this.state.showPopupWho) {
            this.setState({ showPopupWho: false })
        }
        if ( (!this.refPopupRoleType.current.contains(e.target)) && this.state.showPopupRoleType) {
            this.setState({ showPopupRoleType: false })
        }
        if ( this.state.showPopupWho ) this.refInputWho.current.focus();
        if ( this.state.showPopupWithWhom ) this.refInputWithWhom.current.focus();
    }

    loadRolesTypesArray = () => {
        Axios.get('/api/rtp/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;

            var investorArray = [];
            var investeeArray = [];
            var otherArray = [];

            for(let oneRow of results){
                if(oneRow.a_cont_tp_id == 1 && oneRow.b_cont_tp_id == 4){
                    otherArray.push({"id":oneRow.id, "name": oneRow.a_b_name, "relation":"a_b"});
                }
            }
            this.setState({ roleTypesArray: otherArray });
        });
    }

    loadTeamTypesArray = () => {
        Axios.get('/api/team/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;

            var teamsArray = [];

            for(let oneRow of results){
                teamsArray.push({"id":oneRow.id, "name": oneRow.label});
            }
            this.setState({ teamTypesArray: teamsArray });
        });
    }

    loadExistingRole = async (id) => {
        await Axios.get('/api/rel/get/'+id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;
            this.setState({ descr: results[0].descr });

            this.setState({ whoId: results[0].a_cont_id });
            this.setState({ whoName: results[0].a_rcg_name });

            this.setState({ withWhom: results[0].b_cont_id });
            this.setState({ withWhomName: results[0].b_rcg_name });

            this.setState({ withWhatTeam: results[0].team_id }); // need to receive this in API
            this.setState({ withWhatTeamName: results[0].team });

            this.setState({ roleSelected: results[0].a_role });
            this.setState({ roleType: results[0].rel_tp_id+";a_b" });


            if(results[0].start_dt != null){
                this.setState({ startDate: results[0].start_dt });
            }

            if(results[0].end_dt != null){
                this.setState({ endDate: results[0].end_dt });
            }
            else { this.setState({ endDate: getTodaysDate() }); }

            if(results[0].is_primary == 1){
                this.setState({ isPrimary: "1" });
            }
            else {
                this.setState({ isPrimary: "0" });
            }

            if(results[0].is_current == 1){
                this.setState({ isCurrent: "current" });
            }
            else {
                this.setState({ isCurrent: "past" });
            }
        });
    }

    deleteRole = () => {
        if(this.props.roleId != -1 && this.props.actionType == "EditRole"){
            Axios.delete('/api/rel/del/'+this.props.roleId, {
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.props.closeModal(this.state.editingMode, false);
            });
        }
    }

    // this method works for both creating new Role and saving changed Role
    saveRoleAction = async (createAnotherNewAfter) => {
        if(this.state.roleType == "0;null"){
            this.props.openAlertMessage("You didn't fill a role.","OK");
            return false;
        }
        else if(this.state.withWhom == -1){
            this.props.openAlertMessage("You didn't fill a church.","OK");
            return false;
        }
        else if(this.state.withWhatTeam == -1){
            this.props.openAlertMessage("You didn't fill a team.","OK");
            return false;
        }
        else if(this.state.descr != undefined && this.state.descr.length > 255){
            this.props.openAlertMessage("Your description is longer than allowed. The limit is 255 characters.","OK");
            return false;
        }
        else if(this.state.isCurrent == "past" && this.state.startDate > this.state.endDate){
            this.props.openAlertMessage("Start date must be set before End date.","OK");
            return false;
        }
        // check year validity
        const objectStartDate = new Date(this.state.startDate);
        const objectEndDate = new Date(this.state.endDate);
        /*if(objectStartDate.getFullYear() < 1900){
            this.props.openAlertMessage("Year cannot be lower than 1900.","OK");
            return;
        }
        else if(objectEndDate.getFullYear() < 1900){
            this.props.openAlertMessage("Year cannot be lower than 1900.","OK");
            return;
        }*/

        var roleType = this.state.roleType;
        var RoleTpId = roleType.substring(0, roleType.indexOf(';'));
        var relation = roleType.substring(roleType.indexOf(';')+1);

        var A_ContId = this.state.whoId;
        var B_ContId = this.state.withWhom;
        var TeamId = this.state.withWhatTeam;

        var StartDt = this.state.startDate;
        var EndDt = this.state.endDate;
        var isPrimary = this.state.isPrimary;
        var isCurrent = this.state.isCurrent == "current" ? 1 : 0;
        var Descr = this.state.descr == null ? "" : this.state.descr;

        //alert("SAVE:\nA_ContId: "+A_ContId+"\nB_ContId: "+B_ContId+"\nisPrimary: "+isPrimary+"\nisCurrent: "+isCurrent+"\nDesc: "+Descr+"\nStart date: "+StartDt+"\nEnd date: "+EndDt+"\nRoleTpId: "+RoleTpId+"\nRoleType: "+relation+"\nTeamId: "+TeamId);
        //return;

        var url = "/api/rel/ins";
        if(this.props.actionType == "EditRole"){url = "/api/rel/upd/"+this.props.roleId;}

        Axios.post(url, {
            A_ContId: A_ContId, // id of person to whom I am setting the role
            B_ContId: B_ContId, // id of team or church with whom the person has the role
            RelTpId: RoleTpId, // type role id
            TeamId: TeamId,
            StartDt: StartDt, // start date of the role
            EndDt: EndDt, // end date of the role
            IsPrimary: isPrimary, // boolean if the role is primary
            IsCurrent: isCurrent,
            Descr: Descr,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.closeModal(this.state.editingMode, createAnotherNewAfter);
        });
    }

    searchForChurches = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=4&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundContacts: response.data.result });
            });
        }
    }

    searchForPeople = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=1&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundPeople: response.data.result });
            });
        }
    }

    selectFoundContactA = async (contactId, contactName) => {
        this.setState({ whoId: contactId });
        this.setState({ whoName: contactName });

        this.setState({ showPopupWho: false });
        this.setState({ findA_ContactID: "" });
        this.setState({ foundPeople: [] });
    }

    selectFoundContactB = async (contactId, contactName) => {
        this.setState({ withWhom: contactId });
        this.setState({ withWhomName: contactName });

        this.setState({ showPopupWithWhom: false });
        this.setState({ findB_ContactID: "" });
        this.setState({ foundContacts: [] });
    }

    selectRoleType = async (roleType) => {
        this.setState({ roleType: roleType });

        var RoleTpId = roleType.substring(0, roleType.indexOf(';'));
        var relation = roleType.substring(roleType.indexOf(';')+1);

        for(let oneRow of this.state.roleTypesArray){
            if(oneRow.id == RoleTpId && oneRow.relation == relation){
                this.setState({ roleSelected: oneRow.name });
            }
        }

        this.setState({ showPopupRoleType: false });
    }

    selectTeamType = async (teamId) => {
        this.setState({ withWhatTeam: teamId });

        for(let oneRow of this.state.teamTypesArray){
            if(oneRow.id == teamId){
                this.setState({ withWhatTeamName: oneRow.name });
            }
        }

        this.setState({ showPopupWithWhatTeam: false });
    }

	render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={this.state.modalExpanded}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{this.state.editingMode == "EditRole" ? "Edit Role" : "Add Role"}</span>

                                <div className="horizontalStackCenter">

                                    <SearchPopup
                                        id={"searchContact"}
                                        textName={this.state.whoName}
                                        textStyle={this.state.whoId == -1 ? "italicOrange" : "normalBlue"}
                                        value={this.state.findA_ContactID}
                                        changeValue={(text) => {this.setState({ findA_ContactID: text }); this.searchForPeople(text);}}
                                        foundRecords={this.state.foundPeople}
                                        refPopup={this.refPopupWho}
                                        refInput={this.refInputWho}
                                        showPopup={this.state.showPopupWho}
                                        showHidePopup={() => this.setState({ showPopupWho: !this.state.showPopupWho })}
                                        addContactToArray={(item) => this.selectFoundContactA(item.id,item.first_name+" "+item.last_name)}
                                        showEmailStyle={false}
                                    />

                                    <div style={{width: 10}}></div>

                                    <SearchPopup
                                        id={"searchContact"}
                                        textName={this.state.withWhomName}
                                        textStyle={this.state.withWhom == -1 ? "italicOrange" : "normalBlue"}
                                        value={this.state.findB_ContactID}
                                        changeValue={(text) => {this.setState({ findB_ContactID: text }); this.searchForChurches(text);}}
                                        foundRecords={this.state.foundContacts}
                                        refPopup={this.refPopupWithWhom}
                                        refInput={this.refInputWithWhom}
                                        showPopup={this.state.showPopupWithWhom}
                                        showHidePopup={() => this.setState({ showPopupWithWhom: !this.state.showPopupWithWhom })}
                                        addContactToArray={(item) => this.selectFoundContactB(item.id,item.short_name)}
                                        showEmailStyle={false}
                                    />
                                </div>

                            </div>

                            <div className="verticalStack">
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                                    <div className="flex"></div>
                            </div>
                        </div>

                        <div className="horizontalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 12}}>

                            <div className="horizontalStack flex">

                                <div className="universalPopupSelectContainer" ref={this.refPopupRoleType}>

                                    <div className="universalPopupSelectVisible horizontalStack">
                                        <span className="fontPoppinsRegular13">Role:*</span>
                                        <div style={{width: 5}}></div>
                                        <span className="fontPoppinsRegular13 clickable" style={{color: this.state.roleType == "0;null" ? "var(--jv-orange)" : "var(--blue-button-link)"}} onClick={()=>this.setState({showPopupRoleType: !this.state.showPopupRoleType})}>{this.state.roleSelected}</span>
                                    </div>

                                    <div className="universalPopupSelectHidden" style={{display: this.state.showPopupRoleType ? "" : "none"}}>
                                        <div className="verticalStack" onClick={() => this.setState({showPopupRoleType: false})}>
                                            <span className="fontPoppinsRegular13">Select Role Type</span>

                                            <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                            <div className="verticalStack" onChange={event => this.selectRoleType(event.target.value)}>

                                                {this.state.roleTypesArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                            <input className="clickable" type="radio" id={item.id+""+item.relation+""+item.name} name="relationship_type" value={item.id+";"+item.relation} checked={item.id+";"+item.relation == this.state.roleType ? true : false}/>
                                                            <label for={item.id+""+item.relation+""+item.name} className="fontPoppinsRegular13 clickable" style={{marginLeft: 5}}>{item.name}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack flex">

                                <div className="universalPopupSelectContainer" ref={this.refPopupWithWhatTeam}>

                                    <div className="universalPopupSelectVisible horizontalStack">
                                        <span className="fontPoppinsRegular13">Team:*</span>
                                        <div style={{width: 5}}></div>
                                        <span className="fontPoppinsRegular13 clickable" style={{color: this.state.withWhatTeam == -1 ? "var(--jv-orange)" : "var(--blue-button-link)"}} onClick={()=>this.setState({showPopupWithWhatTeam: !this.state.showPopupWithWhatTeam})}>{this.state.withWhatTeamName}</span>
                                    </div>

                                    <div className="universalPopupSelectHidden" style={{display: this.state.showPopupWithWhatTeam ? "" : "none"}}>
                                        <div className="verticalStack" onClick={() => this.setState({showPopupWithWhatTeam: false})}>
                                            <span className="fontPoppinsRegular13">Select Team Type</span>

                                            <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                            <div className="verticalStack" onChange={event => this.selectTeamType(event.target.value)}>

                                                {this.state.teamTypesArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                            <input className="clickable" type="radio" id={item.id+""+item.name} name="team_type" value={item.id} checked={item.id == this.state.withWhatTeam ? true : false}/>
                                                            <label for={item.id+""+item.name} className="fontPoppinsRegular13 clickable" style={{marginLeft: 5}}>{item.name}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack">
                                <span className="fontPoppinsRegular13">Approximate Start Date:*</span>
                                <div style={{width: 5}}></div>
                                <input className="modalDatePicker fontPoppinsRegular13" type="date" id="start_date" name="start_date" min="1900-01-01" max="9999-01-01" value={this.state.startDate} onChange={e => this.setState({ startDate: e.target.value })}/>
                            </div>

                        </div>

                        <div className="verticalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 2}}>

                            <div className="horizontalStack">

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">Is this their primary role?</span>

                                    <div className="horizontalStack flex" style={{marginTop: 12}} onChange={event => this.setState({ isPrimary: event.target.value })}>
                                        <input type="radio" id="is_primary" name="role_primary" value={"1"} checked={this.state.isPrimary == "1" ? true : false}/>
                                        <label for="is_primary" className="fontPoppinsRegular13">Yes</label>
                                        <div style={{width: 30}}></div>
                                        <input type="radio" id="is_not_primary" name="role_primary" value={"0"} checked={this.state.isPrimary == "0" ? true : false}/>
                                        <label for="is_not_primary" className="fontPoppinsRegular13">No</label>
                                    </div>
                                </div>

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">Are they currently serving in this role?</span>

                                    <div className="horizontalStack flex" style={{marginTop: 12}} onChange={event => this.setState({ isCurrent: event.target.value })}>
                                        <input type="radio" id="current" name="role_current" value="current" checked={this.state.isCurrent == "current" ? true : false}/>
                                        <label for="current" className="fontPoppinsRegular13">Yes</label>
                                        <div style={{width: 30}}></div>
                                        <input type="radio" id="past" name="role_current" value="past" checked={this.state.isCurrent == "past" ? true : false}/>
                                        <label for="past" className="fontPoppinsRegular13">No</label>
                                    </div>
                                </div>

                                <div className="verticalStack">
                                    <div className="horizontalStack" style={{display: this.state.isCurrent == "past" ? "" : "none"}}>
                                        <span className="fontPoppinsRegular13">Approximate End Date:*</span>
                                        <div style={{width: 5}}></div>
                                        <input className="modalDatePicker fontPoppinsRegular13" type="date" id="end_date" name="end_date" min="1900-01-01" max="9999-01-01" value={this.state.endDate} onChange={e => this.setState({ endDate: e.target.value })}/>
                                    </div>
                                </div>

                            </div>

                            <span className="fontPoppinsRegular13" style={{marginTop: 12}}>Description:</span>

                            <textarea className="modalInputAddNote" rows="8" style={{backgroundColor: "white"}} placeholder="Write here anything you think that may help clarify their role." id="textAreaDescr" name="textAreaDescr" value={this.state.descr} onChange={e => this.setState({ descr: e.target.value })}></textarea>

                        </div>

                        <div className="horizontalStack" style={{marginTop: "12px"}}>
                            <RoundButton title={"Save Role"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.saveRoleAction(false)} />
                            <RoundButton title={"Save and Add New"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.saveRoleAction(true)} />
                            {this.props.roleId != -1 && this.props.actionType == "EditRole" ? <RoundButton title={"Delete"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={this.deleteRole} /> : null }
                            <div className="flex"></div>
                            <span className="fontPoppinsItalic13Gray">*Required fields</span>
                        </div>

                    </div>
                </Modal>
            </React.Fragment>
		);
	}

}

export default RolesModal;